<script setup lang="ts">
import { useAlertStore } from '@/stores';
import { SectionContentItemOverview, VocabListDetails } from '@/apiclient';
import { computed, onMounted, ref } from 'vue';
import EditIntroducedVocabModal from '@/components/didactics/EditIntroducedVocabModal.vue';
import { getApiClient } from '@/apiclient/client';
const alertStore = useAlertStore();
const unsavedChanges = ref(false);
const showEditModal = ref(false);
const introducedVocabListInUse = ref<VocabListDetails | null>(null);

const props = defineProps<{
  contentItem: SectionContentItemOverview;
}>();

onMounted(async () => {
  if (!props.contentItem.use_introduced_vocab_list) {
    return;
  }
  const response = await (
    await getApiClient()
  ).courseSectionItems.getIntroducedVocabListOfContentItem(props.contentItem.id);
  introducedVocabListInUse.value = response;
});

const isIntroducedVocabListInUse = computed(() => {
  return introducedVocabListInUse.value !== null;
});

const toggleIntroducedVocab = async (contentItem: SectionContentItemOverview) => {
  if (!isIntroducedVocabListInUse.value) {
    const response = await (
      await getApiClient()
    ).courseSectionItems.addOrActivateVocabIntroToContentItem(contentItem.id, true);
    // TODO this needs to be handled using the store!
    introducedVocabListInUse.value = response;
  } else {
    await (await getApiClient()).courseSectionItems.addOrActivateVocabIntroToContentItem(contentItem.id, false);
    // TODO this needs to be handled using the store!
    introducedVocabListInUse.value = null;
  }
};

const editNewVocab = async () => {
  // TODO fetch introduced_vocab_list from content item

  showEditModal.value = true;
};
</script>

<template>
  <div class="absolute top-1 end-1">
    <div class="flex items-center justify-end gap-8">
      <button
        v-if="isIntroducedVocabListInUse"
        @click="editNewVocab"
        class="px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
      >
        Neue Vokabeln bearbeiten
      </button>

      <label class="flex items-center gap-1 text-sm py-1">
        <input
          type="checkbox"
          class="form-checkbox h-4 w-4 text-blue-600 rounded-md"
          :checked="isIntroducedVocabListInUse"
          @change="toggleIntroducedVocab(contentItem)"
        />
        <span class="text-gray-700 dark:text-gray-200">Diese Übung führt neues Vokabular ein</span>
      </label>
    </div>
  </div>

  <EditIntroducedVocabModal
    v-if="showEditModal"
    :show="showEditModal"
    :content-item-id="contentItem.id"
    :section-id="contentItem.section_id"
    v-model="introducedVocabListInUse"
    @close="showEditModal = false"
  />
</template>
