<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { UserRoundPlus } from 'lucide-vue-next';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  withBottomNav: {
    type: Boolean,
    default: true,
  },
});

const isScrollingDown = ref(false);
let lastScrollPosition = 0;

const handleScroll = (event: Event) => {
  const scrollContainer = event.target as Element;
  const currentScrollPosition = scrollContainer.scrollTop;

  const isAtTop = currentScrollPosition <= 30;
  const isAtBottom = currentScrollPosition + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 30;

  // Determine scroll direction (ignoring bounce backs from top / bottom)
  if (currentScrollPosition > lastScrollPosition && !isAtTop) {
    isScrollingDown.value = true;
  } else if (currentScrollPosition < lastScrollPosition && !isAtBottom) {
    isScrollingDown.value = false;
  }

  lastScrollPosition = currentScrollPosition;
};

onMounted(() => {
  // Find the scrollable container (the one with overflow-auto)
  const scrollContainer = document.querySelector('.overflow-auto') || document.querySelector('.overflow-y-auto');
  if (scrollContainer) {
    scrollContainer.addEventListener('scroll', handleScroll, { passive: true });
  }
});

onUnmounted(() => {
  const scrollContainer = document.querySelector('.overflow-auto') || document.querySelector('.overflow-y-auto');
  if (scrollContainer) {
    scrollContainer.removeEventListener('scroll', handleScroll);
  }
});

const router = useRouter();

const buttonClasses = computed(() => {
  const classes = [
    'flex bg-black text-white rounded-full text-base font-semibold pointer-events-auto cursor-pointer transition-all duration-300 px-4 py-3',
  ];

  return classes;
});
</script>

<template>
  <div
    :class="[
      'fixed z-20 left-0 flex justify-center w-full items-center mb-6 pointer-events-none select-none',
      props.withBottomNav ? 'bottom-16' : 'bottom-0',
      { 'opacity-40': isScrollingDown },
    ]"
    @click="
      async () => {
        await router.push({ name: 'all-cases' });
      }
    "
  >
    <div :class="buttonClasses">
      <UserRoundPlus :size="20" />
      <span
        class="transform -translate-y-[1px] transition-all duration-300 ease-in select-none"
        :class="['overflow-hidden whitespace-nowrap', { collapsed: isScrollingDown, expanded: !isScrollingDown }]"
        >{{ $t('message.startNewCase') }}</span
      >
    </div>
  </div>
</template>

<style scoped>
.collapsed {
  max-width: 0;
  margin-left: 0;
  opacity: 0;
  transition: all 300ms ease-out;
}
.expanded {
  max-width: 200px; /* Large enough to accommodate different text lengths */
  margin-left: 0.5rem;
  opacity: 1;
  transition: all 300ms ease-out;
}
</style>
