<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { MailIcon } from 'lucide-vue-next';

const props = defineProps({
  type: {
    type: String,
    default: 'library',
  },
});

const { t } = useI18n();
const router = useRouter();

const emptyStateMessage = computed(() => {
  return props.type === 'library' ? t('course.noCourses') : t('course.noEnrolledCourses');
});

const emptyStateDescription = computed(() => {
  return props.type === 'library' ? t('course.noCoursesDescription') : t('course.noEnrolledCoursesDescription');
});
</script>

<template>
  <div class="flex flex-col items-center justify-center h-full w-full px-4 py-12 text-center">
    <div class="max-w-md">
      <svg
        class="mx-auto h-24 w-24 text-gray-400 mb-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        />
      </svg>

      <h3 class="text-xl font-medium text-gray-900 dark:text-white">
        {{ emptyStateMessage }}
      </h3>

      <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">
        {{ emptyStateDescription }}
      </p>

      <div class="mt-6" v-if="props.type === 'my-courses'">
        <a
          class="inline-flex cursor-pointer bg-black items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          href="mailto:hello@casuu.care"
        >
          <MailIcon class="w-4 h-4 mr-2" />
          {{ t('course.eMail') }}
        </a>
      </div>
    </div>
  </div>
</template>
