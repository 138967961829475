<script setup lang="ts">
import { computed, PropType, ref } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, watch } from 'vue';

import { useI18n } from 'vue-i18n';
import { useExerciseLifecycle } from '@/composables';
import { SectionContentItemOverview } from '@/apiclient';
const { t } = useI18n();

// props
const props = defineProps({
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
});

// emits
const emit = defineEmits(['addNewVocab']);

// composables
const { handleExerciseFinished, fetchInteractionState, reopenExercise, itemInteractionState, fetchCompleted } =
  useExerciseLifecycle({
    contentItem: props.contentItem,
    sectionIndex: props.sectionIndex,
    pageIndex: props.pageIndex,
    isEditing: false,
    emit,
    onComplete: (response) => {
      itemInteractionState.value = response.section_content_item_interaction;
    },
  });

// stores
const courseInteractionStore = useCourseInteractionStore();
const alertStore = useAlertStore();
const authStore = useAuthStore();

// refs
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

// computed
const textCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

onMounted(async () => {
  await fetchInteractionState();
});

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    await nextTick();
    await fetchInteractionState();
  },
  { immediate: true },
);
</script>

<template>
  <button
    v-if="fetchCompleted"
    type="button"
    class="flex w-full bg-black text-white rounded-full text-base font-semibold pointer-events-auto cursor-pointer transition-all duration-300 px-4 py-3 justify-center items-center"
    @click.capture="() => handleExerciseFinished()"
    :class="{
      'opacity-100 hover:opacity-90': !textCompleted,
      'opacity-40 cursor-default': textCompleted,
    }"
  >
    <span v-show="!textCompleted" class="material-symbols-outlined block group-hover:hidden"> circle </span>
    <span v-show="!textCompleted" class="material-symbols-outlined hidden group-hover:block"> task_alt </span>
    <span v-show="textCompleted" translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
    <span class="ml-2">{{ textCompleted ? 'Gelesen' : 'Gelesen' }}</span>
  </button>
</template>

<style scoped></style>
