<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, nextTick, onMounted } from 'vue';
import {
  useCaseInteractionStore,
  useCaseStore,
  useCompanionInteractionStore,
  usePatientInteractionStore,
} from '@/stores';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import ChatBubbleUser from '@/components/chat_bubbles/ChatBubbleUser.vue';
import ChatBubbleNpc from '@/components/chat_bubbles/ChatBubbleNpc.vue';
import ChatBubbleCompanion from '@/components/chat_bubbles/ChatBubbleCompanion.vue';
import ChatBubbleInfo from '@/components/chat_bubbles/ChatBubbleInfo.vue';
import UndoInteractionButton from '@/components/case_interaction/UndoInteractionButton.vue';
import ImproveUserMessageButton from '@/components/case_interaction/ImproveUserMessageButton.vue';
import { InteractionMessage, InfoMessage, ExtendedMessageType } from '@/helper/typing';
import { SubtaskInteraction, TaskInteraction } from '@/apiclient';
import { storeToRefs } from 'pinia';
import CaseLineup from '@/components/case_interaction/CaseLineup.vue';
import gsap from 'gsap';
import { TransitionGroup } from 'vue';
import CaseInteractionService from '@/services/CaseInteractionService';
import { useI18n } from 'vue-i18n';
import UserMessageRecommendation from './UserMessageRecommendation.vue';

const props = defineProps({
  selectedPerson: {
    type: Object,
    required: true,
  },
  showFullHistory: {
    type: Boolean,
    default: false,
  },
  canUndo: {
    type: Boolean,
    default: true,
  },
  canRequestImprove: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['undoInteraction', 'improveUserMessage']);

// Store refs
const patientInteractionStore = usePatientInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const companionInteractionStore = useCompanionInteractionStore();
const { currentTask } = storeToRefs(caseInteractionStore);
const caseStore = useCaseStore();
const { t } = useI18n();

// State
const messagesToRemove = ref(new Set());
const seenUndoneMessages = ref(new Set());
const messagesToRestore = ref(new Set());
const lastNpcMessageFromEnd = ref(-1);
const lastChatBubbleUser = ref<InstanceType<typeof ChatBubbleUser> | null>(null);
const lastUserRecommendation = ref<InstanceType<typeof UserMessageRecommendation> | null>(null);
const scrollContainer = ref<HTMLElement | null>(null);
const userHasScrolled = ref(false);
const isScrolledToBottom = ref(true);
const collapsedScrollContainer = ref<HTMLElement | null>(null);
const isUndoing = ref(false);

// Helper functions
function stringNotEmptyOrNull(str: string | null): boolean {
  return str != null && str.trim() !== '';
}

// Computed
const chatMessages = computed<(InteractionMessage | InfoMessage)[]>(() => {
  if (!props.selectedPerson) return [];

  // Start with patient messages
  let allMessages: InteractionMessage[] | InfoMessage[] = patientInteractionStore.chatMessages.map((msg) => ({
    id: msg.id,
    type: msg.type,
    content: msg.content,
    language: msg.language ?? null,
    translations: msg.translations ?? null,
    created_at: msg.created_at,
    undone_at: msg.undone_at,
  }));

  // Add third person messages if they exist
  for (let chat of thirdPersonInteractionStore.chatMessages) {
    allMessages = allMessages.concat(...chat);
  }

  // Add companion messages
  allMessages = allMessages.concat(
    companionInteractionStore.chatMessages.map((msg) => ({
      id: msg.id,
      type: msg.type,
      content: msg.content,
      language: msg.language ?? null,
      translations: msg.translations ?? null,
      created_at: msg.created_at,
      undone_at: null, // cannot be undone
    })),
  );

  // Add info messages
  allMessages = allMessages.concat(infoMessages.value);

  // Sort messages by timestamp
  return allMessages.sort((a, b) => {
    if (a.created_at === 'incomplete') return 1;
    if (b.created_at === 'incomplete') return -1;
    const timeA = new Date(a.created_at).getTime();
    const timeB = new Date(b.created_at).getTime();
    return timeA - timeB;
  });
});

const infoMessages = computed(() => {
  if (!caseInteractionStore.currentCaseInteraction?.task_interactions) {
    return [];
  }

  return caseInteractionStore.currentCaseInteraction?.task_interactions
    .flatMap((taskInteraction: TaskInteraction) => taskInteraction.subtask_interactions || [])
    .filter(
      (subtaskInteraction: SubtaskInteraction): subtaskInteraction is SubtaskInteraction =>
        subtaskInteraction.completed_at !== null,
    )
    .map((subtaskInteraction: SubtaskInteraction) => {
      const taskInteractionId = subtaskInteraction.task_interaction_id;
      const taskInteraction = caseInteractionStore.currentCaseInteraction?.task_interactions.find(
        (ti: TaskInteraction) => ti.id === taskInteractionId,
      );
      const taskIndex = taskInteraction?.index;
      const task = caseInteractionStore.currentCase?.tasks?.[taskIndex];

      const thisSubtaskTarget = task?.subtasks?.[subtaskInteraction.index]?.details.target;

      // Find next incomplete subtask
      let nextSubtask = CaseInteractionService.getNextSubtask(subtaskInteraction, caseInteractionStore);
      let nextSubtaskTarget = nextSubtask?.details?.target;

      return {
        type: 'SUBTASK_COMPLETION' as ExtendedMessageType,
        content: {
          info_message: t('message.goodJobGoalChecked'),
          this_target: thisSubtaskTarget,
          next_target: nextSubtaskTarget,
        },
        created_at: subtaskInteraction.completed_at!,
        undone_at: null,
      } satisfies InfoMessage;
    });
});

const isStreaming = computed(() => {
  return (
    caseInteractionStore.someChatIsStreaming ||
    caseInteractionStore.isReplaying ||
    companionInteractionStore.chatIsStreaming
  );
});

const displayMessages = computed(() => {
  console.log('displayMessages - chatMessages = ', chatMessages.value);
  if (!chatMessages.value) return [];
  if (isUndoing.value && !props.showFullHistory) return [];
  const visibleMessages = chatMessages.value.filter((msg) => msg && !msg.undone_at);

  let numMessagesToShowWhenCollapsed = 1;
  let lastMessage = visibleMessages.length > 0 ? visibleMessages[visibleMessages.length - 1] : null;
  let messageBeforeLastMessage = visibleMessages.length > 1 ? visibleMessages[visibleMessages.length - 2] : null;
  let messageBeforeMessageBeforeLastMessage =
    visibleMessages.length > 2 ? visibleMessages[visibleMessages.length - 3] : null;

  if (
    lastMessage &&
    messageBeforeLastMessage &&
    (lastMessage.type === 'SUBTASK_COMPLETION' ||
      lastMessage.type === 'COMPANION_SAY' ||
      lastMessage.type === 'COMPANION_REACT')
  ) {
    // last message is non-NPC, so additional show that one
    numMessagesToShowWhenCollapsed = 2;
  }

  if (
    numMessagesToShowWhenCollapsed === 2 &&
    messageBeforeLastMessage &&
    messageBeforeMessageBeforeLastMessage &&
    (messageBeforeLastMessage.type === 'SUBTASK_COMPLETION' ||
      messageBeforeLastMessage.type === 'COMPANION_SAY' ||
      messageBeforeLastMessage.type === 'COMPANION_REACT') &&
    messageBeforeMessageBeforeLastMessage.type !== 'SUBTASK_COMPLETION' &&
    messageBeforeMessageBeforeLastMessage.type !== 'COMPANION_SAY' &&
    messageBeforeMessageBeforeLastMessage.type !== 'COMPANION_REACT'
  ) {
    // message before is also non-NPC, but the one before finally is NPC, so show that one as well
    numMessagesToShowWhenCollapsed = 3;
  }

  console.log('numMessagesToShowWhenCollapsed = ', numMessagesToShowWhenCollapsed);

  let messages = props.showFullHistory
    ? visibleMessages
    : visibleMessages.slice(-numMessagesToShowWhenCollapsed).filter(Boolean);
  console.log('messages = ', messages);
  lastNpcMessageFromEnd.value = numMessagesToShowWhenCollapsed;
  return messages;
});

const lastUserMessageIndex = computed(() => {
  if (!displayMessages.value) return -1;
  // Find the last message that has a user_message and is not undone
  for (let i = displayMessages.value.length - 1; i >= 0; i--) {
    const message = displayMessages.value[i];
    if (stringNotEmptyOrNull(message.content['user_message']) && !message.undone_at) {
      return i;
    }
  }
  return -1;
});

const lastNpcMessageIndex = computed(() => {
  if (!displayMessages.value) return -1;
  return displayMessages.value.length - lastNpcMessageFromEnd.value;
});

// Animation handlers
function handleMessageRemoval(messageId) {
  messagesToRemove.value.add(messageId);
  setTimeout(() => {
    messagesToRemove.value.delete(messageId);
  }, 800);
}

function handleMessageRestoration(messageId) {
  messagesToRestore.value.add(messageId);
  setTimeout(() => {
    messagesToRestore.value.delete(messageId);
  }, 800);
}

// Initialize seenUndoneMessages
onBeforeMount(() => {
  if (chatMessages.value) {
    chatMessages.value.forEach((message) => {
      if (message?.undone_at) {
        seenUndoneMessages.value.add(message.id);
      }
    });
  }
});

// Watch for changes to messages that become undone
watch(
  () => chatMessages.value,
  (newMessages, oldMessages) => {
    if (!oldMessages) return;
    newMessages.forEach((message) => {
      if (message && message.undone_at && !seenUndoneMessages.value.has(message.id)) {
        seenUndoneMessages.value.add(message.id);
        handleMessageRemoval(message.id);
      }
      if (message && !message.undone_at && seenUndoneMessages.value.has(message.id)) {
        seenUndoneMessages.value.delete(message.id);
        handleMessageRestoration(message.id);
      }
    });
  },
  { deep: true, immediate: true },
);

const getConversationName = (message) => {
  if (message.type === 'SAY' || message.type === 'REACT') {
    return 'PATIENT';
  } else if (message.type === 'THIRD_PERSON' || message.type === 'THIRD_PERSON_REACT') {
    return 'ATTENDING';
  }
  return 'SYSTEM';
};

const getPersonIndex = (message) => {
  if (message.type === 'THIRD_PERSON' || message.type === 'THIRD_PERSON_REACT') {
    return props.selectedPerson.thirdPersonIndex;
  }
  return null;
};

function undoInteraction() {
  console.log('undoInteraction');
  emit('undoInteraction');
  isUndoing.value = true;
  setTimeout(() => {
    isUndoing.value = false;
  }, 800);
}

function improveUserMessage() {
  if (!lastUserRecommendation.value) return;
  lastUserRecommendation.value.toggleTranslation();
}

const handleNewLastChatBubble = (el: InstanceType<typeof ChatBubbleUser>, message: InteractionMessage) => {
  /*
    Set the ref and animate if for new user input message
    el: the chat bubble element
    message: the message object
  */

  // set ref
  console.log('messageId = ', message.id);
  if (el) {
    lastChatBubbleUser.value = el;
  }

  // animate
  if (el && message.id === 'not_yet_in_database') {
    console.log('target: ', el.$el);
    gsap.from(el.$el, {
      duration: 0.85,
      scale: 0,
      opacity: 0,
      filter: 'blur(2px)',
      ease: 'elastic.out(1, 0.75)',
      transformOrigin: 'top right',
    });
  }
};

const handleNewLastUserRecommendation = (el: InstanceType<typeof UserMessageRecommendation>) => {
  if (el) {
    lastUserRecommendation.value = el;
  }
};

const scrollToBottom = () => {
  const container = props.showFullHistory ? scrollContainer.value : collapsedScrollContainer.value;
  if (!container) return;
  container.scrollTop = container.scrollHeight;
};

const scrollToBottomIfSticking = () => {
  if (userHasScrolled.value && !isScrolledToBottom.value) return;

  nextTick(() => {
    scrollToBottom();
  });
};

const handleScroll = () => {
  const container = props.showFullHistory ? scrollContainer.value : collapsedScrollContainer.value;
  if (!container) return;

  const { scrollTop, scrollHeight, clientHeight } = container;
  const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;

  isScrolledToBottom.value = isAtBottom;
  userHasScrolled.value = !isAtBottom;
};

watch(
  // watch for displayMessages changes, scroll to bottom
  () => displayMessages.value,
  () => scrollToBottomIfSticking(),
  { deep: true },
);

watch(
  () => caseInteractionStore.currentWordIndex,
  () => scrollToBottomIfSticking(),
  { deep: true },
);

onMounted(() => {
  scrollToBottom();
});
</script>

<template>
  <div class="grow h-full flex flex-col">
    <div
      ref="scrollContainer"
      class="scroll-container relative flex-1 overflow-y-auto px-2"
      :class="{ 'chat-history-container': showFullHistory }"
      @scroll="handleScroll"
    >
      <div v-if="showFullHistory" class="p-4">
        <CaseLineup :showDividers="true" />
      </div>

      <!-- collapsed history -->
      <div v-if="!showFullHistory" class="relative flex flex-col justify-end min-h-full">
        <div ref="collapsedScrollContainer" class="collapsed-history-container overflow-y-auto" @scroll="handleScroll">
          <TransitionGroup name="message-transition" tag="div">
            <div
              v-for="(message, index) in displayMessages.filter((msg) => !msg.undone_at)"
              :key="message.id"
              class="message-item"
            >
              <div>
                <div
                  v-if="
                    message.type !== 'SUBTASK_COMPLETION' &&
                    stringNotEmptyOrNull((message as InteractionMessage).content['user_message']) &&
                    message.type !== 'COMPANION_SAY' &&
                    message.type !== 'COMPANION_REACT'
                  "
                  class="relative flex w-full gap-x-2 ml-auto justify-end items-center"
                  :class="{
                    'user-message-long':
                      (message as InteractionMessage).content['user_message'] &&
                      (message as InteractionMessage).content['user_message'].length > 40,
                  }"
                >
                  <div class="button-container">
                    <ImproveUserMessageButton
                      @improveUserMessage="improveUserMessage"
                      :enabled="props.canRequestImprove && message.id !== 'not_yet_in_database'"
                      :in-full-history="true"
                      :animate="message.id === 'not_yet_in_database'"
                      v-if="
                        !message.undone_at &&
                        stringNotEmptyOrNull(message.content['user_message']) &&
                        index === lastUserMessageIndex
                      "
                      class="action-button"
                    />
                    <UndoInteractionButton
                      @undoInteraction="undoInteraction"
                      :enabled="props.canUndo"
                      :in-full-history="true"
                      :animate="message.id === 'not_yet_in_database'"
                      v-if="
                        !message.undone_at &&
                        stringNotEmptyOrNull(message.content['user_message']) &&
                        index === lastUserMessageIndex
                      "
                      class="action-button ml-auto"
                    />
                  </div>
                  <ChatBubbleUser
                    :message="message as InteractionMessage"
                    :conversation="getConversationName(message)"
                    :ref="
                      (el: any) => {
                        if (index === lastUserMessageIndex) {
                          handleNewLastChatBubble(el, message);
                        }
                      }
                    "
                  />
                </div>
                <div
                  v-if="
                    message.type !== 'SUBTASK_COMPLETION' &&
                    stringNotEmptyOrNull((message as InteractionMessage).content['user_message']) &&
                    message.type !== 'COMPANION_SAY' &&
                    message.type !== 'COMPANION_REACT'
                  "
                >
                  <UserMessageRecommendation
                    :message="message"
                    :collapse-if-no-translation="true"
                    :conversation="getConversationName(message)"
                    :ref="
                      (el: any) => {
                        if (index === lastUserMessageIndex) {
                          handleNewLastUserRecommendation(el);
                        }
                      }
                    "
                  />
                </div>
                <div v-if="message.type === 'SUBTASK_COMPLETION'">
                  <ChatBubbleInfo :message="message as InfoMessage" />
                </div>
                <div v-else-if="message.type === 'COMPANION_SAY' || message.type === 'COMPANION_REACT'" class="pr-16">
                  <ChatBubbleCompanion :message="message as InteractionMessage" />
                </div>
                <div v-else class="pr-16">
                  <ChatBubbleNpc
                    :message="message as InteractionMessage"
                    :isLatestBubble="index === lastNpcMessageIndex"
                    :personType="getConversationName(message)"
                    :personIndex="getPersonIndex(message)"
                    :companion-in-footer="true"
                    :reserve-space-for-toggle="true"
                  />
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>
      <!-- End of collapsed history -->

      <!-- Full history -->
      <div v-else class="pb-24">
        <TransitionGroup name="message-transition" tag="div">
          <div
            v-for="(message, index) in displayMessages.filter((msg) => !msg.undone_at)"
            :key="message.id"
            class="message-item"
          >
            <div>
              <div
                v-if="
                  message.type !== 'SUBTASK_COMPLETION' &&
                  stringNotEmptyOrNull((message as InteractionMessage).content['user_message']) &&
                  message.type !== 'COMPANION_SAY' &&
                  message.type !== 'COMPANION_REACT'
                "
                class="flex w-full gap-x-2 ml-auto justify-end items-center"
                :class="{
                  'user-message-long':
                    (message as InteractionMessage).content['user_message'] &&
                    (message as InteractionMessage).content['user_message'].length > 40,
                }"
              >
                <div class="button-container">
                  <UndoInteractionButton
                    @undoInteraction="undoInteraction"
                    :enabled="props.canUndo"
                    :in-full-history="true"
                    :animate="message.id === 'not_yet_in_database'"
                    v-if="
                      !message.undone_at &&
                      stringNotEmptyOrNull(message.content['user_message']) &&
                      index === lastUserMessageIndex
                    "
                    class="action-button"
                  />
                  <ImproveUserMessageButton
                    @improveUserMessage="improveUserMessage"
                    :enabled="props.canRequestImprove && message.id !== 'not_yet_in_database'"
                    :in-full-history="true"
                    :animate="message.id === 'not_yet_in_database'"
                    v-if="
                      !message.undone_at &&
                      stringNotEmptyOrNull(message.content['user_message']) &&
                      index === lastUserMessageIndex
                    "
                    class="action-button"
                  />
                </div>
                <ChatBubbleUser
                  :message="message as InteractionMessage"
                  :conversation="getConversationName(message)"
                  :ref="
                    (el) => {
                      if (index === lastUserMessageIndex) {
                        handleNewLastChatBubble(el, message);
                      }
                    }
                  "
                />
              </div>
              <div
                v-if="
                  message.type !== 'SUBTASK_COMPLETION' &&
                  stringNotEmptyOrNull((message as InteractionMessage).content['user_message']) &&
                  message.type !== 'COMPANION_SAY' &&
                  message.type !== 'COMPANION_REACT'
                "
              >
                <UserMessageRecommendation
                  :message="message"
                  :collapse-if-no-translation="true"
                  :conversation="getConversationName(message)"
                  :ref="
                    (el: any) => {
                      if (index === lastUserMessageIndex) {
                        handleNewLastUserRecommendation(el);
                      }
                    }
                  "
                />
              </div>
              <div v-if="message.type === 'COMPANION_SAY' || message.type === 'COMPANION_REACT'" class="pr-16">
                <ChatBubbleCompanion :message="message as InteractionMessage" />
              </div>
              <div v-else-if="message.type === 'SUBTASK_COMPLETION'">
                <ChatBubbleInfo :message="message as InfoMessage" />
              </div>
              <div v-else class="pr-16">
                <ChatBubbleNpc
                  :message="message as InteractionMessage"
                  :isLatestBubble="index === lastNpcMessageIndex"
                  :personType="getConversationName(message)"
                  :personIndex="getPersonIndex(message)"
                  :companion-in-footer="true"
                />
              </div>
            </div>
          </div>
        </TransitionGroup>
      </div>
      <!-- End of full history -->
    </div>
  </div>
</template>

<style scoped>
.scroll-container {
  scroll-behavior: smooth;
  position: relative;
}

.chat-history-container {
  scroll-behavior: smooth;
}

/* height matching the bottom padding from :style="{ paddingBottom: 'calc(64px + 1rem)' }", plus another 20 px for the top buttons and blur */
.collapsed-history-container {
  max-height: calc(100vh - 64px - 1rem - 20px);
  padding-top: 150px; /* we want to be able to scroll the buttons down from the top-fade, e.g. to use "boost" or "undo" */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.collapsed-history-container::-webkit-scrollbar {
  display: none;
}

.animate-pop-out {
  animation: pop-out 800ms ease-in forwards;
}

.animate-pop-in {
  animation: pop-out 800ms ease-in reverse;
}

@keyframes pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  62.5% {
    transform: scale(0);
    opacity: 0;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  100% {
    transform: scale(0);
    opacity: 0;
    height: 0;
    margin-top: 0;
  }
}

.button-container {
  display: flex;
  gap: 0.5rem;

  /* Default: horizontal layout for short messages */
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  min-width: fit-content;
  align-self: center;
}

/* Vertical layout for long messages */
.user-message-long .button-container {
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  height: auto;
}

.action-button {
  flex: 0 0 auto;
}

/* Vue transition animations */
.message-transition-enter-active {
  animation: pop-in 0.5s ease-out;
}

.message-transition-leave-active {
  animation: pop-out 0.5s ease-in;
  position: absolute;
  width: 100%;
}

.message-item {
  transition: all 0.5s;
  position: relative;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
</style>
