<script setup lang="ts">
import { defineProps, defineEmits, ref, nextTick } from 'vue';

const props = defineProps({
  users: {
    type: Array,
    required: true,
  },
  highlightedUser: {
    type: String,
    default: null,
  },
  userHasRegistrationDate: {
    type: Function,
    default: () => true,
  },
  getColorForUser: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['highlight', 'clearHighlight']);

const isLocked = ref(false);
const willClearHighlightTimeout = ref<NodeJS.Timeout | null>(null);

function highlightUser(userId: string) {
  emit('highlight', userId);
}

function clearHighlight() {
  emit('clearHighlight');
}

const lockOrReleaseHighlight = async (userId: string) => {
  isLocked.value = !isLocked.value;
  if (userId === props.highlightedUser) {
    clearHighlight();
  } else {
    highlightUser(userId);
    await nextTick();
    isLocked.value = true;
  }
};

function handleMouseEnter(userId: string) {
  if (isLocked.value) return;
  if (willClearHighlightTimeout.value) {
    clearTimeout(willClearHighlightTimeout.value);
    willClearHighlightTimeout.value = null;
  }
  highlightUser(userId);
}

function handleMouseLeave() {
  if (isLocked.value) return;
  willClearHighlightTimeout.value = setTimeout(clearHighlight, 200);
  // we use this timeout to delay the clearing of the highlight when rapidly hovering over the list
}
</script>

<template>
  <div class="bg-white p-4 rounded-lg shadow overflow-auto">
    <h2 class="text-lg font-semibold mb-4">{{ $t('Students') }}</h2>
    <ul class="space-y-2">
      <li
        v-for="user in users"
        :key="user.id"
        class="p-2 hover:bg-gray-100 rounded cursor-pointer"
        :class="{ 'bg-gray-100': highlightedUser === user.id }"
        @mouseenter="handleMouseEnter(user.id)"
        @click="lockOrReleaseHighlight(user.id)"
        @mouseleave="handleMouseLeave"
      >
        <span v-if="userHasRegistrationDate(user.id)" :style="{ color: props.getColorForUser(user.id) }">
          {{ user.name }}
        </span>
      </li>
    </ul>
  </div>
</template>
