<script setup lang="ts">
import TextEditor from '@/views/courses/TextEditor.vue';
import TextFinishedButton from '@/components/didactics/text/TextFinishedButton.vue';
import DOMPurify from 'dompurify';
import { onMounted, ref, watch, PropType } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useCourseStore } from '@/stores';
import { onBeforeRouteLeave } from 'vue-router';
import { useExerciseLifecycle, useAutosave } from '@/composables';
import { SectionContentItemOverview } from '@/apiclient';

// props
const props = defineProps({
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  idSuffixForTesting: {
    type: [String, null],
    required: false,
    default: null,
  },
});

// emits
const emit = defineEmits(['addNewVocab']);

// stores
const courseStore = useCourseStore();
const alertStore = useAlertStore();

// refs
const contentEditor = ref(null);

// composables
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!contentEditor.value) return;

    const content = contentEditor.value.getHtmlContent();

    await courseStore.updateTextItem(props.sectionId, props.contentItem.id, content);

    contentEditor.value.resetEmitState();
    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

const sanitize = (content: string) => {
  return DOMPurify.sanitize(content);
};

const getHtmlContent = () => {
  if (!contentEditor.value) return '';
  return contentEditor.value.getHtmlContent();
};

defineExpose({
  getHtmlContent,
});
</script>

<template>
  <div class="p-2">
    <TextEditor
      ref="contentEditor"
      :content="sanitize(<string>contentItem.text_item?.content ?? 'invalid text item')"
      :allowList="true"
      :allowTable="true"
      :allowEdit="<boolean>props.isEditing"
      class="text-base"
      @unsavedChanges="
        () => {
          unsavedChanges = true;
        }
      "
      @changesCleared="unsavedChanges = false"
      :data-testid="`text-item-${props.idSuffixForTesting}`"
    />

    <div class="mt-8 px-2">
      <TextFinishedButton
        v-if="!props.isEditing"
        :contentItem="contentItem"
        :sectionIndex="<number>props.sectionIndex"
        :pageIndex="<number>props.pageIndex"
        @addNewVocab="emit('addNewVocab')"
        class="w-full py-3 text-base"
      />
    </div>
  </div>
</template>

<style scoped></style>
