import { defineStore, StoreDefinition, storeToRefs } from 'pinia';
import { Case, CaseInteraction, Task, Subtask, CaseInteractionDetailed } from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { usePatientInteractionStore } from '@/stores/patientInteraction.store';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import { useAuthStore } from '@/stores/auth.store';
import { useAlertStore } from '@/stores/alert.store';
import { useCaseStore } from '@/stores/case.store';
import { nextTick } from 'vue';
import { useCompanionInteractionStore } from './companionInteraction.store';
import CaseInteractionService from '@/services/CaseInteractionService';

interface SubtaskInteraction {
  id: string;
  user_id: string;
  task_interaction_id: string;
  index: number;
  created_at: string;
  completed_at: string | null;
}

interface TaskInteraction {
  id: string;
  user_id: string;
  case_interaction_id: string;
  index: number;
  created_at: string;
  started_at: string | null;
  left_at: string | null;
  revisited_at: string | null;
  completed_at: string | null;
  subtask_interactions: SubtaskInteraction[];
}

interface State {
  currentCase: Case | null;
  currentCaseInteraction: CaseInteractionDetailed | null;
  allUserCaseInteractions: CaseInteraction[];
  currentLearningObjectives: object;
  currentTaskIndex: number | null;
  previousTaskIndex: number | null;
  selectedPersonIndex: number | null;

  currentlyReadingChatMessageText: string;
  readingQueue: object[];
  isProcessingReadingChunk: boolean;

  audioIsStreaming: boolean;
  audioAutoplay: boolean;
  awaitingReactionOrDesc: boolean;

  waitingForResponse: boolean; // halt next reactions etc. until request is sent and before response stream begins
  waitingForAudioPlayback: boolean; // halt next reactions etc. until audio playback is finished
  firstAudioChunkReceived: boolean;

  // audio-synced message / subtitle display
  subtitleWords: Array<{ word: string; startTime: number; endTime: number }>;
  currentWordIndex: number;
  totalDuration: number;

  isReplaying: boolean;
  numSubtasksCompletedLately: number;
}

export const useCaseInteractionStore: StoreDefinition<'caseInteraction', State> = defineStore({
  id: 'caseInteraction',
  state: (): State => ({
    currentCase: null as Case | null,
    currentCaseInteraction: null as CaseInteractionDetailed | null,
    allUserCaseInteractions: [] as CaseInteraction[],
    currentLearningObjectives: [],
    currentTaskIndex: null as number | null,
    previousTaskIndex: null as number | null,
    selectedPersonIndex: null as number | null,

    currentlyReadingChatMessageText: '',
    readingQueue: [],
    isProcessingReadingChunk: false,

    audioIsStreaming: false,
    audioAutoplay: true,
    awaitingReactionOrDesc: false,

    waitingForResponse: true,
    waitingForAudioPlayback: false,
    firstAudioChunkReceived: false,

    subtitleWords: [],
    currentWordIndex: -1,
    totalDuration: 0,

    isReplaying: false,
    numSubtasksCompletedLately: 0,
  }),
  getters: {
    currentTaskIsLastTask(state) {
      return state.currentTaskIndex === state.currentCaseInteraction?.task_interactions.length - 1;
    },
    caseIsFinished(state) {
      return state.currentCaseInteraction?.solved_at;
    },
    audioAutoplayEnabled(state) {
      return state.audioAutoplay;
    },
    currentCaseInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.id : null;
    },
    currentObserverInteractionId(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.observer_interaction.id : '';
    },
    currentCaseInteractionLanguage(state) {
      return state.currentCaseInteraction ? state.currentCaseInteraction.case_language : null;
    },
    anamnesisFinished(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.anamnesis_finished_at);
    },
    patientInteractionFinished(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.patient_interaction_finished_at);
    },
    reportFinished(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.report_finished_at);
    },
    solved(state) {
      return !!(state.currentCaseInteraction && state.currentCaseInteraction.solved_at);
    },
    currentCaseInteractionTasks(state) {
      return !!state.currentCase ? state.currentCase.tasks : [];
    },
    currentTask(state: State): Task | null {
      return state.currentTaskIndex !== null && state.currentCase
        ? state.currentCase.tasks[state.currentTaskIndex]
        : null;
    },
    currentTaskInteraction(state: State): TaskInteraction | null {
      return state.currentTaskIndex !== null && state.currentCaseInteraction
        ? state.currentCaseInteraction.task_interactions[state.currentTaskIndex]
        : null;
    },
    previousTask(state: State): Task | null {
      return state.previousTaskIndex !== null && state.currentCase
        ? state.currentCase.tasks[state.previousTaskIndex]
        : null;
    },
    currentSubtaskInteraction(state: State): SubtaskInteraction | null {
      if (!state.currentCase || state.currentTaskIndex === null) {
        return null;
      }

      const currentTask = state.currentCase.tasks[state.currentTaskIndex];
      if (!currentTask) {
        return null;
      }

      // Get current task interaction to check completed subtasks
      const currentTaskInteraction = state.currentCaseInteraction?.task_interactions[state.currentTaskIndex];
      if (!currentTaskInteraction) {
        return null;
      }

      // Find first subtask that hasn't been completed
      const uncompletedSubtaskInteraction = currentTaskInteraction.subtask_interactions.find(
        (subtask: SubtaskInteraction) => subtask.completed_at === null,
      );

      return uncompletedSubtaskInteraction;
    },
    currentSubtask(state: State): Subtask | null {
      const store = useCaseInteractionStore();
      return CaseInteractionService.getSubtaskForSubtaskInteraction(store.currentSubtaskInteraction, store);
    },
    studentRole(state) {
      return state.currentCase?.student_details.role;
    },
    currentTaskInvolvements(state) {
      return;
    },
    somthingIsStreaming(state) {
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      const caseInteractionStore = useCaseInteractionStore();

      return (
        caseInteractionStore.waitingForResponse ||
        caseInteractionStore.awaitingReactionOrDesc ||
        caseInteractionStore.audioIsStreaming ||
        patientInteractionStore.chatIsStreaming ||
        patientInteractionStore.descIsStreaming ||
        patientInteractionStore.labIsStreaming ||
        patientInteractionStore.examinationIsStreaming ||
        thirdPersonInteractionStore.anyChatIsStreaming
      );
    },
    someChatIsStreaming(state) {
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      return patientInteractionStore.chatIsStreaming || thirdPersonInteractionStore.anyChatIsStreaming;
    },
    allPersons(state, index: number | null = null) {
      console.log('allPersons');
      if (index == null && state.currentTaskIndex == null) {
        console.log('returning null list');
        return [null, null, null];
      }
      let store = useCaseInteractionStore();
      return store.getPersons(state.currentTaskIndex);
    },
    selectedPerson(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      return state.allPersons[state.selectedPersonIndex];
    },
    selectedPersonMaxImage(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      return (
        state.allPersons[state.selectedPersonIndex].profileImageExtraLarge ||
        state.allPersons[state.selectedPersonIndex].profileImageLarge
      );
    },
    unselectedPersons(state) {
      if (state.selectedPersonIndex == null || !state.currentCaseInteraction?.case.persons) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      // return everything except at the index caseInteractionStore.selectedPersonIndex
      return caseInteractionStore.allPersons
        .filter((_: any, index: number) => index !== caseInteractionStore.selectedPersonIndex)
        .filter((person: Object) => person !== null);
    },
    selectedPersonsLastChatMessage(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      if (selectedPerson?.type === 'PATIENT') {
        let store = usePatientInteractionStore();
        return store.chatMessages[store.chatMessages.length - 1];
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        let store = useThirdPersonInteractionStore();
        return store.chatMessages[selectedPerson.thirdPersonIndex][
          store.chatMessages[selectedPerson.thirdPersonIndex].length - 1
        ];
      }
    },
    selectedPersonsVoice(state) {
      if (state.selectedPersonIndex == null) {
        return;
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      if (selectedPerson?.type === 'PATIENT') {
        let store = usePatientInteractionStore();
        return store.patientVoice;
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        let store = useThirdPersonInteractionStore();
        return store.thirdPersonVoice([selectedPerson.thirdPersonIndex]);
      }
    },
    selectedPersonsName(state) {
      if (state.selectedPersonIndex == null) {
        return '';
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      console.log('selectedPerson: ', selectedPerson);
      return selectedPerson?.person.details.first_name + ' ' + selectedPerson?.person.details.last_name;
    },
    someMessageToRedo(state) {
      if (state.selectedPersonIndex == null) {
        return false;
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      return caseInteractionStore.selectedPerson?.type === 'PATIENT'
        ? patientInteractionStore.someMessageToRedo
        : thirdPersonInteractionStore.someMessageToRedo(selectedPerson.thirdPersonIndex);
    },
    someMessageToUndo(state) {
      if (state.selectedPersonIndex == null) {
        return false;
      }
      const caseInteractionStore = useCaseInteractionStore();
      let selectedPerson = caseInteractionStore.selectedPerson;
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      return caseInteractionStore.selectedPerson?.type === 'PATIENT'
        ? patientInteractionStore.someMessageToUndo
        : thirdPersonInteractionStore.someMessageToUndo(selectedPerson.thirdPersonIndex);
    },
  },
  actions: {
    enableAudioAutoplay() {
      console.log('Enabling audio autoplay');
      this.audioAutoplay = true;
    },
    disableAudioAutoplay() {
      console.log('Disabling audio autoplay');
      this.audioAutoplay = false;
    },
    setFirstAudioChunkReceived(val: boolean) {
      console.log('First audio chunk received');
      this.firstAudioChunkReceived = val;
    },
    /**
     * Sets the current case interaction and fetches the case from the API if necessary.
     * Using this setter it is ensured that the case interaction and case are consistent.
     * @param {CaseInteraction} caseInteraction - The case interaction to set.
     */
    getPersons(taskIndex: number) {
      let store = useCaseInteractionStore();
      console.log(taskIndex);
      let involvements =
        taskIndex !== null && store.currentCase ? store.currentCase.tasks[taskIndex].person_task_involvements : [];
      console.log(involvements);
      let involvedPatientIds = involvements
        .filter((involvement: any) => involvement.patient_id !== null)
        .map((involvement: any) => involvement.patient_id);
      console.log(involvedPatientIds);
      let patientInvolvementTypes = involvements
        .filter((involvement: any) => involvement.patient_id !== null)
        .map((involvement: any) => involvement.type);
      let involvedThirdPersonIds = involvements
        .filter((involvement: any) => involvement.third_person_id !== null)
        .map((involvement: any) => involvement.third_person_id);
      console.log(involvedThirdPersonIds);
      let thirdPersonInvolvementTypes = involvements
        .filter((involvement: any) => involvement.third_person_id !== null)
        .map((involvement: any) => involvement.type);
      console.log(thirdPersonInvolvementTypes);
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      const patient =
        patientInteractionStore.currentPatient && involvedPatientIds.includes(patientInteractionStore.currentPatient.id)
          ? {
              index: 0,
              type: 'PATIENT',
              person: patientInteractionStore.currentPatient,
              profileImageSmall: patientInteractionStore.patientProfileImageSmall,
              profileImageLarge: patientInteractionStore.patientProfileImageLarge,
              profileImageExtraLarge: patientInteractionStore.patientProfileImageExtraLarge,
              placeholderMessage:
                `Mit Patient:in sprechen` +
                (patientInvolvementTypes[involvedPatientIds.indexOf(patientInteractionStore.currentPatient.id)] ===
                'ON_THE_PHONE'
                  ? '(am Telefon)'
                  : ''),
              involvement:
                patientInvolvementTypes[involvedPatientIds.indexOf(patientInteractionStore.currentPatient.id)],
            }
          : null;

      const thirdPersons = thirdPersonInteractionStore.currentThirdPersons
        .filter((person) => involvedThirdPersonIds.includes(person.id))
        .map((person, index) => ({
          index: index + 2,
          type: 'THIRD_PERSON',
          person: person,
          thirdPersonIndex: person.index,
          profileImageSmall: thirdPersonInteractionStore.thirdPersonProfileImageSmall(person.index),
          profileImageLarge: thirdPersonInteractionStore.thirdPersonProfileImageLarge(person.index),
          profileImageExtraLarge: thirdPersonInteractionStore.thirdPersonProfileImageExtraLarge(person.index),
          placeholderMessage:
            'Mit ' +
            thirdPersonInteractionStore.thirdPersonName(person.index) +
            ' sprechen' +
            (thirdPersonInvolvementTypes[involvedThirdPersonIds.indexOf(person.id)] === 'ON_THE_PHONE'
              ? '(am Telefon)'
              : ''),
          involvement: thirdPersonInvolvementTypes[involvedThirdPersonIds.indexOf(person.id)],
        }));
      console.log(thirdPersonInteractionStore.currentThirdPersons);
      console.log(thirdPersons);
      console.log(patient);
      console.log([patient, null, ...thirdPersons]);
      // console.log([patient, ...thirdPersons].filter((item) => item !== null));
      return [patient, null, ...thirdPersons];
    },
    getFirstNonNullPersonIndex(taskIndex: number) {
      let store = useCaseInteractionStore();
      let persons = store.getPersons(taskIndex);
      console.log('allPersons', persons);
      // get first non-null person: patient, third person
      //let firstPersonIndex = persons.findIndex((person) => person !== null);
      let firstNonNullPerson = persons.find((person) => person !== null);
      console.log('firstNonNullPerson: ', firstNonNullPerson);
      let firstPersonIndex = firstNonNullPerson?.index;
      console.log('firstPersonIndex for first non-null person', firstPersonIndex);
      return firstPersonIndex;
    },
    getFirstNonNullPerson(taskIndex: number) {
      let store = useCaseInteractionStore();
      let persons = store.getPersons(taskIndex);
      console.log('allPersons', persons);
      // get first non-null person: patient, third person
      return persons.find((person) => person !== null);
    },
    _getPersonIndexFromCaseInteraction(caseInteraction: CaseInteraction) {
      if (caseInteraction.current_person_selection_tablename === 'patient') {
        return 0;
      } else if (caseInteraction.current_person_selection_tablename === 'third_person') {
        return 2 + caseInteraction.current_person_selection_index;
      } else {
        console.log('Invalid current_person_selection_tablename ', caseInteraction.current_person_selection_tablename);
        return null;
      }
    },
    async _updateCaseInteractionWithNewSelectedPerson(personIndex: number) {
      if (personIndex === 0) {
        return (await getApiClient()).caseInteractions.selectPersonForInteraction(
          this.currentCaseInteractionId,
          'patient',
          null,
        );
      } else {
        return (await getApiClient()).caseInteractions.selectPersonForInteraction(
          this.currentCaseInteractionId,
          'third_person',
          personIndex - 2,
        );
      }
    },
    async setCaseInteraction(caseInteraction: CaseInteractionDetailed) {
      this.resetCurrentlyReadingChatMessageText();
      this.currentCaseInteraction = caseInteraction;

      // check if case is already set and the same as in the case interaction
      // otherwise, fetch case from API
      if (!(this.currentCase && this.currentCase.id === caseInteraction.case.id)) {
        this.currentCase = await (await getApiClient()).cases.getCase(caseInteraction.case.id);
      }
      console.debug('this.currentCaseInteraction', this.currentCaseInteraction);
      console.debug(' for case ' + this.currentCase.id);
      console.log('caseInteraction', caseInteraction);
      if (caseInteraction.form_data_content_item_id) {
        let currentFormDataContentItem = await (
          await getApiClient()
        ).courseSectionItems.getSectionContentItem(caseInteraction.form_data_content_item_id);
        let caseStore = useCaseStore();
        caseStore.setCurrentFormCase(currentFormDataContentItem);
      } else {
        let caseStore = useCaseStore();
        caseStore.setCurrentCase(caseInteraction.case.id);
      }
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      const companionInteractionStore = useCompanionInteractionStore();
      await Promise.all([
        patientInteractionStore._initiatePatientInteraction(),
        thirdPersonInteractionStore._initiateThirdPersonInteraction(),
        companionInteractionStore._initiateCompanionInteractionOnCaseInteraction(),
      ]);

      let store = useCaseInteractionStore();
      console.log('allPersons after init of patient and third person interaction stores: ', store.allPersons);

      this.currentTaskIndex = caseInteraction.current_task_interaction_index;
    },
    async createCaseInteraction(
      caseId: string,
      formDataContentItemId: string | null,
      t: any = null,
      useAsCurrentCaseInteraction: boolean = true,
    ) {
      console.log('learningObjectives: ', this.currentLearningObjectives);
      const response = await (
        await getApiClient()
      ).cases.createCaseInteraction(caseId, {
        learning_objectives: this.currentLearningObjectives,
        form_data_content_item_id: formDataContentItemId,
      });
      if (useAsCurrentCaseInteraction) {
        await this.setCaseInteraction(response['case_interaction']);
      }
      let authStore = useAuthStore();
      if (!!response['notifications'] && !!t) {
        const alertStore = useAlertStore();
        for (const notification of response['notifications']) {
          if (notification.type === 'XP') {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          }
        }
      }
      await authStore.fetchUserXp(); // update xp
      return response['case_interaction'].id;
    },
    async setCaseInteractionById(id: string) {
      // fetch patient interaction from API
      const caseInteraction = await (await getApiClient()).caseInteractions.getCaseInteraction(id, true);
      await this.setCaseInteraction(caseInteraction);
    },
    // async updateCurrentCaseInteractionState() {
    //   if (!this.currentCaseInteraction) {
    //     return;
    //   }
    //   const caseInteraction = await (await getApiClient()).caseInteractions.getCaseInteraction(this.currentCaseInteractionId, true);
    //   this.currentCaseInteraction = caseInteraction;
    // },
    setLearningObjectives(learningObjectives: object) {
      console.log('setLearningObjectives called with learningObjectives: ', learningObjectives);
      this.currentLearningObjectives = learningObjectives;
    },
    async setLearningObjectivesForSection(sectionId: string) {
      const client = await getApiClient();
      const learningObjectives = await client.sections.getLearningObjectivesOfSection(sectionId);
      this.setLearningObjectives(learningObjectives);
    },
    resetLearningObjectives() {
      this.currentLearningObjectives = [];
    },
    async endCase() {
      if (this.currentTaskIndex !== null) {
        await this._leaveTask(this.currentTaskIndex, true);
      }
      // TODO: handle other finish case stuff
    },
    async continueCaseInteraction() {
      console.log('continueCaseInteraction called');
      console.log(JSON.stringify(this.currentCaseInteraction));
      this.currentTaskIndex = this.currentCaseInteraction.current_task_interaction_index;
      this.selectedPersonIndex = this._getPersonIndexFromCaseInteraction(this.currentCaseInteraction);
      this.setWaitingForResponse(false);
      this.enableAudioAutoplay();
    },
    async _setCurrentTaskIndex(taskIndex: number) {
      if (!this.currentCaseInteraction) {
        console.error('No current case interaction');
        return;
      }
      this.currentTaskIndex = taskIndex;
      await (
        await getApiClient()
      ).caseInteractions.startTaskInteractionByIndex(this.currentCaseInteractionId, taskIndex);
      let taskInteraction = this.currentCaseInteraction.task_interactions[taskIndex];

      if (!taskInteraction.started_at) {
        taskInteraction.started_at = new Date();
        console.log('task interaction started: ', taskInteraction);
      }

      console.log('currentTaskIndex: ', this.currentTaskIndex);
    },
    async goToNextTask(reactToOldTaskLeave: boolean = true) {
      if (this.currentTaskIndex === null) {
        await this.goToTask(0, true);
        return;
      }
      await this.goToTask(this.currentTaskIndex + 1, false, reactToOldTaskLeave);
    },
    async goToTask(newTaskIndex: number, isInitialTask: boolean = false, reactToOldTaskLeave: boolean = true) {
      console.log('goToTask called with newTaskIndex: ', newTaskIndex, ' and isInitialTask: ', isInitialTask);
      if (!this.currentCaseInteraction) {
        return;
      }
      this.awaitingReactionOrDesc = true;
      console.log(this.currentTaskIndex);
      if (this.currentTaskIndex != null && reactToOldTaskLeave) {
        await this._leaveTask(this.currentTaskIndex, false); // no desc: one desc for task change from begin/revisit of new task
      }

      // set new task & select first non-null person
      console.log(newTaskIndex);
      this.previousTaskIndex = this.currentTaskIndex;
      let store = useCaseInteractionStore();
      let alreadyVisited = !!store.currentCaseInteraction.task_interactions[newTaskIndex].started_at;
      await store._setCurrentTaskIndex(newTaskIndex);
      await store.selectFirstNonNullPerson();
      store = useCaseInteractionStore();

      store = useCaseInteractionStore();
      console.log(store.currentCaseInteraction.task_interactions);
      if (alreadyVisited) {
        await this._revisitTask(newTaskIndex);
      } else {
        await this._beginNewTask(newTaskIndex, isInitialTask, !isInitialTask);
      }
      this.awaitingReactionOrDesc = false;
    },
    async _requestDescriptionMessageForEvent(eventType: string, taskIndex: number) {
      let store = useCaseInteractionStore();
      this.waitingForResponse = true;
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();

      let currentTask =
        this.currentTaskIndex !== null && this.currentCase ? this.currentCase.tasks[this.currentTaskIndex] : null;
      await patientInteractionStore.createPreliminaryDescMessage();
      let authStore = useAuthStore();
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        '/observer-interactions/' + patientInteractionStore.currentObserverInteractionId,
        {
          content: '',
          type: 'DESCRIPTION',
          event: {
            type: eventType,
            details: {
              new_task_id: this.currentCaseInteraction.case.tasks[taskIndex].id,
            },
          },
          current_task: currentTask,
          language_level: authStore.currentLanguageLevel,
        },
        patientInteractionStore.appendToLastDescMessageAndRefetchOnceFinished,
      );
    },
    async _triggerReactionForEvent(
      eventType: string,
      taskIndex: number,
      resumeBeforePlaybackFinished: boolean = false, // only used at initial REACT when user waiting at CaseInteraction spinner for the case to start
    ) {
      let caseInteractionStore = useCaseInteractionStore();
      let authStore = useAuthStore();
      this.waitingForResponse = true; // used in this same function directly
      if (caseInteractionStore.selectedPersonIndex == null) {
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();
      const thirdPersonInteractionStore = useThirdPersonInteractionStore();
      let store = null as typeof patientInteractionStore | typeof thirdPersonInteractionStore | null;
      let url = '';
      let messageType = '';
      let concernsThirdPerson = false;
      let thirdPersonIndex = null as number | null;
      let appendToChatMessageAndRefetchOnceFinished = null;
      caseInteractionStore = useCaseInteractionStore();
      console.log(caseInteractionStore.selectedPersonIndex);
      console.log(caseInteractionStore.allPersons);
      console.log(caseInteractionStore.selectedPersonIndex);
      let selectedPerson = caseInteractionStore.selectedPerson;
      console.log(selectedPerson);
      if (selectedPerson?.type === 'PATIENT') {
        store = patientInteractionStore;
        appendToChatMessageAndRefetchOnceFinished = store.appendToLastChatMessageAndRefetchOnceFinished;
        url = '/patient-interactions/' + patientInteractionStore.currentPatientInteractionId;
        messageType = 'REACT';
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        concernsThirdPerson = true;
        thirdPersonIndex = selectedPerson?.thirdPersonIndex as number;
        store = thirdPersonInteractionStore;
        appendToChatMessageAndRefetchOnceFinished = (chunk: string) =>
          store.appendToLastChatMessageAndRefetchOnceFinished(thirdPersonIndex, chunk);
        url =
          '/third-person-interactions/' +
          thirdPersonInteractionStore.currentThirdPersonInteractionIds[thirdPersonIndex];
        messageType = 'THIRD_PERSON_REACT';
      } else {
        console.error('Unknown person type', selectedPerson?.type, ' of person ', selectedPerson, '.');
        return;
      }
      if (concernsThirdPerson) {
        await store.createPreliminaryChatMessage(thirdPersonIndex);
      } else {
        await store.createPreliminaryChatMessage();
      }
      let currentTask =
        this.currentTaskIndex !== null && this.currentCase ? this.currentCase.tasks[this.currentTaskIndex] : null;
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        url,
        {
          content: '',
          type: messageType,
          event: {
            type: eventType,
            details: {
              new_task_id: this.currentCaseInteraction.case.tasks[taskIndex].id,
            },
          },
          current_task: currentTask,
          language_level: authStore.currentLanguageLevel,
        },
        appendToChatMessageAndRefetchOnceFinished,
      );
      let waitingForAudioAfterTextStreamFinished = 0;
      caseInteractionStore = useCaseInteractionStore();
      while (
        (concernsThirdPerson ? store.anyChatIsStreaming : store.chatIsStreaming) ||
        caseInteractionStore.audioIsStreaming ||
        caseInteractionStore.waitingForResponse
      ) {
        console.log('waiting for ' + messageType + ' for ' + eventType + ' to finish.');
        caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
          return;
        }
        if (
          concernsThirdPerson
            ? store.anyChatIsStreaming
            : store.chatIsStreaming || caseInteractionStore.audioIsStreaming
        ) {
          console.log('CHAT response started');
          this.waitingForResponse = false;
        }
        if (
          !caseInteractionStore.waitingForResponse &&
          resumeBeforePlaybackFinished &&
          caseInteractionStore.firstAudioChunkReceived
        ) {
          // this shall be done if the user is waiting at the CaseInteraction spinner for the case to start
          console.log('Resuming before playback');
          break;
        }
        if (!caseInteractionStore.waitingForResponse && !caseInteractionStore.audioIsStreaming) {
          if (concernsThirdPerson) {
            console.log(store.anyChatIsStreaming);
          } else {
            console.log(store.chatIsStreaming);
          }
          // console.log(
          //   'Text stream FINISHED. Waiting for audio to finish - ' + waitingForAudioAfterTextStreamFinished + '/60',
          // );
          waitingForAudioAfterTextStreamFinished++;
        }
        if (waitingForAudioAfterTextStreamFinished > 10) {
          console.warn('Audio stream did not start after 10 seconds. Breaking loop.');
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log('FINISHED waiting for ' + messageType + ' for ' + eventType + ' to finish.');
    },
    async _leaveTask(taskIndex: number, requestDescription: boolean = false) {
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();
      if (requestDescription) {
        await this._requestDescriptionMessageForEvent('LEAVE_TASK', taskIndex);
        let caseInteractionStore = useCaseInteractionStore();
        // wait until streaming is FINISHED (so not two incomplete messages are in the chat at the same time)
        while (patientInteractionStore.descIsStreaming || caseInteractionStore.waitingForResponse) {
          caseInteractionStore = useCaseInteractionStore();
          if (!caseInteractionStore.currentCaseInteraction) {
            console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
            return;
          }
          if (patientInteractionStore.descIsStreaming) {
            this.waitingForResponse = false;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      // get reaction of currently focused/ selected person
      await this._triggerReactionForEvent('LEAVE_TASK', taskIndex);
    },
    async _beginNewTask(taskIndex: number, isInitialTask: boolean = false, requestDescription: boolean = true) {
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      let caseInteractionStore = useCaseInteractionStore();
      const patientInteractionStore = usePatientInteractionStore();
      console.log('Beginning task ', taskIndex);
      await caseInteractionStore.selectFirstNonNullPerson();
      if (requestDescription) {
        await this._requestDescriptionMessageForEvent('BEGIN_TASK', taskIndex);
        while (patientInteractionStore.descIsStreaming || caseInteractionStore.waitingForResponse) {
          caseInteractionStore = useCaseInteractionStore();
          if (!caseInteractionStore.currentCaseInteraction) {
            console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
            return;
          }
          if (patientInteractionStore.descIsStreaming) {
            console.log('DESC response started');
            this.waitingForResponse = false;
          }
          console.log('beingNewTask: waiting for DESC to finish.');
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        console.log('beingNewTask: DESC FINISHED.');
      }
      // get reaction of currently focused/ selected person being approached by the student
      await this._triggerReactionForEvent('BEGIN_TASK', taskIndex, isInitialTask);
      console.log('beginNewTask: REACT FINISHED.');
    },
    async _revisitTask(taskIndex: number) {
      if (this.currentCaseInteraction == null) {
        console.error('No current case interaction');
        return;
      }
      const patientInteractionStore = usePatientInteractionStore();
      console.log('Revisiting task ', taskIndex);
      await this.selectFirstNonNullPerson();
      // request description message for beginning new task
      await this._requestDescriptionMessageForEvent('REVISIT_TASK', taskIndex);
      // wait until streaming is FINISHED (so not two incomplete messages are in the chat at the same time)
      let caseInteractionStore = useCaseInteractionStore();
      while (patientInteractionStore.descIsStreaming || caseInteractionStore.waitingForResponse) {
        caseInteractionStore = useCaseInteractionStore();
        if (!caseInteractionStore.currentCaseInteraction) {
          console.warn('currentCaseInteraction changed to null. Breaking loop and returning.');
          return;
        }
        if (patientInteractionStore.descIsStreaming) {
          console.log('DESC response started');
          this.waitingForResponse = false;
        }
        console.log('revisitTask: waiting for DESC to finish.');
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log('revisitTask: DESC FINISHED.');
      // get reaction of currently focused/ selected person
      await this._triggerReactionForEvent('REVISIT_TASK', taskIndex);
      console.log('revisitTask: REACT FINISHED.');
    },
    async selectFirstNonNullPerson() {
      console.log('selectFirstNonNullPerson');
      let store = useCaseInteractionStore();
      let firstPersonIndex = store.getFirstNonNullPersonIndex(store.currentTaskIndex);
      if (firstPersonIndex === -1) {
        console.error('No person available');
        return;
      }
      console.log('selecting person at firstPersonIndex', firstPersonIndex);
      await store.selectPerson(firstPersonIndex);
    },
    async selectPerson(personIndex: number) {
      this.selectedPersonIndex = personIndex;
      const caseInteractionStore = useCaseInteractionStore();
      await caseInteractionStore._updateCaseInteractionWithNewSelectedPerson(personIndex);
    },
    async say(message: string) {
      const store = useCaseInteractionStore();
      let selectedPerson = store.selectedPerson;

      if (selectedPerson?.type === 'PATIENT') {
        const patientInteractionStore = usePatientInteractionStore();
        await patientInteractionStore.say(message);
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        const thirdPersonInteractionStore = useThirdPersonInteractionStore();
        await thirdPersonInteractionStore.say(selectedPerson.thirdPersonIndex, message);
      } else {
        console.error('Unknown person type');
      }
    },
    async undoSay() {
      const store = useCaseInteractionStore();
      let selectedPerson = store.selectedPerson;
      let someMessageHasBeenUndone = false;
      if (selectedPerson?.type === 'PATIENT') {
        const patientInteractionStore = usePatientInteractionStore();
        someMessageHasBeenUndone = await patientInteractionStore.undoSay();
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        const thirdPersonInteractionStore = useThirdPersonInteractionStore();
        someMessageHasBeenUndone = await thirdPersonInteractionStore.undoSay(selectedPerson.thirdPersonIndex);
      }
      return someMessageHasBeenUndone;
    },
    async redoSay() {
      const store = useCaseInteractionStore();
      let selectedPerson = store.selectedPerson;
      let someMessageHasBeenRedone = false;
      if (selectedPerson?.type === 'PATIENT') {
        const patientInteractionStore = usePatientInteractionStore();
        someMessageHasBeenRedone = await patientInteractionStore.redoSay();
      } else if (selectedPerson?.type === 'THIRD_PERSON') {
        const thirdPersonInteractionStore = useThirdPersonInteractionStore();
        someMessageHasBeenRedone = await thirdPersonInteractionStore.redoSay(selectedPerson.thirdPersonIndex);
      }
      return someMessageHasBeenRedone;
    },
    async _markSubtaskAsCompleted(subtaskInteractionId: string, completedAt: string | Date) {
      /*
      Mark a subtask as completed, if not completed yet.
      @param subtaskInteractionId - The ID of the subtask interaction to mark as completed.
      @param completedAt - The date to mark the subtask as completed, as string or Date object.
      */
      const currentTaskInteraction = this.currentCaseInteraction?.task_interactions[this.currentTaskIndex];
      const subtaskInteraction = currentTaskInteraction.subtask_interactions.find(
        (subtaskInteraction: SubtaskInteraction) => subtaskInteraction.id === subtaskInteractionId,
      );
      if (!subtaskInteraction) {
        console.error('Subtask interaction not found');
        return;
      }
      if (subtaskInteraction.completed_at) return;

      console.log('marking new subtask as completed: ', subtaskInteraction.id, ' - date: ', completedAt);
      this.numSubtasksCompletedLately++;

      // Handle both string and Date inputs
      subtaskInteraction.completed_at = completedAt instanceof Date ? completedAt : new Date(completedAt);
    },
    async evaluateIfTaskIsCompleted() {
      /*
      Evaluate if for the current task, any of the subtasks are completed.
      If so, mark them as completed.
      Note: this is unidirectional in the sense that subtasks once marked completed will not be undone (even if the backend model changes its mind)
      */
      const currentTaskInteraction = this.currentCaseInteraction?.task_interactions[this.currentTaskIndex];

      const evaluation = await (
        await getApiClient()
      ).evaluation.evaluateSubtask({
        case_interaction_id: this.currentCaseInteraction.id,
        task_id: this.currentTask.id,
        subtasks: this.currentTask.subtasks,
        subtask_interaction_ids: currentTaskInteraction.subtask_interactions.map(
          (subtaskInteraction: SubtaskInteraction) => subtaskInteraction.id,
        ),
      });

      console.log('Evaluation: ', JSON.stringify(evaluation));
      for (const subtaskEvaluation of evaluation) {
        if (!subtaskEvaluation.completed) continue;
        await this._markSubtaskAsCompleted(subtaskEvaluation.subtask_interaction_id, subtaskEvaluation.completed_at);
      }
      // NOTE: we currently dont check in the frontend if this completes task or case, only in the backend
      // Would be reflected after re-fetch only.
      // Currently not a problem.
      await nextTick();
    },
    resetCurrentlyReadingChatMessageText() {
      this.readingQueue.length = 0;
      this.currentlyReadingChatMessageText = '';
    },
    setAudioIsStreaming(val: boolean) {
      console.log('Setting audioIsStreaming to ', val);
      this.audioIsStreaming = val;
    },
    setWaitingForResponse(val: boolean) {
      console.log('Setting waitingForResponse to ', val);
      this.waitingForResponse = val;
    },
    async updateSubtitleWords(
      wordTimings: {
        startTime: number;
        endTime: number;
        word: string;
      }[],
    ) {
      this.subtitleWords = [...this.subtitleWords, ...wordTimings];
    },
    async loadCaseInteractionsOfUser(forceReload: boolean = false) {
      const authStore = useAuthStore();
      if (!authStore.userId) {
        console.error('No user id');
        return;
      }
      if (forceReload || this.allUserCaseInteractions.length === 0) {
        this.allUserCaseInteractions = await (
          await getApiClient()
        ).users
          .listCaseInteractionsOfUser(authStore.userId, null, true)
          .then((caseInteractions: CaseInteraction[]) => {
            return caseInteractions;
          })
          .catch((error) => {
            console.error('Error loading case interactions of user: ' + error);
            return [];
          })
          .finally(() => {});
      }
    },

    async reset() {
      this.currentCase = null;
      this.currentCaseInteraction = null;
      this.currentTaskIndex = null;
      this.currentLearningObjectives = [];
      this.previousTaskIndex = null;
      this.selectedPersonIndex = null;
      this.currentlyReadingChatMessageText = '';
      this.readingQueue.length = 0;
      this.isProcessingReadingChunk = false;
      this.audioIsStreaming = false;
      this.audioAutoplay = true;
      this.awaitingReactionOrDesc = false;
      this.waitingForResponse = true;
      this.firstAudioChunkReceived = false;
      this.numSubtasksCompletedLately = 0;
    },
    setCurrentWordIndex(index: number) {
      this.currentWordIndex = index;
    },
    resetSubtitles() {
      this.subtitleWords = [];
      this.currentWordIndex = -1;
      this.totalDuration = 0;
    },
    setIsReplaying(value: boolean) {
      this.isReplaying = value;
    },
    setWaitingForAudioPlayback(value: boolean) {
      this.waitingForAudioPlayback = value;
    },
    reflectCaseFinishedInStore() {
      this.currentCaseInteraction.solved_at = new Date(); // backend knows this already. Set here to make it visible in the frontend before refetch
    },
  },
});

export const getCurrentTask = () => {
  let store = useCaseInteractionStore();
  return store.currentTask;
};
