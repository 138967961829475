<script setup lang="ts">
import { ref, computed, reactive, onMounted, defineProps, onBeforeUnmount, onBeforeMount, watch } from 'vue';
import { router } from '@/router';
import {
  usePatientInteractionStore,
  useCaseInteractionStore,
  useCaseStore,
  useAlertStore,
  useCompanionInteractionStore,
} from '@/stores';
import { storeToRefs } from 'pinia';
import { GraduationCap, ChevronLeft } from 'lucide-vue-next';

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

import SolveModal from '@/components/case_interaction/SolveModal.vue';
import TaskTransitionModal from '@/components/case_interaction/TaskTransitionModal.vue';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import CaseInteractionMainNew from '@/components/case_interaction/CaseInteractionMainNew.vue';
import CaseFinishedModal from '@/components/case_interaction/CaseFinishedModal.vue';
import { useI18n } from 'vue-i18n';
import { getApiClient } from '@/apiclient/client';
import CaseLineup from '@/components/case_interaction/CaseLineup.vue';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
import GoalsCard from '@/components/case_interaction/GoalsCard.vue';
import { useRoute } from 'vue-router';
const { t } = useI18n();

const patientInteractionStore = usePatientInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const companionInteractionStore = useCompanionInteractionStore();
const caseStore = useCaseStore();
const alertStore = useAlertStore();

const { descIsStreaming } = storeToRefs(patientInteractionStore);

const caseFinishedModalHandle = ref<typeof CaseFinishedModal | null>(null);

const interactionMain = ref<typeof CaseInteractionMainNew | null>(null);
const thirdPersonHandles = ref([]);

const isLoading = ref(true);
const isNewCase = ref(false);
const userIsReady = ref(false);
const originChapterId = ref('');
const previousPath = ref(null as string | null);
const inputText = ref('');
const lastInputAt = ref('');
const isShowingMainCaseView = ref(false);
const route = useRoute();
const allSubtasksCompleted = ref(false);
const isShowingTransition = ref(false);
const nextTaskIndex = ref(-1);

onBeforeMount(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;
  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  isShowingMainCaseView.value = true;
  isLoading.value = true;

  const caseInteractionId = route.params.caseInteractionId;

  if (!caseInteractionId) {
    alertStore.error('CaseInteractionId not found and not "new"', 'Error');
    await router.push('/home');
    return;
  }
  if (caseInteractionId === 'new') {
    const formDataContentItemId = route.query.formDataContentItemId as string;
    await handleNewCaseInteraction(formDataContentItemId);
    return;
  }
  await handleCaseInteractionIdChanged(caseInteractionId as string);
});

watch(
  () => route.params.caseInteractionId,
  async (newCaseInteractionId) => {
    if (!newCaseInteractionId) return;
    isShowingMainCaseView.value = true;
    if (newCaseInteractionId === 'new') {
      await handleNewCaseInteraction(route.query.formDataContentItemId as string);
      return;
    }
    await handleCaseInteractionIdChanged(newCaseInteractionId as string);
  },
);

const storePreviousPath = () => {
  let backPath = <string>router.options.history.state.back || '/home';
  if (!backPath.includes('case-interactions/')) {
    // skip /case-interactions/new and /case-interactions/uuid to avoid infinite loops
    previousPath.value = backPath;
  }
};

const handleNewCaseInteraction = async (forFormDataContentItemWithId: string) => {
  console.log('forFormDataContentItemWithId', forFormDataContentItemWithId);

  storePreviousPath();
  let currentCase = caseStore.currentCase;
  if (!currentCase) {
    alertStore.error('Case not found', 'Error');
    await router.push('/home');
    throw new Error('Case not found');
  }
  console.debug('Case found: ' + currentCase.id);
  await reset(true);
  caseInteractionStore
    .createCaseInteraction(currentCase.id, forFormDataContentItemWithId, t, false)
    .then(async (newCaseInteractionId: string) => {
      console.debug('CaseInteractionId: ' + newCaseInteractionId);
      await router.push(`/case-interactions/${newCaseInteractionId}`);
    })
    .catch((error: any) => {
      console.error('Error while creating case interaction: ' + error);
    });
};

const handleCaseInteractionIdChanged = async (caseInteractionId: string) => {
  storePreviousPath();
  console.log('caseInteractionId', caseInteractionId);
  console.log('caseInteractionStore.currentCaseInteractionId', caseInteractionStore.currentCaseInteractionId);

  if (caseInteractionId === caseInteractionStore.currentCaseInteractionId) {
    return;
  }

  await reset(true);
  isShowingMainCaseView.value = true;

  (await getApiClient()).caseInteractions.getCaseInteraction(caseInteractionId).then(async (caseInteraction) => {
    await caseInteractionStore.setCaseInteraction(caseInteraction);

    // disable audio autoplay, enable it later if user is ready and clicks on next
    caseInteractionStore.disableAudioAutoplay();

    await loadHistoryOrInitNewInteraction();

    console.log('third persons ', JSON.stringify(thirdPersonInteractionStore.currentThirdPersons));
    console.log('# third persons ', thirdPersonInteractionStore.currentThirdPersons.length);
    for (let i = 0; i < thirdPersonInteractionStore.currentThirdPersons.length; i++) {
      thirdPersonHandles.value.push(ref(null));
    }
  });
};

onBeforeUnmount(async () => {
  await reset(false);
});

const reset = async (resetStores: boolean = true) => {
  if (resetStores) {
    await Promise.all([
      caseInteractionStore.reset(),
      patientInteractionStore.reset(),
      thirdPersonInteractionStore.reset(),
      companionInteractionStore.reset(),
    ]);
  }
  isShowingMainCaseView.value = false;
  isLoading.value = true;
  isNewCase.value = false;
  userIsReady.value = false;
};

watch(
  () => caseInteractionStore.numSubtasksCompletedLately,
  async (newValue) => {
    if (!newValue) return;

    // Check if all subtasks are completed
    const currentTaskInteraction = caseInteractionStore.currentTaskInteraction;
    if (currentTaskInteraction?.subtask_interactions?.every((si) => si.completed_at !== null)) {
      allSubtasksCompleted.value = true;
    }
  },
);

watch(
  () => allSubtasksCompleted.value,
  async (newValue) => {
    if (!newValue) return;

    if (caseInteractionStore.currentTaskIsLastTask && !caseStore.isFormCase) {
      // we are done now (except if there is a form to fill in)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      caseInteractionStore.reflectCaseFinishedInStore();
      await caseFinishedModalHandle.value.show();
    } else {
      await new Promise((resolve) => setTimeout(resolve, 500));
      // Instead of immediately going to next task, show transition screen
      nextTaskIndex.value = caseInteractionStore.currentTaskIndex + 1;
      isShowingTransition.value = true;
      // Prepare next task but disable audio autoplay
      caseInteractionStore.disableAudioAutoplay();
      await caseInteractionStore.goToTask(nextTaskIndex.value, t, false);
    }
  },
);

const proceedToNextTask = async () => {
  if (isLoading.value) return;

  // Enable audio autoplay before proceeding
  caseInteractionStore.enableAudioAutoplay();
  if (interactionMain.value) {
    interactionMain.value.unlockAudioContext();
  }

  isShowingTransition.value = false;
  allSubtasksCompleted.value = false;
};

async function loadHistoryOrInitNewInteraction() {
  try {
    const [patientHistory, thirdPersonHistory, _] = await Promise.all([
      patientInteractionStore.fetchHistory(),
      thirdPersonInteractionStore.fetchHistories(),
      companionInteractionStore.fetchHistory(),
    ]);

    const historyLoaded = patientHistory || thirdPersonHistory; // companion history does not count here
    console.debug('history available and loaded: ' + historyLoaded);

    if (!historyLoaded) {
      // no history found, so we init the interaction and start streaming
      isNewCase.value = true;
      await caseInteractionStore.goToTask(0, t, true);
    } else {
      // we have a history, so we enable audio autoplay and forward the user directly to the case interaction
      isNewCase.value = false;
      userIsReady.value = true;
      await caseInteractionStore.continueCaseInteraction();
    }
  } catch (e) {
    console.error('Error while loading history or init new interaction: ' + e);
  }
  isLoading.value = false;
}

/**
 * Sets the value of `userIsReady` to `true`, indicating that the user is ready to proceed to the case interaction.
 */
const proceedToCaseInteraction = async () => {
  if (isLoading.value || descIsStreaming.value) return;

  // Enable audio autoplay before unlocking audio context
  caseInteractionStore.enableAudioAutoplay();
  if (interactionMain.value) {
    interactionMain.value.unlockAudioContext();
  }

  userIsReady.value = true;
};

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

const caseIsSet = computed(() => {
  return caseInteractionStore.currentCase !== null;
});

const closeCase = async () => {
  console.log('Navigating back to:', previousPath.value);
  isShowingMainCaseView.value = false;
  if (previousPath.value) {
    await router.push(previousPath.value);
    return;
  }
  await router.push('/home');
};

const handleChatSubmit = (payload: string) => {
  lastInputAt.value = new Date().toISOString();
  inputText.value = payload;
};
</script>

<template>
  <div class="w-full h-full relative overflow-hidden">
    <div
      v-if="!userIsReady || isLoading"
      class="absolute top-104 left-0 w-full flex flex-col bg-white z-[40] overflow-auto"
      :style="computedStyleMain"
    >
      <div
        v-if="isNewCase"
        class="flex-col flex overflow-auto pb-24 transition-opacity duration-500"
        :class="{
          'opacity-0': !caseIsSet,
          'opacity-100': caseIsSet,
        }"
        :style="computedStyleMain"
      >
        <!-- Title Section -->
        <div class="max-w-3xl h-fit overflow-y-visible mx-auto px-4 w-full">
          <div class="sticky top-0 z-10 py-2">
            <button
              @click="closeCase"
              class="p-2 rounded-full bg-white hover:bg-gray-100 transition-colors mt-6 mb-4 border border-gray-200"
              aria-label="Go back"
            >
              <ChevronLeft class="w-6 h-6" />
            </button>
          </div>

          <div>
            <h1 class="text-3xl font-bold mb-2">{{ caseInteractionStore.currentCase?.tasks[0]?.title }}</h1>
            <p class="text-gray-600">{{ caseInteractionStore.currentCase?.tasks[0]?.details.description }}</p>
          </div>

          <CaseLineup />
          <GoalsCard :localeGerman="true" />
        </div>
      </div>

      <LoadingSpinnerFullScreen :show="!caseIsSet" />

      <!-- Start Button - Fixed to bottom -->
      <div
        v-if="!userIsReady && isNewCase"
        class="fixed bottom-0 left-0 right-0 mb-8 flex justify-center transition-opacity duration-500"
        :class="{ 'opacity-0': !caseIsSet }"
      >
        <button
          @click="proceedToCaseInteraction"
          :disabled="isLoading || descIsStreaming"
          type="button"
          class="rounded-full py-3.5 px-4 bg-black hover:bg-gray-800 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed w-fit"
          data-testid="start-case-next-button"
        >
          <div class="flex items-center justify-center gap-2 relative">
            <!-- Icon and text -->
            <div
              class="flex items-center gap-2 transition-opacity duration-200"
              :class="{ 'opacity-0': isLoading, 'opacity-100': !isLoading }"
            >
              <GraduationCap class="w-5 h-5" />
              <span>{{ t('message.startCase') }}</span>
            </div>

            <!-- Loading spinner -->
            <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center">
              <div class="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
              <span></span>
            </div>
          </div>
        </button>
      </div>
    </div>

    <div
      v-if="caseIsSet"
      class="w-full flex flex-col flex-grow overflow-hidden transition-opacity duration-500"
      :class="{
        'opacity-0': isLoading || !userIsReady,
        'opacity-100': userIsReady,
      }"
      :style="computedStyleMain"
    >
      <CaseFinishedModal ref="caseFinishedModalHandle" :previous-path="previousPath" />

      <div
        v-if="isShowingTransition"
        class="absolute top-104 px-4 left-0 w-full flex flex-col bg-white z-[40] overflow-auto"
        :style="computedStyleMain"
      >
        <div class="flex-col flex overflow-auto pb-24 transition-opacity duration-500" :style="computedStyleMain">
          <!-- Title Section -->
          <div class="max-w-3xl h-fit overflow-y-visible mx-auto px-4 w-full">
            <div class="mt-8">
              <h1 class="text-3xl font-bold mb-2">
                {{ caseInteractionStore.currentCase?.tasks[nextTaskIndex]?.title }}
              </h1>
              <p class="text-gray-600">
                {{ caseInteractionStore.currentCase?.tasks[nextTaskIndex]?.details.description }}
              </p>
            </div>

            <CaseLineup />
            <GoalsCard :localeGerman="true" :taskIndex="nextTaskIndex - 1" />
          </div>
        </div>

        <!-- Continue Button - Fixed to bottom -->
        <div class="fixed bottom-0 left-0 right-0 p-4 flex justify-center transition-opacity duration-500">
          <button
            @click="proceedToNextTask"
            :disabled="isLoading"
            type="button"
            class="rounded-full py-3.5 px-4 bg-black hover:bg-gray-800 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed w-fit"
            data-testid="continue-task-button"
          >
            <div class="flex items-center justify-center gap-2 relative">
              <div
                class="flex items-center gap-2 transition-opacity duration-200"
                :class="{ 'opacity-0': isLoading, 'opacity-100': !isLoading }"
              >
                <GraduationCap class="w-5 h-5" />
                <span>{{ t('message.continue') }}</span>
              </div>

              <!-- Loading spinner -->
              <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center">
                <div class="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                <span></span>
              </div>
            </div>
          </button>
        </div>
      </div>

      <CaseInteractionMainNew
        v-if="isShowingMainCaseView"
        ref="interactionMain"
        :outerHeaderHeight="props.outerHeaderHeight"
        :originChapterId="originChapterId"
        :isLoadingInteractionHistory="isLoading"
        :showingMainCaseView="userIsReady"
        :inputText="inputText"
        :lastInputAt="lastInputAt"
        @chatSubmit="handleChatSubmit"
        @closeCase="closeCase"
        @showCaseFinishedModal="
          () => {
            if (!caseFinishedModalHandle) return;
            caseFinishedModalHandle.show();
          }
        "
      />
    </div>
  </div>
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
