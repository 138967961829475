<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { t } = useI18n();
const localLanguageLevel = ref(authStore.currentLanguageLevel);

function cycleDifficulty() {
  if (!localLanguageLevel.value) {
    return 'SIMPLE';
  }
  const levels = ['SIMPLE', 'MEDIUM', 'NATIVE'];
  const currentIndex = levels.indexOf(localLanguageLevel.value);
  const nextIndex = (currentIndex + 1) % levels.length;
  localLanguageLevel.value = levels[nextIndex];
}

const difficultyButtonText = computed(() => {
  if (!localLanguageLevel.value) {
    return t('difficulty.simple');
  }
  switch (localLanguageLevel.value) {
    case 'SIMPLE':
      return t('difficulty.simple');
    case 'MEDIUM':
      return t('difficulty.medium');
    case 'NATIVE':
      return t('difficulty.native');
    default:
      return t('difficulty.simple');
  }
});

watch(
  () => localLanguageLevel.value,
  (value) => {
    if (!value) return;
    authStore.temporarilySetLanguageLevel(value);
  },
  { immediate: true },
);
</script>

<template>
  <div class="flex items-center space-x-2">
    <button
      @click="cycleDifficulty"
      class="px-3 py-1.5 bg-gray-50 text-black hover:text-gray-900 rounded-full text-sm font-semibold border border-gray-200"
    >
      <span>{{ difficultyButtonText }}</span>
    </button>
  </div>
</template>

<style scoped></style>
