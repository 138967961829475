<script setup lang="ts">
import { ref, onMounted, PropType, computed, watch, onActivated } from 'vue';
import { useRouter } from 'vue-router';
import CourseCard from './CourseCard.vue';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
import { useCourseStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { CourseInteractionWithProgress, CourseOverview, CourseOverviewWithoutAuthorDetails } from '@/apiclient';
import { getApiClient } from '@/apiclient/client';
import CourseService from '@/services/CourseService';
import BottomNav from '@/components/BottomNav.vue';
import { useI18n } from 'vue-i18n';
import CourseListEmptyState from './CourseListEmptyState.vue';

const props = defineProps({
  type: {
    type: String as PropType<'library' | 'my-courses'>,
    default: 'library',
  },
});

const router = useRouter();
const courseStore = useCourseStore();
const authStore = useAuthStore();
const courseInteractionStore = useCourseInteractionStore();
const { t } = useI18n();
const isLoading = ref(false);

const courses = ref<
  Array<{
    overview: CourseOverview;
    interaction?: CourseInteractionWithProgress;
  }>
>([]);

const title = computed(() => {
  return props.type === 'library' ? t('message.courses') : t('message.myCourses');
});

const loadCourses = async () => {
  isLoading.value = true;

  if (props.type === 'library') {
    const result = await CourseService.listAvailableCourses();
    courses.value = result.map((course: CourseOverview) => ({
      overview: course,
    }));
  } else {
    const result = await courseInteractionStore.loadCourseInteractionsOfUserWithProgress(authStore.user.id, false);
    courses.value = result.map((courseInteraction: CourseInteractionWithProgress) => ({
      overview: courseInteraction.course,
      interaction: courseInteraction,
    }));
  }

  console.log('fetched courses: ', courses.value);
  isLoading.value = false;
};

onMounted(async () => {
  await loadCourses();
});

onActivated(async () => {
  // if we are coming from a course interaction, we need to update the progress
  if (!courseInteractionStore.currentCourseInteraction) return;
  const currentCourseInteractionProgress = await courseInteractionStore.refetchProgressForCurrentCourseInteraction();
  courses.value = courses.value.map((course) => {
    if (course.interaction?.id === courseInteractionStore.currentCourseInteraction?.id) {
      return {
        ...course,
        interaction: currentCourseInteractionProgress,
      };
    }
    return course;
  });
});

watch(
  () => props.type,
  async () => {
    await loadCourses();
  },
);

const onNavigateToCourse = async (courseId: string) => {
  await router.push('/course/' + courseId);
};

const onNavigateToEditCourse = async (courseId: string) => {
  await router.push('/edit-course/' + courseId);
};

const onResume = async (courseInteraction: CourseInteractionWithProgress | undefined, fallbackCourseId: string) => {
  console.log('courseInteraction - ', courseInteraction);
  if (!courseInteraction) {
    await onNavigateToCourse(fallbackCourseId);
    return;
  }

  try {
    await CourseService.resumeCourse(router, courseInteraction);
  } catch (error) {
    await onNavigateToCourse(fallbackCourseId);
  }
};
</script>

<template>
  <main class="flex h-full max-w-[85rem] mx-auto">
    <LoadingSpinnerFullScreen :show="isLoading" />

    <div v-if="courses.length > 0" class="flex flex-col w-full mt-2 gap-y-3">
      <h1 class="text-[28px] font-semibold mx-2">
        {{ title }}
      </h1>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-2 px-2 overflow-y-auto pb-20">
        <CourseCard
          v-for="course in courses"
          :key="course.overview.id"
          :type="props.type"
          :courseOverview="course.overview"
          :courseInteraction="course.interaction"
          @navigateToCourse="onNavigateToCourse(course.overview.id)"
          @navigateToEditCourse="onNavigateToEditCourse(course.overview.id)"
          @resume="onResume(course.interaction, course.overview.id)"
        />
      </div>
    </div>

    <CourseListEmptyState v-if="!isLoading && courses.length === 0" :type="props.type" />

    <BottomNav />
  </main>
</template>
