<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { computed, nextTick, onMounted, ref, watch, onBeforeUnmount } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps(nodeViewProps);

interface HTMLElementEvent extends Event {
  target: HTMLElement;
  clientX: number;
  clientY: number;
}

const hintTextarea = ref<HTMLTextAreaElement | null>(null);
const isMaximized = ref(false);
const rootContainer = ref<HTMLElement | null>(null);
const placeholder = ref('Gesucht');
const attemptOrSolutionInput = ref<HTMLInputElement | null>(null);
const isSolved = ref(false);
const isWrong = ref(false);
const isRecentlyWrong = ref(false);
const uuid = uuidv4();
const shuffledAnswers = ref<string[]>([]);
const dropdownTitleIfCorrect = ref('');
const dropdownTitleIfIncorrect = ref('');

onMounted(async () => {
  if (!props.editor.isEditable) placeholder.value = '\u2000'.repeat(props.node.attrs.solutionText.length);

  if (!!attemptOrSolutionInput.value) {
    await nextTick();
    attemptOrSolutionInput.value.focus();
  }

  if (!props.editor.isEditable) {
    shuffledAnswers.value = generateShuffledAnswers();
    props.editor.emit('nodeSolved', {
      id: uuid,
      isSolved: false,
    });
    props.editor.emit('nodeAttempted', {
      id: uuid,
      isAttempted: false,
    });
  }

  await nextTick();
  isMaximized.value = false;
  dropdownTitleIfCorrect.value = getRandomDropdownTitleForCorrect();
  dropdownTitleIfIncorrect.value = getRandomDropdownTitleForIncorrect();

  // Listen for the minimize event
  if (props.editor) {
    props.editor.on('minimizeAllClozeInputsExcept', (exceptId) => {
      if (uuid !== exceptId) {
        minimize();
      }
    });
  }
});

onBeforeUnmount(() => {
  if (props.editor) {
    props.editor.off('minimizeAllClozeInputsExcept');
  }
});

const isAttempted = computed(() => isSolved.value || isWrong.value);

watch(
  () => isWrong.value,
  (newVal) => {
    console.log('maximize because wrong');
    if (newVal) {
      setTimeout(() => {
        isMaximized.value = true;
      }, 500); // wait for close animations to finish!
    }
  },
);

watch(
  () => isAttempted.value,
  (newVal) => {},
  // NOTE: we count an MC cloze as attempted only once solved!
);

watch(
  () => isSolved.value,
  async (newVal) => {
    props.editor.emit('nodeAttempted', {
      id: uuid,
      isAttempted: true,
    });
    props.editor.emit('nodeSolved', {
      id: uuid,
      isSolved: newVal,
    });
    await new Promise((resolve) => setTimeout(resolve, 300)); // else animations interfere
    isMaximized.value = false;
  },
);

watch(
  () => props.node.attrs.attemptText,
  (newVal) => {
    if (newVal !== '') return;
    // so we have been reset:
    isWrong.value = false;
    isSolved.value = false;
  },
);

const delayedPositionClasses = ref('relative');
const appearAtOnceDisappearDelayed = ref(false);
const handleClickOutside = (event: HTMLElementEvent) => {
  if (!rootContainer.value) return;
  let rect = rootContainer.value.getBoundingClientRect();
  isMaximized.value =
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom;
};

const debounceHandleClickOutside = debounce(handleClickOutside, 200);

const innerClozeInputWidth = computed(() => {
  if (!solution.value) return '7ch';
  if (isMaximized.value) {
    return '100%';
  }
  return 1.05 * Math.max(solution.value.length + 1, 7) + 'ch';
});

const outerClozeInputWidthNonExpanding = computed(() => {
  if (!solution.value) return '8ch';
  return 1.05 * Math.max(solution.value.length + 2, 8) + 'ch';
});

watch(
  () => isMaximized.value,
  async (nowMaximized) => {
    if (nowMaximized) {
      // Switch to absolute for symmetric opening
      window.addEventListener('click', debounceHandleClickOutside);
      delayedPositionClasses.value = 'absolute top-0 border-2';
      appearAtOnceDisappearDelayed.value = true;
      await nextTick();
      updateMinTextareaHeight(hintTextarea.value, 1);
      return;
    }

    window.removeEventListener('click', debounceHandleClickOutside);
    // Wait for collapse animation to complete (height transition)
    appearAtOnceDisappearDelayed.value = true;
    delayedPositionClasses.value = 'absolute -top-[9px] left-0 border-2';
    await new Promise((resolve) => setTimeout(resolve, 100));

    // After collapse finishes, switch back to relative to reflow inline
    delayedPositionClasses.value = 'relative bg-transparent border-none';
    appearAtOnceDisappearDelayed.value = false;
  },
  { immediate: true },
);

const solutionOrAttempt = computed({
  get() {
    return props.editor.isEditable ? props.node.attrs.solutionText : props.node.attrs.attemptText;
  },
  set(value) {
    if (props.editor.isEditable) {
      props.node.attrs.solutionText = value;
    } else {
      props.node.attrs.attemptText = value;
    }
  },
});

const solution = computed(() => props.node.attrs.solutionText);
const wrongAnswers = computed(() => {
  try {
    return props.node.attrs.wrongAnswers ? JSON.parse(props.node.attrs.wrongAnswers) : [];
  } catch (e) {
    console.error('Failed to parse wrongAnswers:', e, ' when parsing ', props.node.attrs.wrongAnswers);
    return [];
  }
});

watch(
  () => props.node.attrs.hintText,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: 'hint',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => props.node.attrs.wrongAnswers,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: 'wrongAnswers',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => solutionOrAttempt.value,
  (newVal) => {
    props.editor.emit('clozeInput', {
      type: props.editor.isEditable ? 'solution' : 'attempt',
      value: newVal,
      nodeId: uuid,
    });
  },
);

watch(
  () => isMaximized.value,
  async (newVal) => {
    if (newVal) {
      await nextTick();
      updateMinTextareaHeight(hintTextarea.value, 1);

      props.editor.emit('nodeMaximized', {
        id: uuid,
        isMaximized: true,
      });
    } else {
      props.editor.emit('nodeMaximized', {
        id: uuid,
        isMaximized: false,
      });
    }
  },
);

watch(
  () => isRecentlyWrong.value,
  (newVal) => {
    if (!newVal) return;
    setTimeout(() => {
      isRecentlyWrong.value = false;
    }, 1000);
  },
);

const newWrongAnswer = ref('');
const addWrongAnswer = () => {
  if (!newWrongAnswer.value.trim()) return;

  // Parse existing solutions from JSON string, or start with empty array
  const currentWrongAnswers = props.node.attrs.wrongAnswers ? JSON.parse(props.node.attrs.wrongAnswers) : [];

  const updatedWrongAnswers = [...currentWrongAnswers, newWrongAnswer.value.trim()];

  console.log('updatedWrongAnswers', updatedWrongAnswers);

  // Store as JSON string
  props.node.attrs.wrongAnswers = JSON.stringify(updatedWrongAnswers);

  console.log('updatedWrongAnswers result', props.node.attrs.wrongAnswers);

  // Clear the input
  newWrongAnswer.value = '';
};

const removeWrongAnswer = (index: number) => {
  const currentWrongAnswers = props.node.attrs.wrongAnswers ? JSON.parse(props.node.attrs.wrongAnswers) : [];

  currentWrongAnswers.splice(index, 1);

  props.node.attrs.wrongAnswers = JSON.stringify(currentWrongAnswers);
};

const generateShuffledAnswers = () => {
  const allAnswers = [props.node.attrs.solutionText, ...wrongAnswers.value];
  // Fisher-Yates shuffle
  for (let i = allAnswers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [allAnswers[i], allAnswers[j]] = [allAnswers[j], allAnswers[i]];
  }
  return allAnswers;
};

const handleAnswerSelection = (selectedAnswer: string) => {
  if (props.editor.isEditable) return;

  dropdownTitleIfCorrect.value = getRandomDropdownTitleForCorrect();
  dropdownTitleIfIncorrect.value = getRandomDropdownTitleForIncorrect();

  props.node.attrs.attemptText = selectedAnswer;
  isWrong.value = selectedAnswer !== props.node.attrs.solutionText;
  isRecentlyWrong.value = isWrong.value;
  isSolved.value = selectedAnswer === props.node.attrs.solutionText;
};

const getRandomDropdownTitleForCorrect = () => {
  const messages = [
    'Richtig!',
    'Gut gemacht!',
    'Super!',
    'Perfekt!',
    'Ausgezeichnet!',
    'Genau!',
    'Das stimmt!',
    'Du hast es!',
    'Absolut korrekt!',
    'Prima!',
  ];

  // Get a random index between 0 and messages.length-1
  const randomIndex = Math.floor(Math.random() * messages.length);

  // Return the randomly selected message
  return messages[randomIndex];
};

const getRandomDropdownTitleForIncorrect = () => {
  const messages = [
    'Nicht ganz',
    'Leider falsch',
    "Versuch's nochmal",
    'Fast...',
    'Nicht korrekt',
    'Leider nicht',
    'Denk nochmal nach',
    'Schau genau hin',
    'Neeeeeeein',
  ];

  // Get a random index between 0 and messages.length-1
  const randomIndex = Math.floor(Math.random() * messages.length);

  // Return the randomly selected message
  return messages[randomIndex];
};

const dropdownTitle = computed(() => {
  if (props.editor.isEditable) return 'MC-Lücke';
  if (isSolved.value) return dropdownTitleIfCorrect.value;
  if (isRecentlyWrong.value) return dropdownTitleIfIncorrect.value;
  if (isWrong.value) return "Probier's nochmal";
  return 'Wähle aus';
});

const handleClick = (event: MouseEvent) => {
  // Prevent text selection
  const selection = window.getSelection();
  if (selection && isTouchDevice()) {
    selection.removeAllRanges();
  }

  // Set maximized state
  isMaximized.value = true;
};

const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const handleTouchStart = (event: TouchEvent) => {
  // For mobile devices, prevent default behavior which might trigger context menu
  event.preventDefault();

  // Set maximized state
  isMaximized.value = true;
};

const minimize = () => {
  isMaximized.value = false;
};

defineExpose({
  minimize,
  uuid,
});
</script>

<template>
  <node-view-wrapper
    class="relative inline-flex w-1 items-center align-middle -translate-y-[2px] -my-1"
    as="span"
    :data-mc-cloze-uuid="uuid"
    :class="{
      'z-[10]': isMaximized,
      'z-1': !isMaximized,
    }"
    @click.stop="handleClick"
    @touchstart.prevent="handleTouchStart"
    :style="{
      verticalAlign: 'center',
      lineHeight: 'inherit',
      width: outerClozeInputWidthNonExpanding,
    }"
  >
    <!-- Placeholder to maintain space when absolute/ maxed -->
    <span
      v-if="appearAtOnceDisappearDelayed"
      :style="{
        width: outerClozeInputWidthNonExpanding,
      }"
    ></span>

    <span
      ref="rootContainer"
      :class="[
        'transition-all duration-100 transform',
        isMaximized ? 'top-0 left-0 rounded-lg ' : '',
        !isSolved && !isRecentlyWrong && isMaximized ? 'bg-white border-black' : '',
        !isSolved && !isRecentlyWrong && !isMaximized ? 'border-transparent bg-transparent' : '',
        isSolved ? 'border-green bg-green-veryLight' : '',
        isRecentlyWrong ? 'border-red bg-red-veryLight' : '',
        delayedPositionClasses,
      ]"
      :style="{
        transform: isMaximized ? 'translateY(-36px)' : 'translateY(0)',
      }"
    >
      <label
        class="text-orange w-full px-2 transition-all duration-100 transform overflow-hidden text-xs font-semibold rounded-b-none rounded-t-md flex justify-between items-center"
        :class="{
          'py-1 mt-1': isMaximized,
          'bg-white': !isSolved && !isRecentlyWrong,
          'bg-green-veryLight text-green': isSolved,
          'bg-red-veryLight text-red': isRecentlyWrong,
        }"
        contenteditable="false"
        :style="{ height: isMaximized ? 'auto' : '0', lineHeight: isMaximized ? '16px' : '0' }"
      >
        {{ dropdownTitle }}
        <span
          v-show="props.editor.isEditable"
          draggable="true"
          data-drag-handle
          translate="no"
          class="pl-1 material-symbols-outlined no-translate text-lg cursor-move"
        >
          drag_indicator
        </span>
      </label>

      <span class="flex-col flex transition-all duration-100 transform" :class="{ 'my-1 gap-y-1': isMaximized }">
        <!-- Edit mode input -->
        <input
          v-if="props.editor.isEditable"
          @focusin="isMaximized = true"
          ref="attemptOrSolutionInput"
          contenteditable="true"
          class="mx-1 my-0 px-2 border transition-all duration-100 transform border-gray-300 rounded-md text-base md:text-sm"
          :class="{
            'focus:border-black focus:ring-black bg-gray-50': !isSolved && !isRecentlyWrong,
            'focus:border-green-light focus:ring-green-light bg-green-veryLight text-green': isSolved,
            'focus:border-red-light focus:ring-red-light bg-red-veryLight text-red': isRecentlyWrong,
            'py-1': isMaximized,
            'py-0': !isMaximized,
          }"
          :style="{ width: innerClozeInputWidth }"
          :placeholder="placeholder"
          spellcheck="false"
          autocomplete="off"
          autocorrect="off"
          v-model="solutionOrAttempt"
        />

        <!-- View mode display -->
        <div
          v-else-if="!isMaximized"
          class="mx-1 my-0 px-2 whitespace-nowrap min-h-[1.5rem] flex items-center cursor-pointer border transition-all duration-100 transform border-gray-300 rounded-md text-base md:text-sm"
          :class="{
            'bg-gray-50': !isSolved && !isRecentlyWrong,
            'bg-green-veryLight text-green': isSolved,
            'bg-red-veryLight text-red': isRecentlyWrong,
          }"
          :style="{ width: innerClozeInputWidth }"
        >
          {{ isAttempted ? solutionOrAttempt : placeholder }}
        </div>

        <!-- Multiple choice options container -->
        <div
          class="flex-col mx-1 flex bg-transparent mx-auto overflow-hidden transition-all duration-100 transform fallback-break"
          :class="{
            'h-fit': isMaximized,
            'h-0': !isMaximized,
          }"
          :style="{
            width: innerClozeInputWidth,
          }"
        >
          <!-- Edit mode content - keep existing -->
          <div v-if="props.editor.isEditable" class="flex-col flex gap-1 w-full">
            <div class="flex items-center gap-1">
              <input
                type="text"
                class="flex-grow px-1 mx-1 py-0 text-xs border border-gray-300 rounded-md focus:border-blue-600 focus:ring-blue-600"
                placeholder="Falschantwort hinzufügen"
                v-model="newWrongAnswer"
                @keyup.enter="addWrongAnswer"
              />
              <button
                @click="addWrongAnswer"
                class="px-2 py-0.5 text-xs text-white bg-blue-600 rounded-md hover:bg-blue-700"
                :disabled="!newWrongAnswer"
              >
                +
              </button>
            </div>
            <div class="flex flex-wrap gap-1">
              <span
                v-for="(wrongAnswer, index) in wrongAnswers"
                :key="index"
                class="flex items-center gap-1 px-2 py-0.5 text-xs bg-gray-100 rounded-full"
              >
                {{ wrongAnswer }}
                <button @click="removeWrongAnswer(index)" class="text-gray-500 hover:text-gray-700">×</button>
              </span>
            </div>
          </div>

          <!-- View mode - multiple choice options -->
          <div v-else-if="isMaximized" class="flex flex-col gap-2 p-1">
            <button
              v-for="answer in shuffledAnswers"
              :key="answer"
              @click.prevent="handleAnswerSelection(answer)"
              @touchstart.prevent="handleAnswerSelection(answer)"
              class="rounded-2xl py-3 px-2 font-medium text-sm cursor-pointer border w-full text-left transition-colors"
              :class="{
                'bg-orange-light border-orange border-opacity-20 text-orange':
                  solutionOrAttempt === answer && !isSolved && !isWrong,
                'bg-gray-light border-gray-light': solutionOrAttempt !== answer && !isSolved && !isWrong,
                'bg-green-veryLight border-green-light text-green':
                  isSolved && answer === props.node.attrs.solutionText,
                'bg-red-veryLight border-red-light text-red':
                  isWrong && solutionOrAttempt === answer && answer !== props.node.attrs.solutionText,
              }"
            >
              {{ answer }}
            </button>
          </div>

          <!-- Hint text area - keep existing -->
          <textarea
            v-show="props.editor.isEditable || !!props.node.attrs.hintText"
            ref="hintTextarea"
            contenteditable="true"
            class="px-1 resize-none rounded-md flex w-full text-gray-800 focus:ring-blue-600"
            :class="{
              'mx-1 bg-gray-50 text-base focus:border-blue-600 py-0': props.editor.isEditable,
              'text-xs': !props.editor.isEditable,
              'bg-transparent border-none': !isSolved && !isRecentlyWrong,
              'bg-teal-50 border-none': isSolved,
              'bg-red-50 border-none': isRecentlyWrong,
            }"
            placeholder="Hinweis"
            rows="1"
            v-model="props.node.attrs.hintText"
            @input="updateMinTextareaHeight($event)"
            :disabled="!props.editor.isEditable"
          />
        </div>
      </span>
    </span>
  </node-view-wrapper>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
