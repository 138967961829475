<script setup lang="ts">
import { computed, ref } from 'vue';
import { Star, StarHalf } from 'lucide-vue-next';

const props = defineProps({
  maxRating: {
    type: Number,
    default: 5,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md', // sm, md, lg
  },
});

const rating = defineModel<number>('modelValue', {
  default: 0,
});

const activeRating = ref<number | null>(null);
const containerRef = ref<HTMLElement | null>(null);

// Calculate rating from click/touch position
const calculateRating = (event: MouseEvent | TouchEvent) => {
  if (!containerRef.value) return 0;

  const rect = containerRef.value.getBoundingClientRect();
  const x = 'touches' in event ? event.touches[0].clientX : event.clientX;
  const relativeX = x - rect.left;
  let newRating = Math.max(0, Math.min(props.maxRating * (relativeX / rect.width), props.maxRating));

  // Optional: snap to nearest 0.5
  if (newRating < 0.5) newRating = 0.0;
  if (newRating > props.maxRating - 0.5) newRating = props.maxRating;

  return newRating;
};

// Handle continuous rating
const handleRating = (event: MouseEvent | TouchEvent) => {
  if (props.disabled) return;
  const newRating = calculateRating(event);
  activeRating.value = newRating;
};

const handleRatingStart = (event: MouseEvent | TouchEvent) => {
  if (props.disabled) return;
  handleRating(event);
};

const handleRatingEnd = () => {
  if (props.disabled || activeRating.value === null) return;
  rating.value = activeRating.value;
  activeRating.value = null;
};

const getStarClass = (starIndex: number) => {
  const currentRating = activeRating.value !== null ? activeRating.value : rating.value;
  if (currentRating < starIndex - 0.75) return 'empty';
  if (currentRating > starIndex - 0.75 && currentRating < starIndex - 0.25) return 'half';
  return 'full';
};

const getStarFill = (starIndex: number) => {
  const currentRating = activeRating.value !== null ? activeRating.value : rating.value;
  if (Math.ceil(currentRating) < starIndex) return 'none';
  return 'currentColor';
};

const sizeClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-xl';
    case 'lg':
      return 'text-3xl';
    default:
      return 'text-2xl';
  }
});
</script>

<template>
  <div
    ref="containerRef"
    class="flex items-center gap-x-1 select-none w-fit"
    :class="{ 'opacity-70': disabled }"
    @touchstart.stop.prevent="handleRatingStart"
    @touchmove.stop.prevent="handleRating"
    @touchend.stop.prevent="handleRatingEnd"
    @mousedown.stop.prevent="handleRatingStart"
    @mousemove.stop.prevent="(e) => e.buttons && handleRating(e)"
    @mouseup.stop.prevent="handleRatingEnd"
    @mouseleave.stop.prevent="handleRatingEnd"
    @click.stop.prevent
  >
    <button
      v-for="star in maxRating"
      :key="star"
      class="focus:outline-none pointer-events-none relative"
      :disabled="disabled"
    >
      <Star
        v-if="getStarClass(star) === 'full'"
        :class="[sizeClass, 'transition-colors text-yellow-400']"
        :fill="getStarFill(star)"
      />
      <template v-else-if="getStarClass(star) === 'half'">
        <Star :class="[sizeClass, 'transition-colors text-yellow-400 absolute']" fill="none" />
        <StarHalf :class="[sizeClass, 'transition-colors text-yellow-400']" fill="currentColor" />
      </template>
      <Star v-else :class="[sizeClass, 'transition-colors text-yellow-400']" fill="none" />
    </button>
  </div>
</template>
