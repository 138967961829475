<script setup>
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { boolean } from 'yup';

const router = useRouter();
const props = defineProps({
  text: {
    type: String,
    default: 'Abschicken',
  },
  type: {
    type: String,
    default: 'submit',
  },
  showProgress: {
    default: false,
  },
  onclick: {
    default: null,
  },
  disabled: {
    default: false,
  },
  color: {
    type: String,
    default: 'blue',
  },
  size: {
    type: String,
    default: 'sm',
  },
  additionalClasses: {
    type: String,
    default: '',
  },
  routerLinkTo: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  iconLeft: {
    type: Boolean,
    default: true,
  },
  iconSize: {
    type: String,
    default: null,
  },
  fontMedium: {
    type: Boolean,
    default: true,
  },
  roundedFull: {
    type: Boolean,
    default: false,
  },
  hovered: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const colorClass = computed(() => {
  if (props.color === 'white') {
    return `bg-${
      props.hovered ? 'gray-50' : 'white'
    } focus:ring-gray-200 text-gray-800 border-gray-200 hover:bg-gray-50 font-medium`;
  }

  if (props.color === 'black') {
    return 'bg-black hover:bg-gray-800 focus:ring-black text-white border-transparent font-medium';
  }

  if (props.color === 'purple') {
    return 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-600 text-white border-transparent font-medium';
  }

  if (props.color === 'teal') {
    return 'bg-teal-500 hover:bg-teal-600 focus:ring-teal-500 text-white border-transparent font-medium';
  }

  if (props.color === 'win-blue') {
    return 'bg-blue-300 hover:bg-blue-700 focus:ring-blue-300 text-white border-transparent font-medium';
  }

  // Default blue
  return 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-600 text-white border-transparent font-medium';
});

const sizeClasses = computed(() => ({
  sm: 'py-2 px-3 text-xs md:text-sm min-w-16 md:min-w-24 min-h-8 md:min-h-10',
  md: 'py-3 px-4 text-sm md:text-base min-w-24 md:min-w-28 min-h-10 md:min-h-12',
  lg: 'py-4 px-5 text-base md:text-lg min-w-28 md:min-w-36 min-h-12 md:min-h-14',
}));

const iconSizeClasses = computed(() => {
  if (props.iconSize) {
    return {
      sm: `text-${props.iconSize}`,
      md: `text-${props.iconSize}`,
      lg: `text-${props.iconSize}`,
    };
  }
  return {
    sm: 'text-sm',
    md: 'text-sm',
    lg: 'text-md',
  };
});

const iconClasses = computed(() => [iconSizeClasses.value[props.size]]);

const buttonClasses = computed(() => [
  'inline-flex',
  'justify-center',
  'items-center',
  'content-center',
  'align-middle',
  'gap-2',
  props.roundedFull ? 'rounded-full' : 'rounded-lg',
  'border',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'disabled:pointer-events-none',
  'transition-all',
  'shadow-sm',
  'dark:focus:ring-offset-gray-800',
  colorClass.value,
  sizeClasses.value[props.size],
  props.fontMedium ? 'font-medium' : 'font-normal',
  props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
]);

const onclick = computed(() => {
  if (props.routerLinkTo) {
    return () => {
      router.push(props.routerLinkTo);
    };
  }
  if (props.onclick) {
    return props.onclick;
  }
  return () => {
    emit('click');
  };
});
</script>

<template>
  <button
    :disabled="disabled"
    :type="type"
    :class="[buttonClasses, { 'opacity-50': disabled }]"
    @click="onclick && onclick()"
  >
    <span class="inline-flex cursor-pointer h-full w-full items-center justify-center relative">
      <span
        class="inline-flex items-center transition-opacity duration-500"
        :class="{
          'opacity-0': showProgress,
          'opacity-100': !showProgress,
        }"
      >
        <span v-show="iconLeft && !!icon" class="-ml-1 mr-1 -my-1 flex align-bottom" :class="iconClasses">
          <span v-if="icon === 'ai_sparkle'" class="mx-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-stars mysterious-pulse"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
              />
            </svg>
          </span>
          <span v-else class="material-symbols-outlined notranslate" translate="no">
            {{ icon }}
          </span>
        </span>
        <span class="text-center">{{ text }}</span>
        <span class="flex items-center min-h-full">
          <slot></slot>
        </span>
        <span
          v-show="!iconLeft && !!icon"
          class="ml-2 -my-1 material-symbols-outlined flex align-bottom"
          :class="iconClasses"
        >
          {{ icon }}
        </span>
      </span>
      <span
        class="animate-spin absolute mx-auto w-5 h-5 border-[2px] border-current border-t-transparent text-white rounded-full transition-opacity duration-500"
        role="status"
        aria-label="loading"
        :class="{
          'opacity-0': !showProgress,
          'opacity-100': showProgress,
        }"
      >
        <span class="sr-only">Loading</span>
      </span>
    </span>
  </button>
</template>
