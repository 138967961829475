<script setup>
import { useRouter } from 'vue-router';
import { useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { Play } from 'lucide-vue-next';
import CaseService from '@/services/CaseService';

const caseStore = useCaseStore();
const router = useRouter();

// Get random case details from service
const randomCaseDetails = ref(CaseService.getRandomCaseDetails());

// Fetch cases when component mounts
onMounted(async () => {
  const { allCases } = storeToRefs(caseStore);
  if (allCases.value.length === 0) {
    await caseStore.fetchAllCases();
    // Refresh random case details after fetching
    randomCaseDetails.value = CaseService.getRandomCaseDetails();
  }
});

// Watch for changes to the random case
watch(
  () => CaseService.getRandomCaseDetails(),
  (newDetails) => {
    randomCaseDetails.value = newDetails;
  },
);

const caseHeading = computed(() => {
  return randomCaseDetails.value.heading;
});

const randomCaseXpReward = computed(() => {
  if (!randomCaseDetails.value) return 0;
  return CaseService.getXpRewardForCase(randomCaseDetails.value.id);
});
</script>

<template>
  <div
    @click="
      async () => {
        // play the current random case, then get a fresh one
        await CaseService.playRandomCase(true, true);
      }
    "
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-2xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col h-[198px]"
  >
    <!-- Header -->
    <div class="flex-1">
      <div class="flex items-start justify-between">
        <div class="space-y-2">
          <p class="text-sm text-gray-600 dark:text-gray-400">{{ $t('message.startDailyCase') }}</p>
          <h3 class="text-xl font-medium text-gray-900 dark:text-white break-words leading-tight line-clamp-2">
            {{ caseHeading }}
          </h3>
        </div>
      </div>
    </div>

    <!-- Start Button -->
    <div class="flex items-center justify-between mt-auto">
      <button
        class="inline-flex items-center gap-1 pl-3 pr-4 py-2 bg-white text-gray-800 font-semibold rounded-full hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
      >
        <Play size="16" class="dark:text-white rounded" fill="currentColor" />
        {{ $t('message.startDailyCase') }}
      </button>
      <div class="flex items-center whitespace-nowrap gap-1 text-sm text-gray-600 dark:text-gray-400">
        <span>+{{ randomCaseXpReward }} XP</span>
      </div>
    </div>
  </div>
</template>
