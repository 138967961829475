import { defineStore } from 'pinia';

import { Case, SectionContentItemOverview } from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { joinURL } from '@/helper';

export const useCaseStore = defineStore({
  id: 'case',
  state: () => ({
    currentCase: null as Case | null,
    currentFormDataContentItem: null as SectionContentItemOverview | null,
    allCases: [] as Case[],
    fetchInProgress: false,
  }),
  getters: {
    studentRole(state) {
      return state.currentCase?.student_details.role;
    },
    isFormCase(state) {
      return state.currentFormDataContentItem !== null;
    },
  },
  actions: {
    async setCurrentCase(caseId: string) {
      this.currentFormDataContentItem = null;
      this.currentCase = await (await getApiClient()).cases.getCase(caseId);
    },
    async setCurrentFormCase(formCaseContentItem: SectionContentItemOverview) {
      this.currentFormDataContentItem = formCaseContentItem;
      if (!formCaseContentItem.form_case?.case_id) {
        throw new Error('Case ID is missing when trying to set current form case in case store');
      }
      this.currentCase = await (await getApiClient()).cases.getCase(formCaseContentItem.form_case.case_id);
    },
    async fetchAllCases() {
      this.fetchInProgress = true;
      this.allCases = await (await getApiClient()).cases.listCases();
      this.fetchInProgress = false;
    },
    async fetchCasesIfEmpty() {
      if (this.allCases.length === 0) {
        await this.fetchAllCases();
      }
    },
    async listAllCases(forceRefresh = false) {
      if (forceRefresh || this.allCases.length === 0) {
        await this.fetchAllCases();
      }
      return this.allCases;
    },
    // async getTrendingCases() {
    //   // console.debug('getting trending cases from API');
    //
    //   const cases = await (await getApiClient()).cases.listCases();
    //
    //   return cases;
    // },
    async getNumCasesUnsolved() {
      // TODO: implement sth useful here; like new unsolved cases or new trending case or sth alike
      return 57;
    },
    async getCasesByTagsAndSpecialties(tags: string[], specialties: string[]) {
      // console.debug('getting cases by tags and specialties from API');

      // TODO: generate client fails to correctly geneate request with query paramters
      // const cases = await (await getApiClient()).cases.getCasesToMultipleCaseLists({
      //   tag_names: tags,
      //   other_case_list_names: specialties
      // });

      const cases = await (await getApiClient()).cases.listCases(tags, specialties);

      // // Convert arrays to comma-separated strings
      // const tagsParam = tags.length > 0 ? `tag_names=${tags.join(',')}` : '';
      // const specsParam = specialties.length > 0 ? `other_case_list_names=${specialties.join(',')}` : '';
      //
      // // Construct the URL with query parameters
      // const queryParams = [tagsParam, specsParam].filter(param => param !== '').join('&');
      // const url = `/cases?${queryParams}`;
      //
      // const client = await getStreamingClient()
      // const full_url = joinURL('http://' + client.baseUrl, url);
      //
      // console.log('URL: ' + url)
      //
      // const cases = await fetch(
      //     full_url, {
      //         method: 'GET',
      //         headers: {
      //             'Content-Type': 'application/json',
      //             Authorization: `Bearer ${localStorage.getItem('token')}`,
      //         }
      //     })
      //     .then(response => {
      //         if (!response.ok) {
      //             throw new Error(`HTTP error! Status: ${response.status}`);
      //         }
      //         const data = response.text()
      //         console.debug('Response: ' + JSON.stringify(data))
      //         return data;
      //     });

      return cases;
    },
    resetCurrentCase() {
      this.currentCase = null;
      this.currentFormDataContentItem = null;
    },
  },
});
