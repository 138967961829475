import { ref } from 'vue';

export function useOrganizationMemberHighlighting() {
  const highlightedUser = ref<string | null>(null);

  function highlightUser(userId: string) {
    highlightedUser.value = userId;
  }

  function clearHighlight() {
    highlightedUser.value = null;
  }

  return {
    highlightedUser,
    highlightUser,
    clearHighlight,
  };
}
