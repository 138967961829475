<!-- TODO 1: link feedback to new dialog form (=trascript), mark errors therein -->
<!-- TODO 2: check if exercise completed -->
<!-- TODO 3: emit add new vocab -->

<script setup lang="ts">
import BarthelAsklepios from '@/components/customer_forms/BarthelAsklepios.vue';
import { onMounted, reactive, ref, watch, nextTick } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { storeToRefs } from 'pinia';
import { ratio } from 'fuzzball';
import CreateListeningDialog from '@/components/didactics/listening_dialog/create/CreateListeningDialog.vue';
import DialogHistory from '@/components/didactics/listening_dialog/history/DialogHistory.vue';
import { useAutosave } from '@/composables';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// stores
const courseInteractionStore = useCourseInteractionStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const courseStore = useCourseStore();
const alertStore = useAlertStore();

// props
const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
});

// composables
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.contentItem.resource_with_questions) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Lese-/Hörverständnisübung - keine Übung', 'Error');
      return;
    }
    if (!props.isEditing) return;

    await courseStore.updateFormStructuredExercise(props.contentItem.section_id, props.contentItem.id, {
      task_instructions: localContentItem.form_structured_exercise.task_instructions,
      case_story: localContentItem.form_structured_exercise.case_story,
      case_description: localContentItem.form_structured_exercise.case_description,
    });
    caseStoryEditor.value?.resetEmitState();

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

// refs
const caseStoryEditor = ref(null);
const createListeningDialogModal = ref(null);

// state
const localContentItem = reactive(props.contentItem);
const isLoading = ref(true);
const isGeneratingDialog = ref(false);
const form = ref(null);
const isShowingFeedback = ref(false);
const caseStoryEditorKey = ref(0);
const dialogUpdateTimestamp = ref(new Date().toISOString());
const hasDialog = ref(false);
const dialogIsFetched = ref(false);
const dialog = ref(null);

// lifecycle
onMounted(async () => {
  console.log('FormStructuredExercise onMounted');
  if (!props.contentItem.form_structured_exercise) return;
  localContentItem.form_structured_exercise = props.contentItem.form_structured_exercise;
  isLoading.value = false;

  if (props.contentItem.form_structured_exercise?.dialog_id) {
    hasDialog.value = true;
    dialog.value = await (
      await getApiClient()
    ).dialogs.getDialog(props.contentItem.form_structured_exercise.dialog_id, props.contentItem.id);
    dialogIsFetched.value = true;
  } else {
    hasDialog.value = false;
    dialog.value = null;
  }
});

// methods
watch(
  () => dialogUpdateTimestamp.value,
  async () => {
    if (dialog.value) {
      dialogIsFetched.value = false;
      dialog.value = await (
        await getApiClient()
      ).dialogs.getDialog(props.contentItem.form_structured_exercise.dialog_id, props.contentItem.id);
      dialogIsFetched.value = true;
    }
  },
);

watch(
  () => !!props.contentItem.form_structured_exercise,
  () => {
    localContentItem.form_structured_exercise = props.contentItem.form_structured_exercise;
  },
);

const refreshCaseStoryEditor = () => {
  caseStoryEditorKey.value += 1;
};

const generateDialogFromForm = async () => {
  if (!form.value) return;
  console.log('Starting dialog generation...');

  try {
    console.log('Saving form...');
    await form.value.saveForm();
    console.log('Form saved');
    await saveChanges();
    console.log('Instructions saved');

    await nextTick(); // the saving makes a remount

    isGeneratingDialog.value = true;
    console.log('Getting API client...');
    const client = await getApiClient();
    console.log('Making API request...');
    const dialogPromise = client.courseSectionItems.generateDialogForFormStructuredExerciseItem(props.contentItem.id);

    console.log('Waiting for dialog...');
    const dialog = await dialogPromise.then((result) => {
      console.log('Dialog received!');
      return result;
    });

    console.log('Updating content...');
    localContentItem.form_structured_exercise.dialog_id = dialog.id;
    await nextTick();
    refreshCaseStoryEditor();
    alertStore.success('Patientendialog wurde erfolgreich erzeugt');
  } catch (error) {
    console.error('Error in dialog generation:', error);
    alertStore.error('Failed to generate dialog from form', 'Error', error);
  } finally {
    console.log('Finishing dialog generation...');
    isGeneratingDialog.value = false;
  }
};

// Add a watch to debug the isGeneratingDialog state
watch(
  () => isGeneratingDialog.value,
  (newVal) => {
    console.log('isGeneratingDialog changed to:', newVal);
  },
  { immediate: true },
);

watch(
  () => !!form.value?.isSolved,
  async (newVal) => {
    if (!newVal) return;
    console.log('isSolved', newVal);
    await courseInteractionStore.setSectionContentItemCompletedIfNotAlready(
      props.contentItem.id,
      props.sectionIndex,
      props.pageIndex,
      t,
    );
  },
);

watch(
  () => !!form.value?.feedback,
  (newVal) => {
    if (!newVal) return;
    let feedback = form.value.feedback;
    // feedback.wrong_categories.forEach((wrongCategory: any) => {
    //   // take localContentItem and mark everything red which is in wrongCategory.quotation
    //
    //   localContentItem.form_structured_exercise.case_story = localContentItem.form_structured_exercise.case_story.replace(
    //       wrongCategory.quotation,
    //       `<span style="color: #EF4444">${wrongCategory.quotation}</span>`
    //   );
    //   isShowingFeedback.value = true;
    // });

    const similarityThreshold = 85;

    // Function to split text into sentences (basic splitting by `.`, `?`, or `!`)
    function splitIntoSentences(text) {
      return text.match(/[^.!?]*[.!?]/g) || [text];
    }

    // Function to escape HTML entities for safe manipulation
    function escapeHtmlEntities(text) {
      const div = document.createElement('div');
      div.innerText = text;
      return div.innerHTML;
    }

    // Function to safely wrap only text nodes in <span> tags
    function highlightWords(sentence, colorClass) {
      // Use a regex to split content into HTML tags and text
      const parts = sentence.split(/(<[^>]+>)/g); // Split by HTML tags, keeping them in the result

      return parts
        .map((part) => {
          if (part.startsWith('<') && part.endsWith('>')) {
            // If it's an HTML tag, return it untouched
            return part;
          }

          // Otherwise, process the text content
          const words = part.split(/(\s+)/); // Split by spaces, keeping spaces as separators

          // Highlight matching words
          return words
            .map((word) => {
              if (word.trim().length > 0) {
                // Wrap only non-empty words
                return `<span style="color: ${colorClass}">${escapeHtmlEntities(word)}</span>`;
              }
              return escapeHtmlEntities(word); // Keep spaces intact
            })
            .join(''); // Rejoin the words
        })
        .join(''); // Rejoin all parts (HTML tags and highlighted text)
    }

    // Function to highlight all similar sentences in a text
    function highlightSimilarPassages(text, targetText, similarityThreshold) {
      const sentences = splitIntoSentences(text); // Sentences in the case story
      const targetSentences = splitIntoSentences(targetText); // Sentences in the quotation

      return sentences
        .map((sentence) => {
          const cleanSentence = sentence.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags for clean comparison

          // Check similarity with any of the target sentences
          const isSimilar = targetSentences.some((target) => {
            const cleanTarget = target.replace(/<\/?[^>]+(>|$)/g, '');
            const similarity = ratio(cleanSentence, cleanTarget); // Use Fuzzball's ratio function
            return similarity >= similarityThreshold;
          });

          // Highlight if any match is found
          if (isSimilar) {
            return highlightWords(sentence, '#EF4444'); // Highlight each word in the sentence
          }
          return sentence; // Return the sentence unchanged if not similar
        })
        .join(''); // Join back into a single text
    }

    // Apply the logic for each wrong category
    feedback.wrong_categories.forEach((wrongCategory) => {
      localContentItem.form_structured_exercise.case_story = highlightSimilarPassages(
        localContentItem.form_structured_exercise.case_story,
        wrongCategory.quotation,
        similarityThreshold,
      );

      localContentItem.form_structured_exercise.case_story =
        localContentItem.form_structured_exercise.case_story.replace('<br>', '');
      isShowingFeedback.value = true;
    });
  },
);
</script>

<template>
  <div class="w-full flex-col flex p-2">
    <h2 class="text font-semibold text-center">Übung: Barthel-Index dokumentieren</h2>
    <div>
      <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4 py-2" v-show="props.isEditing">
        <span translate="no" class="material-symbols-outlined notranslate text-2xl select-none">info</span>
        <h3 class="text-sm text-justify">
          Fülle das Barthel-Index-Formular aus. Aus diesem wird auf Knopfdruck ("Patientengespräch erzeugen")
          automatisch ein Pflegekraft-Patienten-Gespräch erzeugt. Dies kannst du nach Belieben überarbeiten.
        </h3>
        <h3 class="text-sm text-justify">
          Deine Schüler:innen absolvieren die Aufgabe in umgekehrter Reihenfolge: Sie lesen oder hören das Gespräch und
          dokumentieren die Inhalte dann in dem Formular.
        </h3>
      </div>

      <h2
        class="bg-white pt-4 pb-1 w-full flex-col flex rounded-lg text-center"
        :class="{ 'border border-gray-200 shadow-sm': props.isEditing }"
      >
        <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
          Aufgabenstellung
        </span>
        <span v-if="!props.isEditing && !isLoading" class="text-xs md:text-sm">{{
          localContentItem.form_structured_exercise?.task_instructions
        }}</span>
        <textarea
          v-if="props.isEditing && !isLoading"
          v-model="localContentItem.form_structured_exercise.task_instructions"
          @change="unsavedChanges = true"
          rows="3"
          placeholder="Aufgabenstellung, die dem User angezeigt werden soll, in der Regel etwas wie
            'Lies dir das Gespräch durch und dokumentiere den Barthel-Index des Patienten auf dem Formular.
            Achte darauf, es vollständig auszufüllen!"
          class="w-full font-normal text-xs md:text-sm text-center border-gray-200 rounded-lg resize-none min-w-2"
        />
      </h2>
    </div>

    <div class="w-full pt-6 flex-col flex items-center">
      <p class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
        Kurzbeschreibung des Patienten
      </p>
      <!--      <p class="text-blue-600 text-sm text-justify">-->
      <!--        Nur für die Erzeugung des Gesprächs relevant, für deine Schülerinnen nicht sichtbar-->
      <!--      </p>-->
      <div
        class="bg-white pb-1 w-full rounded-lg text-center"
        :class="{ 'border border-gray-200 shadow-sm': props.isEditing }"
      >
        <span v-if="!props.isEditing && !isLoading" class="text-xs pt-2 md:text-sm">{{
          localContentItem.form_structured_exercise.case_description
        }}</span>
        <textarea
          v-if="props.isEditing && !isLoading"
          v-model="localContentItem.form_structured_exercise.case_description"
          @change="unsavedChanges = true"
          rows="3"
          placeholder="Genauere Beschreibung des Patienten (optional)"
          class="w-full font-normal text-xs md:text-sm text-center border-gray-200 rounded-lg resize-none min-w-2"
        />
      </div>
    </div>

    <div v-if="!props.isEditing && hasDialog" class="w-full pt-6 flex-col flex items-center">
      <p class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">Gesprächsverlauf</p>
      <!-- this displays the case for the user -->

      <!-- <div class="text-xs md:text-sm">
        <TextEditor
          v-if="!isShowingFeedback"
          :content="sanitizedHtml(props.contentItem.form_structured_exercise.case_story)"
          :allowEdit="false"
        />
        <div
          v-else
          class="w-full text-xs md:text-sm flex-col flex gap-y-2"
          v-html="sanitizedHtml(localContentItem.form_structured_exercise.case_story)"
        ></div>
      </div> -->

      <DialogHistory
        v-if="dialogIsFetched"
        :dialog="dialog"
        :updateTimestamp="dialogUpdateTimestamp"
        :allowViewClosedCaptions="localContentItem.form_structured_exercise.show_dialog_closed_captions"
        :isEditing="props.isEditing"
      />
      <LoadingSpinnerLarge v-else />
    </div>

    <div class="w-full flex-col flex items-center overflow-x-scroll pt-6">
      <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
        Formular zum Barthel-Index <span v-if="props.isEditing">(Musterlösung)</span>
      </span>
      <BarthelAsklepios
        ref="form"
        :sectionIndex="props.sectionIndex"
        :contentItem="props.contentItem"
        :allowSubmit="!props.isEditing"
        :isEditingSolution="props.isEditing"
      />
    </div>

    <div v-if="props.isEditing" class="w-full relative items-center flex-col flex">
      <div class="absolute -top-12 z-0 flex-col flex gap-y-4">
        <div class="mx-auto material-symbols-outlined text-gray-300 text-8xl mt-3">keyboard_double_arrow_down</div>
        <div class="mx-auto material-symbols-outlined text-gray-300 text-8xl mt-3">keyboard_double_arrow_down</div>
      </div>
      <div class="pt-14 pb-24 z-10">
        <ProgressButton
          icon="ai_sparkle"
          :disabled="!form?.isValid || isLoading"
          text="Passenden Dialog erzeugen lassen"
          @click="generateDialogFromForm"
        />
      </div>
      <!-- lets the teacher edit the case -->
      <div class="w-full" v-if="!isGeneratingDialog">
        <div class="w-full relative flex justify-center py-8" :key="dialogUpdateTimestamp">
          <div class="absolute top-4 right-3">
            <ProgressButton
              v-if="props.isEditing"
              icon="manufacturing"
              iconSize="2xl"
              color="blue"
              :text="hasDialog ? 'Dialog bearbeiten' : 'Dialog erstellen'"
              @click="createListeningDialogModal.open()"
            />
          </div>
          <DialogHistory
            v-if="dialogIsFetched"
            :dialog="dialog"
            :updateTimestamp="dialogUpdateTimestamp"
            :allowViewClosedCaptions="localContentItem.form_structured_exercise.show_dialog_closed_captions"
          />
          <LoadingSpinnerLarge v-else-if="hasDialog" />
        </div>

        <div v-show="props.isEditing" class="flex items-center justify-center w-full">
          <input
            :checked="!localContentItem.form_structured_exercise.show_dialog_closed_captions"
            @change="
              () => {
                localContentItem.form_structured_exercise.show_dialog_closed_captions =
                  !localContentItem.form_structured_exercise.show_dialog_closed_captions;
                unsavedChanges = true;
              }
            "
            type="checkbox"
            class="shrink-0 mr-2 border-gray-200 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
          />
          <span class="text-xs md:text-sm">Dialog-Untertitelung für Schüler:innen verbergen</span>
        </div>

        <CreateListeningDialog
          ref="createListeningDialogModal"
          :contentItemId="props.contentItem.id"
          :overlayId="`create-listening-dialog-modal-${props.contentItem.id}`"
          @updated="
            () => {
              dialogUpdateTimestamp = new Date().toISOString();
              unsavedChanges = true;
            }
          "
          :fromDialogId="props.contentItem.form_structured_exercise.dialog_id"
        />

        <!-- <TextEditor
          :key="caseStoryEditorKey"
          ref="caseStoryEditor"
          :content="sanitizedHtml(localContentItem.form_structured_exercise.case_story)"
          :allow-eit="true"
          :allow-formatting="true"
          :allow-lists="true"
          :allow-table="true"
          @unsavedChanges="
            (updatedContent) => {
              localContentItem.form_structured_exercise.case_story = updatedContent;
              unsavedChanges = true;
            }
          "
          @blur="console.log('blurred')"
        /> -->
      </div>
      <div v-else class="pt-20 flex justify-center items-center h-full">
        <LoadingSpinnerLarge />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
