<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import ExerciseMediaUploadCard from '@/components/didactics/pair_of_terms/ExerciseMediaUploadCard.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  isCorrect: {
    type: Boolean,
    default: false,
  },
  showResults: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  wasSolvedBefore: {
    type: Boolean,
    default: false,
  },
  isItem1: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
    default: 0,
  },
  contentItemId: {
    type: String,
    required: true,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const localItem = ref(props.item);

watch(
  () => props.item,
  () => {
    // if editing, we want to retain the local media file, so we can view it even if game not saved
    // if not editing, we need to update this by the external prop to handle drag/drop swaps of cards
    if (props.isEditing) return;
    localItem.value = props.item;
  },
  { immediate: true },
);

const emit = defineEmits(['inputItem']);
const textareaRef = ref(null);
const cardRef = ref(null);

watch(
  () => props.isEditing,
  () => {
    if (props.isEditing) {
      if (!props.item.content) return;
      updateMinTextareaHeight(textareaRef.value);
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (props.isEditing) {
    if (!props.item.content) return;
    updateMinTextareaHeight(textareaRef.value);
  }
});
</script>

<template>
  <!-- Card -->
  <a
    ref="cardRef"
    :key="props.item.id"
    class="group z-10 w-full flex flex-col border rounded-xl transition dark:bg-neutral-900 dark:border-neutral-800 cursor-pointer items-center justify-center pair-item"
    :class="{
      'bg-green-50 border-green-500 text-green-700': props.isCorrect && props.showResults,
      'bg-red-50 border-red-500 text-red-700': !props.isCorrect && !props.wasSolvedBefore && props.showResults,
      'bg-orange-light border-orange border-opacity-20 text-orange': props.isSelected && !props.showResults,
      'bg-gray-light border-gray-light text-gray-800': !props.isSelected && !props.showResults && !props.isEditing,
      'bg-white': props.isEditing,
      'pop-out': props.wasSolvedBefore,
    }"
    :style="{
      width: props.width + 'px',
      minHeight: '96px',
    }"
  >
    <div
      v-if="!!props.item"
      class="w-full h-full flex items-center justify-center p-2"
      :style="{ width: props.width + 'px' }"
    >
      <div class="flex min-w-full">
        <div class="flex-col flex gap-y-1 w-full">
          <span
            v-show="!isEditing"
            class="select-none flex items-center justify-center text-center font-medium text-xl fallback-break"
            :style="{ whiteSpace: 'normal' }"
          >
            {{ props.item.content }}</span
          >
          <textarea
            ref="textareaRef"
            v-show="isEditing"
            type="text"
            class="resize-none text-xs md:text-sm p-2 border border-gray-200 rounded-md dark:bg-neutral-800 dark:border-neutral-700"
            :value="props.item.content"
            :placeholder="props.isItem1 ? 'z.B. ein Begriff' : 'z.B. seine Erklärung'"
            :style="{ width: props.width - 16 + 'px' }"
            @input="
              async (event) => {
                await updateMinTextareaHeight(event.target);
                $emit('inputItem', { ...props.item, content: event.target.value });
              }
            "
            :rows="1"
          />
          <ExerciseMediaUploadCard
            :contentItemId="props.contentItemId"
            v-if="props.isEditing"
            v-show="!localItem.media_item_id"
            @uploaded-item="
              async (mediaItemId, mediaItemType, mediaItemUrl) => {
                $emit('inputItem', { ...props.item, media_item_id: mediaItemId });
                localItem = { ...props.item, media_item_id: mediaItemId };
                localItem.media_item = {
                  media_url: mediaItemUrl,
                  media_item_type: mediaItemType,
                };
              }
            "
          />
          <div class="w-full relative pointer-events-none" v-if="!!localItem.media_item_id" :key="props.item.id">
            <!-- TODO fix remove button not clickable -->
            <MediaHighlightCard
              :content-item-id="null"
              :url="localItem.media_item.media_url"
              :type="localItem.media_item.media_item_type"
              :allow-edit="props.isEditing"
              :max-on-click="false"
              :is-maximized="true"
              :show-description="false"
              :normalize-height="false"
              :hide-remove-button-because-not-working="true"
              @removeMediaItem="
                async () => {
                  $emit('inputItem', { ...props.item, media_item_id: null });
                  localItem = { ...props.item, media_item_id: null };
                  localItem.media_item = {
                    media_url: '',
                    media_item_type: '',
                  };
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
  </a>
  <!-- End Card -->
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}

.pair-item {
  transition: transform 0.3s ease-in-out;
}

.pop-out {
  transform: scale(0);
}

.pop-in {
  animation: popIn 0.3s ease-out forwards;
}

@keyframes popIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
