<script setup lang="ts">
import { PropType, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';

const props = defineProps({
  color: {
    type: String as PropType<'blue' | 'green' | 'teal' | 'black'>,
    default: 'black',
  },
  show: {
    type: Boolean,
    default: false,
  },
  blockClickIfShown: {
    type: Boolean,
    default: true,
  },
  solidBackground: {
    type: Boolean,
    default: false,
  },
  timeout: {
    type: Number,
    default: 6000,
  },
});

const timeoutTimer = ref<NodeJS.Timeout | null>(null);

const setTimeoutTimer = () => {
  timeoutTimer.value = setTimeout(() => {
    throw new Error('LoadingSpinnerFullScreen timeout');
  }, props.timeout);
};

const clearTimeoutTimer = () => {
  if (!timeoutTimer.value) return;
  clearTimeout(timeoutTimer.value);
  timeoutTimer.value = null;
};

watch(
  () => props.show,
  (newValue) => {
    if (!newValue) {
      // show changed to false, clear timeout, if any
      clearTimeoutTimer();
      return;
    }
    // show changed to true, set timeout
    setTimeoutTimer();
  },
);

onBeforeUnmount(() => {
  clearTimeoutTimer();
});

onMounted(() => {
  if (!props.show) return;
  setTimeoutTimer();
});
</script>

<template>
  <div
    class="absolute z-10 top-0 left-0 right-0 bottom-0 flex h-full items-center justify-center transition-opacity duration-500"
    :class="{
      'opacity-0': !show,
      'pointer-events-auto': blockClickIfShown && show,
      'pointer-events-none': !blockClickIfShown || !show,
      'bg-gray': solidBackground,
      'bg-transparent': !solidBackground,
    }"
  >
    <LoadingSpinnerLarge :color="color" />
  </div>
</template>

<style scoped></style>
