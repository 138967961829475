<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, ref } from 'vue';

const props = defineProps({
  preview: {
    type: Boolean,
    default: false,
  },
  paragraphs: {
    type: Array,
    required: false,
  },
  summary: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  author: {
    type: Object,
    required: false,
  },
  image: {
    type: String,
    required: false,
  },
  showMore: {
    type: Boolean,
    default: false,
  },
  showMoreLabel: {
    type: String,
    default: 'Mehr erfahren',
  },
  url: {
    type: String,
    required: false,
  },
});

const thumbnailImage = ref();
const images = import.meta.glob('@/assets/images/blog/*.{png,jpg,jpeg,svg}');

const getImagePath = computed(async () => {
  if (!props.image) {
    return null;
  }
  try {
    const importImage = images[`/src/assets/images/blog/${props.image}`];
    if (importImage) {
      const module = await importImage();
      return module.default;
    } else {
      throw new Error('Image not found');
    }
  } catch (error) {
    console.error('Image not found:', error);
    return null;
  }
});

onMounted(async () => {
  thumbnailImage.value = await getImagePath.value;
});
</script>

<template>
  <div v-if="!!thumbnailImage" class="items-center min-w-full w-full">
    <div v-if="props.preview" class="items-center min-w-full w-full">
      <div class="min-w-full w-full max-w-full flex justify-center mx-auto px-6">
        <!-- Card -->
        <a class="min-w-full w-full max-w-full group flex flex-col focus:outline-none" href="#">
          <div class="min-w-full w-full max-w-full relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <img
              class="min-w-full w-full max-w-full absolute top-0 start-0 object-cover group-hover:scale-105 group-focus:scale-105 transition-transform duration-500 ease-in-out rounded-2xl"
              :src="thumbnailImage"
            />
          </div>

          <div class="mt-4">
            <h3
              class="text-2xl font-medium text-gray-800 group-hover:text-gray-600 dark:text-neutral-300 dark:group-hover:text-white"
            >
              {{ props.title }}
            </h3>
            <p class="mt-2 text-base text-gray-800 dark:text-neutral-200">
              {{ props.summary }}
            </p>

            <a
              v-if="props.showMore"
              class="mt-5 inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 group-hover:underline group-focus:underline font-medium dark:text-blue-500"
              :href="props.url"
            >
              {{ props.showMoreLabel }}
              <svg
                class="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </div>
        </a>
        <!-- End Card -->
      </div>
    </div>
    <div v-else>test</div>
  </div>
</template>

<style scoped></style>
