<script setup lang="ts">
import MainHeader from '@/components/headers/MainHeader.vue';
import { ref, onMounted, onBeforeUnmount, computed, nextTick, watch } from 'vue';
import { debounce } from 'lodash';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores';
import VocabTestModalNew from '@/components/didactics/vocab/VocabTestModalNew.vue';
import { useAlertStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';
import { v4 as uuidv4 } from 'uuid';
import { VocabItem } from '@/apiclient';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const authStore = useAuthStore();
const alertStore = useAlertStore();
const { vocabLists } = storeToRefs(authStore);

const outerHeader = ref(null);
const outerHeaderHeight = ref(0);
const mobileInteractionsFooter = ref(null);
const mobileInteractionsFooterHeight = ref(0);
const route = useRoute();
const modalInstanceKey = ref(0);
const vocabTestModalIsOpen = ref(false);
const randomVocabPick = ref(null as VocabItem[] | null);
const lastDailyVocabTestWasFinished = ref(null as boolean | null);
const currentTestSessionId = ref<string>(uuidv4());
const transitionDirection = ref(''); // Tracks the transition direction (e.g., 'slide-left' or 'slide-right')

const handleTransitionDirection = (direction) => {
  console.log('received transition: ' + direction);
  transitionDirection.value = direction;
};

const props = defineProps({
  nItemsForDaily: {
    type: Number,
    default: 5,
  },
});

const pickRandomVocabItems = (nItems: number) => {
  /*
   * Pick new random vocab items, mixed from active vocab list and personal "archive" (=all vocab items)
   * - 20% of the items are from the active vocab list, 80% from the archive
   * Set currentTestSessionId to a new uuid with new pick.
   */
  let jitter = Math.round(Math.random() - 0.5);

  let nItemsOld = Math.ceil(0.2 * nItems) + jitter;
  let nItemsNew = nItems - nItemsOld;

  console.log('nItemsOld: ', nItemsOld);
  console.log('nItemsNew: ', nItemsNew);

  let newVocabItems = vocabLists.value[1]?.vocab_items?.sort(() => Math.random() - 0.5).slice(0, nItemsNew) || [];
  let oldVocabItems = vocabLists.value[0]?.vocab_items?.sort(() => Math.random() - 0.5).slice(0, nItemsOld) || [];

  console.log('newVocabItems: ', newVocabItems);
  console.log('oldVocabItems: ', oldVocabItems);

  let pick = [...newVocabItems, ...oldVocabItems];
  if (pick.length < nItems) {
    let additionalItems =
      vocabLists.value[0]?.vocab_items?.sort(() => Math.random() - 0.5).slice(0, nItems - pick.length) || [];
    pick = [...pick, ...additionalItems];
  }

  console.log('pick: ', pick);

  randomVocabPick.value = pick;
  currentTestSessionId.value = uuidv4();
};

const adjustHeight = async () => {
  await nextTick();
  if (outerHeader.value) {
    const newHeight = outerHeader.value.offsetHeight;
    console.log('New header height:', newHeight); // Debug log
    if (newHeight < 10) {
      // Sanity check for unreasonably small heights
      console.warn('Suspiciously small header height detected');
      return; // Don't update with invalid height
    }
    outerHeaderHeight.value = newHeight;
  }
  if (mobileInteractionsFooter.value) {
    mobileInteractionsFooterHeight.value = mobileInteractionsFooter.value.offsetHeight;
  }
};

const outerObjectsHeight = computed(() => {
  return outerHeaderHeight.value + mobileInteractionsFooterHeight.value;
});

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onMounted(async () => {
  window.addEventListener('resize', debouncedAdjustHeight);

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  // Initial height calculation with retry mechanism
  let attempts = 0;
  const maxAttempts = 5;

  const tryAdjustHeight = async () => {
    await adjustHeight();
    if (outerHeaderHeight.value < 10 && attempts < maxAttempts) {
      attempts++;
      console.log(`Retrying height calculation, attempt ${attempts}`);
      setTimeout(tryAdjustHeight, 100);
    }
  };

  await tryAdjustHeight();
  await authStore.fetchUserVocabLists();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${outerObjectsHeight.value}px)`,
  };
});

const computedStyleMainPlusHeader = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh)`,
  };
});

const takeDailyQuickTest = async () => {
  console.log('LoggedInRoot: takeDailyQuickTest');
  modalInstanceKey.value++; // Increment the key to force recreation
  await authStore.fetchUserVocabLists();
  await new Promise((resolve) => setTimeout(resolve, 50));
  if (lastDailyVocabTestWasFinished.value || !randomVocabPick.value) {
    pickRandomVocabItems(props.nItemsForDaily);
  }
  vocabTestModalIsOpen.value = true;
};

const hideOldHeaderOnMobile = () => {
  return (
    route.path.includes('/vocab-dashboard') ||
    route.path.includes('/course/') ||
    route.path.includes('/chapter/') ||
    route.path.includes('/section/') ||
    route.path.includes('/case-interactions') ||
    route.path.includes('/home') ||
    route.path.includes('/my-courses') ||
    route.path.includes('/wartebereich-zna')
  );
};
</script>

<template>
  <div class="flex flex-col w-screen overflow-hidden" :style="computedStyleMainPlusHeader">
    <header
      ref="outerHeader"
      class="flex-none bg-white sticky top-0 md:justify-start md:flex-nowrap z-50 w-full text-sm py-3 md:py-0 dark:bg-neutral-900"
      :class="hideOldHeaderOnMobile() ? 'hidden sm:block' : ''"
    >
      <MainHeader
        :showRootLink="true"
        :showCoursesDropdown="true"
        :showPatientCaseDropdown="true"
        :showChallengesAndEventsLink="true"
        :showCommunityLink="true"
        :showAboutUsLink="false"
        :showQuizDropdown="true"
        :showSignInOrEditProfile="true"
        @takeDailyQuickTest="takeDailyQuickTest"
      />
    </header>
    <main :style="computedStyleMain" class="flex-grow w-full overflow-hidden" ref="mainContent">
      <router-view v-slot="{ Component, route }">
        <KeepAlive>
          <component
            :is="Component"
            :outer-header-height="outerObjectsHeight"
            @transition-direction="handleTransitionDirection"
            @takeDailyQuickTest="takeDailyQuickTest"
          />
        </KeepAlive>
      </router-view>
    </main>
  </div>

  <VocabTestModalNew
    v-if="vocabTestModalIsOpen"
    :testSessionId="currentTestSessionId || uuidv4()"
    :vocabItems="randomVocabPick || vocabLists[0]?.vocab_items || []"
    :nItems="randomVocabPick?.length!"
    @closed="
      async (isFinished) => {
        console.log('VocabTestModalNew closed: ', isFinished);
        vocabTestModalIsOpen = false;
        await authStore.fetchUserVocabLists(); // to acknowledge if sth added to on or removed from active vocab
        if (!isFinished) {
          lastDailyVocabTestWasFinished = false;
          return;
        }
        lastDailyVocabTestWasFinished = true;
        await (await getApiClient()).users.noteDailyVocabTestTaken().then(async (response) => {
          console.log('VocabTestModalNew closed: ', response);
          response.notifications?.forEach((notification) => {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          });
          await authStore.fetchUserXp();
        });
      }
    "
  />
</template>

<style scoped>
/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease;
  position: absolute;
  top: 64;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-left-enter-from {
  transform: translateX(50%);
}

.slide-left-enter-to {
  transform: translateX(0%);
}

.slide-left-leave-from {
  transform: translateX(0%);
}

.slide-left-leave-to {
  transform: translateX(-50%);
}

/* Slide Right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
  position: absolute;
  top: 64;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-right-enter-from {
  transform: translateX(-50%);
}

.slide-right-enter-to {
  transform: translateX(0%);
}

.slide-right-leave-from {
  transform: translateX(0%);
}

.slide-right-leave-to {
  transform: translateX(50%);
}
</style>
