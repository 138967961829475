<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { PCMPlayer } from '@/audio';
import { useCaseInteractionStore, useAlertStore, useAuthStore } from '@/stores';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';

const emit = defineEmits(['playbackFinished', 'playingAudio', 'wordChange']);

// Props
const props = defineProps({
  message: String,
  messageIsComplete: {
    type: Boolean,
    default: true,
  },
  /**
   * @property {String} messageId - The unique identifier for the message.
   * Once this is a valid UUID, the backend will store the audio stream.
   * The messageId is sent to the backend with each chunk of the message.
   * @default 'not_yet_in_database'
   */
  messageId: {
    type: String,
    default: 'not_yet_in_database',
  },
  bufferFirstNChunks: {
    type: Number,
    default: 0,
  },
  voice: {
    type: Object, // Assuming Voice is an object type
    required: false,
  },
  volumeTrackingUpdateInterval: {
    type: [Number, null],
    default: 100, // in ms. Null or 0 to disable.
  },
});

// State
const websocket = ref(null);
const prevMessage = ref('');
const pcmPlayer = ref(null);
const fileReader = ref(null);
const audioDataBuffer = ref([]);
const audioDataQueue = ref([]);
const totalReceivedDuration = ref(0);
const fileReaderBusy = ref(false);
const numberOfChunksReceived = ref(0);
const streamingStarted = ref(false);
const playbackStarted = ref(false);
const waitingForPlayback = ref(false);
const audioPlaying = ref(false);
const playbackFinished = ref(false);
const allAudioBytesReceived = ref(false);
const uuid = ref('');
const currentWordStartTime = ref(0);
const wordTimings = ref([]);
const alertStore = useAlertStore();
const debugInterval = ref(null);
const audioVolumeInterval = ref(null);
const volumeLevel = ref(0);

const caseInteractionStore = useCaseInteractionStore();
const authStore = useAuthStore();
const { audioAutoplayEnabled: autoplay } = storeToRefs(caseInteractionStore);
const { textToSpeechEnabled: audioEnabled } = storeToRefs(authStore);

const audioElement = ref(null);

// Watchers
watch(
  () => props.message,
  (newMessage) => {
    if (props.messageIsComplete) {
      console.debug('Message is complete. Do not send new message part.');
      return;
    }
    if (!audioEnabled.value) {
      console.log('Audio is disabled. Not sending string chunk.');
      return;
    }

    const newPart = newMessage.slice(prevMessage.value.length);
    sendStringChunk(newPart);

    prevMessage.value = newMessage;
  },
);

// Methods
const startStreaming = async () => {
  if (!audioEnabled.value) {
    console.log('Audio is disabled. Not starting streaming.');
    return;
  }
  console.log(uuid.value + ': startStreaming');
  caseInteractionStore.setAudioIsStreaming(true);
  caseInteractionStore.resetCurrentlyReadingChatMessageText();
  caseInteractionStore.setFirstAudioChunkReceived(false);
  caseInteractionStore.resetSubtitles();
  caseInteractionStore.setCurrentWordIndex(-1);
  streamingStarted.value = true;
  console.log(
    'Creating websocket with voice provider: ' +
      props.voice.voice_provider +
      ', voice model: ' +
      props.voice.voice_model +
      ', voice id: ' +
      props.voice.voice_id +
      '.',
  );
  websocket.value = (await getStreamingClient()).createWebSocket(
    'tts/',
    props.voice.voice_provider,
    props.voice.voice_model,
    props.voice.voice_id,
  );

  websocket.value.onmessage = (event) => {
    if (!caseInteractionStore.currentCaseInteraction) {
      console.warn('currentCaseInteraction changed to null. Refusing reception of audio chunk.');
      return;
    }
    let data = JSON.parse(event.data);
    let readingChars = data['aligned_chars'];
    let readingDurations = data['char_durations_ms'];

    // Calculate word timings for this chunk
    const chunkTimings = calculateWordTimings(readingChars, readingDurations);

    // Add offset based on total duration of previous chunks
    const totalDurationSoFar = caseInteractionStore.subtitleWords.reduce((acc, word) => Math.max(acc, word.endTime), 0);

    const adjustedTimings = chunkTimings.map((timing) => ({
      ...timing,
      startTime: timing.startTime + totalDurationSoFar,
      endTime: timing.endTime + totalDurationSoFar,
    }));

    wordTimings.value = [...wordTimings.value, ...adjustedTimings];

    // Process audio data
    const binaryData = atob(data['audio_b64']);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      view[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'audio/wav' });

    audioDataQueue.value.push(blob);
    caseInteractionStore.updateSubtitleWords(adjustedTimings);

    caseInteractionStore.setFirstAudioChunkReceived(true);
    numberOfChunksReceived.value += 1;

    if (numberOfChunksReceived.value <= props.bufferFirstNChunks || !autoplay.value) {
      console.debug('Received chunk #' + numberOfChunksReceived.value + '. Adding to buffer.');
      return;
    }

    processNextAudioChunk();

    console.log('Calculated word timings:', chunkTimings);
    console.log('Adjusted timings:', adjustedTimings);
  };

  websocket.value.onclose = () => {
    console.log(uuid.value + ': WebSocket closed externally');
    if (props.messageIsComplete) {
      console.log(
        uuid.value + ': Text streaming completed and websocket closed externally, so all audio bytes received.',
      );
      console.log(uuid.value + ': Decreasing PCMPlayer timeout interval');
      pcmPlayer.value.setTimeoutInterval(500);
      console.log(uuid.value + ': Playback started: ' + playbackStarted.value);
      console.log(uuid.value + ': Audio data queue length: ' + audioDataQueue.value.length);
      allAudioBytesReceived.value = true;
    }
    if (!playbackStarted.value && autoplay.value) {
      console.log(uuid.value + ': No playback started yet. Initiating immediate playback.');
      processNextAudioChunk();
    }
  };

  websocket.value.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  sendStringChunk(props.message);
};

const processNextAudioChunk = () => {
  console.log(uuid.value + ': Processing next audio chunk');
  if (!caseInteractionStore.currentCaseInteraction) {
    console.warn('currentCaseInteraction changed to null. Breaking audio playback.');
    return;
  }
  if (fileReaderBusy.value) {
    console.warn('FileReader is busy.');
    return;
  }
  if (audioDataQueue.value.length === 0) {
    console.log(uuid.value + ': No audio chunks in queue.');
    return;
  }
  const blob = audioDataQueue.value.shift();
  fileReaderBusy.value = true;

  fileReader.value.onload = () => {
    const arrayBuffer = fileReader.value.result;
    const audioData = new Int16Array(arrayBuffer);

    audioDataBuffer.value.push(audioData);
    totalReceivedDuration.value += audioData.length / 16000;

    playAudio(audioData);

    if (audioDataQueue.value.length === 0) {
      console.log(uuid.value + ': Queue emptied.');
      if (allAudioBytesReceived.value) {
        console.debug('All audio bytes received and played.');
        audioPlaying.value = false;
        playbackFinished.value = true;
      }
    }
    console.log(uuid.value + ': After play audio in process next audio chunk');

    processNextAudioChunk();
    fileReaderBusy.value = false;
  };

  fileReader.value.onerror = (error) => {
    console.error('Error reading Blob:', error);
    fileReaderBusy.value = false;
    processNextAudioChunk();
  };

  fileReader.value.readAsArrayBuffer(blob);
  console.log(uuid.value + ': End of process next audio chunk');
};

const onPlaybackFinished = () => {
  console.log(uuid.value + ': Playback finished');
  console.log(uuid.value + ': Audio data queue length: ' + audioDataQueue.value.length);
  console.log(uuid.value + ': All audio bytes received: ' + allAudioBytesReceived.value);
  if (audioDataQueue.value.length > 0) {
    console.debug('But there are more audio chunks to play in the queue, so resuming.');
    processNextAudioChunk();
  } else {
    console.debug('All audio bytes received and nothing more to play.');
    audioPlaying.value = false;
    playbackFinished.value = true;
    caseInteractionStore.setAudioIsStreaming(false);
    caseInteractionStore.setIsReplaying(false); // Reset flag when playback ends
    wordTimings.value = [];
    emit('playbackFinished');
    resetWebSocket();
    // Reset PCMPlayer timing
    if (pcmPlayer.value) {
      pcmPlayer.value.resetTime();
    }
  }
};

const resetWebSocket = async () => {
  if (websocket.value) {
    // Close the existing connection if it's still open
    if (websocket.value.readyState === WebSocket.OPEN || websocket.value.readyState === WebSocket.CONNECTING) {
      websocket.value.close();
    }
    websocket.value = null;
  }

  // Reset related state
  streamingStarted.value = false;
  prevMessage.value = '';
  audioDataQueue.value = [];
  audioDataBuffer.value = [];
  numberOfChunksReceived.value = 0;
  allAudioBytesReceived.value = false;

  // Reset subtitle-related state
  caseInteractionStore.resetSubtitles();
  caseInteractionStore.setCurrentWordIndex(-1);

  await getStreamingClient(); // Ensure streaming client is ready for next connection
};

const playAudio = (audioData) => {
  waitingForPlayback.value = false;
  playbackStarted.value = true;
  audioPlaying.value = true;
  caseInteractionStore.setIsReplaying(true);
  emit('playingAudio', true);

  pcmPlayer.value.feed(audioData);
};

const sendFinished = () => {
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    console.debug('Sending finished message');
    websocket.value.send(
      JSON.stringify({
        text: '',
        is_finished: true,
        message_id: props.messageId,
      }),
    );
  } else {
    console.error('WebSocket not open when trying to send finished message.');
  }
};

const sendStringChunk = (chunk) => {
  if (!caseInteractionStore.currentCaseInteraction) {
    console.warn('currentCaseInteraction changed to null. Aborting string send to TTS.');
    return;
  }
  if (!audioEnabled.value) {
    console.log('Audio is disabled. Not sending string chunk.');
    return;
  }
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    if (!playbackStarted.value) waitingForPlayback.value = true;
    playbackFinished.value = false;
    console.debug('Sending message: ' + chunk);
    websocket.value.send(
      JSON.stringify({
        text: chunk,
        fetched_complete_message_from_db: false,
        is_finished: false,
        message_id: props.messageId,
      }),
    );
  } else {
    console.log(uuid.value + ': WebSocket not open yet. Waiting for connection...');
    setTimeout(() => {
      sendStringChunk(chunk);
    }, 500);
  }
};

watch(
  () => props.messageIsComplete,
  (newStatus) => {
    console.log(uuid.value + ': messageIsComplete changed to: ' + newStatus + '.');
    if (newStatus && streamingStarted.value) {
      console.debug('messageIsComplete changed to: ' + newStatus + '. Send finished message.');
      sendFinished();
    }
    if (!newStatus && !streamingStarted.value) {
      console.debug('messageIsComplete changed to: ' + newStatus + '. Start streaming.');
      startStreaming();
    }
  },
  { immediate: true },
);

watch(
  fileReaderBusy,
  (newStatus) => {
    if (!newStatus) {
      console.debug('fileReaderBusy changed to: ' + newStatus + '. Resume playback.');
      processNextAudioChunk();
    }
  },
  { immediate: true },
);

watch(
  autoplay,
  (newVal) => {
    console.log(uuid.value + ': autoplay is now ' + newVal);
    console.log(autoplay.value);
    if (newVal) {
      console.debug('autoplay enabled. Starting from queue of length ' + audioDataQueue.value.length);
      processNextAudioChunk();
    }
  },
  { immediate: true },
);

watch(
  () => waitingForPlayback.value,
  (newStatus) => {
    caseInteractionStore.setWaitingForAudioPlayback(newStatus);
  },
  { immediate: true },
);

// Create stable references to the callbacks
const handleStart = () => {
  caseInteractionStore.setCurrentWordIndex(-1);
};

const handleTimeUpdate = (currentTime) => {
  const store = useCaseInteractionStore();

  // Find the current word based on timing
  const currentWordIndex = store.subtitleWords.findIndex((timing) => {
    return currentTime >= timing.startTime && currentTime <= timing.endTime;
  });

  if (currentWordIndex !== -1 && currentWordIndex !== store.currentWordIndex) {
    store.setCurrentWordIndex(currentWordIndex);
  } else if (currentWordIndex === -1 && store.currentWordIndex !== -1) {
    store.setCurrentWordIndex(-1);
  }
};

// Mounted
onMounted(() => {
  if (!audioEnabled.value) {
    console.log('Audio is disabled.');
    return;
  }

  uuid.value = uuidv4();
  console.log(uuid.value + ': AudioPlayerTTS mounted');
  playbackFinished.value = true;

  pcmPlayer.value = new PCMPlayer({
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 16000,
    flushingTime: 200,
    playbackFinishedCallback: onPlaybackFinished,
  });
  pcmPlayer.value.createContext();

  if (props.volumeTrackingUpdateInterval) {
    audioVolumeInterval.value = setInterval(() => {
      volumeLevel.value = pcmPlayer.value.getCurrentVolume();
    }, props.volumeTrackingUpdateInterval);
  }

  fileReader.value = new FileReader();
  caseInteractionStore.resetCurrentlyReadingChatMessageText();
  caseInteractionStore.setFirstAudioChunkReceived(false);
  pcmPlayer.value.on('timeupdate', handleTimeUpdate);
  caseInteractionStore.resetSubtitles();

  // Reset word index when playback starts
  pcmPlayer.value.on('start', () => {
    caseInteractionStore.setCurrentWordIndex(-1);
  });

  if (!!import.meta.env.DEV) {
    console.log('DEV environment detected: Setting window.audioPlayer to pcmPlayer.value');
    window.audioPlayer = pcmPlayer.value;
  }

  // Add event listeners through the store
  // audioStore.addEventListener('timeupdate', handleTimeUpdate);
  // audioStore.addEventListener('start', handleStart);
});

const calculateWordTimings = (readingChars, readingDurations) => {
  let currentTime = 0;
  let currentWord = '';
  let wordStartTime = 0;
  let timings = [];

  for (let i = 0; i < readingChars.length; i++) {
    const char = readingChars[i];
    const duration = readingDurations[i];

    if (currentWord === '') {
      wordStartTime = currentTime;
    }

    currentWord += char;
    currentTime += duration;

    if (char === ' ' || i === readingChars.length - 1) {
      const trimmedWord = currentWord.trim();
      if (trimmedWord) {
        // Add a small buffer to the end time to ensure smooth transitions
        timings.push({
          word: trimmedWord,
          startTime: wordStartTime,
          endTime: currentTime + 50, // Add 50ms buffer
        });
      }
      currentWord = '';
    }
  }

  console.log('Word timings:', timings);
  return timings;
};

// Add cleanup in onUnmounted
onBeforeUnmount(() => {
  // Remove event listeners
  // audioStore.removeEventListener('timeupdate', handleTimeUpdate);
  // audioStore.removeEventListener('start', handleStart);
  if (debugInterval.value) {
    clearInterval(debugInterval.value);
  }
  if (audioVolumeInterval.value) {
    clearInterval(audioVolumeInterval.value);
  }

  pcmPlayer.value.destroy();
});

// Expose the unlock method to parent components
defineExpose({
  unlockAudioContext: async () => {
    if (!audioEnabled.value) {
      console.log('Audio is disabled. Not unlocking audio context.');
      return true;
    }

    let unlocked = false;

    // Create and play a silent audio element
    const silentAudio = new Audio(
      'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA',
    );
    silentAudio
      .play()
      .then(() => {
        silentAudio.pause();
        silentAudio.currentTime = 0;
      })
      .catch((e) => {
        console.warn('Failed to play silent audio:', e);
      });

    if (pcmPlayer.value) {
      unlocked = await pcmPlayer.value.unlockAudioContext();
    }

    if (!unlocked) alertStore.info('Failed to unlock audio context. Audio may be muted.');
    return unlocked;
  },
  volumeLevel,
});
</script>

<template>
  <div>
    <!-- Hidden audio element (to unlock audio context on iOS) -->
    <audio
      ref="audioElement"
      src="data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA"
      preload="auto"
      style="display: none"
    ></audio>
  </div>
</template>
