<script setup>
import { reactive, computed, ref, watch, onMounted } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { getApiClient } from '@/apiclient/client';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Quote from '@/components/didactics/form_case_exercise/Quote.vue';
import { ChevronDown } from 'lucide-vue-next';

const { t, locale } = useI18n();

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const feedback = ref(null);
const isSolved = ref(false);
const isSavingChanges = ref(false);

const props = defineProps({
  contentItem: {
    type: Object,
    required: false,
  },
  caseInteractionId: {
    type: String,
    required: false,
    default: null,
  },
  sectionIndex: {
    type: Number,
    required: false,
  },
  allowSubmit: {
    type: Boolean,
    required: false,
    default: true,
  },
  isEditingSolution: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  forCase: {
    type: Boolean,
    required: false,
  },
  reserveSpaceForBottomButtons: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const emit = defineEmits(['submit']);

const unsavedChanges = ref(false);
const internalIsLoading = ref(!props.isEditingSolution);
const isLoading = computed(() => props.isLoading || internalIsLoading.value || isGettingFeedback.value);
const isGettingFeedback = ref(false);
const showingResults = ref(false);
const isShowingQuote = reactive(new Map());

const categories = [
  {
    name: 'Essen',
    key: 'essen',
    options: [
      { label: 'unabhängig', points: 10 },
      { label: 'braucht etwas Hilfe (Brot, Fleisch schneiden)', points: 5 },
      { label: 'unselbständig (Essen muss angereicht werden)', points: 0 },
    ],
  },
  {
    name: 'Bett- / (Roll-)Stuhltransfer',
    key: 'transfer',
    options: [
      { label: 'unabhängig', points: 15 },
      { label: 'geringe Hilfen bzw. Beaufsichtigung', points: 10 },
      { label: 'erhebliche Hilfen bei Transfer, Lagewechsel', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Waschen',
    key: 'waschen',
    options: [
      { label: 'selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Toilettenbenutzung',
    key: 'toilette',
    options: [
      { label: 'selbständig', points: 10 },
      { label: 'benötigt z.T. Hilfe (Gleichgewicht, Kleidung)', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Baden',
    key: 'baden',
    options: [
      { label: 'selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Gehen auf Flurebene bzw. Rollstuhlfahren',
    key: 'gehen',
    options: [
      { label: 'selbständig >50m Hilfsmittel erlaubt (kein Gehwagen)', points: 15 },
      { label: 'geringe Hilfe, Überwachung kann mit Hilfsm. 50m gehen', points: 10 },
      { label: 'unselbständig, kann aber Rollstuhl alleine benutzen, mind. 50m', points: 5 },
      { label: 'völlig unselbständig', points: 0 },
    ],
  },
  {
    name: 'Treppensteigen',
    key: 'treppensteigen',
    options: [
      { label: 'selbständig', points: 10 },
      { label: 'Hilfe erford. Jedoch 50% selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'An- Auskleiden',
    key: 'auskleiden',
    options: [
      { label: 'selbständig', points: 10 },
      { label: 'Hilfe erford. Jedoch 50% selbständig', points: 5 },
      { label: 'unselbständig', points: 0 },
    ],
  },
  {
    name: 'Stuhlkontrolle',
    key: 'stuhlkontrolle',
    options: [
      { label: 'kontinent', points: 10 },
      { label: 'gelegentlich inkontinent (max. 1x/Woche)', points: 5 },
      { label: '(häufiger) inkontinent', points: 0 },
    ],
  },
  {
    name: 'Urinkontrolle',
    key: 'urinkontrolle',
    options: [
      { label: 'kontinent (ggf. selbständige DK/Cystofixversorgung)', points: 10 },
      { label: 'gelegentlich inkontinent (max. 1x/Tag)', points: 5 },
      { label: '(häufiger) inkontinent', points: 0 },
    ],
  },
];

const footerCategories = [
  { label: 'Summe:', key: 'sum' },
  { label: 'erhoben am:', key: 'erhobenAm' },
  { label: 'erhoben von (Handzeichen):', key: 'erhobenVon' },
];

const formData = reactive(
  categories.reduce((acc, category) => {
    acc[category.key] = { aufnahme: '', entlassung: '' };
    return acc;
  }, {}),
);

const footerFormData = reactive(
  footerCategories.reduce((acc, category) => {
    acc[category.key] = { aufnahme: '', entlassung: '' };
    return acc;
  }, {}),
);

const solutionData = reactive(
  categories.reduce((acc, category) => {
    acc[category.key] = { aufnahme: '', entlassung: '' };
    return acc;
  }, {}),
);

const sum = reactive({
  aufnahme: computed(() => {
    return Object.values(formData).reduce((total, values) => total + (Number(values.aufnahme) || 0), 0);
  }),
  entlassung: computed(() => {
    return Object.values(formData).reduce((total, values) => total + (Number(values.entlassung) || 0), 0);
  }),
});

// Add these for custom dropdown functionality
const activeDropdown = reactive({ key: null, field: null });

function toggleDropdown(key, field) {
  if (activeDropdown.key === key && activeDropdown.field === field) {
    activeDropdown.key = null;
    activeDropdown.field = null;
  } else {
    activeDropdown.key = key;
    activeDropdown.field = field;
  }
}

function selectOption(key, field, value) {
  formData[key][field] = value;
  activeDropdown.key = null;
  activeDropdown.field = null;
  unsavedChanges.value = true;
}

// Close dropdown when clicking outside
onMounted(() => {
  document.addEventListener('click', (event) => {
    const isClickInsideDropdown = event.target.closest('.custom-select-container');
    if (!isClickInsideDropdown) {
      activeDropdown.key = null;
      activeDropdown.field = null;
    }
  });
});

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditingSolution) {
        if (!courseInteractionFetchCompleted.value) return;
        courseInteractionStore.startSectionContentItemIfNotAlready(props.contentItem.id);
        return;
      }
      setTimeout(() => {
        saveForm();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

watch(
  () => props.contentItem?.form_structured_exercise?.form_data,
  (newValue) => {
    if (newValue) {
      loadForm();
    }
  },
);

watch(
  () => props.contentItem?.form_case?.form_data,
  (newValue) => {
    if (newValue) {
      loadForm();
    }
  },
);

onMounted(() => {
  loadForm();

  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      saveForm();
      return undefined;
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveForm();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

// Convert results in human-readable format
const toHumanReadable = () => {
  const result = {};

  // Process each category
  ['aufnahme', 'entlassung'].forEach((type) => {
    categories.forEach((category) => {
      const selectedValue = formData[category.key][type];
      console.log(selectedValue);
      console.log(Number(selectedValue));
      const selectedOption = category.options.find((option) => option.points === Number(selectedValue));
      const otherOptions = category.options.filter((option) => option.points !== Number(selectedValue));
      const betterOptions = category.options.filter((option) => option.points > selectedValue);

      if (!result[type]) {
        result[type] = {};
      }

      result[type][category.key] = {
        selected:
          (selectedOption != null ? selectedOption.label : 'No selection') +
          (betterOptions.length ? '' : ' (best possible)'),
        otherOptions: otherOptions.map((option) => option.label),
        betterOptions: betterOptions.map((option) => option.label),
      };
    });
  });

  console.log(result);
  console.log(sum.aufnahme, sum.entlassung);

  // Generate the patient summary string for further use
  const patientSummary = generatePatientSummary(result.aufnahme);
  // console.log('Patient Summary (at Aufnahme, with disabilities):', patientSummary);
  return patientSummary;
  //
  // const patientSummary2 = generatePatientSummary(result.aufnahme, false);
  // console.log('Patient Summary (at Aufnahme, only abilities):', patientSummary2);
};

// Function to generate a human-readable summary of the patient's abilities
const generatePatientSummary = (result, showInabilities = true) => {
  let summary = "Patient's Barthel Index Summary:\n";

  for (const [key, data] of Object.entries(result)) {
    // Find the category object to get the proper title
    const category = categories.find((cat) => cat.key === key);
    if (!category) continue;

    summary += `\n${category.name}\n`;

    // Get all options for this category
    const categoryOptions = category.options.map((option) => {
      const isSelected = option.label === data.selected.split(' (best possible)')[0];
      return `${isSelected ? '[x]' : '[ ]'} ${option.label}`;
    });

    // Add all options with checkboxes to the summary
    summary += categoryOptions.join('\n');
  }

  return summary;
};

const courseStore = useCourseStore();

const saveForm = async () => {
  if (!props.contentItem?.id) {
    throw new Error('Cannot save form: No content item ID');
  }
  if (!props.isEditingSolution) {
    throw new Error('Cannot save form: Not in editing mode');
  }
  if (isSavingChanges.value) {
    throw new Error('Cannot save form: Already saving changes');
  }
  isSavingChanges.value = true;

  let humanReadable = toHumanReadable();
  console.log('readable: ', humanReadable);

  try {
    await courseStore.updateBarthelForm(
      props.contentItem.section_id,
      props.contentItem.id,
      {
        form_data: formData,
        human_readable_form_data: humanReadable,
      },
      props.forCase,
    );
    unsavedChanges.value = false;
  } catch (error) {
    alertStore.error('Failed to autosave form', 'Error', error);
    throw new Error('Failed to autosave Asklepios Barthel form');
  } finally {
    isSavingChanges.value = false;
  }
};

function loadForm() {
  if (!props.forCase) {
    // this is in a structured exercise
    if (!props.contentItem?.form_structured_exercise?.form_data) return;

    if (props.isEditingSolution) {
      let loadedData = props.contentItem.form_structured_exercise.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (formData[key]) {
          Object.assign(formData[key], loadedData[key]);
        }
      });
    } else {
      // so we are playing, not editing as a teacher
      let loadedData = props.contentItem.form_structured_exercise.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (solutionData[key]) {
          Object.assign(solutionData[key], loadedData[key]);
        }
      });
    }
  } else {
    // this is in a case
    if (!props.contentItem?.form_case?.form_data) return;

    if (props.isEditingSolution) {
      let loadedData = props.contentItem.form_case.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (formData[key]) {
          Object.assign(formData[key], loadedData[key]);
        }
      });
    } else {
      // so we are playing, not editing as a teacher
      let loadedData = props.contentItem.form_case.form_data;
      Object.keys(loadedData).forEach((key) => {
        if (solutionData[key]) {
          Object.assign(solutionData[key], loadedData[key]);
        }
      });
    }
  }
  internalIsLoading.value = false;
}

function fillFormWithRandomValues() {
  categories.forEach((category) => {
    // Select a random option from the category's options array
    const randomOption = category.options[Math.floor(Math.random() * category.options.length)];

    // Assign the random option's points to the `aufnahme` field
    formData[category.key].aufnahme = randomOption.points;
  });
  unsavedChanges.value = true;
}

const aufnahmeAllFilled = computed(() => {
  return Object.values(formData).every((category) => category.aufnahme !== '');
});

const entlassungAllFilled = computed(() => {
  return Object.values(formData).every((category) => category.entlassung !== '');
});

const isValid = computed(() => aufnahmeAllFilled.value || entlassungAllFilled.value);

const fieldClasses = (section, field) => {
  const userValue = formData[section][field];
  // const solutionValue = solutionData[section][field];

  if (!showingResults.value) return '';

  const isCorrect = feedback.value?.correct_categories.some((category) => category.category_key === section);
  if (isCorrect) {
    return 'text-teal-500'; // Correct input
  } else if (userValue !== '') {
    return 'text-red-500'; // Incorrect input
  }
  return ''; // Neutral/default
};

const getFeedback = (categoryKey) => {
  if (!feedback.value) return '';
  const categoryFeedback = feedback.value?.correct_categories.find((category) => category.category_key === categoryKey);
  if (categoryFeedback) {
    return categoryFeedback.feedback;
  }
  const wrongCategoryFeedback = feedback.value?.wrong_categories.find(
    (category) => category.category_key === categoryKey,
  );
  return wrongCategoryFeedback ? wrongCategoryFeedback.feedback : '';
};

const getQuoteMessageIds = (categoryKey) => {
  if (!feedback.value) return null;
  const categoryFeedback = feedback.value?.correct_categories.find((category) => category.category_key === categoryKey);
  if (categoryFeedback) {
    return categoryFeedback.quotation_message_ids;
  }
  const wrongCategoryFeedback = feedback.value?.wrong_categories.find(
    (category) => category.category_key === categoryKey,
  );
  if (wrongCategoryFeedback) {
    return wrongCategoryFeedback.quotation_message_ids;
  }
  return [];
};

const isWrongCategory = (categoryKey) => {
  return feedback.value?.wrong_categories.some((category) => category.category_key === categoryKey);
};

const isSetInSolution = (categoryKey, field) => {
  return solutionData[categoryKey][field] !== '';
};

// Handle form submission
const handleSubmit = async () => {
  showingResults.value = true;
  feedback.value = null;
  isGettingFeedback.value = true;
  console.log('Form Data:', formData);
  console.log('Comparing to Solution:', solutionData);

  // Trigger class updates by ensuring reactivity
  for (const section in formData) {
    for (const field in formData[section]) {
      fieldClasses(section, field); // Update styling dynamically
    }
  }

  if (props.forCase) {
    courseInteractionStore
      .evaluateFormCaseExercise(props.contentItem.id, {
        case_interaction_id: props.caseInteractionId,
        form_type: 'BARTHEL',
        form_categories: categories,
        data_true: solutionData,
        data_student: formData,
      })
      .then(async (response) => {
        feedback.value = response;
        console.log(response);
        response.notifications?.forEach((notification) => {
          alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
        });
        await authStore.fetchUserXp();
        isSolved.value = response.wrong_categories.length === 0;
      })
      .catch((error) => {
        alertStore.error('Failed to evaluate form', 'Error', error);
        console.error(error);
        throw new Error('Failed to evaluate form');
      })
      .finally(() => {
        isGettingFeedback.value = false;
      });
    return;
  }

  throw new Error('Not implemented');
  // TODO: we need to update this for the new dialogs !
  courseInteractionStore
    .evaluateFormStructuredExercise(props.contentItem.id, {
      dialog: props.contentItem.form_structured_exercise.case_story,
      form_type: 'BARTHEL',
      description: props.contentItem.form_structured_exercise.case_description,
      human_readable_form_data_true: props.contentItem.form_structured_exercise.human_readable_form_data,
      human_readable_form_data_student: toHumanReadable(),
    })
    .then(async (response) => {
      feedback.value = response;
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      await authStore.fetchUserXp();
      isSolved.value = response.wrong_answers.length === 0;
    })
    .catch((error) => {
      alertStore.error('Failed to evaluate form', 'Error', error);
      console.error(error);
      throw new Error('Failed to evaluate form');
    })
    .finally(() => {
      isGettingFeedback.value = false;
    });
};

const togleShowQuote = (categoryKey) => {
  // // close all other quotes
  // isShowingQuote.forEach((value, key) => {
  //   if (key !== categoryKey) {
  //     isShowingQuote.delete(key);
  //   }
  // });

  if (isShowingQuote.has(categoryKey)) {
    isShowingQuote.delete(categoryKey);
  } else {
    isShowingQuote.set(categoryKey, true);
  }
};

defineExpose({
  saveForm,
  isValid,
  feedback,
  isSolved,
});
</script>

<template>
  <div v-show="isLoading" class="pt-20 w-full flex justify-center items-center h-full">
    <LoadingSpinnerLarge color="black" />
  </div>
  <div
    v-show="!isLoading"
    class="form-container h-full w-full flex-col flex overflow-auto"
    :class="{ 'pb-20': props.reserveSpaceForBottomButtons }"
    @blur="
      async () => {
        console.log('form blurred');
        if (props.isEditingSolution) await saveForm();
      }
    "
  >
    <div class="w-full flex items-center mt-1 mb-6">
      <div class="mx-auto" v-show="props.isEditingSolution">
        <ProgressButton @click="fillFormWithRandomValues" text="Fill form with random values" color="red" />
      </div>
    </div>
    <h1 class="form-title">Barthel Index (Aktivitäten des täglichen Lebens - ADL)</h1>
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Aufnahme</th>
          <th>Entlassung</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="category in categories" :key="category.name">
          <!-- For categories with borders -->
          <tr>
            <td colspan="3" class="category-spacing"></td>
          </tr>
          <tr v-if="category.key === 'gehen' || category.key === 'transfer'" class="bordered-category-heading">
            <td colspan="3" class="category-heading">{{ category.name }}</td>
          </tr>
          <tr v-if="category.key === 'gehen' || category.key === 'transfer'" class="bordered-category-content">
            <td class="category-content">
              <div v-for="option in category.options" :key="option.label" class="option-container">
                <span class="option-label">{{ option.label }}</span>
                <span class="points">{{ option.points }}</span>
              </div>
            </td>
            <td class="input-cell">
              <div class="custom-select-container">
                <div
                  v-if="isSetInSolution(category.key, 'aufnahme') || props.isEditingSolution"
                  @click="toggleDropdown(category.key, 'aufnahme')"
                  class="custom-select-field"
                  :class="fieldClasses(category.key, 'aufnahme')"
                >
                  {{ formData[category.key].aufnahme !== '' ? formData[category.key].aufnahme : 'Wähle' }}
                </div>
                <div v-else class="custom-select-field">---</div>
                <div
                  v-if="activeDropdown.key === category.key && activeDropdown.field === 'aufnahme'"
                  class="custom-dropdown"
                >
                  <div
                    v-for="option in category.options"
                    :key="option.points"
                    @click="selectOption(category.key, 'aufnahme', option.points)"
                    class="custom-option"
                  >
                    {{ option.points }}
                  </div>
                </div>
              </div>
            </td>
            <td class="input-cell">
              <div class="custom-select-container">
                <div
                  v-if="isSetInSolution(category.key, 'entlassung') || props.isEditingSolution"
                  @click="toggleDropdown(category.key, 'entlassung')"
                  class="custom-select-field"
                  :class="fieldClasses(category.key, 'entlassung')"
                >
                  {{ formData[category.key].entlassung !== '' ? formData[category.key].entlassung : 'Wähle' }}
                </div>
                <div v-else class="custom-select-field">---</div>
                <div
                  v-if="activeDropdown.key === category.key && activeDropdown.field === 'entlassung'"
                  class="custom-dropdown"
                >
                  <div
                    v-for="option in category.options"
                    :key="option.points"
                    @click="selectOption(category.key, 'entlassung', option.points)"
                    class="custom-option"
                  >
                    {{ option.points }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <!-- For categories without borders -->
          <tr v-else>
            <td colspan="3" class="category-heading">{{ category.name }}</td>
          </tr>
          <tr v-if="category.key !== 'gehen' && category.key !== 'transfer'">
            <td class="category-content">
              <div v-for="option in category.options" :key="option.label" class="option-container">
                <span class="option-label">{{ option.label }}</span>
                <span class="points">{{ option.points }}</span>
              </div>
            </td>
            <td class="input-cell">
              <div class="custom-select-container">
                <div
                  v-if="isSetInSolution(category.key, 'aufnahme') || props.isEditingSolution"
                  @click="toggleDropdown(category.key, 'aufnahme')"
                  class="custom-select-field"
                  :class="fieldClasses(category.key, 'aufnahme')"
                >
                  {{ formData[category.key].aufnahme !== '' ? formData[category.key].aufnahme : 'Wähle' }}
                </div>
                <div v-else class="custom-select-field">---</div>
                <div
                  v-if="activeDropdown.key === category.key && activeDropdown.field === 'aufnahme'"
                  class="custom-dropdown"
                >
                  <div
                    v-for="option in category.options"
                    :key="option.points"
                    @click="selectOption(category.key, 'aufnahme', option.points)"
                    class="custom-option"
                  >
                    {{ option.points }}
                  </div>
                </div>
              </div>
            </td>
            <td class="input-cell">
              <div class="custom-select-container">
                <div
                  v-if="isSetInSolution(category.key, 'entlassung') || props.isEditingSolution"
                  @click="toggleDropdown(category.key, 'entlassung')"
                  class="custom-select-field"
                  :class="fieldClasses(category.key, 'entlassung')"
                >
                  {{ formData[category.key].entlassung !== '' ? formData[category.key].entlassung : 'Wähle' }}
                </div>
                <div v-else class="custom-select-field">---</div>
                <div
                  v-if="activeDropdown.key === category.key && activeDropdown.field === 'entlassung'"
                  class="custom-dropdown"
                >
                  <div
                    v-for="option in category.options"
                    :key="option.points"
                    @click="selectOption(category.key, 'entlassung', option.points)"
                    class="custom-option"
                  >
                    {{ option.points }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <!-- Feedback (and quotes if wrong)-->
          <tr>
            <td colspan="3">
              <div class="flex-row flex gap-x-6">
                <span>{{ getFeedback(category.key) }}</span>
                <button
                  v-if="isWrongCategory(category.key) && getQuoteMessageIds(category.key) !== null"
                  @click="togleShowQuote(category.key)"
                  class="text-blue-600 hover:text-blue-700 transition-transform duration-300 flex-row flex gap-x-1 items-center"
                >
                  Details
                  <ChevronDown size="20" :class="{ 'rotate-180': isShowingQuote.has(category.key) }" />
                </button>
              </div>

              <transition name="slide">
                <div v-if="isShowingQuote.has(category.key)" class="w-screen pr-4">
                  <Quote :messageIds="getQuoteMessageIds(category.key)" />
                </div>
              </transition>
            </td>
          </tr>
          <!-- End Feedback (and quotes if wrong) -->
        </template>
        <template
          v-if="!props.isEditingSolution && !isLoading"
          v-for="category in footerCategories"
          :key="category.key"
        >
          <tr>
            <td class="footer-label-container">
              <div
                :class="{
                  'text-teal-500':
                    category.key !== 'sum' && showingResults && footerFormData[category.key]?.aufnahme !== '',
                  'text-red-500':
                    category.key !== 'sum' &&
                    showingResults &&
                    (!footerFormData[category.key].aufnahme || footerFormData[category.key].aufnahme === ''),
                }"
                class="footer-label"
              >
                {{ category.label }}
              </div>
            </td>
            <td v-if="category.key === 'sum'" class="footer-input-cell">
              <div class="sum-field">{{ sum.aufnahme }}</div>
            </td>
            <td v-else class="footer-input-cell">
              <input
                :class="{
                  'text-teal-500':
                    category.key !== 'sum' && showingResults && footerFormData[category.key]?.aufnahme !== '',
                  'text-red-500':
                    category.key !== 'sum' &&
                    showingResults &&
                    (!footerFormData[category.key]?.aufnahme || footerFormData[category.key]?.aufnahme === ''),
                }"
                type="text"
                v-model="footerFormData[category.key].aufnahme"
                class="input-field"
              />
              <span
                v-if="
                  category.key === 'erhobenAm' &&
                  showingResults &&
                  (!footerFormData[category.key].aufnahme || footerFormData[category.key].aufnahme === '')
                "
              >
                <span class="text-red-500 flex leading-0 text-xs justify-between">
                  Es ist sehr wichtig, dass du deine Doku mit einem Datum versiehst! Es kommt sonst zu medizinischen (!)
                  oder Abrechnungsfehlern.
                </span>
              </span>
              <span
                v-if="
                  category.key === 'erhobenVon' &&
                  showingResults &&
                  (!footerFormData[category.key].aufnahme || footerFormData[category.key].aufnahme === '')
                "
              >
                <span class="text-red-500 flex leading-0 text-xs justify-between">
                  Es ist sehr wichtig, dass du deine Doku "abzeichntest" (=dein Handzeichen darunter machst). Dies ist
                  von deinem Arbeitgeber so vorgeschrieben.
                </span>
              </span>
            </td>
            <td v-if="category.key === 'sum'" class="footer-input-cell">
              <div class="sum-field">{{ sum.entlassung }}</div>
            </td>
            <td v-else class="footer-input-cell">
              <input type="text" v-model="footerFormData[category.key].entlassung" class="input-field" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-show="props.allowSubmit" class="px-2">
      <ProgressButton
        color="black"
        size="md"
        :rounded-full="true"
        :disabled="!isValid || props.isEditingSolution"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<style scoped>
.form-container {
  font-family: Arial, sans-serif;
  background-color: white;
  overflow: auto;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  text-decoration: underline;
  background-color: #f0f0f0; /* Gray background for the title */
  padding: 10px 15px; /* Added padding to make it visually consistent */
  margin-bottom: 15px; /* Space between the title and the table */
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are clean */
  margin-bottom: 20px;
}

table th {
  background-color: white;
  padding: 5px; /* Reduced padding for denser rows */
  text-align: center;
  font-weight: bold;
}

th,
td {
  border: none;
}

td {
  padding: 5px; /* Reduced padding to make rows more compact */
  text-align: left; /* Ensure all text is left-aligned */
  vertical-align: bottom; /* Align all inputs and content consistently at the bottom */
}

.option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px; /* Reduced margin to make rows denser */
}

.option-label {
  flex: 1;
  padding-left: 10px;
  font-size: 14px; /* Reduced font size for compact layout */
  text-align: left; /* Ensures all option labels are left-aligned */
}

.points {
  margin-left: 10px; /* Reduced space to make the layout tighter */
  font-weight: bold;
  font-size: 14px; /* Consistent smaller font size */
}

.input-field {
  width: 95%; /* Slightly increased width for better alignment */
  margin-top: 0; /* Removed top margin for better alignment at the bottom */
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid black;
}

.bottom-aligned {
  margin-bottom: 0; /* Align input field strictly at the bottom */
}

.category-heading {
  font-weight: bold;
  padding-left: 10px;
  text-align: left; /* Ensures the category headings are left-aligned */
  background-color: #ffffff;
}

.footer-label-container {
  text-align: right;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
  vertical-align: middle;
}

.footer-label {
  padding-top: 3px; /* Reduced padding for tighter alignment */
  padding-bottom: 3px;
}

.footer-input-cell {
  text-align: center;
  padding: 3px; /* Reduced padding for compactness */
}

/* Adding black borders for specific categories that encompass the entire section */
.bordered-category-heading {
  border: 2px solid black;
  border-bottom: none; /* Remove bottom border to merge with the next row */
}

.bordered-category-content {
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.category-content {
  padding-top: 2px; /* Reduced padding for a tighter layout */
  padding-bottom: 2px; /* Reduced padding for a tighter layout */
  padding-right: 40px;
}

.category-spacing {
  height: 20px; /* Increased height for better separation between categories */
}

.select-field {
  width: 95%;
  padding: 4px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  text-align: center;
  text-align-last: center;
  /* Reset positioning */
  position: static;
  /* Remove any transform */
  transform: none;
  /* Ensure it's visible */
  z-index: auto;
  /* Force native appearance */
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.input-cell {
  position: static;
  transform: none;
}

/* Remove any complex CSS that might be causing issues */
select option {
  font-size: 14px;
  padding: 2px 6px;
  background-color: white;
  color: black;
}

/* Remove any :deep or :global selectors that might be conflicting */

/* Updated custom dropdown styles */
.custom-select-container {
  position: relative;
  width: 95%;
}

.custom-select-field {
  width: 100%;
  padding: 4px;
  border: none;
  border-bottom: 1px solid black;
  background-color: #f5f5f5;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.custom-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-option {
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.custom-option:hover {
  background-color: #f5f5f5;
}

/* Expand/collapse transition */
.slide-enter-active,
.slide-leave-active {
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  max-height: 1000px;
  overflow: hidden;
  opacity: 1;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
