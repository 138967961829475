<script setup lang="ts">
import { Play } from 'lucide-vue-next';
import { computed } from 'vue';

const props = defineProps({
  color: { type: String, default: 'black' },
  size: { type: Number, default: 20 },
});

const strokeLength = computed(() => props.size - 2);
</script>

<template>
  <div class="relative flex-shrink-0 mr-1.5" :style="{ width: `${size}px`, height: `${size}px` }">
    <!-- Vertical line -->
    <div
      class="absolute left-0 top-[1px] w-[3px] rounded-full"
      :class="`bg-${color}`"
      :style="{ height: `${strokeLength}px` }"
    ></div>
    <!-- Play icon -->
    <Play
      class="absolute left-[2px]"
      :size="size"
      :stroke="color"
      :fill="color"
      :strokeWidth="3"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </div>
</template>
