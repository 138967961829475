<script setup>
import { useAuthStore } from '@/stores';
import { ref, onMounted, onBeforeMount, reactive, onBeforeUnmount, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useOrganizationStore } from '@/stores';
import 'vue-advanced-cropper/dist/style.css';
import UserListItem from '@/components/dashboard/UserListItem.vue';
import _, { debounce } from 'lodash';
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import ProgressButton from '@/components/ProgressButton.vue';

import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { storeToRefs } from 'pinia';
import { useManagedOrganizations } from '@/composables/useManagedOrganizations';
const organizationStore = useOrganizationStore();
const outerContainer = ref(null);
const outerContainerObserver = ref(null);

const { orgCountryStats, maxCountForSingleCountry } = storeToRefs(organizationStore);
const outerContainerWidth = ref(1024);

useManagedOrganizations({
  handleOrganizationChange: () => {
    plotWorldMap();
  },
});

const getWidth = () => {
  if (!outerContainer.value) {
    console.error('outerContainer is not set');
  }
  outerContainerWidth.value = outerContainer.value.offsetWidth;
};

const getWidthAndPlotWorldMap = () => {
  getWidth();
  plotWorldMap();
};

const debounceRePlot = debounce(getWidthAndPlotWorldMap, 200);

function getCountForIsoAlpha3(stats, countryCode) {
  // this is not the most efficient way to do this, but it's fine for now as the data set is small
  // stats is an array of the form [{'country': {'iso_alpha_3': 'XXX', ...}, 'count': 123}, ...]
  const countryStat = stats.find((stat) => stat.country.iso_alpha_3 === countryCode);

  return countryStat ? countryStat.count : 0;
}

async function plotWorldMap() {
  await organizationStore.loadOrganizationCountryStats();
  try {
    const data = await import('@/assets/visionscarto-world-atlas-world-110m-v1.json');

    const width = outerContainerWidth.value;
    const height = Math.round(outerContainerWidth.value * 0.75);

    // remove old world map, if any
    d3.select('#origin-world-map').selectAll('svg').remove();

    // add new one
    const svg = d3
      .select('#origin-world-map')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .call(
        d3.zoom().on('zoom', (event) => {
          svg.attr('transform', event.transform);
        }),
      )
      .append('g');

    const projection = d3.geoMercator().fitSize([width, height], topojson.feature(data, data.objects.countries));

    const path = d3.geoPath().projection(projection);

    var tooltip = d3.select('#origin-world-map').append('div').style('position', 'absolute').style('opacity', 0);

    // A function that change this tooltip when the user hover a point.
    // Its opacity is set to 1: we can now see it. Plus it set the text and position of tooltip depending on the datapoint (d)
    var mouseover = function (event, d) {
      tooltip.style('opacity', 1);
    };

    var mousemove = function (event, d) {
      // console.log(d);
      tooltip
        .html(
          `<div class="bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] w-[150px] p-3">
          <div class="flex mb-1">
            <div class="me-2">
          <div class="size-4 rounded-full bg-no-repeat bg-center bg-cover"></div>
            </div>
            <span class="text-sm font-medium">${d.properties.name}: ${getCountForIsoAlpha3(
              orgCountryStats.value,
              d.properties.a3,
            )}</span>
          </div>
<!--          <div class="flex items-center">-->
<!--            <span class="text-sm text-gray-500 dark:text-neutral-500">Active:</span>-->
<!--             <span class="text-sm font-medium"></span>-->
<!--             <span class="text-sm text-teal-500 dark:text-teal-400">1337</span>-->
<!--          </div>-->
        </div>`,
        )
        .style('left', event.x + 30 + 'px')
        .style('top', event.y + 30 + 'px');

      // draw a outline around the country
      //d3.select(this).attr('stroke', 'black').attr('stroke-width', 0.5);
    };

    // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
    var mouseleave = function (event, d) {
      // tooltip
      //   .style("opacity", 0)
      //   .attr('stroke', 'white')
      //   .attr('stroke-width', 0.5)
      // remove the outline around the country
      //d3.select(this).attr('stroke', 'white').attr('stroke-width', 0.5);
    };

    // create a color scale from 0 to maxCountForSingleCountry
    const colorScale = d3.scaleSequential(d3.interpolatePlasma).domain([0, maxCountForSingleCountry.value]);

    svg
      .selectAll('path')
      .data(topojson.feature(data, data.objects.countries).features.filter((d) => d.id !== '010')) // Exclude Antarctica using UN M49 code
      .enter()
      .append('path')
      .attr('d', path)
      .attr('stroke', 'white')
      .attr('stroke-width', 0.5)
      // set the color of each country
      .attr('fill', function (d) {
        const count = getCountForIsoAlpha3(orgCountryStats.value, d.properties.a3);
        return colorScale(count);
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);
  } catch (error) {
    console.error('Error fetching world map data:', error);
  }
}

onMounted(() => {
  getWidth();
  plotWorldMap();
  window.addEventListener('resize', debounceRePlot);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceRePlot);
});
</script>
<template>
  <div ref="outerContainer" class="xl:grid xl:grid-cols-12 xl:items-center xl:gap-x-5">
    <div class="xl:col-span-7">
      <div id="origin-world-map" class="sm:min-h-[430px]"></div>
    </div>
  </div>
</template>
