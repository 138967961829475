<script setup>
import { useAuthStore, useOrganizationStore } from '@/stores';
import { onMounted, defineProps, computed, ref, onUnmounted } from 'vue';
import 'vue-advanced-cropper/dist/style.css';
import { router } from '@/router';
import _ from 'lodash';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { BookOpen, Users, CreditCard, LayoutDashboard, BookHeart, Globe, GraduationCap } from 'lucide-vue-next';

const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const isOrgDropdownOpen = ref(false);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

const toggleOrgDropdown = () => {
  isOrgDropdownOpen.value = !isOrgDropdownOpen.value;
};

const selectOrganization = (orgId) => {
  organizationStore.setCurrentOrganization(orgId);
  isOrgDropdownOpen.value = false;
};

// Close dropdown when clicking outside
const handleClickOutside = (event) => {
  const dropdown = document.querySelector('.organization-dropdown');
  if (dropdown && !dropdown.contains(event.target)) {
    isOrgDropdownOpen.value = false;
  }
};

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  await organizationStore.loadOrganizations();

  // Add click outside listener
  document.addEventListener('click', handleClickOutside);
});

// Clean up event listener
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <!-- ========== MAIN SIDEBAR ========== -->
  <aside
    id="hs-pro-sidebar"
    :style="{ paddingTop: `${outerHeaderHeight}px` }"
    class="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-[260px] hidden fixed inset-y-0 start-0 z-[20] bg-white border-e border-gray-200 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-neutral-800 dark:border-neutral-700 dark:hs-overlay-backdrop-open:bg-neutral-900/90"
  >
    <div class="flex flex-col h-full max-h-full py-3">
      <!-- Profile -->
      <header class="pb-5 text-center border-b border-gray-200 dark:border-neutral-700">
        <span class="font-semibold text-gray-800 dark:text-neutral-300">
          {{ authStore.userFullName }}
        </span>

        <!-- Organization Selector Dropdown -->
        <div v-if="organizationStore.organizations.length > 0" class="mt-2 mx-4">
          <div class="relative flex w-full">
            <button
              @click="toggleOrgDropdown"
              class="flex items-center justify-between w-full px-3 py-1.5 text-sm text-gray-500 bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-300 dark:hover:bg-neutral-600"
            >
              <span>{{ organizationStore.currentlyViewedOrganization?.name || 'Select Organization' }}</span>
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>

            <div
              v-if="isOrgDropdownOpen"
              class="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg dark:bg-neutral-700 dark:border-neutral-600"
            >
              <ul class="py-1 overflow-auto max-h-60">
                <li
                  v-for="org in organizationStore.organizations"
                  :key="org.id"
                  @click="selectOrganization(org.id)"
                  class="px-3 py-2 text-sm justify-start text-left text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-600"
                  :class="{
                    'bg-gray-50 dark:bg-neutral-600': org.id === organizationStore.currentlyViewedOrganizationId,
                  }"
                >
                  {{ org.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- End Organization Selector Dropdown -->
      </header>
      <!-- End Profile -->

      <!-- Content -->
      <div
        class="h-[calc(100%-35px)] lg:h-[calc(100%-93px)] overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
      >
        <!-- Nav -->
        <nav class="hs-accordion-group pb-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
          <ul>
            <!-- Link -->
            <li class="px-5 mb-1.5">
              <router-link
                class="flex items-center gap-x-4 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard"
              >
                <span class="flex-shrink-0"><Globe :size="20" /></span>
                <span>Global {{ organizationStore.currentlyViewedOrganization?.name }}</span>
              </router-link>
            </li>
            <!-- End Link -->

            <!-- Link -->
            <li class="px-5 mb-1.5" v-if="organizationStore.orgIsManagedByUser">
              <router-link
                class="flex items-center gap-x-4 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard/users"
              >
                <span class="flex-shrink-0"><Users :size="20" /></span>
                <span>Benutzer:innen</span>
              </router-link>
            </li>
            <!-- End Link -->

            <!-- Link -->
            <li class="px-5 mb-1.5" v-if="organizationStore.orgIsManagedByUser">
              <router-link
                class="flex items-center gap-x-4 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard/subscription"
              >
                <span class="flex-shrink-0"><CreditCard :size="20" /></span>
                <span>Abonnement</span>
              </router-link>
            </li>
            <!-- End Link -->

            <!-- Link -->
            <li class="px-5 mb-1.5">
              <router-link
                class="flex items-center gap-x-4 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard/course-progress"
              >
                <span class="flex-shrink-0"><GraduationCap :size="20" /></span>
                <span>Lernfortschritt</span>
              </router-link>
            </li>
            <!-- End Link -->

            <!-- Link -->
            <li class="px-5 mb-1.5">
              <router-link
                class="flex items-center gap-x-4 py-2 px-3 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                to="/dashboard/journalling"
              >
                <span class="flex-shrink-0"><BookHeart :size="20" /></span>
                <span>Lerntagebücher</span>
              </router-link>
            </li>
            <!-- End Link -->
          </ul>
        </nav>
        <!-- End Nav -->
      </div>
      <!-- End Content -->

      <div class="lg:hidden absolute top-3 -end-3 z-10">
        <!-- Sidebar Close -->
        <button
          type="button"
          class="w-6 h-7 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-500 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
          data-hs-overlay="#hs-pro-sidebar"
          aria-controls="hs-pro-sidebar"
          aria-label="Toggle navigation"
        >
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
        </button>
        <!-- End Sidebar Close -->
      </div>
    </div>
  </aside>
  <!-- ========== END MAIN SIDEBAR ========== -->

  <!-- ========== MAIN CONTENT ========== -->
  <main id="content" :style="computedStyleMain" class="lg:ps-[260px] w-full flex-grow overflow-hidden">
    <router-view :outer-header-height="props.outerHeaderHeight" />
  </main>
  <!-- ========== END MAIN CONTENT ========== -->
</template>
