<script setup lang="ts">
// component properties:
// message: message content
// messageType: "SAY", "LAB", etc
// conversation: store to use for conversation (currently: PATIENT [default], ATTENDING, ORAL-EXAM or MC-EXAM)

import Translation from '@/components/case_interaction/Translation.vue';
import ExpertEdit from '@/components/interaction_columns/ExpertEdit.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore, useCompanionInteractionStore } from '@/stores';
import QuickTipButton from '@/components/companion/QuickTipButton.vue';

// const expertMode = ref(false);

const { expertMode, textToSpeechEnabled, userFirstName, userLastName } = storeToRefs(useAuthStore());
const messageTypesWithAudio = ['SAY-PATIENT', 'SAY-ATTENDING', 'SAY-THIRD-PERSON', 'SAY-ORAL-EXAM'];

const props = defineProps({
  message: Object,
  messageType: String,
  conversation: String,
  hideEdit: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  collapseFooterIfNoTranslation: {
    type: Boolean,
    default: false,
  },
  markedSentenceIndcs: {
    type: Array,
    default: () => [],
  },
  showCollapse: {
    type: Boolean,
    default: false,
  },
  audioPollingInterval: {
    type: Number,
    default: 1500,
  },
  hideAudio: {
    type: Boolean,
    default: false,
  },
  isStreaming: {
    type: Boolean,
    required: true,
  },
  showCompanionHelpButton: {
    type: Boolean,
    default: false,
  },
  reserveSpaceForToggle: {
    type: Boolean,
    default: false,
  },
});

const audioIsAvailable = ref(false);
const checkInterval = ref(null); // stores the setInterval return value (which is an id)
const audioPlayer = ref(null);
const audioUrlWithTimestamp = ref(null);
const companionInteractionStore = useCompanionInteractionStore();
const translationComponent = ref(null);

// show audio player only if message.id has a UUID
// this is the HTML 5 audio player to replay the TTS once stored
const showAudioPlayer = computed(() => {
  if (props.hideAudio) return false;
  return props.message.id !== 'not_yet_in_database' && messageTypesWithAudio.includes(props.messageType);
});

watch(
  () => showAudioPlayer.value,
  (newVal) => {
    if (newVal && !checkInterval.value) {
      checkInterval.value = setInterval(updateAudioSource, props.audioPollingInterval);
    }
  },
);

// Event handler for when the audio metadata is loaded
const onAudioLoaded = () => {
  if (audioPlayer.value && audioPlayer.value.duration > 0) {
    // File is available, so stop polling
    if (!!checkInterval.value) {
      clearInterval(checkInterval.value);
      checkInterval.value = null;
    }
  }
};

// remove dashes in the message.id for the wav file
const audioSrc = computed(() => {
  const uuidHex = props.message.id.replace(/-/g, '');
  return `https://audiodata-prd.data.casuu.health/${uuidHex}.wav`;
});

// Update the audio source with a cache-busting timestamp
const updateAudioSource = () => {
  audioUrlWithTimestamp.value = `${audioSrc.value}?t=${Date.now()}`;
  if (audioPlayer.value) {
    audioPlayer.value.load(); // Reload the audio element to check if file is available
  }
};

const getHelpWithCase = async (message: string = 'Wie kann ich fortfahren?') => {
  console.log('getHelpWithCase');
  await companionInteractionStore.getTipForCase(message);
};

const editOn = ref(false);
const emit = defineEmits(['onEditToggled', 'onTranslationToggled', 'onCollapse']);
const isShowingTranslation = ref(false);

// const hideFooter = ref(false);

function editToggled(new_value) {
  console.debug('Hide footer is: ' + hideFooter);
  editOn.value = new_value.value;
  console.debug('Footer: Edit mode toggled to: ' + editOn.value);
  emit('onEditToggled', editOn);
}

function translationToggled(new_value: boolean) {
  console.debug('Footer: Translation mode toggled to: ' + new_value);
  isShowingTranslation.value = new_value;
  emit('onTranslationToggled', new_value);
}

function onCollapse() {
  console.debug('Footer: Collapse button clicked');
  emit('onCollapse');
}

onMounted(() => {
  console.log('Footer: Mounted');
  if (showAudioPlayer.value) {
    checkInterval.value = setInterval(updateAudioSource, props.audioPollingInterval);
  }
});

onBeforeUnmount(() => {
  if (checkInterval.value) clearInterval(checkInterval.value);
  checkInterval.value = null;
});

const toggleTranslation = async () => {
  if (!translationComponent.value) return;
  await translationComponent.value.toggleTranslation();
};

defineExpose({
  toggleTranslation,
});
</script>

<template>
  <!-- Button Group -->
  <div
    class="w-full flex-col transition-all duration-300"
    :class="{
      'opacity-0': hideFooter,
      'opacity-100': !hideFooter,
      'max-h-0 overflow-hidden': collapseFooterIfNoTranslation && !isShowingTranslation,
    }"
  >
    <div v-show="true" class="flex justify-between w-full items-center">
      <!-- Left buttons -->
      <div class="items-center inline-flex w-full">
        <!-- Thumb up/down buttons -->
        <!--         <div class="inline-flex border border-gray-200 rounded-full p-0.5 dark:border-gray-700"> -->
        <!-- Thumbs up-button -->
        <!--            <button type="button" class="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-full text-gray-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all dark:hover:bg-blue-900 dark:hover:text-blue-200">-->
        <!--              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">-->
        <!--                <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>-->
        <!--              </svg>-->
        <!--            </button>-->
        <!-- End of thumbs up-button -->
        <!-- Thumbs down-button -->
        <!--            <button type="button" class="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-full text-gray-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all dark:hover:bg-blue-900 dark:hover:text-blue-200">-->
        <!--              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">-->
        <!--                <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856 0 .289-.036.586-.113.856-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a9.877 9.877 0 0 1-.443-.05 9.364 9.364 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964l-.261.065zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a8.912 8.912 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581 0-.211-.027-.414-.075-.581-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.224 2.224 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.866.866 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1z"/>-->
        <!--              </svg>-->
        <!--            </button>-->
        <!-- End of thumbs up-button -->
        <!--         </div> -->
        <!-- End of thumbs up/down buttons -->
        <!-- Former "copy" button => now translate -->

        <!-- HTML5 Audio Player -->
        <div v-show="showAudioPlayer" class="flex-grow items-center">
          <audio
            ref="audioPlayer"
            controls
            controlsList="nodownload"
            oncontextmenu="return false;"
            class="h-8 text-xs items-center"
            :style="{
              zoom: '0.85',
            }"
            @loadedmetadata="onAudioLoaded"
          >
            <source :src="audioUrlWithTimestamp" type="audio/wav" />
            Your browser does not support the audio replay.
          </audio>
        </div>
      </div>
      <!-- End of left buttons -->

      <!-- Right button = expert edit -->
      <div v-if="expertMode && !props.hideEdit">
        <ExpertEdit @onToggle="editToggled" />
      </div>
      <div class="mt-2" v-show="showCollapse">
        <button
          type="button"
          @click="onCollapse"
          class="text-xs py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-50 focus:outline-none transition-all text-sm dark:hover:bg-neutral-800 dark:hover:text-gray-400 dark:hover:border-gray-900"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m18 15-6-6-6 6"></path>
          </svg>
          Einklappen
        </button>
      </div>
      <!-- End of right button -->
    </div>
    <div
      class="inline-flex transition-all duration-300 justify-between items-center w-full mt-2 py-2 rounded-2xl text-sm"
      :class="{
        'pr-12': props.reserveSpaceForToggle,
        'opacity-0 max-h-0 overflow-hidden': props.isStreaming,
        'opacity-100': !props.isStreaming,
      }"
    >
      <div class="flex items-center gap-2">
        <Translation
          ref="translationComponent"
          :message="props.message"
          :messageType="props.messageType"
          :conversation="props.conversation"
          :markedSentencesOrigin="props.markedSentenceIndcs"
          @onTranslationToggled="translationToggled"
        />
        <QuickTipButton
          v-if="props.showCompanionHelpButton && !isShowingTranslation"
          :text="$t('message.help')"
          @clicked="getHelpWithCase('Was soll ich als nächstes sagen?')"
          :in-footer-style="true"
          :disabled="props.isStreaming"
        />
      </div>
    </div>
  </div>
  <!-- End Button Group -->
</template>

<style scoped></style>
