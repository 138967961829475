import { useCaseInteractionStore, useCaseStore } from '@/stores';
import { router } from '@/router';
import { storeToRefs } from 'pinia';
import { computed, ComputedRef, ref, Ref } from 'vue';

export interface RandomCaseDetails {
  id: string;
  heading: string;
  randomCase: any;
}

// Store a reference to the current random case
const currentRandomCase = ref<any>(null);

export default class CaseService {
  /**
   * Selects a random case from available cases and navigates to it
   * @param useCurrent Whether to use the existing random case or select a new one
   * @returns Promise that resolves when navigation is complete
   */
  static async playRandomCase(useCurrent: boolean = true, refreshAfterStart: boolean = true): Promise<void> {
    const caseStore = useCaseStore();
    const { allCases } = storeToRefs(caseStore);

    // Fetch cases if not already loaded
    if (allCases.value.length === 0) {
      await caseStore.fetchAllCases();
    }

    // Check if we have cases after fetching
    if (allCases.value.length === 0) {
      console.error('No cases available');
      return;
    }

    let caseToPlay;

    // Use existing random case if available and requested
    if (useCurrent && currentRandomCase.value) {
      caseToPlay = currentRandomCase.value;
    } else {
      // Select a new random case
      caseToPlay = allCases.value[Math.floor(Math.random() * allCases.value.length)];
      // Store it for future use
      currentRandomCase.value = caseToPlay;
    }

    try {
      // Set the current case and navigate to it
      await caseStore.setCurrentCase(caseToPlay.id);
      await router.push('/case-interactions/new');
    } catch (error) {
      console.error('Error while loading case:', error);
    }

    if (refreshAfterStart) {
      this.refreshRandomCase();
    }
  }

  /**
   * Navigates to the user's case interactions page
   * @param userId The ID of the user
   */
  static async navigateToMyCases(userId: string): Promise<void> {
    await router.push(`/users/${userId}/case-interactions`);
  }

  /**
   * Gets details for a random case to display in UI
   * @param forceRefresh Whether to force selecting a new random case
   * @returns Object containing random case details
   */
  static getRandomCaseDetails(forceRefresh: boolean = false): RandomCaseDetails {
    const caseStore = useCaseStore();
    const { allCases } = storeToRefs(caseStore);

    // Ensure cases are loaded
    if (allCases.value.length === 0) {
      caseStore.fetchAllCases().then(() => {
        // If we don't have a random case yet, select one after loading
        if (!currentRandomCase.value && allCases.value.length > 0) {
          currentRandomCase.value = allCases.value[Math.floor(Math.random() * allCases.value.length)];
        }
      });
    }

    // If we need to refresh or don't have a case yet, and we have cases available
    if ((forceRefresh || !currentRandomCase.value) && allCases.value.length > 0) {
      currentRandomCase.value = allCases.value[Math.floor(Math.random() * allCases.value.length)];
    }

    // Get heading from the current random case
    const heading = currentRandomCase.value?.details?.heading || 'Start a new medical case';

    return {
      id: currentRandomCase.value?.id || '',
      heading: heading,
      randomCase: currentRandomCase.value,
    };
  }

  /**
   * Refreshes the current random case selection
   */
  static refreshRandomCase(): void {
    const caseStore = useCaseStore();
    const { allCases } = storeToRefs(caseStore);

    if (allCases.value.length > 0) {
      currentRandomCase.value = allCases.value[Math.floor(Math.random() * allCases.value.length)];
    }
  }

  /*
   * Get XP reward for an entire case
   * @param caseId The ID of the case
   * @returns The XP reward
   */
  static getXpRewardForCase(caseId: string): number {
    const caseStore = useCaseStore();
    const { allCases } = storeToRefs(caseStore);

    // find element in allCases with id
    let caseObject = allCases.value.find((c) => c.id === caseId);
    if (!caseObject) return 0;

    // go over tasks and sum up all subtasks to get number of all subtasks
    let numberOfSubtasks = 0;
    caseObject.tasks.forEach((task) => {
      numberOfSubtasks += task.subtasks.length;
    });

    return 20 + numberOfSubtasks * 65 + 125;
    // TODO get from constants/xp.py
  }

  /*
   * Get remaining XP for a case interaction
   * @param caseInteractionId The ID of the case interaction
   * @returns The remaining XP
   */
  static getRemainingXpForCaseInteraction(caseInteractionId: string): number {
    const caseInteractionStore = useCaseInteractionStore();
    const { allUserCaseInteractions } = storeToRefs(caseInteractionStore);

    // find case interaction in allUserCaseInteractions
    let caseInteraction = allUserCaseInteractions.value.find((c) => c.id === caseInteractionId);
    if (!caseInteraction) return 0;

    // go over task interactions and sum up all subtask_interactions which have completed_at == null
    let remainingSubtasks = 0;
    caseInteraction.task_interactions.forEach((taskInteraction) => {
      taskInteraction.subtask_interactions.forEach((subtaskInteraction) => {
        if (!subtaskInteraction.completed_at) remainingSubtasks += 1;
      });
    });
    if (remainingSubtasks === 0) return 0;
    return remainingSubtasks * 65 + 125;
    // TODO get from constants/xp.py
  }
}
