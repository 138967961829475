<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useAutosave, useExerciseLifecycle } from '@/composables';
import type { PropType } from 'vue';
import type { SectionContentItemOverview, ScoringExerciseItem } from '@/apiclient';
import { useAlertStore, useCourseStore } from '@/stores';
import ProgressButton from '@/components/ProgressButton.vue';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { cloneDeep } from 'lodash';
import { updateMinTextareaHeight } from '@/helper';
import { Star, StarHalf, ChevronDown, Check, ArrowDownFromLine } from 'lucide-vue-next';
import Title from '@/components/didactics/title/Title.vue';
import Rating from '@/components/inputs/Rating.vue';

// stores
const courseStore = useCourseStore();
const alertStore = useAlertStore();

// props
const props = defineProps({
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  allowEditing: {
    type: Boolean,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
});

// emit
const emit = defineEmits(['onDeleteContentItem', 'addNewVocab']);

// Store the ratings for each item
const itemRatings = ref(new Map<string, number>());

// state
const title = ref(props.contentItem.title || '');
const taskInstructions = ref(props.contentItem.task_instructions || '');
const isSubmitting = ref(false);
const editingItem = ref<ScoringExerciseItem | null>(null);
const contentTextareas = ref<Map<string, HTMLTextAreaElement>>(new Map());
const descriptionTextareas = ref<Map<string, HTMLTextAreaElement>>(new Map());
const expandedDescriptions = ref(new Set<string>());
const activeRatingId = ref<string | null>(null);

// composables
const {
  handleExerciseFinished,
  fetchInteractionState,
  reopenExercise,
  itemInteractionState,
  fetchCompleted: interactionFetchCompleted,
} = useExerciseLifecycle({
  contentItem: props.contentItem,
  sectionIndex: props.sectionIndex,
  pageIndex: props.pageIndex,
  isEditing: props.allowEditing,
  emit,
  onComplete: (response) => {
    itemInteractionState.value = response.section_content_item_interaction;
  },
  onReopen: () => {
    itemRatings.value.clear();
  },
});
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (editingItem.value) {
      await updateItem(editingItem.value.id, editingItem.value);
      editingItem.value = null;
    } else if (!props.contentItem.scoring_exercise) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Scoringübung - keine Übung', 'Error');
      return;
    } else {
      await courseStore.updateScoringExercise(props.sectionId, props.contentItem.id, {
        title: title.value,
        task_instructions: taskInstructions.value,
      });
    }

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Scoringübung', 'Error', error as Error);
  },
});

// Add item to exercise
const addItem = async () => {
  if (!props.contentItem.id) return;

  isSubmitting.value = true;
  try {
    await courseStore.addScoringItem(props.sectionId, props.contentItem.id);
    alertStore.success('status.success.itemAdded');
  } catch (error) {
    alertStore.error('Error adding scoring exercise item', 'Error', error as Error);
  } finally {
    isSubmitting.value = false;
  }
};

// Remove item from exercise
const removeItem = async (itemId: string) => {
  if (!props.contentItem.id) return;

  isSubmitting.value = true;
  try {
    await courseStore.removeScoringItem(props.sectionId, props.contentItem.id, itemId);
    alertStore.success('status.success.itemRemoved');
  } catch (error) {
    alertStore.error('Error removing scoring exercise item', 'Error', error as Error);
  } finally {
    isSubmitting.value = false;
  }
};

// Update item
const updateItem = async (itemId: string, itemUpdate: ScoringExerciseItemUpdate) => {
  if (!props.contentItem.id) return;

  isSubmitting.value = true;
  try {
    await courseStore.updateScoringItem(props.sectionId, props.contentItem.id, itemId, itemUpdate);
    alertStore.success('status.success.itemUpdated');
  } catch (error) {
    alertStore.error('Error updating scoring exercise item', 'Error', error as Error);
  } finally {
    isSubmitting.value = false;
  }
};

const hasBeenRated = (itemId: string) => {
  return itemRatings.value.has(itemId);
};

// Check if all items have been rated
const allItemsRated = computed(() => {
  if (!props.contentItem.scoring_exercise?.scoring_exercise_items) return false;
  return props.contentItem.scoring_exercise.scoring_exercise_items.every((item) => itemRatings.value.has(item.id));
});

const exerciseCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null && itemInteractionState.value?.reopened_at == null;
});

const { t } = useI18n();

// Start editing an item
const startEditing = (item: ScoringExerciseItem) => {
  editingItem.value = cloneDeep(item);

  // Update textarea heights after the next tick
  setTimeout(() => {
    const contentTextarea = contentTextareas.value.get(item.id);
    if (contentTextarea) {
      updateMinTextareaHeight(contentTextarea);
    }
    const descriptionTextarea = descriptionTextareas.value.get(item.id);
    if (descriptionTextarea) {
      updateMinTextareaHeight(descriptionTextarea);
    }
  }, 0);
};

const toggleDescription = (itemId: string) => {
  if (expandedDescriptions.value.has(itemId)) {
    expandedDescriptions.value.delete(itemId);
  } else {
    expandedDescriptions.value.clear();
    expandedDescriptions.value.add(itemId);
  }
};

// lifecycle hooks & watchers
onMounted(async () => {
  await nextTick();
  if (props.allowEditing) interactionFetchCompleted.value = true; // no need to wait for interaction fetch
  await fetchInteractionState();
  if (
    props.contentItem.scoring_exercise?.scoring_exercise_items &&
    props.contentItem.scoring_exercise.scoring_exercise_items.length > 0
  ) {
    expandedDescriptions.value.add(props.contentItem.scoring_exercise.scoring_exercise_items[0].id);
  }
});

watch(
  () => allItemsRated.value,
  async (newValue) => {
    if (!newValue) return;
    if (props.allowEditing) return;
    if (!props.contentItem.scoring_exercise?.scoring_exercise_items) return;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    handleExerciseFinished();
  },
);
</script>

<template>
  <div class="min-w-full pt-0.5 px-4 pb-1 min-h-48 relative text-gray-800 dark:text-gray-200">
    <div
      :class="{
        'opacity-25':
          !props.allowEditing &&
          (!interactionFetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <Title
        :icon-component="Star"
        v-model:title="title"
        :is-editing="props.allowEditing"
        headline="Zustimmung"
        @change="unsavedChanges = true"
      />

      <!-- task instructions -->
      <h3 v-if="props.allowEditing || taskInstructions !== ''" class="pt-2 pb-1 px-4 w-full text-center">
        <span v-if="!props.allowEditing" class="text-xs md:text-sm font-semibold">
          {{ taskInstructions }}
        </span>
        <textarea
          v-if="props.allowEditing"
          v-model="taskInstructions"
          class="w-full text-xs md:text-sm px-2 py-0 mb-2 resize-none border-gray-200 rounded-lg text-center"
          placeholder="Erläuterungen zur Aufgabenstellung (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
      </h3>

      <!-- Scoring items list -->
      <div class="w-full py-2">
        <div class="py-4 w-full min-h-48 flex-col flex gap-y-2">
          <div
            v-for="item in props.contentItem.scoring_exercise?.scoring_exercise_items"
            :key="item.id"
            class="relative"
          >
            <div
              @click="toggleDescription(item.id)"
              class="w-full relative rounded-2xl transition-colors duration-1000"
              :class="{
                'bg-white': !hasBeenRated(item.id) || activeRatingId === item.id,
                'bg-gray-bg focus-within:bg-white border border-gray-200':
                  hasBeenRated(item.id) && activeRatingId !== item.id,
              }"
            >
              <div class="p-4">
                <!-- Content -->
                <div class="flex justify-between items-center">
                  <div v-if="props.allowEditing && editingItem?.id === item.id" class="flex-grow">
                    <textarea
                      :ref="
                        (el: HTMLTextAreaElement | null) => {
                          if (el) contentTextareas.set(item.id, el as HTMLTextAreaElement);
                          else contentTextareas.delete(item.id);
                        }
                      "
                      v-model="editingItem.content"
                      class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
                      placeholder="Inhalt"
                      rows="1"
                      @input="
                        (e: Event) => {
                          const target = e.target as HTMLInputElement;
                          updateMinTextareaHeight(target);
                        }
                      "
                      @change="
                        (e: Event) => {
                          if (e.target?.value === '') return;
                          unsavedChanges = true;
                        }
                      "
                    />
                  </div>
                  <div v-else class="text-lg font-semibold" @click="props.allowEditing && startEditing(item)">
                    {{ item.content }}
                  </div>
                  <div class="flex items-center h-full justify-center">
                    <ChevronDown
                      v-if="!props.allowEditing && item.description"
                      :size="16"
                      class="transition-transform duration-300 cursor-pointer ml-2"
                      :class="{ 'rotate-180': expandedDescriptions.has(item.id) }"
                    />
                  </div>
                  <div class="ml-auto flex-grow flex justify-end text-end">
                    <Check :size="20" v-if="hasBeenRated(item.id) && activeRatingId !== item.id" />
                  </div>
                </div>

                <!-- Description -->
                <transition name="slide" class="overflow-hidden text-sm pb-2">
                  <div v-if="props.allowEditing && editingItem?.id === item.id" class="my-2">
                    <textarea
                      :ref="
                        (el) => {
                          if (el) descriptionTextareas.set(item.id, el as HTMLTextAreaElement);
                          else descriptionTextareas.delete(item.id);
                        }
                      "
                      v-model="editingItem.description"
                      class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
                      placeholder="Beschreibung"
                      rows="1"
                      @input="
                        (e: Event) => {
                          const target = e.target as HTMLInputElement;
                          updateMinTextareaHeight(target);
                        }
                      "
                      @change="unsavedChanges = true"
                    />
                  </div>
                  <div v-else-if="item.description && expandedDescriptions.has(item.id)" class="mt-2">
                    {{ item.description }}
                  </div>
                </transition>

                <!-- Star Rating container -->
                <Rating
                  v-if="!props.allowEditing"
                  :model-value="itemRatings.get(item.id) ?? 0"
                  @update:model-value="(value) => itemRatings.set(item.id, value)"
                  :disabled="exerciseCompleted"
                  size="md"
                />
              </div>
            </div>

            <!-- Remove button in edit mode -->
            <button
              v-if="props.allowEditing"
              @click="removeItem(item.id)"
              class="text-red-600 select-none hover:text-red-800 transition-colors absolute top-0.5 end-0.5"
              :disabled="isSubmitting"
            >
              <span translate="no" class="material-symbols-outlined notranslate bg-transparent w-fit text-2xl"
                >delete</span
              >
            </button>
          </div>
        </div>
      </div>

      <!-- Add item button in edit mode -->
      <div v-if="props.allowEditing" class="justify-center w-full flex gap-x-4 pt-12 pb-2">
        <ProgressButton
          @click="addItem"
          text="Item hinzufügen"
          :show-progress="isSubmitting"
          :disabled="isSubmitting"
          icon="add"
          iconSize="2xl"
        />
      </div>
    </div>

    <!-- Loading overlay -->
    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!interactionFetchCompleted"
    >
      <LoadingSpinnerLarge />
    </div>

    <!-- Exercise completed overlay -->
    <div
      v-if="
        interactionFetchCompleted &&
        !props.allowEditing &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease-out;
  max-height: 500px;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
}
</style>
