<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import draggable from 'vuedraggable';
import { useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';

const courseStore = useCourseStore();
const { numberOfChapters } = storeToRefs(courseStore);
const courseInteractionStore = useCourseInteractionStore();
const { numChaptersCompleted } = storeToRefs(courseInteractionStore);

const props = defineProps({
  sortedChapters: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  savingChangedOrder: {
    type: Boolean,
    required: true,
  },
  isDraggingEnabled: {
    type: Boolean,
    required: true,
  },
  initialFetchCompleted: {
    type: Boolean,
    required: true,
  },
  isOwnerOrEditorOrAdmin: {
    type: [Boolean, null],
    required: true,
  },
  aboutToDeleteChapterWithIndex: {
    type: [Number, null],
    required: true,
  },
  chapterCompletedProgress: {
    type: Function,
    required: true,
  },
  chapterStartedProgress: {
    type: Function,
    required: true,
  },
  titlePerson: {
    type: Function,
    required: true,
  },
  titlePersonProfileImage: {
    type: Function,
    required: true,
  },
  titlePersonFirstName: {
    type: Function,
    required: true,
  },
  titlePersonLastName: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['onMoveChapter', 'onStartChapter', 'onDeleteChapter', 'onNewChapter', 'onEditChapter']);
</script>

<template>
  <!-- Table -->
  <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
    <!-- Table head -->
    <thead class="bg-gray-50 dark:bg-neutral-800">
      <tr>
        <th scope="col" class="">
          <a class="group inline-flex items-center gap-x-2">
            <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200"> </span>
          </a>
        </th>

        <th scope="col" class="px-2 md:px-6 md:py-3 text-start">
          <a class="group inline-flex items-center gap-x-2">
            <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
              {{ $t('message.chapter') }}
            </span>
          </a>
        </th>

        <th scope="col" class="px-2 md:px-6 md:py-3 text-start">
          <a class="group inline-flex items-center gap-x-2">
            <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
              {{ $t('message.abstract') }}
            </span>
          </a>
        </th>

        <th scope="col" class="px-2 md:px-6 md:py-3 text-start">
          <a class="group inline-flex items-center gap-x-2">
            <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
              {{ $t('message.progress') }}
            </span>
          </a>
        </th>

        <th scope="col" class="px-2 md:px-6 md:py-3 text-start">
          <a class="group inline-flex items-center gap-x-2">
            <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
              {{ $t('message.patient', 2) }}
            </span>
          </a>
        </th>

        <th scope="col" class="px-2 md:px-6 md:py-3 text-end"></th>
      </tr>
    </thead>

    <!-- Table body -->
    <!-- Table body row -->
    <draggable
      v-if="props.sortedChapters.length > 0"
      v-model="props.sortedChapters"
      item-key="chapter.id"
      tag="tbody"
      class="divide-y divide-gray-200 dark:divide-gray-700"
      @end="emit('onMoveChapter', $event)"
      :disabled="!props.isDraggingEnabled || !props.isEditing || props.savingChangedOrder"
      :class="{
        'cursor-move': isDraggingEnabled && props.isEditing && !props.savingChangedOrder,
        'cursor-pointer': !isDraggingEnabled || !props.isEditing || props.savingChangedOrder,
      }"
    >
      <template #item="{ element: chapter }">
        <tr
          class="min-w-full w-full hover:bg-gray-50 dark:bg-neutral-900 dark:hover:bg-neutral-800"
          :class="{
            'bg-white': chapter.index !== props.aboutToDeleteChapterWithIndex,
            'bg-red-100': chapter.index === props.aboutToDeleteChapterWithIndex,
          }"
          :key="chapter.id"
          @click="emit('onStartChapter', chapter.id)"
          v-if="props.isOwnerOrEditorOrAdmin || chapter.published_at != null"
          :data-testid="`large-screen-course-chapter-${chapter.index}`"
        >
          <td class="w-[10px] size-px text-end items-center justify-end whitespace-nowrap text-gray-500">
            <div class="font-bold text-gray-300 px-2 md:px-6 text-xs pt-[1px]">
              {{ chapter.index + 1 }}
            </div>
          </td>
          <td class="size-px max-w-36 md:max-w-full">
            <a
              class="block relative z-10"
              :class="{
                'cursor-move': props.isDraggingEnabled && props.isEditing && !props.savingChangedOrder,
                'cursor-pointer': !props.isDraggingEnabled || !props.isEditing || props.savingChangedOrder,
              }"
            >
              <div class="px-2 md:px-6 py-2">
                <p
                  class="block text-xs md:text-sm text-blue-600 decoration-2 hover:underline dark:text-blue-600 cursor-pointer"
                  :class="{
                    'text-gray-400': props.savingChangedOrder,
                    'text-blue-600': !props.savingChangedOrder,
                  }"
                >
                  {{ chapter.title }}
                </p>
              </div>
            </a>
          </td>
          <td class="h-px w-72 min-w-72">
            <a class="block relative z-10">
              <div class="px-2 md:px-6 py-2">
                <p class="text-xs md:text-sm text-gray-500">
                  {{ chapter.description }}
                </p>
              </div>
            </a>
          </td>
          <td class="size-px whitespace-nowrap">
            <a class="block relative z-10">
              <div class="px-2 md:px-6 py-2 flex gap-x-1">
                <div v-show="props.initialFetchCompleted" class="grid min-w-full w-full h-fit items-center">
                  <div
                    class="row-start-1 col-start-1 w-full h-fit bg-transparent z-10 rounded-full overflow-hidden dark:bg-neutral-700 text-xs text-center my-auto"
                    :class="{
                      'text-gray-600': props.chapterCompletedProgress(chapter.index) < 50,
                      'text-white pr-8':
                        props.chapterCompletedProgress(chapter.index) >= 50 &&
                        props.chapterCompletedProgress(chapter.index) < 60,
                      'text-white': props.chapterCompletedProgress(chapter.index) >= 60,
                    }"
                  >
                    {{ props.chapterCompletedProgress(chapter.index).toFixed(0) }}%
                  </div>
                  <div
                    class="row-start-1 col-start-1 grid w-full h-3.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
                  >
                    <div
                      class="row-start-1 col-start-1 flex flex-col justify-center overflow-hidden bg-blue-200 text-xs text-white text-center whitespace-nowrap rounded-[4px]"
                      :style="'width: ' + props.chapterStartedProgress(chapter.index) + '%'"
                      role="progressbar"
                      :aria-valuenow="props.chapterStartedProgress(chapter.index)"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                    <div
                      class="row-start-1 col-start-1 flex flex-col justify-center overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap rounded-[4px]"
                      :style="'width: ' + props.chapterCompletedProgress(chapter.index) + '%'"
                      role="progressbar"
                      :aria-valuenow="props.chapterCompletedProgress(chapter.index)"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
              </div>
            </a>
          </td>
          <td class="size-px whitespace-nowrap">
            <a class="block relative z-10">
              <div class="px-2 md:px-6 py-2 flex -space-x-1">
                <div class="hs-tooltip inline-flex" v-for="(case_, idx) in chapter.exemplary_cases">
                  <img
                    v-if="!!props.titlePerson(case_)"
                    class="hs-tooltip-toggle inline-block size-6 rounded-full ring-2 ring-white dark:ring-gray-800"
                    :src="props.titlePersonProfileImage(case_)"
                    alt="Image Description"
                  />
                  <span
                    v-if="!!props.titlePerson(case_)"
                    class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                    role="tooltip"
                  >
                    {{ props.titlePersonFirstName(case_) + ' ' + props.titlePersonLastName(case_) }}
                  </span>
                </div>
              </div>
            </a>
          </td>
          <td class="size-px whitespace-nowrap">
            <div class="px-2 md:px-6 py-2">
              <button
                v-show="props.isEditing"
                :disabled="props.savingChangedOrder"
                translate="no"
                class="material-symbols-outlined notranslate text-red-600 hover:text-red-800 text-xl"
                @click="emit('onDeleteChapter', $event, chapter.index)"
              >
                delete
              </button>
            </div>
          </td>
        </tr>
      </template>
    </draggable>

    <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
      <tr v-show="props.isEditing">
        <td colspan="5">
          <button
            type="button"
            class="min-w-full py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            @click="emit('onNewChapter')"
          >
            <div
              v-if="props.isLoading"
              class="animate-spin inline-block w-4 h-4 text-white rounded-full"
              role="status"
              aria-label="loading"
            >
              <span class="sr-only">Loading</span>
            </div>
            <div class="flex min-w-full gap-x-3 items-center justify-center" v-else>
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5v14" />
              </svg>
              {{ $t('message.addChapter') }}
            </div>
          </button>
        </td>
      </tr>
      <!-- End Table body row -->
    </tbody>
    <!-- End Table body -->
  </table>
  <!-- End Table -->

  <!-- Footer -->
  <div
    class="px-2 md:px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700"
  >
    <div>
      <p class="text-sm text-gray-600 dark:text-gray-400">
        <span class="font-semibold text-gray-800 dark:text-gray-200">
          {{ numChaptersCompleted }} / {{ numberOfChapters }}</span
        >
        {{ $t('message.chaptersCompleted') }}
      </p>
    </div>
  </div>
  <!-- End Footer -->
</template>

<style scoped></style>
