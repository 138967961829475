<script setup lang="ts">
import { useCaseInteractionStore, useCaseStore } from '@/stores';
import { ListChecks, Circle, CheckCircle } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const { t } = useI18n();

// stores
const caseInteractionStore = useCaseInteractionStore();
const caseStore = useCaseStore();

// props
const props = defineProps({
  withTitle: {
    type: Boolean,
    default: false,
  },
  localeGerman: {
    type: Boolean,
    default: false,
  },
  taskIndex: {
    type: Number,
    default: undefined,
  },
});

const { isFormCase } = storeToRefs(caseStore);

const currentTask = computed(() => {
  if (props.taskIndex !== undefined) {
    return caseInteractionStore.currentCase?.tasks[props.taskIndex];
  }
  return caseInteractionStore.currentTask;
});

const taskInteraction = computed(() => {
  if (props.taskIndex !== undefined) {
    return caseInteractionStore.currentCaseInteraction?.task_interactions[props.taskIndex];
  }
  return caseInteractionStore.currentTaskInteraction;
});

const subtaskIsComplete = computed(() => {
  let completed: { [key: number]: { [key: number]: boolean } } = {};
  const tasks = caseInteractionStore.currentCase?.tasks || [];
  const taskInteractions = caseInteractionStore.currentCaseInteraction?.task_interactions || [];

  tasks.forEach((task, taskIndex) => {
    completed[taskIndex] = {};
    const taskInteraction = taskInteractions[taskIndex];
    if (taskInteraction?.subtask_interactions) {
      taskInteraction.subtask_interactions.forEach((si, subtaskIndex) => {
        completed[taskIndex][subtaskIndex] = si.completed_at !== null;
      });
    }
  });
  return completed;
});

const isTaskComplete = computed(() => {
  const completed: { [key: number]: boolean } = {};
  const tasks = caseInteractionStore.currentCase?.tasks || [];

  tasks.forEach((task) => {
    if (task.index === undefined) return;
    completed[task.index] = task.subtasks.every((subtask) => subtaskIsComplete.value[task.index]?.[subtask.index]);
  });
  return completed;
});

const tDeu = (key: string) => {
  return t(key, undefined, { locale: 'deu' });
};

const localT = (key: string) => {
  if (props.localeGerman) {
    return tDeu(key);
  }
  return t(key);
};
</script>

<template>
  <div class="bg-green-50 rounded-2xl p-4 mb-24 border border-green-light">
    <div v-if="withTitle">
      <h2 class="text-2xl font-semibold mb-2">{{ currentTask?.title }}</h2>
      <p class="text-gray-600 mb-6">{{ currentTask?.details?.description }}</p>
      <div class="h-px bg-green-light mb-6"></div>
    </div>

    <div class="flex items-center gap-2 mb-3">
      <ListChecks class="w-5 h-5 text-green" />
      <span class="font-semibold text-green tracking-[0.005rem]">{{ localT('message.goals') }}</span>
    </div>
    <div class="space-y-6">
      <div v-for="task in caseInteractionStore.currentCase?.tasks" :key="task.id" class="space-y-3">
        <div class="font-medium" :class="{ 'text-green': isTaskComplete[task.index] }">
          {{ task.title }}
        </div>
        <div class="space-y-3 flex-col flex">
          <!-- subtasks -->
          <div v-for="subtask in task.subtasks" :key="subtask.id" class="flex items-start gap-2">
            <span class="flex-shrink-0 mt-1 mx-0.5">
              <Circle v-if="!subtaskIsComplete[task.index]?.[subtask.index]" class="w-4 h-4" />
              <CheckCircle v-else class="w-4 h-4 text-green" />
            </span>
            <span
              :class="{
                'text-green': subtaskIsComplete[task.index]?.[subtask.index],
              }"
              >{{ subtask.details.target }}</span
            >
          </div>

          <!-- additional subtask to fill and submit form -->
          <div v-if="isFormCase" class="flex items-start gap-2">
            <span class="flex-shrink-0 mt-1 mx-0.5">
              <Circle class="w-4 h-4" />
            </span>
            <span>Formular ausfüllen und abschicken</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
