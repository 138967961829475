<script setup lang="ts">
import ClozeEditor from '@/components/didactics/cloze/ClozeEditor.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, PropType, reactive, ref } from 'vue';
import { watch } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { updateMinTextareaHeight } from '@/helper';
import { onBeforeRouteLeave } from 'vue-router';
import { useExerciseLifecycle, useAutosave } from '@/composables';
import { SectionContentItemOverview } from '@/apiclient';
import { Space } from 'lucide-vue-next';
import Title from '@/components/didactics/title/Title.vue';

const { t } = useI18n();

// stores
const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const courseStore = useCourseStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

// props
const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
});

// emits
const emit = defineEmits(['addNewVocab']);

// composables
const {
  handleExerciseFinished,
  fetchInteractionState,
  reopenExercise,
  itemInteractionState,
  fetchCompleted: interactionFetchCompleted,
} = useExerciseLifecycle({
  contentItem: props.contentItem,
  sectionIndex: props.sectionIndex,
  pageIndex: props.pageIndex,
  isEditing: props.isEditing,
  emit,
  onComplete: (response) => {
    itemInteractionState.value = response.section_content_item_interaction;
  },
  onReopen: () => {
    hideSolutions();
    resetIsAttempted();
  },
});
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.isEditing) return;
    if (!props.contentItem.cloze) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Lückentext-Übung - keine Übung', 'Error');
      return;
    }
    await courseStore.updateClozeItem(props.contentItem.section_id, props.contentItem.id, {
      title: localTitle.value,
      task_instructions: localTaskInstructions.value,
      html_content: localHtmlContent.value,
    });
    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

// refs
const clozeEditor = ref(null);

// state
const localHtmlContent = ref(props.contentItem.cloze?.html_content);
const localTitle = ref(props.contentItem.title);
const localTaskInstructions = ref(props.contentItem.task_instructions);
const nodeIsAttempted = reactive({});
const nodeIsSolved = reactive({});

// lifecycle hooks & methods
onMounted(async () => {
  await nextTick();
  if (props.isEditing) {
    interactionFetchCompleted.value = true; // no need to wait for interaction fetch
  } else {
    await fetchInteractionState();
  }
});

const allNodesSolved = computed(() => {
  return Object.values(nodeIsSolved).every((value) => value === true);
});

const allNodesAttempted = computed(() => {
  return Object.values(nodeIsAttempted).every((value) => value === true);
});

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) {
        {
          if (!courseInteractionFetchCompleted.value) return;
          courseInteractionStore.startSectionContentItemIfNotAlready(props.contentItem.id).then((response) => {
            if (!response) return; // nothing has happened as the item was already started
            console.log('reopened_at', response.reopened_at);
            itemInteractionState.value = response;
          });
        }
      }
    }
  },
);

watch(
  () => props.contentItem.cloze?.html_content,
  (newValue) => {
    localHtmlContent.value = newValue;
  },
);

watch(
  () => allNodesAttempted.value,
  //  () => allNodesSolved.value,
  async (newValue) => {
    console.log('allNodesSolved', newValue);
    console.log('allNodesAttempted', allNodesAttempted.value);
    if (newValue) {
      await handleExerciseFinished();
    }
  },
);

const hideSolutions = () => {
  Object.keys(nodeIsSolved).forEach((nodeId) => {
    nodeIsSolved[nodeId] = false;
  });
  if (!clozeEditor.value) return;
  clozeEditor.value.resetAllAttempts();
};

const resetIsAttempted = () => {
  Object.keys(nodeIsAttempted).forEach((nodeId) => {
    nodeIsAttempted[nodeId] = false;
  });
};
</script>

<template>
  <div class="w-full pt-2 px-4 pb-1 min-h-48 relative">
    <Title
      :iconComponent="Space"
      headline="Lückentext"
      :isEditing="props.isEditing"
      v-model:title="localTitle"
      @change="unsavedChanges = true"
    />

    <div
      :class="{
        'opacity-25':
          !props.isEditing &&
          (!interactionFetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <div
        v-show="props.isEditing || localTaskInstructions !== ''"
        class="text-justify py-2 w-full flex items-center text-base"
      >
        <textarea
          v-if="props.isEditing"
          v-model="localTaskInstructions"
          class="w-full px-1 py-0 mb-2 text-base resize-none rounded-lg text-justify"
          placeholder="Aufgabenstellung (optional)"
          rows="2"
          @input="
            (event) => {
              const target = event.target as HTMLInputElement;
              updateMinTextareaHeight(target);
            }
          "
          @change="unsavedChanges = true"
        />
        <span v-else class="w-full px-0.5 my-4 text-base rounded-lg flex text-justify">{{
          localTaskInstructions
        }}</span>
      </div>
      <div class="text-justify text-base my-8" v-if="contentItem.cloze">
        <ClozeEditor
          ref="clozeEditor"
          :allowList="true"
          :allowEdit="props.isEditing"
          :content="contentItem.cloze.html_content"
          @unsavedChanges="
            (newHtml) => {
              localHtmlContent = newHtml;
              unsavedChanges = true;
            }
          "
          @nodeSolved="
            ({ id, isSolved }) => {
              console.log(id, ' is solved: ', isSolved);
              nodeIsSolved[id] = isSolved;
            }
          "
          @nodeAttempted="
            ({ id, isAttempted }) => {
              console.log(id, ' is attempted: ', isAttempted);
              nodeIsAttempted[id] = isAttempted;
            }
          "
        />
      </div>
    </div>

    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!interactionFetchCompleted"
    >
      <LoadingSpinnerLarge />
    </div>

    <div
      v-if="
        interactionFetchCompleted &&
        !props.isEditing &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped></style>
