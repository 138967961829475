<script setup lang="ts">
import SlideCard from '@/components/various/SlideCard.vue';
import { useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { ref, computed, onMounted, watch, onActivated } from 'vue';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
import { useRoute } from 'vue-router';
import { useAlertStore } from '@/stores';
import { useCourseInteractionStore } from '@/stores/courseInteraction.store';
import { router } from '@/router';
import CourseService from '@/services/CourseService';
import BottomNav from '@/components/BottomNav.vue';
import { useI18n } from 'vue-i18n';
import { Check, ChevronLeft, ChevronsUpDown, ChevronsDownUp, Play } from 'lucide-vue-next';
import ResumeIcon from '@/components/various/ResumeIcon.vue';
import { ChapterOverview, SectionOverview } from '@/apiclient';
import ProgressBar from '@/components/various/ProgressBar.vue';
import LastCompletedAndNextUp from '@/components/various/LastCompletedAndNextUp.vue';
const { t } = useI18n();

const route = useRoute();
const courseStore = useCourseStore();
const courseInteractionStore = useCourseInteractionStore();
const { isEnrolled, currentCourseInteractionWithProgress } = storeToRefs(courseInteractionStore);

const {
  currentCourse,
  currentCourseTitle,
  currentCourseDescription,
  currentCourseChapters,
  numberOfChapters,
  numberOfCases,
} = storeToRefs(courseStore);

const alertStore = useAlertStore();

const isLoading = ref(true);
const openChapterId = ref<string | null>(null);
const openSectionId = ref<string | null>(null);
const openLearningObjectives = ref<string | null>(null);
const sortedChapters = ref<ChapterOverview[]>([]);

const openChapter = computed(() => {
  return sortedChapters.value.find((chapter) => chapter.id === openChapterId.value);
});

const toggleChapter = (chapterId: string) => {
  openChapterId.value = openChapterId.value === chapterId ? null : chapterId;
  openLearningObjectives.value = null;
};

const toggleSection = (sectionId: string) => {
  openSectionId.value = openSectionId.value === sectionId ? null : sectionId;
  openLearningObjectives.value = null;
};

const toggleLearningObjectives = (sectionId: string) => {
  openLearningObjectives.value = openLearningObjectives.value === sectionId ? null : sectionId;
  console.log('openLearningObjectives', openLearningObjectives.value);
};

watch(
  () => route.params.courseId,
  async (newCourseId) => {
    if (!newCourseId) return;
    await handleCourseChange(newCourseId as string);
  },
);

onMounted(async () => {
  const courseId = route.params.courseId;
  if (courseId) {
    await handleCourseChange(courseId as string);
  }
});

const handleCourseChange = async (courseId: string) => {
  isLoading.value = true;
  sortedChapters.value = [];

  try {
    await courseStore.setCurrentCourse(courseId);

    try {
      await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();

      if (currentCourseChapters.value?.length > 0) {
        sortedChapters.value = getOrderedChapters();
      }
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error('Error loading course:', error);
    alertStore.error('Failed to load course');
  } finally {
    isLoading.value = false;
  }
};

const getOrderedChapters = (): ChapterOverview[] => {
  // order currentCourseChapters.value by index
  let chapters = currentCourseChapters.value;
  if (!chapters) {
    return [];
  }
  chapters = chapters.filter((chapter: ChapterOverview) => chapterHasPublishedContent(chapter));
  return [...chapters].sort((a: ChapterOverview, b: ChapterOverview) => a.index - b.index);
  // return chapters;
};

const isChapterCompleted = (chapter: ChapterOverview): boolean => {
  // TODO: check if chapter is completed
  return false;
};

const itemwiseSectionProgress = (sectionId: string) => {
  if (!currentCourseInteractionWithProgress.value?.progress?.content_item_progress_per_section) return 0;
  return currentCourseInteractionWithProgress.value.progress.content_item_progress_per_section[sectionId];
};

const isSectionCompleted = (section: SectionOverview): boolean => {
  const progress = itemwiseSectionProgress(section.id);
  if (!progress) return false;
  return progress.n_items_completed === progress.n_items_total;
};

const sectionProgress = (sectionId: string) => {
  const progress = itemwiseSectionProgress(sectionId);
  if (!progress) return 0;
  return (progress.n_items_completed / progress.n_items_total) * 100;
};

const numCompleted = (sectionId: string) => {
  const progress = itemwiseSectionProgress(sectionId);
  if (!progress) return 0;
  return progress.n_items_completed;
};

const numTotal = (sectionId: string) => {
  const progress = itemwiseSectionProgress(sectionId);
  if (!progress) return 0;
  return progress.n_items_total;
};

const resumeOpenChapter = async () => {
  if (!openChapterId.value) return;
  await courseStore.setCurrentChapter(openChapterId.value);
  await courseInteractionStore.setInteractionsForCurrentChapter();

  let progress = currentCourseInteractionWithProgress.value?.progress?.content_item_progress_per_chapter;
  console.log('progress', progress);
  let firstSectionInOpenChapter = openChapter.value?.sections?.[0]?.id;
  let currentSectionInOpenChapter = progress?.[openChapterId.value]?.current_section_id || firstSectionInOpenChapter;
  let currentPageInOpenSection = progress?.[openChapterId.value]?.current_page_index || -1;

  if (!currentSectionInOpenChapter) return;
  await router.push('/section/' + currentSectionInOpenChapter + '?page=' + currentPageInOpenSection);
};

const resumeSection = async (sectionId: string) => {
  let progress = currentCourseInteractionWithProgress.value?.progress?.content_item_progress_per_section;
  let currentPageInOpenSection = progress?.[sectionId]?.current_page_index || -1;
  await router.push('/section/' + sectionId + '?page=' + currentPageInOpenSection);
};

const beginSection = async (sectionId: string) => {
  await router.push('/section/' + sectionId + '?page=-1');
};

onActivated(async () => {
  if (!courseInteractionStore.currentCourseInteraction) return;
  const currentCourseInteractionProgress = await courseInteractionStore.refetchProgressForCurrentCourseInteraction();
});

const chapterHasPublishedContent = (chapter: ChapterOverview): boolean => {
  return chapter.sections?.some((section) => section.published_at) || false;
};

const progress = computed(() => {
  if (!currentCourseInteractionWithProgress.value?.progress) return 0;
  return (
    (currentCourseInteractionWithProgress.value.progress.n_items_completed /
      currentCourseInteractionWithProgress.value.progress.n_items_total) *
    100
  );
});

const typeToTitle = (type: string) => {
  switch (type) {
    case 'TEXT':
      return 'Tipp';
    case 'MEDIA':
      return 'Media';
    case 'CASE':
      return 'Fall';
    case 'FORM_CASE':
      return 'Fall-Doku';
    case 'FORM_STRUCTURED_EXERCISE':
      return 'Doku';
    case 'VOCAB_LIST':
      return 'Wortschatz';
    case 'PAIR_OF_TERMS_GAME':
      return 'Zuordnen';
    case 'CATEGORIZATION_EXERCISE':
      return 'Kategorisieren';
    case 'CLOZE':
      return 'Lückentext';
    case 'RESOURCE_WITH_QUESTIONS':
      return 'Quiz';
    case 'SET_PHRASE_LIST':
      return 'Wortschatz';
    case 'MEANING_AND_MAKING':
      return 'Wortschatz';
    case 'SCORING_EXERCISE':
      return 'Bewerten';
    case 'IMAGE_LABELING_EXERCISE':
      return 'Beschriften';
    case 'ORDERING_EXERCISE':
      return 'Sortieren';
    case null:
      return 'Übung';
    default:
      return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  }
};
</script>

<template>
  <main class="flex h-full max-w-[85rem] mx-auto">
    <LoadingSpinnerFullScreen :show="isLoading" />

    <div v-if="!isLoading" class="flex flex-col w-full px-2 mt-2 gap-y-3 relative overflow-y-auto">
      <!-- header -->
      <div class="flex flex-col gap-y-2 mb-4 px-2">
        <div
          @click="router.push({ name: 'my-courses' })"
          class="absolute bg-gray top-0 left-0 w-full sticky flex-col flex items-center gap-x-2 py-2 cursor-pointer"
        >
          <span class="inline-flex items-center gap-x-2 w-full mr-auto mb-4"
            ><ChevronLeft class="w-5 h-5" /> {{ $t('message.myCourses') }}</span
          >
          <hr class="h-[1px] w-full bg-gray-medium border-0" />
        </div>

        <h1 class="text-[28px] font-semibold">{{ currentCourseTitle }}</h1>
        <div class="text-sm">{{ currentCourseDescription }}</div>

        <ProgressBar v-if="isEnrolled && progress > 0" :progress="progress" :label="t('course.overallProgress')" />
      </div>
      <!-- end header -->

      <!-- course chapters -->
      <div class="flex flex-col gap-y-2 pb-40">
        <SlideCard
          v-for="(chapter, chapterIndex) in sortedChapters"
          :key="chapter.id"
          :is-open="openChapterId === chapter.id"
          @toggle="toggleChapter(chapter.id)"
          :bg-color="isChapterCompleted(chapter) ? 'bg-green-veryLight border border-green-light' : 'bg-white'"
          :show-arrow-icon="!isChapterCompleted(chapter)"
          :show-divider="false"
          :arrange-icons-horizontally="true"
        >
          <template #header>
            <div class="flex flex-row items-center w-full">
              <div
                class="text-base font-semibold w-9 h-9 rounded-full flex items-center justify-center mr-2"
                :class="{
                  'bg-white border border-green-light': isChapterCompleted(chapter),
                  'bg-gray-light-bg': !isChapterCompleted(chapter),
                }"
              >
                {{ (chapter.index + 1).toString().padStart(2, '0') }}
              </div>

              <h2 class="text-base font-semibold">
                {{ chapter.title }}
              </h2>

              <div v-if="isChapterCompleted(chapter)" class="ml-auto text-green">
                <Check :size="20" />
              </div>
            </div>
          </template>

          <template #content>
            <div>{{ chapter.description }}</div>
            <div class="font-semibold text-sm">
              {{ $t('course.section', { count: chapter.sections?.length || 0 }) }}
            </div>

            <!-- sections -->
            <SlideCard
              v-for="section in chapter.sections"
              :key="section.id"
              :is-open="openSectionId === section.id"
              @toggle="toggleSection(section.id)"
              @play="beginSection(section.id)"
              :bg-color="
                isSectionCompleted(section) ? 'border border-green-light bg-green-veryLight' : 'bg-gray-light-bg'
              "
              border-radius="rounded-lg"
              :show-arrow-icon="!isSectionCompleted(section)"
              :open-icon="ChevronsDownUp"
              :close-icon="ChevronsUpDown"
              :show-divider="false"
              :play-icon="Play"
            >
              <template #header v-if="section.published_at">
                <div class="flex flex-col gap-y-3 w-full">
                  <div class="flex items-center gap-x-3 w-full">
                    <h3 class="text-sm font-semibold">
                      {{ section.title }}
                    </h3>

                    <div
                      v-if="isSectionCompleted(section)"
                      class="ml-auto"
                      :class="{ 'text-green': isSectionCompleted(section) }"
                    >
                      <Check :size="20" />
                    </div>
                  </div>

                  <div v-if="!isSectionCompleted(section)" class="flex flex-row items-center gap-x-2">
                    <ProgressBar
                      :progress="sectionProgress(section.id)"
                      :show-percentage="false"
                      progressColor="bg-black"
                      :height="4"
                      class="w-full"
                    />
                    <div class="text-xs font-medium text-nowrap">
                      {{ $t('course.task') }} {{ numCompleted(section.id) }}/{{ numTotal(section.id) }}
                    </div>
                  </div>
                </div>
              </template>

              <template #content>
                <div class="relative">
                  <SlideCard
                    v-if="section.learning_objectives?.length"
                    :is-open="openLearningObjectives === section.id"
                    @toggle="toggleLearningObjectives(section.id)"
                    :show-arrow-icon="true"
                    :show-divider="false"
                    border-radius="rounded-lg"
                    :bg-color="isSectionCompleted(section) ? 'border border-green-light bg-green-veryLight' : ''"
                  >
                    <template #header>
                      <div class="text-sm font-semibold w-full">{{ $t('message.learningObjectives') }}</div>
                    </template>

                    <template #content>
                      <ul class="list-disc list-inside">
                        <li v-for="learningObjective in section.learning_objectives">
                          {{ learningObjective.description }}
                        </li>
                      </ul>
                    </template>
                  </SlideCard>

                  <!-- <div :class="{ 'mt-2': section.learning_objectives?.length }">
                    <ItemBadgesGroup :progress="itemwiseSectionProgress(section.id)" />
                  </div> -->

                  <div
                    v-if="itemwiseSectionProgress(section.id)"
                    :class="{ 'mt-2 flex-col flex gap-y-1 mb-2': section.learning_objectives?.length }"
                  >
                    <LastCompletedAndNextUp
                      :lastCompleted="{
                        title:
                          itemwiseSectionProgress(section.id).last_completed_title ||
                          typeToTitle(itemwiseSectionProgress(section.id).last_completed_type),
                      }"
                      :current="{
                        title:
                          itemwiseSectionProgress(section.id).next_title ||
                          typeToTitle(itemwiseSectionProgress(section.id).next_type),
                      }"
                    />
                  </div>
                </div>
              </template>
            </SlideCard>
            <!-- end sections -->
          </template>
        </SlideCard>
      </div>
      <!-- end course chapters -->
    </div>

    <BottomNav />

    <div class="fixed left-0 bottom-16 flex justify-center w-full items-center mb-6 pointer-events-none">
      <div
        @click="
          () => {
            if (openChapter) {
              resumeOpenChapter();
            } else {
              if (!currentCourseInteractionWithProgress) return;
              CourseService.resumeCourse(router, currentCourseInteractionWithProgress);
            }
          }
        "
        class="flex bg-black text-white px-4 py-3 max-w-60 rounded-full text-base font-semibold gap-x-2 pointer-events-auto cursor-pointer select-none items-center"
      >
        <ResumeIcon
          color="white"
          class="flex-shrink-0"
          v-if="
            (!openChapter && CourseService.hasProgress(currentCourseInteractionWithProgress)) ||
            (openChapter && CourseService.hasProgressInChapter(currentCourseInteractionWithProgress, openChapter.id))
          "
        />
        <Play v-else :size="20" fill="white" class="flex-shrink-0" />
        <span class="truncate select-none flex-grow" v-if="openChapter">
          <span v-if="CourseService.hasProgressInChapter(currentCourseInteractionWithProgress, openChapter.id)">{{
            $t('button.resume')
          }}</span>
          <span v-else>{{ $t('button.begin') }}</span>
          {{ (openChapter.index + 1).toString().padStart(2, '0') }} - {{ openChapter.title }}
        </span>
        <span class="truncate select-none flex-grow" v-else>
          <span v-if="CourseService.hasProgress(currentCourseInteractionWithProgress)">{{
            $t('button.resumeCourse')
          }}</span>
          <span v-else>{{ $t('button.beginCourse') }}</span>
        </span>
      </div>
    </div>
  </main>
</template>

<style scoped></style>
