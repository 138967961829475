<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useOrganizationStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import UserList from '@/components/UserList.vue';
import { useI18n } from 'vue-i18n';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useManagedOrganizations } from '@/composables/useManagedOrganizations';
import { useOrganizationMemberHighlighting } from '@/composables/useOrganizationMemberHighlighting';
import { MemberProgress, MemberStats } from '@/apiclient';
import { getColorForMember } from '@/views/dashboard/helper';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const { t } = useI18n();
const organizationStore = useOrganizationStore();
const initialFetchCompleted = ref(false);
const selectedBinning = ref('week'); // 'day', 'week', or 'month'
const dateDisplayMode = ref('absolute'); // 'absolute' or 'relative'

// composables
const { hasManagerAccess } = useManagedOrganizations({
  handleOrganizationChange: async () => {
    initialFetchCompleted.value = false;
    await Promise.all([organizationStore.loadMembers(), organizationStore.loadProgress()]);
    initialFetchCompleted.value = true;
  },
});
const { highlightedUser, highlightUser, clearHighlight } = useOrganizationMemberHighlighting();

// Process member data for charts
const processedMemberData = computed(() => {
  if (!organizationStore.orgMemberProgress || !organizationStore.orgMemberProgress.length) return [];

  return organizationStore.orgMemberProgress
    .map((stats: MemberProgress, index: number) => {
      if (!userHasRegistrationDate(stats.user_id)) return null; // signup not completed

      // Process content item data
      const contentItemData = processTimeData(
        stats.content_item_started_at,
        stats.content_item_completed_at,
        stats.user_id,
      );

      // Process case data
      const caseData = processTimeData(stats.cases_played_at, stats.cases_solved_at, stats.user_id);

      // Process vocab test data
      const vocabData = processTimeData(stats.vocab_tests_taken_at, [], stats.user_id);

      return {
        id: stats.user_id,
        name: `Student ${index + 1}`,
        contentItemData,
        caseData,
        vocabData,
      };
    })
    .filter(Boolean);
});

const isDisplayModeRelative = computed(() => dateDisplayMode.value === 'relative');

// Function to process time data based on binning
function processTimeData(startedDates: string[], completedDates: string[], userId: string | null = null) {
  if (!startedDates || !startedDates.length) {
    // Return empty data with the appropriate bins
    const bins = isDisplayModeRelative.value ? userBins(userId) : globalBins.value;
    return {
      labels: bins.labels,
      started: new Array(bins.labels.length).fill(0),
      completed: new Array(bins.labels.length).fill(0),
    };
  }

  // Convert string dates to Date objects, preserving nulls
  const startedDateObjects = startedDates.map((d) => (d ? new Date(d) : null));
  const completedDateObjects = (completedDates || []).map((d) => (d ? new Date(d) : null));

  // Use the appropriate bins
  const bins = isDisplayModeRelative.value ? userBins(userId) : globalBins.value;

  // Count started and completed items per bin
  const started = new Array(bins.labels.length).fill(0);
  const completed = new Array(bins.labels.length).fill(0);

  startedDateObjects.forEach((date) => {
    if (!date) return;
    const binIndex = getBinIndex(date, bins.bins);
    if (binIndex >= 0 && binIndex < started.length) {
      started[binIndex]++;
    }
  });

  completedDateObjects.forEach((date) => {
    if (!date) return;
    const binIndex = getBinIndex(date, bins.bins);
    if (binIndex >= 0 && binIndex < completed.length) {
      completed[binIndex]++;
    }
  });

  return {
    labels: bins.labels,
    started,
    completed,
  };
}

// Create time bins based on binning type
function createBins(startDate: Date, endDate: Date, binning: string) {
  const labels = [];
  const bins = [];

  let current = new Date(startDate);
  let deltaTimeInterval = 0;

  while (current <= endDate) {
    let label = '';

    if (binning === 'day') {
      if (dateDisplayMode.value === 'absolute') {
        label = current.toISOString().split('T')[0];
      } else {
        label = `Day ${deltaTimeInterval}`;
      }
      bins.push(new Date(current));
      current.setDate(current.getDate() + 1);
    } else if (binning === 'week') {
      if (dateDisplayMode.value === 'absolute') {
        const weekStart = new Date(current);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        label = `${weekStart.toISOString().split('T')[0]}`;
      } else {
        label = `Week ${deltaTimeInterval}`;
      }
      bins.push(new Date(current));
      current.setDate(current.getDate() + 7);
    } else if (binning === 'month') {
      if (dateDisplayMode.value === 'absolute') {
        label = `${current.getFullYear()}-${String(current.getMonth() + 1).padStart(2, '0')}`;
      } else {
        label = `Month ${deltaTimeInterval}`;
      }

      let startOfMonth = null;
      if (dateDisplayMode.value === 'absolute') {
        // first day of month = calendary start of month
        startOfMonth = new Date(current.getFullYear(), current.getMonth(), 1);
      } else {
        // first day of month = day of registration
        startOfMonth = new Date(current);
      }

      bins.push(startOfMonth);
      current.setMonth(current.getMonth() + 1);
    }

    labels.push(label);
    deltaTimeInterval++;
  }

  return { labels, bins };
}

// Get bin index for a date
function getBinIndex(date: Date, bins: Date[]) {
  // Find the first bin where the bin start is before or equal to the date
  for (let i = 0; i < bins.length; i++) {
    if (i === bins.length - 1 || (date >= bins[i] && date < bins[i + 1])) {
      console.log('date=', date, 'bins[i]=', bins[i], 'bins[i+1]=', bins[i + 1], 'i=', i);
      return i;
    }
  }
  return -1; // Date is before the first bin
}

// Chart options
const getChartOptions = (title) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0, // Only show integer values
        },
      },
    },
  };
};

// Get common labels for all charts
const commonChartLabels = computed(() => {
  if (!processedMemberData.value.length) return [];

  // In relative mode, all members should have the same number of bins
  // In absolute mode, we should use the global bins
  if (dateDisplayMode.value === 'absolute') {
    return globalBins.value.labels;
  } else {
    // For relative mode, find the member with the most bins (longest history)
    const memberWithMostBins = processedMemberData.value.reduce((prev, current) => {
      return current.contentItemData.labels.length > prev.contentItemData.labels.length ? current : prev;
    }, processedMemberData.value[0]);

    return memberWithMostBins.contentItemData.labels;
  }
});

// Chart data for content items
const contentItemChartData = computed(() => {
  if (!processedMemberData.value.length) return { labels: [], datasets: [] };

  return {
    labels: commonChartLabels.value,
    datasets: processedMemberData.value.flatMap((member) => [
      {
        label: `${member.name} Started`,
        data: member.contentItemData.started,
        borderColor: getColorForMember(member.id),
        borderDash: [5, 5],
        borderWidth: highlightedUser.value === member.id ? 3 : 1,
        pointRadius: 2,
        tension: 0.1,
      },
      {
        label: `${member.name} Completed`,
        data: member.contentItemData.completed,
        borderColor: getColorForMember(member.id),
        borderWidth: highlightedUser.value === member.id ? 3 : 1,
        pointRadius: 2,
        tension: 0.1,
      },
    ]),
  };
});

// Chart data for cases
const caseChartData = computed(() => {
  if (!processedMemberData.value.length) return { labels: [], datasets: [] };

  return {
    labels: commonChartLabels.value,
    datasets: processedMemberData.value.flatMap((member) => [
      {
        label: `${member.name} Started`,
        data: member.caseData.started,
        borderColor: getColorForMember(member.id),
        borderDash: [5, 5],
        borderWidth: highlightedUser.value === member.id ? 3 : 1,
        pointRadius: 2,
        tension: 0.1,
      },
      {
        label: `${member.name} Completed`,
        data: member.caseData.completed,
        borderColor: getColorForMember(member.id),
        borderWidth: highlightedUser.value === member.id ? 3 : 1,
        pointRadius: 2,
        tension: 0.1,
      },
    ]),
  };
});

// Chart data for vocab tests
const vocabChartData = computed(() => {
  if (!processedMemberData.value.length) return { labels: [], datasets: [] };

  return {
    labels: commonChartLabels.value,
    datasets: processedMemberData.value.map((member) => ({
      label: `${member.name}`,
      data: member.vocabData.started,
      borderColor: getColorForMember(member.id),
      borderWidth: highlightedUser.value === member.id ? 3 : 1,
      pointRadius: 2,
      tension: 0.1,
    })),
  };
});

// Find the earliest date across all members and all data types
const earliestDateAcrossAllData = computed(() => {
  if (!organizationStore.orgMemberProgress || !organizationStore.orgMemberProgress.length) {
    return new Date(); // Default to current date if no data
  }

  let earliestDate = new Date(); // Start with current date

  organizationStore.orgMemberProgress.forEach((stats) => {
    // Collect all date arrays
    const allDates = [
      ...(stats.content_item_started_at || []).filter(Boolean),
      ...(stats.cases_played_at || []).filter(Boolean),
      ...(stats.vocab_tests_taken_at || []).filter(Boolean),
    ];

    // Find earliest date in this member's data
    if (allDates.length > 0) {
      const memberEarliestDate = new Date(Math.min(...allDates.map((d) => new Date(d).getTime())));
      if (memberEarliestDate < earliestDate) {
        earliestDate = memberEarliestDate;
      }
    }
  });

  return earliestDate;
});

const globalBins = computed(() => {
  return createBins(earliestDateAcrossAllData.value, new Date(), selectedBinning.value);
});

const userBins = (userId: string) => {
  if (!organizationStore.orgMemberProgress) return [];
  let userStats = organizationStore.orgMemberProgress.find((stats) => stats.user_id === userId);
  if (!userStats) return [];
  let bins = createBins(new Date(userStats.registered_at), new Date(), selectedBinning.value);
  return bins;
};

const longestUserBins = computed(() => {
  return processedMemberData.value.reduce((max, member) => {
    const bins = userBins(member.id);
    return bins.length > max.length ? bins : max;
  }, []);
});

const userHasRegistrationDate = (userId: string) => {
  if (!organizationStore.orgMemberProgress) return false;
  let user = organizationStore.orgMemberProgress.find((stats) => stats.user_id === userId);
  return user && user.registered_at;
};
</script>

<template>
  <main class="flex relative h-full max-w-[85rem] mx-auto">
    <div
      class="flex absolute top-0 left-0 justify-center items-center h-full w-full transition-opacity duration-300"
      :class="{ 'opacity-100': !initialFetchCompleted, 'opacity-0 pointer-events-none': initialFetchCompleted }"
    >
      <LoadingSpinnerLarge />
    </div>

    <div v-if="initialFetchCompleted" class="flex flex-col m-4 gap-4 w-full">
      <!-- Binning and display mode selectors -->
      <div class="flex justify-end gap-4 mb-4">
        <!-- Date display mode selector -->
        <div class="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium border rounded-l-lg"
            :class="dateDisplayMode === 'absolute' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'"
            @click="dateDisplayMode = 'absolute'"
          >
            {{ t('Absolute Dates') }}
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium border-t border-b border-r rounded-r-lg"
            :class="dateDisplayMode === 'relative' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'"
            @click="dateDisplayMode = 'relative'"
          >
            {{ t('Relative to Registration') }}
          </button>
        </div>

        <!-- Binning selector -->
        <div class="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium border rounded-l-lg"
            :class="selectedBinning === 'day' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'"
            @click="selectedBinning = 'day'"
          >
            {{ t('Day') }}
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium border-t border-b border-r"
            :class="selectedBinning === 'week' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'"
            @click="selectedBinning = 'week'"
          >
            {{ t('Week') }}
          </button>
          <button
            type="button"
            class="px-4 py-2 text-sm font-medium border-t border-b border-r rounded-r-lg"
            :class="selectedBinning === 'month' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'"
            @click="selectedBinning = 'month'"
          >
            {{ t('Month') }}
          </button>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-6 gap-4 h-full overflow-hidden">
        <!-- User list -->
        <UserList
          :users="processedMemberData"
          :highlighted-user="highlightedUser"
          :user-has-registration-date="userHasRegistrationDate"
          :get-color-for-user="getColorForMember"
          @highlight="highlightUser"
          @clear-highlight="clearHighlight"
        />

        <!-- Charts container -->
        <div class="md:col-span-5 space-y-6 overflow-auto">
          <!-- Content Items Chart -->
          <div class="bg-white p-4 rounded-lg shadow">
            <h2 class="text-lg font-semibold mb-4">{{ t('Content Items Progress') }}</h2>
            <div class="h-80">
              <Line :data="contentItemChartData" :options="getChartOptions(t('Started and Completed Items'))" />
            </div>
          </div>

          <!-- Cases Chart -->
          <div class="bg-white p-4 rounded-lg shadow">
            <h2 class="text-lg font-semibold mb-4">{{ t('Case Interactions') }}</h2>
            <div class="h-80">
              <Line :data="caseChartData" :options="getChartOptions(t('Started and Completed Cases'))" />
            </div>
          </div>

          <!-- Vocab Tests Chart -->
          <div class="bg-white p-4 rounded-lg shadow">
            <h2 class="text-lg font-semibold mb-4">{{ t('Vocabulary Tests') }}</h2>
            <div class="h-80">
              <Line :data="vocabChartData" :options="getChartOptions(t('Vocabs tested'))" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
/* Add any specific styles here */
</style>
