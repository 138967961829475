<script setup lang="ts">
import Card from '@/components/didactics/pair_of_terms/Card.vue';
import { computed, nextTick, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import { PairOfTermsItemType } from '@/apiclient';
import { PairOfTermsItem } from '@/helper/typing';
import PairOfTermsCardMediaUpload from '@/components/didactics/pair_of_terms/ExerciseMediaUploadCard.vue';
import { debounce } from 'lodash';

const props = defineProps({
  items: {
    type: Array as PropType<PairOfTermsItem[]>,
    required: true,
  },
  solveTimerMs: {
    type: Number,
    default: 150,
  },
  isItem1Correct: {
    type: Boolean,
    default: false,
  },
  isItem2Correct: {
    type: Boolean,
    default: false,
  },
  showItem1Results: {
    type: Boolean,
    default: false,
  },
  showItem2Results: {
    type: Boolean,
    default: false,
  },
  item1WasSolvedBefore: {
    type: Boolean,
    default: false,
  },
  item2WasSolvedBefore: {
    type: Boolean,
    default: false,
  },
  showConnectingLine: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  contentItemId: {
    type: String,
    required: true,
  },
  item1Selected: {
    type: Boolean,
    default: false,
  },
  item2Selected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['isLoading', 'inputItem1', 'inputItem2', 'delete', 'itemClicked']);

const outerContainer = ref(null);
const outerContainerWidth = ref(0);
const showingResults = ref(false);

const maxCardWidth = computed(() => {
  return outerContainerWidth.value * 0.475;
});

const updateWidth = () => {
  outerContainerWidth.value = outerContainer.value?.offsetWidth ?? 600;
};

const debouncedResize = debounce(updateWidth, 100);

const progress = ref(0);

const signalSolved = async () => {
  // over solveTimerMs, lt progress grow 0->100
  emit('isLoading', true);
  const intervalId = setInterval(async () => {
    progress.value += 1;
    if (progress.value >= 99) {
      showingResults.value = true;
      emit('isLoading', false);
      clearInterval(intervalId);
    }
  }, props.solveTimerMs / 100);
};

onMounted(async () => {
  window.addEventListener('resize', debouncedResize);
  await nextTick();
  updateWidth();

  // the observer is needed to update the width when the container first becomes visible
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.contentRect.width > 0) {
        updateWidth();
      }
    }
  });
  if (outerContainer.value) {
    resizeObserver.observe(outerContainer.value);
  }
});

watch(
  () => props.showResults,
  async (newVal) => {
    if (!!newVal) {
      await signalSolved();
    } else {
      progress.value = 0;
      showingResults.value = false;
    }
  },
);

onUnmounted(() => {
  window.removeEventListener('resize', debouncedResize);
});

defineExpose({
  signalSolved,
});
</script>

<template>
  <div
    ref="outerContainer"
    class="inline-flex relative min-w-full h-fit items-center justify-between py-1 md:py-2 gap-4"
  >
    <div :style="{ width: maxCardWidth + 'px' }" class="card-left z-[11]">
      <Card
        v-if="!!props.items?.[0]"
        :isItem1="true"
        :contentItemId="props.contentItemId"
        :item="props.items[0]"
        :isEditing="isEditing"
        :isSelected="item1Selected"
        :isCorrect="isItem1Correct"
        :wasSolvedBefore="item1WasSolvedBefore"
        :showResults="showItem1Results"
        :width="maxCardWidth"
        @click="() => !isEditing && emit('itemClicked', true)"
        @inputItem="(newItem) => emit('inputItem1', newItem)"
      />
    </div>

    <div
      class="absolute top-0 left-0 w-full h-full flex select-none pointer-events-none items-center justify-center"
      v-if="isEditing"
    >
      <span
        class="cursor-pointer h-6 w-6 pointer-events-auto rounded-lg -mt-2 text-center text-white bg-red-600 hover text-white:bg-red-800"
      >
        <span translate="no" class="material-symbols-outlined notranslate w-fit" @click.prevent="emit('delete')"
          >delete</span
        >
      </span>
    </div>

    <div
      v-if="showConnectingLine"
      class="absolute z-10 top-0 w-full h-full pointer-events-none flex items-center justify-center"
    >
      <div class="w-full h-[12px] bg-black"></div>
    </div>

    <div :style="{ width: maxCardWidth + 'px' }" class="card-right z-[11]">
      <Card
        v-if="!!props.items?.[1]"
        :isItem1="false"
        :contentItemId="props.contentItemId"
        :item="props.items[1]"
        :isEditing="isEditing"
        :isSelected="item2Selected"
        :isCorrect="isItem2Correct"
        :showResults="showItem2Results"
        :wasSolvedBefore="item2WasSolvedBefore"
        :width="maxCardWidth"
        @click="() => !isEditing && emit('itemClicked', false)"
        @inputItem="(newItem) => emit('inputItem2', newItem)"
      />
    </div>
  </div>
</template>

<style scoped></style>
