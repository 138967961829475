/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JournalEntry } from '../models/JournalEntry';
import type { JournalEntryCreate } from '../models/JournalEntryCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class JournalsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * List Journal Entries For Section
   * @param sectionId
   * @param excludeOverwritten
   * @returns JournalEntry Successful Response
   * @throws ApiError
   */
  public listJournalEntriesForSection(
    sectionId: string,
    excludeOverwritten: boolean = true,
  ): CancelablePromise<Array<JournalEntry>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/journals/{section_id}',
      path: {
        section_id: sectionId,
      },
      query: {
        exclude_overwritten: excludeOverwritten,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Journal Entry
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public createJournalEntry(requestBody: JournalEntryCreate): CancelablePromise<JournalEntry | null> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/journals/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
