<script setup lang="ts">
import { AlertError, ContextMenu } from '@/components';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useAlertStore, useAuthStore } from '@/stores';

import { type IStaticMethods } from 'preline/preline';
import { storeToRefs } from 'pinia';
import { getLevelFromXp } from '@/helper';

const authStore = useAuthStore();
const alertStore = useAlertStore();
const { userLevel } = storeToRefs(authStore);
const originalLevel = ref(null);
const mountingComplete = ref(false);
const contextMenu = ref(null);
const isShowingContextMenu = ref(false);

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

watch(
  () => userLevel.value,
  () => {
    if (originalLevel.value === userLevel.value || !mountingComplete.value || userLevel.value === null) {
      return;
    }
    originalLevel.value = userLevel.value;
    console.log('Level up!');
    alertStore.xp(t('message.levelUp', userLevel.value), t('message.levelUpTitle'));
  },
  { immediate: true },
);

// Utility function to check if text selection
const isTextSelected = (): boolean => {
  const selection = window.getSelection();
  return selection ? selection.toString().length > 0 : false;
};

const isTextEditableElement = (element: Element | null): boolean => {
  if (!element) return false;

  // Check if element is an input or textarea and not disabled
  if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
    return !element.disabled;
  }

  // Check for contenteditable attribute and its value
  if (element.hasAttribute('contenteditable')) {
    const editableValue = element.getAttribute('contenteditable');
    return editableValue === '' || editableValue === 'true';
  }

  return false;
};

// Utility function to check if element or its parents are text-selectable
const isTextSelectableElement = (element: Element | null): boolean => {
  if (!element) return false;

  // Get computed style
  const style = window.getComputedStyle(element);

  // Check if the element is user-selectable
  if (
    style.userSelect !== 'none' &&
    style.webkitUserSelect !== 'none' &&
    !element.hasAttribute('contenteditable') === false
  ) {
    return true;
  }

  // Check if it's an input or textarea
  if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
    return true;
  }

  // Recursively check parent
  return element.parentElement ? isTextSelectableElement(element.parentElement) : false;
};

// Prevent default context menu handler
const preventContextMenu = async (e: Event): Promise<void> => {
  const target = e.target as Element;
  if (isTextEditableElement(target)) {
    // normal behaviour for editable elements
    return;
  }
  if (isTextSelectableElement(target)) {
    e.preventDefault();

    // Store the selection
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0).cloneRange(); // Clone the range to preserve it
    const selectedText = selection?.toString();

    // Clear the selection to force close context menu
    selection?.empty();
    await nextTick();

    // If we had text selected, show our custom menu and restore selection
    if (selectedText && range) {
      // Restore the selection
      selection?.addRange(range);

      // Show our menu
      const rect = range.getBoundingClientRect();
      showContextMenu(rect.left, rect.bottom);
    }
  }
};

// Handle touch events for text selection
const handleTouchStart = (e: TouchEvent): void => {
  const target = e.target as Element;
  if (isTextEditableElement(target)) {
    // normal behaviour for editable elements
    return;
  }
  if (isTextSelectableElement(target)) {
    // Store the selection if it exists
    const selection = window.getSelection();
    const range = selection?.rangeCount ? selection.getRangeAt(0).cloneRange() : null;

    // Clear any existing selection/context menu
    selection?.empty();

    // Restore the selection if we had one
    if (range) {
      selection?.addRange(range);
    }
  }
};

// Show the custom context menu at the specified position
const showContextMenu = (x: number, y: number): void => {
  isShowingContextMenu.value = true;
  if (contextMenu.value) {
    (contextMenu.value as any).showContextMenu(x, y);
  }
};

// Handle right-click (desktop) only if text is selected
const handleContextMenu = (event: MouseEvent): void => {
  if (isTextSelected()) {
    event.preventDefault();
    showContextMenu(event.clientX, event.clientY);
  }
};

// // Hide the custom context menu
// const hideContextMenu = (): void => {
//   contextMenu.value?.hideContextMenu();
// };

// Add this near the top with other utility functions
const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

// Modify the handleSelectionChange function
const handleSelectionChange = () => {
  if (isTextEditableElement(document.activeElement)) {
    // normal behaviour for editable elements
    return;
  }
  if (!isTouchDevice()) return; // do not overwrite select text on desktop
  if (isShowingContextMenu.value) {
    // set selection to empty: avoid extending selected text to whole div if context menu pops up and avoids overlapping with context menu
    window.getSelection()?.empty();
    return;
  } // prevent extending selected text to whole div if context menu pops up
  // Small delay to ensure the selection is stable
  setTimeout(() => {
    if (isTextSelected()) {
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const rect = range?.getBoundingClientRect();
      if (rect) {
        showContextMenu(rect.left, rect.bottom);
      }
    }
  }, 10);
};

// Add event listeners for desktop and mobile on mount, remove on unmount
onMounted(() => {
  if (isTouchDevice()) {
    // Mobile: Show menu when text is selected or selection changes
    document.addEventListener('selectionchange', handleSelectionChange);
    document.addEventListener('touchend', handleSelectionChange);
    // Prevent default context menu on mobile
    document.addEventListener('contextmenu', preventContextMenu);
  } else {
    // Desktop: Show on right-click if text is selected
    document.addEventListener('contextmenu', handleContextMenu);
  }

  // Load Material Symbols Outlined font and add class when loaded (avoiding FOUT)
  document.fonts.load("1em 'Material Symbols Outlined'").then(() => {
    document.documentElement.classList.add('font-loaded');
  });
});

onBeforeUnmount(() => {
  if (isTouchDevice()) {
    document.removeEventListener('selectionchange', handleSelectionChange);
    document.removeEventListener('touchend', handleSelectionChange);
    document.removeEventListener('contextmenu', preventContextMenu);
  } else {
    document.removeEventListener('contextmenu', handleContextMenu);
  }
});
</script>

<template>
  <div class="hidden">
    <!-- this enforces preloads and avoids "not in use"-warnings -->
    <span class="material-symbols-outlined" style="display: none">exercise</span>
    <span class="material-symbols-rounded" style="display: none">exercise</span>
  </div>
  <div
    class="app-container flex min-h-full h-full max-h-full w-full min-w-full max-w-full overflow-hidden notranslate"
    @touchstart="handleTouchStart"
  >
    <div class="absolute min-w-fit top-0 right-0 mt-4 mr-4 z-[120]">
      <AlertError />
    </div>
    <div class="flex max-h-full h-full min-h-full min-w-full w-full max-w-full overflow-hidden">
      <router-view />
    </div>
    <div class="z-[110]">
      <ContextMenu ref="contextMenu" @closed="isShowingContextMenu = false" />
    </div>
  </div>
</template>

<style>
@import 'icon_fonts.css';
</style>
