<script setup lang="ts">
import Rating from '@/components/inputs/Rating.vue';

const props = defineProps({
  editable: {
    type: Boolean,
    default: false,
  },
  showScoring: {
    type: Boolean,
    default: false,
  },
});

const description = defineModel('description', {
  default: '',
});

const rating = defineModel('rating', {
  default: 0,
});

const emit = defineEmits(['delete', 'change', 'rated']);

const handleDelete = () => {
  emit('delete');
};
</script>

<template>
  <div class="flex rounded-lg shadow-sm w-full border border-gray-200 shadow-sm px-4 py-3">
    <div class="flex-col flex w-full gap-y-2">
      <input
        v-if="editable"
        type="text"
        :placeholder="$t('message.enterLearningObjective')"
        v-model="description"
        @change="emit('change')"
        class="block w-full text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
      />
      <div
        v-else
        class="block w-full text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        :class="{ 'rounded-s-lg rounded-e-none': editable }"
      >
        {{ description }}
      </div>
      <div v-if="showScoring">
        <Rating
          :model-value="rating"
          @update:model-value="
            (value) => {
              rating = value;
              emit('rated');
            }
          "
        />
      </div>
    </div>
    <button
      v-if="editable"
      type="button"
      @click.prevent="handleDelete"
      class="w-[2.875rem] h-[2.875rem] shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
    >
      <span translate="no" class="material-symbols-outlined notranslate">delete</span>
    </button>
  </div>
</template>
