<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue';
import { useAlertStore, useCourseStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/various/Modal.vue';
import { VocabItem, VocabItemUpdate, VocabListDetails } from '@/apiclient';
import VocabListNew from '@/components/didactics/vocab/VocabListNew.vue';
import ProgressButton from '@/components/ProgressButton.vue';

const { t } = useI18n();

const props = defineProps<{
  show: boolean;
  contentItemId: string;
  sectionId: string;
}>();

const vocabList = defineModel({
  type: Object as PropType<VocabListDetails>,
  required: true,
});

const emit = defineEmits(['close', 'update:vocabList']);

// Stores
const alertStore = useAlertStore();
const courseStore = useCourseStore();

// Refs
const isSubmitting = ref(false);
const vocabListLoading = ref(false);
const show = ref(false);
onMounted(async () => {
  show.value = true;
});

const addVocabItem = async () => {
  isSubmitting.value = true;

  await courseStore
    .addVocabItemToIntroducedVocabList(props.sectionId, props.contentItemId, {
      term: 'Begriff',
      explanation: '',
      usage_examples: [''],
      grammatical_category: 'NOUN',
      specific_article: 'der',
      plural: 'die Begriffe',
      uncountable_noun: false,
      plural_noun: false,
    })
    .then((updatedList) => {
      alertStore.success('Vokabel erfolgreich hinzugefügt');
      vocabList.value = updatedList;
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht hinzugefügt werden', 'Fehler', error);
      console.error(error);
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};

const deleteVocabItem = async (vocabItemId: string) => {
  isSubmitting.value = true;

  await courseStore
    .deleteVocabItemFromIntroducedVocabList(props.sectionId, props.contentItemId, vocabItemId)
    .then((updatedList) => {
      alertStore.success('Vokabel erfolgreich gelöscht');
      vocabList.value = updatedList;
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht gelöscht werden', 'Fehler', error);
      console.error(error);
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};

const updateVocabItem = async (vocabItemId: string, vocabItemUpdate: VocabItemUpdate) => {
  console.log('updateVocabItem', vocabItemId, vocabItemUpdate);
  await courseStore
    .updateVocabItemInIntroducedVocabList(props.sectionId, props.contentItemId, vocabItemId, vocabItemUpdate)
    .then((updatedList) => {
      console.log('Vokabel erfolgreich aktualisiert');
      vocabList.value = updatedList;
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht aktualisiert werden', 'Fehler', error);
      console.error(error);
    });
};
</script>

<template>
  <Modal
    :id="'edit-vocab-modal'"
    :show="show"
    :title="'Vokabeln, die nach der Übung zur Wortschatzkiste deiner Studierenden hinzugefügt werden'"
    @close="emit('close')"
  >
    <div class="w-full h-full flex-col flex">
      <div>
        <VocabListNew
          :vocab-list="vocabList"
          :allow-remove-vocab="true"
          :show-trash-as-remove-icon="true"
          :is-editing-full="true"
          :enable-scroll="false"
          @deleteItem="deleteVocabItem"
          @updateItem="updateVocabItem"
        />
      </div>

      <div class="p-4 pt-8 gap-x-6 w-full flex items-center justify-center">
        <ProgressButton
          @click="addVocabItem"
          text="Vokabel hinzufügen"
          :show-progress="isSubmitting"
          :disabled="isSubmitting || vocabListLoading"
          icon="add"
          iconSize="text-2xl"
        />
        <!-- <ProgressButton
          @click="generateMoreVocab"
          text="Fünf weitere generieren"
          :show-progress="vocabListLoading"
          :disabled="isSubmitting || vocabListLoading"
          icon="ai_sparkle"
          iconSize="text-2xl"
        /> -->
        <!-- TODO generate vocab from exercise as "context"-->
      </div>
    </div>
  </Modal>
</template>

<style scoped>
.progress-bar-inner {
  transition: width 0.3s;
}
</style>
