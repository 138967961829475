<script setup lang="ts">
const title = defineModel<string | null>('title');

const props = defineProps({
  iconComponent: {
    type: Object,
  },
  headline: {
    type: String,
  },
  isEditing: {
    type: Boolean,
  },
});

const emit = defineEmits(['change']);
</script>

<template>
  <div>
    <div v-if="title || props.headline" class="flex items-center gap-2 mb-4">
      <!-- icon: showing type of interaction -->
      <component v-if="props.iconComponent" :is="props.iconComponent" class="w-6 h-6" />
      <!-- headline (view-mode): tutor-defined title, fixed, component-specific headline as fallback -->
      <h1 class="text-[28px] font-semibold text-left" v-if="props.isEditing">{{ props.headline }}</h1>
      <h1 class="text-[28px] font-semibold text-left" v-else>{{ title || props.headline }}</h1>
    </div>

    <!-- title (edit-mode): editable title -->
    <div v-if="props.isEditing !== undefined">
      <h3 v-show="props.isEditing" class="text">
        <textarea
          v-if="props.isEditing"
          v-model="title"
          class="w-full py-0 mb-2 resize-none rounded-lg text-center"
          placeholder="Titel (optional)"
          rows="1"
          @change="emit('change')"
        />
      </h3>
    </div>
  </div>
</template>
