<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import ProfileImage from '@/components/ProfileImage.vue';
import { Check, Play, ArrowRight } from 'lucide-vue-next';
import CaseService from '@/services/CaseService';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
const { t } = useI18n();

const props = defineProps({
  caseInteraction: {
    type: Object,
    required: true,
  },
});

const router = useRouter();
const isLoadingCaseInteraction = ref(false);
const titlePerson = computed(() => {
  if (!!props.caseInteraction.case.patient) {
    return props.caseInteraction.case.patient;
  }
  return props.caseInteraction.case.third_persons[0];
});

function badges(caseObject) {
  return caseObject.tasks.map((task) => ({
    short: task.title.length > 12 ? task.title.substring(0, 12) + '...' : task.title,
    long: task.title,
  }));
}

async function navigateToCaseInteraction() {
  isLoadingCaseInteraction.value = true;
  await router.push(`/case-interactions/${props.caseInteraction.id}`);
  isLoadingCaseInteraction.value = false;
}
</script>

<template>
  <div
    @click="navigateToCaseInteraction"
    class="rounded-2xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col h-[240px]"
    :class="{
      'bg-[#F6F6F6] dark:bg-gray-800': !props.caseInteraction.solved_at,
      'bg-[#EFFDF1] border border-[#A8F5B3] dark:bg-green-900 dark:border-green-700': props.caseInteraction.solved_at,
    }"
  >
    <LoadingSpinnerFullScreen :show="isLoadingCaseInteraction" :blockClickIfShown="false" />

    <!-- Title section -->
    <div class="relative">
      <!-- Tasks badges above heading -->
      <div class="flex flex-wrap gap-1 mb-2 pr-16">
        <span
          v-for="badge in badges(props.caseInteraction.case)"
          class="px-2 py-1 bg-gray-900 text-white dark:bg-gray-800 rounded-full text-xs"
        >
          {{ badge.short }}
        </span>
      </div>

      <div class="flex flex-col pr-16">
        <h3 class="text-xl font-medium text-gray-900 dark:text-white break-words leading-tight line-clamp-2">
          {{ props.caseInteraction.case.details.heading }}
        </h3>
        <!-- Role under heading -->
        <p class="text-sm mt-1">
          <span class="text-gray-500">{{ $t('message.myRole') }}: </span>
          <span class="text-gray-900 dark:text-white">{{ props.caseInteraction.case.student_details?.role }}</span>
        </p>
      </div>

      <!-- Patient info at top right -->
      <div class="absolute top-0 right-0 flex flex-col items-end">
        <div class="relative">
          <!-- Third person image (if exists) -->
          <ProfileImage
            v-if="
              props.caseInteraction.case.third_persons?.[0] &&
              titlePerson !== props.caseInteraction.case.third_persons[0]
            "
            :image="props.caseInteraction.case.third_persons[0]?.profile_image?.image_urls.small || ''"
            :firstName="props.caseInteraction.case.third_persons[0]?.details.first_name"
            :lastName="props.caseInteraction.case.third_persons[0]?.details.last_name"
            class="w-12 h-12 absolute -right-3 -top-3"
          />
          <!-- Patient image -->
          <ProfileImage
            :image="titlePerson.profile_image?.image_urls.small || ''"
            :firstName="titlePerson.details.first_name"
            :lastName="titlePerson.details.last_name"
            class="w-12 h-12 relative z-10"
          />
        </div>

        <p class="text-sm font-medium text-gray-900 dark:text-white text-right">
          {{ titlePerson.details.first_name }}<br />
          {{ titlePerson.details.last_name }}
        </p>
        <p class="text-sm text-gray-400 text-right">
          {{ titlePerson.details.sex[0] }}, {{ titlePerson.details.age }} Jahre
        </p>
      </div>
    </div>

    <!-- Spacer -->
    <div class="flex-grow"></div>

    <!-- Next subtask section -->
    <div class="mb-4">
      <div class="flex items-start gap-1">
        <ArrowRight size="16" class="text-gray-600 dark:text-gray-400 flex-shrink-0 mt-0.5" />
        <p class="text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
          {{ props.caseInteraction.case.tasks[0]?.subtasks[0]?.details?.target || 'Start the case' }}
        </p>
      </div>
    </div>

    <!-- Resume Button / Solved Label -->
    <div class="flex items-center justify-between mt-auto">
      <div
        :class="{
          'inline-flex items-center gap-1 pl-3 pr-4 py-2 rounded-full font-semibold cursor-pointer bg-white text-gray-800 hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600':
            !props.caseInteraction.solved_at,
          'inline-flex items-center gap-1 text-[#19CB06] font-semibold': props.caseInteraction.solved_at,
        }"
      >
        <component
          :is="props.caseInteraction.solved_at ? Check : Play"
          :class="props.caseInteraction.solved_at ? 'text-[#19CB06]' : 'dark:text-white'"
          :size="props.caseInteraction.solved_at ? 20 : 16"
          :fill="props.caseInteraction.solved_at ? 'none' : 'currentColor'"
        />
        {{ props.caseInteraction.solved_at ? 'Gelöst' : $t('message.resume') }}
      </div>
      <div
        class="flex items-center gap-1 text-sm"
        :class="{
          'text-[#19CB06]': props.caseInteraction.solved_at,
          'text-gray-600 dark:text-gray-400': !props.caseInteraction.solved_at,
        }"
      >
        <span>
          +{{
            props.caseInteraction.solved_at
              ? CaseService.getXpRewardForCase(props.caseInteraction.case.id)
              : CaseService.getRemainingXpForCaseInteraction(props.caseInteraction.id)
          }}
          XP
        </span>
      </div>
    </div>
  </div>
</template>
