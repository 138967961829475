<script setup lang="ts">
import DarkModeToggle from '@/components/DarkModeToggle.vue';
import SocialIcon from '@/views/about-us/SocialIcon.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores';

const authStore = useAuthStore();
const { isSignedIn } = storeToRefs(authStore);
</script>

<template>
  <footer class="w-full bg-gray-100 dark:bg-neutral-700">
    <div class="max-w-[85rem] pt-24 pb-8 md:py-12 px-4 sm:px-6 lg:px-8 mx-auto">
      <!-- Grid -->
      <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
        <div class="col-span-full lg:col-span-1 lg:block">
          <a class="flex-none text-xl font-semibold dark:text-white" href="#" aria-label="Brand"
            >casuu<span class="text-blue-600">.health</span></a
          >
          <p class="mt-3 text-xs sm:text-sm text-gray-600 dark:text-gray-400">
            © 2024-25 casuu GmbH. casuu® ist eine eingetragene Marke. Alle Rechte vorbehalten.
          </p>
          <div class="mt-4 flex items-center gap-x-3">
            <SocialIcon brand="linkedin" to="https://www.linkedin.com/company/casuu" size="4" />
            <SocialIcon brand="x" to="https://x.com/casuucare" size="4" />
            <SocialIcon brand="email" to="mailto:hello@casuu.care" size="4" />
          </div>
        </div>
        <!-- End Col -->

        <div>
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Unternehmen</h4>

          <div class="mt-3 grid space-y-3 text-sm">
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/about-us"
                >Unser Team</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/values-and-purpose"
                >Unsere Werte</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/patient-advisory-board"
                >Unser Patient:innenbeirat</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/nurse-expert-board"
                >Unser Expert:innenbeirat</a
              >
            </p>
          </div>
        </div>

        <div>
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Support</h4>

          <div class="mt-3 grid space-y-3 text-sm">
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="mailto:hello@casuu.care"
                >Preise anfragen</a
              >
            </p>
            <!--            <p>-->
            <!--              <a-->
            <!--                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"-->
            <!--                href="/about-us"-->
            <!--                >FAQ</a-->
            <!--              >-->
            <!--            </p>-->
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="mailto:hello@casuu.care"
                >Hilfe holen</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="mailto:hello@casuu.care"
                >Kontakt aufnehmen</a
              >
            </p>
          </div>
        </div>
        <!-- End Col -->

        <div>
          <h4 class="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">
            Damit alles seine Richtigkeit hat
          </h4>
          <div class="mt-3 grid space-y-3 text-sm">
            <p v-if="isSignedIn">
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/attributions"
                >Attributions</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/privacy-and-terms"
                >AGB</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/privacy-and-terms"
                >Datenschutz</a
              >
            </p>
            <p>
              <a
                class="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                href="/impressum"
                >Impressum</a
              >
            </p>
          </div>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Grid -->
    </div>
  </footer>
</template>

<style scoped></style>
