<script setup>
import { ref, nextTick, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { useAlertStore, useAuthStore, useCaseInteractionStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { HSOverlay } from 'preline';
import { v4 as uuidv4 } from 'uuid';
import confetti from 'canvas-confetti';
import TaskInfoCard from '@/components/case_interaction/TaskInfoCard.vue';
import VocabListNew from '@/components/didactics/vocab/VocabListNew.vue';
import SuccessAnimation from '@/components/SuccessAnimation.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '../LoadingSpinnerLarge.vue';
import Modal from '@/components/various/Modal.vue';
const { t } = useI18n();

const props = defineProps({
  contentItemId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['closed']);

// Main cpt refs
const modal = ref(null);
const router = useRouter();
const show = ref(false);

// Refs for component data
const playSuccessAnimation = ref(false);
const canBeClosed = ref(false);
const authStore = useAuthStore();
const alertStore = useAlertStore();
const temporaryVocabList = ref(props.newVocabList);

const onClose = async () => {
  show.value = false;
  let usersActiveListId = authStore.user.vocab_lists[1].id;
  let errorWhenAdding = false;

  for (const vocab of temporaryVocabList.value.vocab_items) {
    await (await getApiClient()).vocabLists.copyVocabToPersonalVocabList(usersActiveListId, vocab.id).catch((error) => {
      alertStore.error('Failed to add vocab item to Wortschatzkiste', 'Error', error);
      errorWhenAdding = true;
    });
  }

  if (!errorWhenAdding) {
    let message = '';
    if (temporaryVocabList.value.vocab_items.length === 1) {
      message = 'Eine neue Vokabel wurde in deine Wortschatzkiste übernommen';
    } else {
      message =
        'Toll, ' +
        temporaryVocabList.value.vocab_items.length +
        ' neue Begriffe wurden in deine Wortschatzkiste übernommen!';
    }
    alertStore.success(message);
  }

  emit('closed');
};

// Lifecycle hooks
onMounted(async () => {
  try {
    const newVocabList = await (
      await getApiClient()
    ).courseSectionItems.getIntroducedVocabListOfContentItem(props.contentItemId);
    temporaryVocabList.value = newVocabList;
  } catch (error) {
    alertStore.error('Failed to get list of new vocab', 'Error', error);
    throw error;
  }
  await nextTick();

  show.value = true;
  await new Promise((resolve) => setTimeout(resolve, 500));
  playSuccessAnimation.value = true;

  setTimeout(() => {
    canBeClosed.value = true;
  }, 400);
});

const discardVocab = (vocabId) => {
  newVocabList.value.vocab_items = newVocabList.value.vocab_items.filter((vocab) => vocab.id !== vocabId);
};

const randomSymbol = computed(() => {
  const randomIndex = Math.floor(Math.random() * (successSymbols.length - 1)) + 1;
  return successSymbols[randomIndex];
});

const successSymbols = [
  { type: 'material', symbol: 'check' },
  { type: 'emoji', symbol: '🎉' },
  { type: 'emoji', symbol: '💫' },
  { type: 'emoji', symbol: '💪' },
  { type: 'emoji', symbol: '❤️' },
  { type: 'emoji', symbol: '⭐' },
];

defineExpose({
  show,
});
</script>

<template>
  <div>
    <Modal :show="show" :id="overlayId" class="w-full h-full" @close="onClose">
      <div class="w-full h-full overflow-y-auto pt-16 pb-24">
        <div class="w-full max-w-4xl mx-auto px-6">
          <h1 class="text-[40px] font-semibold text-center mb-8">
            {{ t('Du hast neue Vokabeln gelernt!') }} {{ randomSymbol.symbol }}
          </h1>

          <div class="px-4 py-2 text-gray-800">
            <h3 class="text-medium font-normal pb-2" v-if="temporaryVocabList">
              {{ t('message.addedVocab', { count: temporaryVocabList.vocab_items.length }) }}
            </h3>
            <div v-if="temporaryVocabList" class="flex justify-center min-w-full">
              <VocabListNew
                :vocabList="temporaryVocabList"
                :start-with-first-opened="false"
                :show-trash-as-remove-icon="true"
                :enable-scroll="false"
                @delete-item="discardVocab"
              />
            </div>
            <div v-else class="flex w-full justify-center py-6">
              <LoadingSpinnerLarge color="black" />
            </div>
          </div>
        </div>
      </div>

      <!-- Fixed Footer -->
      <div
        class="fixed bottom-0 left-0 right-0 py-6 px-6 gap-x-4 flex justify-end bg-white dark:bg-neutral-800 pointer-events-auto"
      >
        <button
          :disabled="!canBeClosed"
          @click="onClose"
          class="inline-flex items-center gap-2 px-4 py-3.5 text-base font-medium text-white bg-black rounded-full hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {{ t('Weiter') }}
        </button>
      </div>
    </Modal>
    <SuccessAnimation :show="playSuccessAnimation" @complete="playSuccessAnimation = false" />
  </div>
</template>

<style scoped>
@keyframes grow-pop {
  0% {
    font-size: 24px; /* Initial text size */
    opacity: 1;
  }
  50% {
    font-size: 30px; /* Pop out to larger size */
    opacity: 1;
  }
  100% {
    font-size: 0px; /* Shrink to disappear */
    opacity: 0;
  }
}

.animate-grow-pop {
  animation: grow-pop 300ms ease-out;
}
</style>
