<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { BookA, CircleCheck, Flame, Search } from 'lucide-vue-next';
import SimpleStatsCardNew from '@/components/dashboard/SimpleStatsCardNew.vue';
import { getApiClient } from '@/apiclient/client';

import { useI18n } from 'vue-i18n';
import { VocabTestStats } from '@/apiclient';
const { t } = useI18n();

const userVocabStats = ref<VocabTestStats | null>(null);
const showSearchbar = ref(false);
const emit = defineEmits(['showSearchbar']);

const fetchStats = async () => {
  userVocabStats.value = await (await getApiClient()).users.getCurrentUserVocabTestStats();
  console.log('userVocabStats:', JSON.stringify(userVocabStats.value));
};

const props = defineProps({
  numVocabsInList: {
    type: Number,
    required: true,
  },
});

onMounted(async () => {
  await fetchStats();
});

defineExpose({
  refetchStats: async () => {
    await fetchStats();
  },
});

watch(showSearchbar, (newValue: boolean) => {
  emit('showSearchbar', newValue);
});
</script>

<template>
  <div class="flex gap-x-2 justify-between">
    <div class="flex gap-x-2">
      <SimpleStatsCardNew
        :icon="BookA"
        :title="$t('message.words', props.numVocabsInList)"
        :value="props.numVocabsInList"
      />
      <SimpleStatsCardNew
        :icon="Flame"
        :title="$t('message.dayStreak', userVocabStats?.current_streak_days ?? 0)"
        :value="null"
        :class="{
          'text-[#19CB06] glow-effect': (userVocabStats?.current_streak_days ?? 0) > 0,
          'animate-pulse': (userVocabStats?.current_streak_days ?? 0) > 0,
        }"
      />
      <!-- <SimpleStatsCardNew
        :icon="CircleCheck"
        :title="$t('message.tests', userVocabStats?.num_tests_last_week ?? 0)"
        :value="userVocabStats?.num_tests_last_week ?? 0 /* TODO: total instead of only last week? */"
      /> -->
    </div>
    <div
      class="flex flex-row items-center border text-sm rounded-lg px-3 py-2 gap-x-1"
      @click="showSearchbar = !showSearchbar"
      :class="showSearchbar ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'"
    >
      <Search :size="20" />
    </div>
  </div>
</template>

<style scoped>
.glow-effect {
  filter: drop-shadow(0 0 0.5rem #19cb06);
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
}

.animate-pulse {
  animation: pulse 2s ease-in-out infinite;
}
</style>
