<script lang="ts" setup>
import { computed, PropType, ref, watch, nextTick } from 'vue';
import { Check, Search, Trash, X, ListChecks, Plus, Pencil, PencilOff, Sparkles } from 'lucide-vue-next';
import { UserVocabListDetails, VocabItem } from '@/apiclient';
import AudioPlayerIcon from '@/components/various/AudioPlayerIcon.vue';
import { useAlertStore } from '@/stores';
import { updateMinTextareaHeight } from '@/helper';
import EditGrammar from './EditGrammar.vue';
import EditUsageExamples from './EditUsageExamples.vue';
import EditVocabIllustrationImage from './EditVocabIllustrationImage.vue';
import { displayGrammaticalCategory, isVerbType } from './helper';
import { getApiClient } from '@/apiclient/client';
import { cloneDeep, debounce } from 'lodash';
import { useAutosave } from '@/composables/useAutosave';
import SlideCard from '@/components/various/SlideCard.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// props
const props = defineProps({
  vocabList: { type: Object as PropType<UserVocabListDetails>, required: true },
  enableSeenCheck: { type: Boolean, default: false },
  allSeenBefore: { type: Boolean, default: false },
  showSearchbar: { type: Boolean, default: false },
  reserveSpaceForButton: { type: Boolean, default: false },
  withBottomNav: { type: Boolean, default: false },
  allowRemoveVocab: { type: Boolean, default: true },
  allowEditFull: { type: Boolean, default: false },
  showTrashAsRemoveIcon: { type: Boolean, default: false },
  isEditingFull: { type: Boolean, default: false },
  isEditingRemarks: { type: Boolean, default: false },
  enableScroll: { type: Boolean, default: true },
  startWithFirstOpened: { type: Boolean, default: true },
  showAddVocabButton: { type: Boolean, default: false },
  hideTitle: { type: Boolean, default: false },
});

// emits
const emit = defineEmits(['allVocabsSeen', 'deleteItem', 'updateItem', 'scrollItemIntoView', 'addVocabItem']);

// composables
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    console.log('saveChanges', editingItem.value);
    if (!editingItem.value) return;
    emit('updateItem', editingItem.value?.id, editingItem.value);
  },
  onError: (error) => {
    alertStore.error('Failed to autosave vocab item ' + editingItem.value?.term, 'Error', error);
  },
});

// refs
const explanationTextareas = ref<Map<string, HTMLTextAreaElement>>(new Map());
const remarksTextareas = ref<Map<string, HTMLTextAreaElement>>(new Map());

// state
const openVocabId = ref('');
const seenVocabIds = ref(new Set<string>());
const searchTerm = ref('');
const alertStore = useAlertStore();
const isGeneratingUsageExampleAudio = ref(new Map<string, boolean>());
const isGeneratingItemAudio = ref(new Map<string, boolean>());
const isGeneratingAutocomplete = ref(new Map<string, boolean>());
const showRemarksInput = ref(false);
const editingItem = ref<VocabItem | null>(null);
const isDraggingId = ref('');
const imageUploadInProgress = ref(false);
const temporarilyEditFull = ref(false);
const isAutofillDiscarded = ref(false);

// refs
const scrollContainer = ref<HTMLElement | null>(null);
const searchInput = ref<HTMLInputElement | null>(null);

// computed
const filteredVocabItems = computed(() => {
  if (!searchTerm.value) {
    return props.vocabList.vocab_items;
  }

  return props.vocabList.vocab_items.filter((item) => item.term.toLowerCase().includes(searchTerm.value.toLowerCase()));
});

const allVocabsSeen = computed(() => {
  return seenVocabIds.value.size === props.vocabList.vocab_items.length;
});

const isEditingFull = computed(() => {
  return temporarilyEditFull.value || props.isEditingFull;
});

const toggleVocab = (item: VocabItem) => {
  if (openVocabId.value === item.id) {
    closeVocab();
  } else {
    openVocab(item);
  }
};

const openVocabItemById = async (id: string, setEditing: boolean = false) => {
  let vocab = props.vocabList.vocab_items.find((item) => item.id === id);
  if (!vocab) return;
  openVocab(vocab, setEditing);
};

const closeVocab = () => {
  openVocabId.value = '';
  temporarilyEditFull.value = false;
};

const openVocab = async (item: VocabItem, setEditing: boolean = false) => {
  if (openVocabId.value === item.id) {
    return;
  }

  openVocabId.value = item.id;
  showRemarksInput.value = false;

  if (props.enableSeenCheck) {
    seenVocabIds.value.add(item.id);

    if (allVocabsSeen.value) {
      emit('allVocabsSeen');
    }
  }

  if (props.isEditingFull || props.isEditingRemarks) {
    await saveChanges();
    const fullItem = props.vocabList.vocab_items.find((i) => i.id === item.id);
    if (fullItem) {
      await startEditing(fullItem);
    }
  }

  if (setEditing) {
    temporarilyEditFull.value = true;
  } else {
    temporarilyEditFull.value = false;
  }
};

const generateExampleAudio = async (item: VocabItem, index: number) => {
  console.log('generateExampleAudio', item, index);
  alertStore.info(t('status.info.generatingAudio'));
  let key = `${item.id}-${index}`;
  isGeneratingUsageExampleAudio.value.set(key, true);

  await (
    await getApiClient()
  ).vocabs
    .generateUsageExampleAudio(item.id, index)
    .then((url) => {
      console.log('generated usage example audio under url: ', url);
    })
    .finally(() => {
      isGeneratingUsageExampleAudio.value.set(key, false);
    });
};

const isGeneratingAudioForExample = (item: VocabItem, index: number) => {
  let key = `${item.id}-${index}`;
  return isGeneratingUsageExampleAudio.value.get(key) || false;
};

const generateItemAudio = async (item: VocabItem) => {
  console.log('generateItemAudio', item);
  alertStore.info(t('status.info.generatingAudio'));
  let key = `${item.id}`;
  isGeneratingItemAudio.value.set(key, true);

  await (
    await getApiClient()
  ).vocabs
    .generateItemAudio(item.id, item.audio_url ?? null)
    .then((url) => {
      console.log('generated item audio under url: ', url);
      item.audio_url = url;
    })
    .finally(() => {
      isGeneratingItemAudio.value.set(key, false);
    });
};

const displayPlural = (item: VocabItem) => {
  if (item.plural_noun) {
    return `[${item.plural}, nur Plural]`;
  }
  if (item.uncountable_noun) {
    return `[nur Singular]`;
  } else {
    return `[Pl. ${item.plural}]`;
  }
};

const displayGrammarDetails = (item: VocabItem) => {
  let info = '';

  if (item.grammatical_category === 'NOUN' && item.specific_article) {
    info += displayPlural(item);
  }
  if (isVerbType(item) && (item.simple_past_tense || item.present_perfect_tense)) {
    if (item.irregular_verb && item.present_tense) {
      info += ` [${item.present_tense || ''} | ${item.simple_past_tense || ''} | ${item.present_perfect_tense || ''}]`;
    } else {
      info += ` [${item.simple_past_tense || ''} | ${item.present_perfect_tense || ''}]`;
    }
  }
  return info;
};

const displayGrammarBase = (item: VocabItem) => {
  let info = '';
  if (item.grammatical_category) {
    info += displayGrammaticalCategory(item.grammatical_category);
  }
  if (item.grammatical_category === 'NOUN') {
    if (item.specific_article === 'der') {
      info += ', m';
    }
    if (item.specific_article === 'die') {
      info += ', f';
    }
    if (item.specific_article === 'das') {
      info += ', n';
    }
  }
  return info;
};

const autofillVocabItem = async (item: VocabItem) => {
  console.log('autofillVocabItem', item);
  isGeneratingAutocomplete.value.set(item.id, true);
  let suggestion = await (await getApiClient()).vocabs.suggestAutocompletion(item.term);
  editingItem.value = { ...item, ...suggestion };
  unsavedChanges.value = true;
  isGeneratingAutocomplete.value.set(item.id, false);
};

const startEditing = async (item: VocabItem) => {
  if (!editingItem.value) {
    // immediately save changes if we are starting to edit a new item
    await saveChanges();
  }

  editingItem.value = cloneDeep(item);
  isAutofillDiscarded.value = false;
  setTimeout(() => {
    const explanationTextarea = explanationTextareas.value.get(item.id);
    if (explanationTextarea) {
      updateMinTextareaHeight(explanationTextarea);
    }
  }, 0);
};

const uploadImage = async (item: VocabItem, event: Event) => {
  console.log('editingItem', editingItem.value);
  if (!editingItem.value) return;
  imageUploadInProgress.value = true;
  console.log('uploadImage', item);

  const image = event.target.files || event.dataTransfer.files;

  if (!image || image.length === 0) {
    imageUploadInProgress.value = false;
    return;
  }
  if (image.length > 1) {
    alertStore.error('Please select only one image');
    imageUploadInProgress.value = false;
    return;
  }

  let returnUrl = await (await getApiClient()).vocabs.uploadVocabIllustration(item.id, { image: image[0] });
  editingItem.value.image_url = returnUrl;
  unsavedChanges.value = true;
  imageUploadInProgress.value = false;
  // Reset the input value after successful upload
  if (event.target instanceof HTMLInputElement) {
    event.target.value = '';
  }
};

const removeImage = async (item: VocabItem) => {
  if (!editingItem.value) return;
  imageUploadInProgress.value = true;
  editingItem.value.image_url = ''; // mind the gotcha: set to empty, not null (null means do not update!)

  await (await getApiClient()).vocabs.deleteVocabIllustration(item.id);
  unsavedChanges.value = true;
  imageUploadInProgress.value = false;
};

const handleDrop = (item: VocabItem, event: DragEvent) => {
  event.preventDefault();
  isDraggingId.value = '';
  uploadImage(item, event);
};

const canBeFilledAutomatically = (item: VocabItem) => {
  const hasNoContent =
    !item.explanation &&
    !item.grammatical_category &&
    !item.image_url &&
    !item.plural &&
    !item.plural_noun &&
    !item.present_perfect_tense &&
    !item.present_tense &&
    !item.remarks &&
    !item.simple_past_tense &&
    !item.specific_article &&
    !item.uncountable_noun &&
    (!item.usage_examples || item.usage_examples.every((example) => !example)) &&
    (!item.usage_example_audio_urls || item.usage_example_audio_urls.every((url) => !url));

  return hasNoContent && !isAutofillDiscarded.value;
};

const handleDragOver = (event: DragEvent, itemId: string) => {
  if (!props.isEditingFull || editingItem.value?.id !== itemId) return;
  console.log('handleDragOver', itemId);
  event.preventDefault();
  isDraggingId.value = itemId;
};

const handleDragLeave = (event: DragEvent) => {
  isDraggingId.value = '';
};

const debounceHandleDragOver = debounce(handleDragOver, 1);
const debounceHandleDragLeave = debounce(handleDragLeave, 1);

const isLargeScreen = computed(() => {
  return window.innerWidth > 768;
});

// update openVocabId when the list changes
watch(
  () => props.vocabList,
  (newList, oldList) => {
    if (!newList?.id) return;
    let listChanged = newList.id !== oldList?.id;
    let newItems = newList.vocab_items;

    if (listChanged && !props.isEditingFull && props.startWithFirstOpened && newItems.length > 0) {
      if (!newItems[0]?.id) return;
      openVocabId.value = newItems[0].id;
      seenVocabIds.value = new Set([newItems[0].id]);
    }
  },
  { immediate: true },
);

watch(
  () => temporarilyEditFull.value,
  async (newVal) => {
    if (!newVal) {
      editingItem.value = null;
      await saveChanges();
    } else {
      let item = props.vocabList.vocab_items.find((item) => item.id === openVocabId.value);
      if (!item) return;
      await startEditing(item);
    }
  },
);

const scrollIntoView = (id: string) => {
  /*
   * scroll the item into view
   * manages to scroll this component or its parent, whichever is scrollable
   * if parent is scrollable, use additional max 50 px offset from the top
   * no scroll for last element
   */
  const element = document.getElementById(`vocab-${id}`);
  if (!element || !scrollContainer.value) return;

  let index = props.vocabList.vocab_items.findIndex((item) => item.id === id);
  if (index === props.vocabList.vocab_items.length - 1) return;

  if (!props.enableScroll) {
    // Scroll the parent container

    let itemHeight = element.clientHeight;
    let windowHeight = window.innerHeight;
    let offset = Math.max(50, itemHeight - windowHeight);

    // Create a temporary invisible element above our target
    const tempElement = document.createElement('div');
    tempElement.style.height = `${itemHeight}px`;
    tempElement.style.marginTop = `-${offset}px`;
    tempElement.style.position = 'absolute';
    tempElement.style.width = '1px';
    tempElement.style.pointerEvents = 'none';

    // Insert before our target element
    element.parentNode.insertBefore(tempElement, element);

    // Scroll to the temporary element
    tempElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    // Remove the temporary element after scrolling completes
    setTimeout(() => {
      tempElement.remove();
    }, 1000); // Remove after scroll animation completes
  } else {
    // Scroll this container
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

watch(openVocabId, async (newId) => {
  if (!newId) return;

  // Wait for slide animation to complete
  setTimeout(() => {
    scrollIntoView(newId);
  }, 600);
});

watch(
  // verb counts as irregular if present tense is set
  () => editingItem.value?.present_tense,
  (newPresentTense) => {
    if (newPresentTense === undefined) return; // editing item set to null
    let isPresentTenseSet = newPresentTense != null;
    editingItem.value!.irregular_verb = isPresentTenseSet;
  },
);

// Watch for changes to showSearchbar prop
watch(
  () => props.showSearchbar,
  (newValue) => {
    if (newValue) {
      // Focus the search input when showSearchbar becomes true
      nextTick(() => {
        if (searchInput.value) {
          searchInput.value.focus();
        }
      });
    }
  },
  { immediate: true },
);

defineExpose({
  openVocabItemById,
});
</script>

<template>
  <div class="flex flex-col min-h-0">
    <!-- Add headline when enableSeenCheck is true -->
    <div v-if="enableSeenCheck && !hideTitle" class="flex items-center px-2 gap-2 mb-4">
      <ListChecks class="w-6 h-6" />
      <h1 class="text-[28px] font-semibold text-left">Vokabelliste</h1>
    </div>

    <div
      class="flex overflow-hidden items-center gap-x-2 border-none bg-white rounded-full px-4 transition-height duration-300"
      :class="{
        'min-h-[48px] h-[48px] mt-0 mb-4 mx-0': showSearchbar,
        'min-h-0 h-0 mb-0 my-0 mx-12': !showSearchbar,
      }"
    >
      <Search :size="20" />
      <input
        ref="searchInput"
        type="text"
        v-model="searchTerm"
        placeholder="Search"
        class="w-full h-12 focus:outline-none focus:ring-0 border-none"
      />
    </div>

    <div
      ref="scrollContainer"
      class="flex flex-col overflow-y-auto gap-y-2"
      :class="{
        'pb-40': reserveSpaceForButton && withBottomNav,
        'pb-24': reserveSpaceForButton && !withBottomNav,
        'pb-20': !reserveSpaceForButton && withBottomNav,
        'pb-4': !reserveSpaceForButton && !withBottomNav,
        'overflow-y-auto': enableScroll,
        'overflow-y-visible': !enableScroll,
      }"
    >
      <div v-if="showAddVocabButton" class="flex justify-center items-center">
        <button
          @click="emit('addVocabItem')"
          class="flex text-black px-4 py-3 my-2 rounded-full text-base items-center font-semibold gap-x-2 pointer-events-auto cursor-pointer bg-white"
        >
          <Plus :size="20" />
          {{ t('button.addVocab') }}
        </button>
      </div>

      <transition-group name="pop-and-fade">
        <div v-for="(item, index) in filteredVocabItems" :key="item.id">
          <SlideCard
            :id="`vocab-${item.id}`"
            :is-open="openVocabId === item.id"
            :bg-color="
              allSeenBefore ||
              isDraggingId === item.id ||
              (enableSeenCheck && seenVocabIds.has(item.id) && openVocabId !== item.id)
                ? 'bg-gray-bg'
                : 'bg-white'
            "
            :is-clickable="openVocabId !== item.id"
            @toggle="toggleVocab(item)"
            @drop.prevent="(event) => handleDrop(item, event)"
            @dragover.prevent="(event) => debounceHandleDragOver(event, item.id)"
            @dragleave.prevent="debounceHandleDragLeave"
          >
            <!-- card header -->
            <template #header>
              <AudioPlayerIcon
                :audio-url="item.audio_url ?? null"
                @missingAudio="generateItemAudio(item)"
                :is-generating="isGeneratingItemAudio.get(item.id) ?? false"
              />

              <!-- <AudioPlayerIcon :audio-url="item.audio_url ?? null" @missingAudio="generateItemAudio(item)" /> -->

              <div
                v-if="isEditingFull && editingItem && editingItem.id === item.id && openVocabId === item.id"
                class="flex-1 mr-20"
                @click.stop="
                  (event) => {
                    if (isEditingFull) {
                      event.stopPropagation();
                    }
                  }
                "
              >
                <input
                  v-model="editingItem.term"
                  class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors text-lg font-semibold"
                  :placeholder="t('placeholder.term')"
                  @change="unsavedChanges = true"
                />
              </div>
              <div v-else class="text-lg font-semibold select-none">
                <span v-if="item.grammatical_category === 'NOUN' && !item.plural_noun" class=""
                  >{{ item.specific_article }} {{ item.term }}</span
                >
                <span v-else-if="item.grammatical_category === 'NOUN' && item.plural_noun" class="">{{
                  item.plural
                }}</span>
                <span v-else>{{ item.term }}</span>
              </div>

              <div v-if="(enableSeenCheck && seenVocabIds.has(item.id)) || allSeenBefore" class="ml-auto">
                <Check :size="20" />
              </div>
              <div
                v-if="openVocabId === item.id"
                class="ml-auto opacity-50 hover:opacity-100 cursor-pointer inline-flex items-center gap-x-4"
              >
                <div v-if="allowEditFull">
                  <Pencil :size="20" v-if="!temporarilyEditFull" @click="temporarilyEditFull = true" />
                  <PencilOff :size="20" v-else @click="temporarilyEditFull = false" />
                </div>
                <div v-if="allowRemoveVocab">
                  <Trash :size="20" v-if="props.showTrashAsRemoveIcon" @click="emit('deleteItem', item.id)" />
                  <X :size="20" v-else @click="emit('deleteItem', item.id)" />
                </div>
              </div>
            </template>
            <!-- end of card header -->

            <!-- Card body -->
            <template #content>
              <div v-if="!canBeFilledAutomatically(item)" class="flex flex-col gap-y-3">
                <!-- Image -->
                <div v-if="item.image_url && !isEditingFull">
                  <img :src="item.image_url" alt="Vocab image" class="w-full h-auto rounded-xl" />
                  <!-- <img src="https://vocabimage-prd.data.casuu.health/161-Verleihung-IDEA-2024-260.jpg" :alt="`Image illustration of ${item.term}`" class="w-full h-auto rounded-xl" /> -->
                </div>
                <div v-else-if="isEditingFull" class="flex flex-col gap-y-2">
                  <img
                    :src="editingItem?.image_url"
                    v-if="editingItem?.image_url"
                    alt="Vocab image"
                    class="w-full h-auto rounded-xl"
                  />
                  <EditVocabIllustrationImage
                    :upload-image="uploadImage"
                    :remove-image="removeImage"
                    :item="editingItem"
                  />
                </div>
                <!-- End image -->

                <!-- Grammar section -->
                <div v-if="isEditingFull && editingItem && editingItem.id === item.id">
                  <EditGrammar :editingItem="editingItem" @unsavedChanges="unsavedChanges = true" />
                </div>
                <div v-else-if="item.grammatical_category" class="flex flex-wrap gap-x-1 font-medium">
                  <span>{{ displayGrammarBase(item) }}</span>
                  <span>{{ displayGrammarDetails(item) }}</span>
                </div>
                <!-- end grammar section -->

                <!-- Explanation -->
                <div v-if="isEditingFull && editingItem && editingItem.id === item.id">
                  <textarea
                    :ref="
                      (el) => {
                        if (el) explanationTextareas.set(item.id, el as HTMLTextAreaElement);
                        else explanationTextareas.delete(item.id);
                      }
                    "
                    v-model="editingItem.explanation"
                    class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
                    :placeholder="t('placeholder.explanation')"
                    rows="2"
                    @input="
                      (e: Event) => {
                        const target = e.target as HTMLInputElement;
                        updateMinTextareaHeight(target);
                      }
                    "
                    @change="unsavedChanges = true"
                  ></textarea>
                </div>
                <div v-else>
                  {{ item.explanation }}
                </div>
                <!-- End explanation -->

                <!-- Usage examples -->
                <div class="flex flex-col gap-y-1">
                  <div
                    class="font-medium ml-12"
                    v-if="
                      item.usage_examples?.filter((example) => example !== '').length > 0 ||
                      (isEditingFull && editingItem?.usage_examples?.length > 0)
                    "
                  >
                    {{
                      $t('message.exampleSentences', {
                        count: item.usage_examples?.filter((example) => example !== '').length,
                      })
                    }}
                  </div>
                  <div v-else-if="isEditingFull" class="flex flex-col w-full gap-y-2 relative">
                    <div
                      class="absolute top-0 pointer-events-none left-0 w-full h-full flex items-center justify-center"
                    >
                      <span
                        class="mx-auto my-auto animate-spin inline-block w-12 h-12 border-[2px] border-black border-current border-t-transparent text-black rounded-full transition-opacity duration-300"
                        :class="{ 'opacity-100': imageUploadInProgress, 'opacity-0': !imageUploadInProgress }"
                      />
                    </div>
                  </div>
                  <!-- End image -->

                  <div
                    v-if="isEditingFull && editingItem && editingItem.id === item.id"
                    class="flex flex-col gap-y-2 ml-9"
                  >
                    <EditUsageExamples :editingItem="editingItem" @unsavedChanges="unsavedChanges = true" />
                  </div>
                  <div
                    v-else
                    class="inline-flex items-center gap-x-3 gap-y-1 relative"
                    v-for="(example, index) in item.usage_examples"
                    :key="example"
                  >
                    <div class="absolute left-1.5" v-if="example">
                      <AudioPlayerIcon
                        in-circle
                        size="small"
                        :audio-url="item.usage_example_audio_urls?.[index] ?? null"
                        :is-generating="isGeneratingAudioForExample(item, index)"
                        @missingAudio="generateExampleAudio(item, index)"
                      />
                    </div>
                    <div class="ml-12">
                      {{ example }}
                    </div>
                  </div>
                </div>
                <!-- End of usage examples -->

                <!-- remarks -->
                <div class="flex flex-col gap-y-1">
                  <div v-if="item.remarks || showRemarksInput" class="ml-12 font-medium">
                    {{ $t('message.columnRemarks') }}
                  </div>
                  <div
                    v-if="
                      (isEditingFull || isEditingRemarks) &&
                      (showRemarksInput || item.remarks) &&
                      editingItem &&
                      editingItem.id === item.id
                    "
                    class="flex gap-x-2 items-center"
                  >
                    <textarea
                      :ref="
                        (el) => {
                          if (el) remarksTextareas.set(item.id, el as HTMLTextAreaElement);
                          else remarksTextareas.delete(item.id);
                        }
                      "
                      v-model="editingItem.remarks"
                      class="w-full ml-9 px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors resize-none"
                      :placeholder="t('placeholder.remarks')"
                      rows="1"
                      @input="
                        (e: Event) => {
                          const target = e.target as HTMLInputElement;
                          updateMinTextareaHeight(target);
                        }
                      "
                      @change="unsavedChanges = true"
                    />
                    <button @click="showRemarksInput = false" class="text-red-500">
                      <X :size="16" />
                    </button>
                  </div>
                  <div v-else class="ml-12">
                    {{ item.remarks }}
                  </div>
                  <button
                    v-if="!showRemarksInput && (isEditingFull || isEditingRemarks)"
                    @click="showRemarksInput = true"
                    class="text-blue-500 self-start mt-2 ml-9"
                  >
                    + {{ t('button.addRemark') }}
                  </button>
                </div>
                <!-- End of remarks -->
              </div>
              <div
                v-else-if="editingItem && editingItem.id === item.id && isEditingFull"
                class="px-4 py-2 inline-flex w-full justify-between font-normal text-gray-500 transition-all duration-300"
              >
                <button
                  @click="autofillVocabItem(editingItem)"
                  :disabled="editingItem.term === '' || (isGeneratingAutocomplete.get(item.id) ?? false)"
                  class="disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span class="flex-col flex items-center max-w-[100px]">
                    <Sparkles :size="28" />
                    Let your companion fill the details
                    <span
                      v-if="isGeneratingAutocomplete.get(item.id) ?? false"
                      class="animate-spin inline-block w-8 h-8 border-[2px] border-black border-current border-t-transparent text-black rounded-full transition-opacity duration-300"
                    />
                  </span>
                </button>
                <button @click="isAutofillDiscarded = true">
                  <span class="flex-col flex items-center max-w-[100px]">
                    <Pencil :size="28" />
                    Manually fill in
                  </span>
                </button>
              </div>
            </template>
            <!-- End of card body -->
          </SlideCard>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style scoped>
.pop-and-fade-leave-active {
  animation: pop-out 500ms ease-in forwards;
  position: relative;
  will-change: opacity;
  background-color: rgb(243 244 246); /* gray-100 */
  transform-origin: center;
}

.pop-and-fade-enter-active,
.pop-and-fade-enter-from {
  animation: pop-in 500ms ease-in forwards;
  will-change: opacity;
  background-color: rgb(243 244 246); /* gray-100 */
  transform-origin: center;
}

@keyframes pop-out {
  0% {
    opacity: 1;
    background-color: rgb(243 244 246);
  }
  50% {
    opacity: 0.8;
    background-color: rgb(243 244 246);
  }
  62.5% {
    opacity: 0.5;
    background-color: rgb(243 244 246);
  }
  100% {
    opacity: 0;
    background-color: rgb(243 244 246);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0.1;
    background-color: rgb(243 244 246);
  }
  100% {
    opacity: 1;
    background-color: rgb(243 244 246);
  }
}
</style>
