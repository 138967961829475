<script setup lang="ts">
import { Sparkles } from 'lucide-vue-next';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  inFooterStyle: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clicked']);
</script>

<template>
  <button
    type="button"
    @click="emit('clicked')"
    :disabled="disabled"
    class="text-black disabled:opacity-50 disabled:pointer-events-none inline-flex items-center text-[14px]"
    :class="{
      'py-1.5 px-3 gap-x-2 rounded-full border border-black': !inFooterStyle,
      'inline-flex items-center gap-x-1.5': inFooterStyle,
    }"
  >
    <Sparkles class="w-4 h-4" />
    {{ text }}
  </button>
</template>

<style scoped></style>
