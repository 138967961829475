<script>
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';

import ClozeInputSpan from './ClozeInputExtension.js';
import McClozeInputSpan from './McClozeInputExtension.js';
import { nextTick } from 'vue';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import _ from 'lodash';

export default {
  components: {
    EditorContent,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    allowFormatting: {
      type: Boolean,
      default: true,
    },
    allowList: {
      type: Boolean,
      default: false,
    },
    showFormatButtonText: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '100%',
    },
  },

  data() {
    return {
      editor: null,
      unsavedChangesSent: false,
      originalHtml: '',
    };
  },

  watch: {
    allowEdit: {
      immediate: true,
      async handler(newVal) {
        if (this.editor) {
          await nextTick();
          this.editor.setOptions({ editable: newVal });
        }
      },
    },
  },

  mounted() {
    this.editor = new Editor({
      autofocus: false,
      editable: this.allowEdit,
      extensions: [
        StarterKit,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        // OrderedList,
        Underline,
        TextStyle,
        Color,
        ClozeInputSpan,
        McClozeInputSpan,
        Placeholder.configure({
          placeholder:
            'Hier kannst du einen Text mit Formatierungen etc. erstellen - und ' +
            'beliebige Teile in Lücken umwandeln: Markiere dazu ein Wort, klicke auf das ' +
            'Quiz-Symbol ("Lücke") und gib im sich öffnenden Fenster optional einen Hinweis ein.',
        }),
      ],
      content: this.content,
      // content: `
      //   <div>
      //             This is still the text editor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //     This is still the text edito but enriched with node views.
      //     This is still the text ed with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //             Thor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //     This is still with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //   <cloze-input-span solutionText="Lösung" hintText="Hinweis 2"  >
      //   </cloze-input-span>
      //     Did you see that? That's a Vue component. We are really living in the future.
      //               This is still the text editor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //     This is still the text editor you're used to, but enriched with node views.
      //   </div>
      // `,
    });

    let initialIntervalPassed = false;
    setTimeout(() => {
      initialIntervalPassed = true;
    }, 500);

    this.editor.on(
      'update',
      _.debounce(() => this.handleInputUpate(), 500),
    );

    this.editor.on(
      // this is pretty aggressive as we treat input events as change events when coming from the ClozeInput
      'clozeInput',
      _.debounce(() => this.handleInputUpate(), 500),
    );

    this.editor.on(
      'focus',
      _.debounce(() => {
        console.log('editor focused');
        // TODO: show menu
      }, 500),
    );

    this.editor.on(
      'blur',
      _.debounce(() => {
        console.log('editor blurred');
        this.$emit('blur');
        // TODO: hide menu
      }, 500),
    );

    const forwardNodeSolved = ({ id, isSolved }) => {
      if (this.allowEdit) return;
      this.$emit('nodeSolved', {
        id,
        isSolved,
      });
    };

    this.editor.on('nodeSolved', initialIntervalPassed ? _.debounce(forwardNodeSolved, 500) : forwardNodeSolved);

    const forwardNodeAttempted = (uuid, isAttempted) => {
      if (this.allowEdit) return;
      this.$emit('nodeAttempted', uuid, isAttempted);
    };

    this.editor.on(
      'nodeAttempted',
      initialIntervalPassed ? _.debounce(forwardNodeAttempted, 500) : forwardNodeAttempted,
    );

    const handleNodeMaximized = ({ id, isMaximized }) => {
      console.log('nodeMaximized', id, isMaximized);
      if (this.allowEdit) return;

      // When a node is maximized, minimize all others
      if (isMaximized) {
        this.minimizeAllExcept(id);
      }
    };

    this.editor.on('nodeMaximized', handleNodeMaximized);
  },

  beforeUnmount() {
    this.editor.destroy();
  },

  methods: {
    getHtmlContent() {
      return this.editor.getHTML();
    },
    getRawTextContent() {
      return this.editor.getText();
    },
    getJsonContent() {
      return this.editor.getJSON();
    },
    resetEmitState() {
      this.unsavedChangesSent = false;
    },
    focus() {
      this.editor.chain().focus().run();
    },
    resetAllAttempts() {
      this.editor.commands.resetAllAttempts();
      this.editor.commands.resetAllMcSelections();
    },
    handleInputUpate() {
      let newText = this.editor.getHTML();
      if (newText !== this.originalHtml) {
        this.$emit('unsavedChanges', newText);
        this.unsavedChangesSent = true;
      } else if (this.unsavedChangesSent && newText === this.originalHtml) {
        this.$emit('changesCleared');
        this.unsavedChangesSent = false;
      }
    },
    minimizeAllExcept(exceptId) {
      // Command to minimize all other cloze inputs
      console.log('minimizeAllExcept', exceptId);
      this.editor.commands.minimizeAllClozeInputsExcept(exceptId);
      this.editor.commands.minimizeAllMcClozeInputsExcept(exceptId);
    },
  },
};
</script>

<template>
  <!--  {{ editor.getHTML()  }}-->

  <div
    v-if="editor"
    class="group overflow-visible dark:border-neutral-700 py-[1px] rounded-md"
    :style="{ width: width }"
  >
    <div
      v-show="allowEdit && allowFormatting"
      class="flex align-middle items-center gap-x-2 border-b border-gray-200 p-2 dark:border-neutral-700"
      :class="{ 'justify-between': showFormatButtonText }"
    >
      <button
        @click="editor.commands.toggleClozeInputSpan()"
        class="size-8 text-blue-600 hover:text-blue-700 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-4xl"> quiz </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Lücke</div>
      </button>
      <button
        @click="editor.commands.toggleMcClozeInputSpan()"
        class="size-8 text-blue-600 hover:text-blue-700 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-4xl"> quiz </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium whitespace-nowrap">MC-Lücke</div>
      </button>
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> format_bold </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Fett</div>
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> format_italic </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Kursiv</div>
      </button>
      <button
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> format_underlined </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium whitespace-nowrap">Unter- /</div>
      </button>
      <button
        @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> format_strikethrough </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Durchstreichen</div>
      </button>
      <button
        v-show="allowList"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> format_list_bulleted </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Liste</div>
      </button>
      <button
        v-show="allowList"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> format_list_numbered </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Nummeriert</div>
      </button>
      <button
        v-show="allowEdit"
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> undo </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Rückgängig</div>
      </button>
      <button
        v-show="allowEdit"
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
        class="size-8 flex-col flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        <span translate="no" class="material-symbols-outlined no-translate -mb-2 text-2xl"> redo </span>
        <div v-show="showFormatButtonText" class="text-xs font-medium">Wiederholen</div>
      </button>
    </div>
    <div class="min-h-32">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<style scoped>
.tiptap :first-child {
  @apply mt-0;
}

.tiptap ul,
.tiptap ol {
  @apply pl-4 pr-4 my-5 ml-2.5;
}

.tiptap li p {
  @apply mt-1 mb-1;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  @apply leading-tight;
}

.tiptap h1,
.tiptap h2 {
  @apply mt-14 mb-6;
}

.tiptap h1 {
  @apply text-xl;
}

.tiptap h2 {
  @apply text-lg;
}

.tiptap h3 {
  @apply text-base;
}

.tiptap h4,
.tiptap h5,
.tiptap h6 {
  @apply text-sm;
}

.tiptap code {
  @apply bg-purple-100 rounded text-black text-sm px-1 py-0.5;
}

.tiptap pre {
  @apply bg-black rounded-lg text-white font-mono my-6 px-4 py-3;
}

.tiptap pre code {
  @apply bg-transparent text-inherit text-xs p-0;
}

.tiptap blockquote {
  @apply border-l-4 border-gray-300 pl-4 my-6;
}

.tiptap hr {
  @apply border-0 border-t border-gray-200 my-8;
}
</style>
