<script setup>
import { useAuthStore, useOrganizationStore, useAlertStore } from '@/stores';
import { ref, onMounted, defineProps, computed } from 'vue';
import 'vue-advanced-cropper/dist/style.css';
import _ from 'lodash';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { useManagedOrganizations } from '@/composables/useManagedOrganizations';
import { storeToRefs } from 'pinia';
import NoAccessState from '@/components/dashboard/NoAccessState.vue';
import { LockKeyholeOpen } from 'lucide-vue-next';
const alertStore = useAlertStore();
const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const isLoading = ref(true);
const numUserSeatsUsed = ref([]);
const numManagerSeatsUsed = ref([]);

const { orgMembers: users, orgSeats: seats, orgActiveSubscriptions: subscriptions } = storeToRefs(organizationStore);

const { hasManagerAccess } = useManagedOrganizations({
  handleOrganizationChange: async () => {
    await Promise.all([
      organizationStore.loadSubscriptions(),
      organizationStore.loadSeats(),
      organizationStore.loadMembers(),
    ]);
  },
});

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const getDate = (datetime) => {
  const date = new Date(datetime);
  return new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
};

const isActive = (subscription) => {
  const date = new Date(subscription.expires_at); // Convert provided datetime to a Date object
  const today = new Date(); // Get the current date and time
  today.setHours(0, 0, 0, 0);
  return date >= today;
};

const percentageUserSeatsUsed = (index) => {
  return Math.round(
    (numUserSeatsUsed.value[index] / subscriptions.value[index].organization_subscription_plan.n_user_seats) * 100,
  );
};

const calculateTakenUserSeatsPerSubscription = () => {
  numUserSeatsUsed.value = [];

  subscriptions.value.forEach((subscription, index) => {
    if (!isActive(subscription)) numUserSeatsUsed.value.push(null);
    console.log(subscription.organization_subscription_plan.n_user_seats);
    const maxSeats = subscription.organization_subscription_plan.n_user_seats;
    console.log(seats.value.n_active_users);
    const activeUsers = seats.value.n_active_users;

    // Calculate the seats taken for this subscription (capped by the max seats allowed)
    const seatsUsed = Math.min(activeUsers, maxSeats);

    numUserSeatsUsed.value.push(seatsUsed);
    seats.value.n_active_users -= seatsUsed;
  });
};

const percentageManagerSeatsUsed = (index) => {
  return Math.round(
    (numManagerSeatsUsed.value[index] / subscriptions.value[index].organization_subscription_plan.n_manager_seats) *
      100,
  );
};

const calculateTakenManagerSeatsPerSubscription = () => {
  numManagerSeatsUsed.value = [];

  subscriptions.value.forEach((subscription, index) => {
    if (!isActive(subscription)) numManagerSeatsUsed.value.push(null);
    console.log(subscription.organization_subscription_plan.n_manager_seats);
    const maxSeats = subscription.organization_subscription_plan.n_manager_seats;
    const activeManagers = seats.value.n_active_managers;

    // Calculate the seats taken for this subscription (capped by the max seats allowed)
    const seatsUsed = Math.min(activeManagers, maxSeats);

    numManagerSeatsUsed.value.push(seatsUsed);
    seats.value.n_active_managers -= seatsUsed;
  });
};

const computedStyleMain = computed(() => {
  return {
    height: `calc(var(--fallback-viewport-height, 100vh) - ${props.outerHeaderHeight}px)`,
  };
});

onMounted(async () => {
  isLoading.value = true;

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  if (!authStore.isSignedIn) {
    return;
  }

  try {
    await Promise.all([
      organizationStore.loadOrganizations(),
      organizationStore.loadSubscriptions(),
      organizationStore.loadSeats(),
      organizationStore.loadMembers(),
    ]);
  } catch (error) {
    console.error('Error:', error);
    alertStore.error('Fehler beim Laden der Daten');
  } finally {
    // Ensure isLoading is set to false after all tasks are done
    isLoading.value = false;

    calculateTakenUserSeatsPerSubscription();
    calculateTakenManagerSeatsPerSubscription();
  }
});
</script>
<template>
  <div v-if="isLoading">
    <div class="flex justify-center items-center pt-64 min-h-full relative">
      <LoadingSpinnerLarge />
    </div>
  </div>
  <main id="content" class="py-10 px-20 flex-col flex-grow overflow-y-auto" :style="computedStyleMain" v-else>
    <!-- Card -->
    <NoAccessState
      v-if="!hasManagerAccess"
      title="Manager Access Required"
      message="You need manager access to view subscription information for this organization."
      actionLink="mailto:hello@casuu.care?subject=Manager%20Access%20Request&body=I%20would%20like%20to%20request%20manager%20access%20for%20my%20organization."
      actionText="Request Access"
    >
      <template #action-icon>
        <LockKeyholeOpen :size="20" class="mr-2" />
      </template>
    </NoAccessState>

    <div
      v-else-if="subscriptions.length === 0"
      class="flex flex-col items-center justify-center py-12 px-4 text-center"
    >
      <div class="max-w-md">
        <svg
          class="mx-auto h-16 w-16 text-gray-400 dark:text-neutral-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
          ></path>
        </svg>

        <h3 class="mt-4 text-lg font-medium text-gray-900 dark:text-neutral-200">No active subscriptions</h3>

        <p class="mt-2 text-sm text-gray-500 dark:text-neutral-400">
          You don't have any active subscriptions yet. Contact us to get started with a subscription plan that fits your
          needs.
        </p>

        <div class="mt-6">
          <a
            href="mailto:hello@casuu.care?subject=Subscription%20Inquiry&body=I'm%20interested%20in%20learning%20more%20about%20your%20subscription%20plans."
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-700 dark:hover:bg-blue-800"
          >
            <svg
              class="mr-2 -ml-1 h-5 w-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              ></path>
            </svg>
            Book Now
          </a>
        </div>
      </div>
    </div>
    <div v-else class="flex-col" v-for="(subscription, index) in subscriptions">
      <div
        class="flex mt-4 flex-col bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700"
      >
        <!-- Body -->
        <div class="h-full p-6">
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="7"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              :class="{
                'fill-blue-200 dark:fill-blue-500': isActive(subscription),
                'fill-gray-200 dark:fill-gray-500': !isActive(subscription),
              }"
            />
            <rect
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              :class="{
                'fill-blue-300 dark:fill-blue-600': isActive(subscription),
                'fill-gray-300 dark:fill-gray-600': !isActive(subscription),
              }"
            />
            <rect
              x="14"
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              :class="{
                'fill-blue-500 dark:fill-blue-700': isActive(subscription),
                'fill-gray-500 dark:fill-gray-700': !isActive(subscription),
              }"
            />
          </svg>

          <!-- Grid -->
          <div class="mt-3 grid grid-cols-2 gap-x-2">
            <div>
              <div class="flex items-center gap-x-2">
                <h2 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                  {{ subscription.organization_subscription_plan.name }}
                </h2>
                <span
                  class="inline-flex items-center gap-1.5 py-1.5 px-2 text-xs font-medium rounded-full"
                  :class="{
                    'bg-blue-100 text-blue-800 dark:bg-blue-500/10 dark:text-blue-500': isActive(subscription),
                    'bg-gray-100 text-gray-800 dark:bg-gray-500/10 dark:text-gray-500': !isActive(subscription),
                  }"
                >
                  <span
                    class="size-1.5 inline-block rounded-full"
                    :class="{
                      'bg-blue-800 dark:bg-blue-500': isActive(subscription),
                      'bg-gray-800 dark:bg-gray-500': !isActive(subscription),
                    }"
                  ></span>
                  {{ isActive(subscription) ? 'Active' : 'Inactive' }}
                </span>
              </div>

              <p class="mt-1.5 text-sm text-gray-500 dark:text-neutral-500">
                {{ isActive(subscription) ? 'Expires on' : 'Expired on' }} {{ getDate(subscription.expires_at) }}
              </p>
            </div>
            <!-- End Col -->
          </div>
          <!-- End Grid -->

          <!-- Progress -->
          <div class="my-4">
            <div class="flex justify-between items-center gap-x-2 mb-1">
              <h4 class="font-medium text-gray-800 dark:text-neutral-200">User-Seats</h4>
              <p class="text-sm text-gray-500 dark:text-neutral-500">
                {{ isActive(subscription) ? numUserSeatsUsed[index] : '--' }} of
                {{ subscription.organization_subscription_plan.n_user_seats }} used
              </p>
            </div>
            <div
              class="flex w-full h-2.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
              role="progressbar"
              :aria-valuenow="isActive(subscription) ? percentageUserSeatsUsed(index) : 100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center overflow-hidden text-xs text-white text-center rounded-full whitespace-nowrap"
                :class="{
                  'bg-blue-600': isActive(subscription),
                  'bg-gray-600': !isActive(subscription),
                }"
                :style="{ width: isActive(subscription) ? `${percentageUserSeatsUsed(index)}%` : '100%' }"
              ></div>
            </div>
          </div>
          <!-- End Progress -->

          <!-- Progress -->
          <div class="my-4">
            <div class="flex justify-between items-center gap-x-2 mb-1">
              <h4 class="font-medium text-gray-800 dark:text-neutral-200">Manager-Seats</h4>
              <p class="text-sm text-gray-500 dark:text-neutral-500">
                {{ isActive(subscription) ? numManagerSeatsUsed[index] : '--' }} of
                {{ subscription.organization_subscription_plan.n_manager_seats }} used
              </p>
            </div>
            <div
              class="flex w-full h-2.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
              role="progressbar"
              :aria-valuenow="isActive(subscription) ? percentageManagerSeatsUsed(index) : 100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center overflow-hidden text-xs text-white text-center rounded-full whitespace-nowrap"
                :class="{
                  'bg-blue-600': isActive(subscription),
                  'bg-gray-600': !isActive(subscription),
                }"
                :style="{ width: isActive(subscription) ? `${percentageManagerSeatsUsed(index)}%` : '100%' }"
              ></div>
            </div>
          </div>
          <!-- End Progress -->
        </div>
        <!-- End Body -->
      </div>
      <!-- End Card -->
    </div>
  </main>
</template>
