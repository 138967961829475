<script setup lang="ts">
import { useAuthStore } from '@/stores';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

onMounted(async () => {
  // Redirect to home if logged in, otherwise to login page
  console.log('redirecting to home');
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const redirectPath = authStore.token ? '/home' : '/';
  router.replace(redirectPath);
});
</script>

<template>
  <div class="flex flex-col items-center justify-center w-full h-full">
    <h1 class="text-4xl font-bold mt-20">404</h1>
    <p class="text-lg">Page not found</p>
  </div>
</template>

<style scoped></style>
