/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LearningObjective } from '../models/LearningObjective';
import type { LearningObjectiveEvaluation } from '../models/LearningObjectiveEvaluation';
import type { LearningObjectiveEvaluationsCreate } from '../models/LearningObjectiveEvaluationsCreate';
import type { LearningObjectivesCreate } from '../models/LearningObjectivesCreate';
import type { SubtaskEvaluation } from '../models/SubtaskEvaluation';
import type { SubtaskEvaluationCreate } from '../models/SubtaskEvaluationCreate';
import type { VocabEvaluation } from '../models/VocabEvaluation';
import type { VocabEvaluationExplainTermCreate } from '../models/VocabEvaluationExplainTermCreate';
import type { VocabEvaluationFindTermNoteCorrectCreate } from '../models/VocabEvaluationFindTermNoteCorrectCreate';
import type { VocabEvaluationMakeSentenceCreate } from '../models/VocabEvaluationMakeSentenceCreate';
import type { VocabEvaluationMakeSpecificSentenceCreate } from '../models/VocabEvaluationMakeSpecificSentenceCreate';
import type { VocabEvaluationUseSetPhraseCreate } from '../models/VocabEvaluationUseSetPhraseCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EvaluationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Extract Learning Objectives
   * Extracts learning objectives from the given content.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user object.
   * learning_objective_create (models.LearningObjectiveCreate): The learning objective create object.
   *
   * Returns:
   * List[str]: A list of extracted learning objectives.
   * @param requestBody
   * @returns LearningObjective Successful Response
   * @throws ApiError
   */
  public extractLearningObjectives(requestBody: LearningObjectivesCreate): CancelablePromise<Array<LearningObjective>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/learning-objectives/extract',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Learning Objectives
   * Evaluate learning objectives based on the provided content and learning
   * objectives.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * learning_objective_evaluation_create (models.LearningObjectiveEvaluationsCreate): The learning objective evaluation data.
   *
   * Returns:
   * evaluations: The evaluations of the learning objectives.
   * @param requestBody
   * @returns LearningObjectiveEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateLearningObjectives(
    requestBody: LearningObjectiveEvaluationsCreate,
  ): CancelablePromise<Array<LearningObjectiveEvaluation>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/learning-objectives/evaluate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Vocab Explain Term
   * Evaluate a single vocabulary explanation.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * vocab_evaluation_create (models.VocabEvaluationCreate): The vocabulary evaluation data.
   *
   * Returns:
   * evaluation: The evaluation of the vocabulary explanation.
   * @param requestBody
   * @returns VocabEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateVocabExplainTerm(requestBody: VocabEvaluationExplainTermCreate): CancelablePromise<VocabEvaluation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/explain-vocab',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Note Find Term Correct
   * Notify that a user has found the correct term.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * evaluation_create: item and test session id
   *
   * Returns:
   * evaluation: The xp notification
   * @param requestBody
   * @returns VocabEvaluation Successful Response
   * @throws ApiError
   */
  public noteFindTermCorrect(
    requestBody: VocabEvaluationFindTermNoteCorrectCreate,
  ): CancelablePromise<VocabEvaluation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/find-term',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Vocab Make Sentence From Term
   * Evaluate an attempt to make an example sentence from a vocabulary term.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * vocab_evaluation_create (models.VocabEvaluationCreate): The vocabulary evaluation data.
   *
   * Returns:
   * evaluation: The evaluation of the example sentence.
   * @param requestBody
   * @returns VocabEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateVocabMakeSentenceFromTerm(
    requestBody: VocabEvaluationMakeSentenceCreate,
  ): CancelablePromise<VocabEvaluation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/make-sentence',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Use Set Phrase
   * Evaluate an attempt to use a set phrase.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * vocab_evaluation_create (models.VocabEvaluationCreate): The vocabulary evaluation data.
   *
   * Returns:
   * evaluation: The evaluation of the example sentence.
   * @param requestBody
   * @returns VocabEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateUseSetPhrase(requestBody: VocabEvaluationUseSetPhraseCreate): CancelablePromise<VocabEvaluation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/use-set-phrase',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Make Specific Sentence
   * Evaluate an attempt to craft a sentence with a specific target
   * construction.
   *
   * Args:
   * current_user (CurrentUserByToken): The current user making the evaluation.
   * evaluation_create (models.VocabEvaluationMakeSpecificSentenceCreate): The data to evalute, including
   * - the term to make a sentence from
   * - the user's sentence
   * - the task to make
   * - the remark by the creator of the exercise, if any
   *
   * Returns:
   * evaluation: The evaluation of the example sentence.
   * @param requestBody
   * @returns VocabEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateMakeSpecificSentence(
    requestBody: VocabEvaluationMakeSpecificSentenceCreate,
  ): CancelablePromise<VocabEvaluation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/make-specific',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Subtask
   * Evaluate subtasks for a task.
   *
   * Args:
   * subtask_evaluation_create (models.SubtaskEvaluationCreate): The subtask evaluation data.
   *
   * Returns:
   * evaluations: The evaluations of the subtasks.
   * @param requestBody
   * @returns SubtaskEvaluation Successful Response
   * @throws ApiError
   */
  public evaluateSubtask(requestBody: SubtaskEvaluationCreate): CancelablePromise<Array<SubtaskEvaluation>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/evaluation/subtask/evaluate',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
