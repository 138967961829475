<script setup lang="ts">
import { ref, computed, onMounted, nextTick, watch, onBeforeUnmount } from 'vue';
import { useAutosave, useExerciseLifecycle } from '@/composables';
import type { PropType } from 'vue';
import type { SectionContentItemOverview } from '@/apiclient';
import { useAlertStore, useCourseStore } from '@/stores';
import ProgressButton from '@/components/ProgressButton.vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { useI18n } from 'vue-i18n';
import { Check, X } from 'lucide-vue-next';
import { shuffle, cloneDeep } from 'lodash';
import MediaUploadCard from '@/components/didactics/media/MediaUploadCard.vue';
import { getApiClient } from '@/apiclient/client';
import ImageLabel from './ImageLabel.vue';
import { v4 as uuidv4 } from 'uuid';

interface ImageLabelingItem {
  id: string;
  name: string;
  distractors: string[];
  x_pos: number;
  y_pos: number;
  isSolved?: boolean;
}

interface ImageLabelingExercise {
  id: string;
  user_id: string | null;
  image_labeling_items: ImageLabelingItem[];
  created_at: string;
  task_instructions: string;
  title: string;
}

const props = defineProps({
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  allowEditing: {
    type: Boolean,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['onDeleteContentItem', 'addNewVocab']);

// State
const imageUrl = ref('');
const selectedPointId = ref<string | null>(null);
const imageContainerRef = ref<HTMLDivElement | null>(null);
const studentAnswersMap = ref<Map<string, string>>(new Map());
const uploadInProgress = ref(false);
const isDragging = ref(false);
const draggedPointId = ref<string | null>(null);
const taskInstructions = ref(props.contentItem.task_instructions || '');
const title = ref(props.contentItem.title || '');
const points = ref<ImageLabelingItem[]>([]);
const pointIsSolved = ref<boolean[]>([]);

// Stores
const alertStore = useAlertStore();
const courseStore = useCourseStore();

// Composables
const { t } = useI18n();
const {
  handleExerciseFinished,
  fetchInteractionState,
  reopenExercise,
  itemInteractionState,
  fetchCompleted: interactionFetchCompleted,
} = useExerciseLifecycle({
  contentItem: props.contentItem,
  sectionIndex: props.sectionIndex,
  pageIndex: props.pageIndex,
  isEditing: props.allowEditing,
  emit,
  onFetched() {
    if (itemInteractionState.value?.completed_at) {
      pointIsSolved.value = Array(points.value.length).fill(true);
    } else {
      pointIsSolved.value = Array(points.value.length).fill(false);
    }
  },
  onReopen() {
    pointIsSolved.value = Array(points.value.length).fill(false);
  },
});
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.contentItem.id) return;

    const apiClient = await getApiClient();
    await apiClient.courseSectionItems.updateSectionContentItemWithImageLabelingExercise(props.contentItem.id, {
      title: title.value,
      task_instructions: taskInstructions.value,
      image_url: imageUrl.value,
    });

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error saving image labeling exercise', 'Error', error as Error);
  },
});

// Methods for editing mode
const handleUploadFile = async (event: Event) => {
  uploadInProgress.value = true;
  try {
    let file = null;

    if (event.target instanceof HTMLInputElement && event.target.files) {
      file = event.target.files[0];
    } else if (event instanceof DragEvent && event.dataTransfer?.files) {
      file = event.dataTransfer.files[0];
    } else {
      throw new Error('No file provided');
    }

    const response = await (
      await getApiClient()
    ).courseSectionItems.uploadMediaItemForUseInExercise(props.contentItem.id, { file });

    imageUrl.value = response.media_url;
    await saveChanges(); // immediate save

    alertStore.success('Upload successful');
  } catch (error) {
    alertStore.error('Error uploading image', 'Error', error as Error);
  } finally {
    uploadInProgress.value = false;
  }
};

const addPointAtBottomMiddle = () => {
  if (!props.allowEditing) return;

  const newPoint: ImageLabelingItem = {
    id: 'temp-' + uuidv4(),
    name: '',
    distractors: [],
    x_pos: 50, // middle of the image
    y_pos: 90, // near bottom of the image
  };

  points.value.push(newPoint);
  selectedPointId.value = newPoint.id;
  // will not save until label is set
};

const handleImageClick = (event: MouseEvent | null = null) => {
  if (!props.allowEditing) return;

  // If no event, it's from the button click
  if (!event) {
    addPointAtBottomMiddle();
    return;
  }

  // If there's an event and imageContainerRef, it's from clicking the image
  if (imageContainerRef.value) {
    const rect = imageContainerRef.value.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 100;
    const y = ((event.clientY - rect.top) / rect.height) * 100;

    const newPoint: ImageLabelingItem = {
      id: 'temp-' + uuidv4(),
      name: '',
      distractors: [],
      x_pos: Math.round(x),
      y_pos: Math.round(y),
    };

    points.value.push(newPoint);
    selectedPointId.value = newPoint.id;
  }
};

const removePoint = async (pointId: string) => {
  if (!props.contentItem.id) return;

  if (!pointId.startsWith('temp-')) {
    await courseStore.removeImageLabelingItem(props.contentItem.id, pointId);
  }
  points.value = points.value.filter((p) => p.id !== pointId);
  if (selectedPointId.value === pointId) {
    selectedPointId.value = null;
  }
};

const updatePointPosition = async (pointId: string, position: { x_pos: number; y_pos: number }) => {
  console.log('updatePointPosition', pointId, position);
  const point = points.value.find((p) => p.id === pointId);
  if (point?.id.startsWith('temp-')) return;
  if (!point || !props.contentItem.id) return;

  await courseStore.updateImageLabelingItem(props.contentItem.id, pointId, {
    name: point.name,
    distractors: point.distractors,
    ...position,
  });
};

const updatePointId = async (pointId: string, newId: string) => {
  points.value = points.value.map((p) => (p.id === pointId ? { ...p, id: newId } : p));
};

// Methods for student mode
const getDistractorsForPoint = (point: ImageLabelingItem) => {
  // Start with existing distractors
  let distractors = [...(point.distractors || [])];

  // If we need more distractors
  if (distractors.length < 3) {
    // Get other point names, excluding the current point
    const otherLabels = points.value
      .filter((p) => p.id !== point.id)
      .map((p) => p.name)
      .filter((name) => name && !distractors.includes(name)); // Filter out empty names and existing distractors

    // Add random other labels until we have 3 distractors or run out of options
    const additionalNeeded = 3 - distractors.length;
    const additionalDistractors = shuffle(otherLabels).slice(0, additionalNeeded);
    distractors = [...distractors, ...additionalDistractors];
  }

  return distractors;
};

const handleStudentSelection = (pointId: string, selectedLabel: string) => {
  const point = points.value.find((p) => p.id === pointId);
  if (!point) return;

  studentAnswersMap.value.set(pointId, selectedLabel);
  point.isSolved = selectedLabel === point.name;
  selectedPointId.value = null;

  if (studentAnswersMap.value.size === points.value.length) {
    handleExerciseFinished();
  }
};

// Methods for dragging
const handlePointDragStart = (event: MouseEvent | TouchEvent, pointId: string) => {
  if (!props.allowEditing) return;

  event.stopPropagation();
  isDragging.value = true;
  draggedPointId.value = pointId;

  // Add event listeners for drag movement and end
  document.addEventListener('mousemove', handlePointDragMove);
  document.addEventListener('mouseup', handlePointDragEnd);
  document.addEventListener('touchmove', handlePointDragMove);
  document.addEventListener('touchend', handlePointDragEnd);
};

const handlePointDragMove = (event: MouseEvent | TouchEvent) => {
  if (!isDragging.value || !draggedPointId.value || !imageContainerRef.value) return;

  event.preventDefault();

  const rect = imageContainerRef.value.getBoundingClientRect();
  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
  const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY;

  const x = ((clientX - rect.left) / rect.width) * 100;
  const y = ((clientY - rect.top) / rect.height) * 100;

  // Constrain coordinates to image boundaries
  const boundedX = Math.max(0, Math.min(100, x));
  const boundedY = Math.max(0, Math.min(100, y));

  const point = points.value.find((p) => p.id === draggedPointId.value);
  if (point) {
    point.x_pos = Math.round(boundedX);
    point.y_pos = Math.round(boundedY);
  }
};

const handlePointDragEnd = (event: MouseEvent | TouchEvent) => {
  if (isDragging.value && draggedPointId.value) {
    isDragging.value = false;
    const point = points.value.find((p) => p.id === draggedPointId.value);
    if (point) {
      console.log('updatePointPosition', draggedPointId.value, { x_pos: point.x_pos, y_pos: point.y_pos });
      updatePointPosition(draggedPointId.value, { x_pos: point.x_pos, y_pos: point.y_pos });
    }
    draggedPointId.value = null;
  }

  // Remove event listeners
  document.removeEventListener('mousemove', handlePointDragMove);
  document.removeEventListener('mouseup', handlePointDragEnd);
  document.removeEventListener('touchmove', handlePointDragMove);
  document.removeEventListener('touchend', handlePointDragEnd);
};

// Lifecycle
onMounted(async () => {
  await nextTick();
  if (props.allowEditing) {
    interactionFetchCompleted.value = true;
  }
  await fetchInteractionState();

  // Load existing exercise data if available
  if (props.contentItem.image_labeling_exercise) {
    points.value = props.contentItem.image_labeling_exercise.image_labeling_items
      ? cloneDeep(props.contentItem.image_labeling_exercise.image_labeling_items)
      : [];
    taskInstructions.value = props.contentItem.image_labeling_exercise.task_instructions;
    title.value = props.contentItem.image_labeling_exercise.title;
    imageUrl.value = props.contentItem.image_labeling_exercise.image_url;
  }
});
// Cleanup on component unmount
onBeforeUnmount(() => {
  document.removeEventListener('mousemove', handlePointDragMove);
  document.removeEventListener('mouseup', handlePointDragEnd);
  document.removeEventListener('touchmove', handlePointDragMove);
  document.removeEventListener('touchend', handlePointDragEnd);
});

watch(
  () => pointIsSolved.value,
  () => {
    console.log('pointIsSolved', pointIsSolved.value);
    if (
      pointIsSolved.value &&
      pointIsSolved.value.every((p) => p) &&
      (!itemInteractionState.value?.completed_at || itemInteractionState.value?.reopened_at != null)
    ) {
      handleExerciseFinished();
    }
  },
  { deep: true },
);
</script>

<template>
  <div class="min-w-full pt-0.5 px-4 pb-1 min-h-48 relative">
    <div
      :class="{
        'opacity-25':
          !props.allowEditing &&
          (!interactionFetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <!-- Title -->
      <h3 v-if="props.allowEditing || title !== ''" class="text text-center px-6">
        <textarea
          v-if="props.allowEditing"
          v-model="title"
          class="w-full px-2 py-0 mb-2 resize-none border-gray-200 rounded-lg text-center"
          placeholder="Titel (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
        <span v-else class="text-gray-800 dark:text-neutral-200 font-semibold">
          {{ title }}
        </span>
      </h3>

      <!-- Task instructions -->
      <h3 v-if="props.allowEditing || taskInstructions !== ''" class="pt-2 pb-1 px-4 w-full text-center">
        <span v-if="!props.allowEditing" class="text-xs md:text-sm font-semibold">
          {{ taskInstructions }}
        </span>
        <textarea
          v-if="props.allowEditing"
          v-model="taskInstructions"
          class="w-full text-xs md:text-sm px-2 py-0 mb-2 resize-none border-gray-200 rounded-lg text-center"
          placeholder="Erläuterungen zur Aufgabenstellung (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
      </h3>

      <!-- Replace old image upload with MediaUploadCard -->
      <div v-if="props.allowEditing && !imageUrl" class="mb-4">
        <MediaUploadCard
          :upload-file="handleUploadFile"
          :upload-in-progress="uploadInProgress"
          :allow-image="true"
          :allow-audio="false"
          :allow-video="false"
          header-text="Bild hochladen"
          text-when-minimized="Bild hier ablegen"
          :minimal="true"
        />
      </div>

      <!-- Image container -->
      <div v-if="imageUrl" ref="imageContainerRef" class="relative w-full select-none" @click="handleImageClick">
        <img :src="imageUrl" class="w-full h-auto" alt="Labeling exercise image" />

        <!-- Labels -->
        <ImageLabel
          v-for="(point, index) in points"
          :key="point.id"
          :id="point.id"
          :content-item-id="props.contentItem.id"
          :name="point.name"
          :distractors="getDistractorsForPoint(point)"
          :x_pos="point.x_pos"
          :y_pos="point.y_pos"
          :is-selected="selectedPointId === point.id"
          :is-editing="props.allowEditing"
          :is-dragged="draggedPointId === point.id"
          @mousedown.stop="(e: MouseEvent) => handlePointDragStart(e, point.id)"
          @touchstart.stop="(e: TouchEvent) => handlePointDragStart(e, point.id)"
          @select="
            props.allowEditing
              ? (selectedPointId = point.id)
              : (selectedPointId = selectedPointId === point.id ? null : point.id)
          "
          @update:id="updatePointId(point.id, $event)"
          @remove="removePoint(point.id)"
          @isSolved="pointIsSolved[index] = true"
        />
      </div>

      <!-- After the image container, before the loading overlay -->
      <div v-if="props.allowEditing && imageUrl" class="flex justify-center mt-4">
        <button
          @click="handleImageClick()"
          class="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          <span translate="no" class="material-symbols-outlined notranslate">add_circle</span>
          Label hinzufügen
        </button>
      </div>

      <!-- Also add instructions when no points exist -->
      <div v-if="props.allowEditing && imageUrl && points.length === 0" class="text-center text-gray-600 mt-2">
        Klicke auf "Label hinzufügen" oder direkt auf das Bild, um Punkte zu markieren
      </div>

      <!-- Add a counter for existing points -->
      <div v-if="props.allowEditing && imageUrl && points.length > 0" class="text-center text-gray-600 mt-2">
        {{ points.length }} {{ points.length === 1 ? 'Punkt' : 'Punkte' }} markiert
      </div>
    </div>

    <!-- Loading overlay -->
    <div
      v-show="!interactionFetchCompleted"
      class="absolute z-10 top-0 start-0 items-center justify-center flex w-full h-full"
    >
      <LoadingSpinnerLarge />
    </div>

    <!-- Exercise completed overlay -->
    <div
      v-if="
        interactionFetchCompleted &&
        !props.allowEditing &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped>
.point-marker {
  transition: all 0.2s ease-in-out;
  touch-action: none; /* Prevents default touch actions like scrolling */
}

.point-marker:hover {
  transform: scale(1.2);
}
</style>
