<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useAutosave } from '@/composables/useAutosave';

const { t, locale } = useI18n();

// stores
const alertStore = useAlertStore();
const authStore = useAuthStore();
const courseInteractionStore = useCourseInteractionStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

// props
const props = defineProps({
  sectionContentItemId: {
    type: String,
    required: true,
  },
  sectionContentItemIndex: {
    type: Number,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  itemInteractionState: {
    type: Object,
    required: true,
  },
});

// emits
const emit = defineEmits(['interactionStarted', 'questionSolved']);

// composables
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.isEditing) return;

    (await getApiClient()).courseSectionItems.updateMcQuestionInContentItemWithResource(
      props.sectionContentItemId,
      props.question.id,
      {
        question_text: localQuestion.value.question_text,
        question_media_item_id: localQuestion.value.question_media_item_id,
        answers: localQuestion.value.answers,
      },
    );
    // TODO would expect this needs to be done over the store, but not problem so far

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

// refs
const textarea = ref<HTMLTextAreaElement | null>(null);
const textareaHeightAdjustedToInitialFetch = ref(false);

// state
const localQuestion = ref(props.question);
const initCompleted = ref(false);
const hasBeenCheckedCorrect = ref(false); // disallow multiple checks (at least until remounted...)

// lifecycle hooks & methods
onMounted(() => {
  if (!props.isEditing) {
    // add field is_selected
    localQuestion.value.answers.forEach((a) => {
      a.is_selected = false;
    });
    emit('questionSolved', false);
  }
  localQuestion.value.answers.forEach((a, idx) => {
    a.originalIndex = idx;
  });
  initCompleted.value = true;
});

const shuffledAnswers = computed(() => {
  if (props.isEditing) return localQuestion.value.answers;
  let shuffled = [...localQuestion.value.answers]; // 1st order copy, 2nd order ref
  return shuffled.sort(() => Math.random() - 0.5);
});

const numCorrect = computed(() => {
  return localQuestion.value.answers.filter((a) => a.is_correct).length;
});

const numAnswers = computed(() => {
  return localQuestion.value.answers.length;
});

// Getter for checkbox value
const getAnswerValue = (index: number) => {
  return props.isEditing
    ? localQuestion.value.answers[index]?.is_correct ?? false
    : localQuestion.value.answers[index]?.is_selected ?? false;
};

// Add this computed property to track if the user has selected the correct number of answers
const hasSelectedCorrectNumberOfAnswers = computed(() => {
  if (props.isEditing) return false;
  const selectedCount = localQuestion.value.answers.filter((a) => a.is_selected).length;
  return selectedCount === numCorrect.value;
});

// Modify the setAnswerValue function to reset selections after wrong answers
const setAnswerValue = (index: number, value: boolean) => {
  if (props.isEditing) {
    localQuestion.value.answers[index].is_correct = value;
    unsavedChanges.value = true;
  } else {
    // If we previously got a wrong answer and user is clicking again, reset all selections
    if (isWrong.value) {
      // Reset all selections
      localQuestion.value.answers.forEach((a) => {
        a.is_selected = false;
      });
      // Then set the clicked one to selected
      localQuestion.value.answers[index].is_selected = true;
      emit('interactionStarted');
      return;
    }

    // If we're trying to select more than numCorrect answers, prevent it
    const selectedCount = localQuestion.value.answers.filter((a) => a.is_selected).length;
    if (value && selectedCount >= numCorrect.value) {
      // If we're already at the limit, don't allow more selections
      return;
    }

    localQuestion.value.answers[index].is_selected = value;
    emit('interactionStarted');

    // Check if we've selected exactly numCorrect answers
    if (hasSelectedCorrectNumberOfAnswers.value) {
      // Now check if the answers are correct
      const isAllCorrect = localQuestion.value.answers.every((a) => a.is_correct === a.is_selected);
      const isAnyWrong = localQuestion.value.answers.some((a) => a.is_selected && !a.is_correct);

      // Play sound feedback if any selected answer is wrong
      if (isAnyWrong) alertStore.playMajorFailureSound();
    }
  }
};

// Modify the isSolved computed property to only evaluate when the correct number is selected
const isSolved = computed(() => {
  if (props.isEditing) return false;

  // Only check if solved when the correct number of answers are selected
  if (!hasSelectedCorrectNumberOfAnswers.value) return false;

  return localQuestion.value.answers.every((a) => a.is_correct === a.is_selected);
});

const isWrong = computed(() => {
  if (props.isEditing) return false;

  // Only check if wrong when the correct number of answers are selected
  if (!hasSelectedCorrectNumberOfAnswers.value) return false;

  return localQuestion.value.answers.some((a) => a.is_selected && !a.is_correct);
});

watch(
  () => isSolved.value,
  async (newValue) => {
    if (newValue) {
      if (!courseInteractionFetchCompleted.value) return;
      if (hasBeenCheckedCorrect.value) return; // disallow multiple checks (at least until remounted...)
      await courseInteractionStore
        .evaluateMcQuestion(props.sectionContentItemId, localQuestion.value.id, localQuestion.value.answers)
        .then(async (response) => {
          if (!response?.is_correct) return;
          console.log(response);
          response.notifications?.forEach((notification) => {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          });
          await authStore.fetchUserXp();
          hasBeenCheckedCorrect.value = true;
          emit('questionSolved', true);
        })
        .catch((error) => {
          alertStore.error('Failed to evaluate form', 'Error', error);
          console.error(error);
          throw new Error('Failed to evaluate form');
        });
    }
  },
);

watch(
  () => textarea.value,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        updateMinTextareaHeight(newVal, 1);
      }, 100);
    }
  },
);

watch(
  // this updates the textarea height when the question text is set initially
  () => localQuestion.value.question_text,
  (newVal) => {
    if (textareaHeightAdjustedToInitialFetch.value) return;
    if (!textarea.value) return;
    if (newVal) {
      setTimeout(() => {
        console.log('question text watcher: adjusting height');
        updateMinTextareaHeight(textarea.value, 1);
        textareaHeightAdjustedToInitialFetch.value = true;
      }, 100);
    }
  },
);

// Add a watch for itemInteractionState.reopened_at
watch(
  () => props.itemInteractionState?.reopened_at,
  () => {
    // Reset the question state
    hasBeenCheckedCorrect.value = false;
    if (!props.isEditing) {
      localQuestion.value.answers.forEach((a) => {
        a.is_selected = false;
      });
    }
    emit('questionSolved', false);
  },
);
</script>

<template>
  <div class="p-4 border-gray-200 shadow-sm rounded-2xl text-sm bg-white">
    <div class="px-2 text-xs font-medium">Frage</div>
    <textarea
      v-if="props.isEditing"
      ref="textarea"
      v-model="localQuestion.question_text"
      class="w-full px-2 py-0 my-2 resize-none text-sm rounded-lg text-start border-gray-200"
      rows="1"
      @input="
        (event) => {
          const target = event.target as HTMLInputElement;
          updateMinTextareaHeight(target);
          unsavedChanges = true;
        }
      "
    />
    <h2
      v-else-if="localQuestion.question_text"
      class="w-full px-2 py-0 my-2 mb-8 text-3xl font-medium rounded-lg text-start"
    >
      {{ localQuestion.question_text }}
    </h2>

    <div v-if="!props.isEditing" class="px-2 text-xs font-semibold">
      Wähle {{ numCorrect }} aus {{ numAnswers }}
      <span v-if="!hasSelectedCorrectNumberOfAnswers && !props.isEditing" class="text-orange">
        ({{ localQuestion.answers.filter((a) => a.is_selected).length }}/{{ numCorrect }} ausgewählt)
      </span>
    </div>
    <div
      v-else
      @click.prevent="
        localQuestion.answers.push({
          answer_text: '',
          is_correct: false,
          originalIndex: localQuestion.answers.length,
        })
      "
      class="flex items-center group text-center uppercase text-xs font-semibold text-blue-600 hover:text-blue-700 cursor-pointer"
    >
      <span translate="no" class="pr-2 no-translate material-symbols-outlined text-xl">add_circle</span>
      <span class="group-hover:underline">Anwortmöglichkeit hinzufügen</span>
    </div>
    <div
      v-if="initCompleted"
      v-for="answer in shuffledAnswers"
      :key="answer.originalIndex"
      class="flex-col flex items-center text-sm"
      :class="{ 'pl-7': props.isEditing }"
    >
      <div class="inline-flex items-center w-full">
        <div v-if="props.isEditing" class="relative">
          <input
            :disabled="isSolved"
            :checked="getAnswerValue(<number>answer.originalIndex)"
            @change="setAnswerValue(<number>answer.originalIndex, $event.target.checked)"
            type="checkbox"
            class="shrink-0 border-gray-200 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:focus:ring-offset-gray-800"
            :class="{
              'text-blue-600 focus:ring-blue-500 dark:checked:bg-blue-500 dark:checked:border-blue-500':
                !isSolved && !isWrong,
              'text-teal-600 focus:ring-teal-600': isSolved,
              'text-red-600 focus:ring-red-600': answer.is_selected && !answer.is_correct,
            }"
          />
          <span
            class="absolute select-none z-10 top-1 shrink-0 border-gray-200 rounded font-bold cursor-pointer h-4 w-4 bg-red-600 text-white flex items-center justify-center text-center"
            :class="{
              'opacity-100': answer.is_selected && !answer.is_correct,
              'opacity-0': !answer.is_selected || answer.is_correct,
            }"
            @click.prevent="setAnswerValue(<number>answer.originalIndex, !getAnswerValue(<number>answer.originalIndex))"
            :style="{ fontVariationSettings: '\'wght\' 600' }"
          >
            <span translate="no" class="notranslate select-none material-symbols-rounded text-xl"> close </span>
          </span>
        </div>

        <!-- Non-editing mode -->
        <div
          v-if="!props.isEditing"
          class="rounded-2xl py-4 px-6 font-medium text-xl cursor-pointer border w-full mt-2"
          :class="{
            'bg-orange-light border-orange border-opacity-20 text-orange':
              answer.is_selected && !hasSelectedCorrectNumberOfAnswers,
            'bg-gray-light border-gray-light': !answer.is_selected && !hasSelectedCorrectNumberOfAnswers,
            'bg-green-veryLight border-green-light text-green':
              hasSelectedCorrectNumberOfAnswers && isSolved && answer.is_correct,
            'bg-red-veryLight border-red-light text-red':
              hasSelectedCorrectNumberOfAnswers && isWrong && answer.is_selected && !answer.is_correct,
          }"
          @click="setAnswerValue(<number>answer.originalIndex, !getAnswerValue(<number>answer.originalIndex))"
        >
          {{ answer.answer_text }}
        </div>

        <!-- Editing mode: textarea -->
        <textarea
          v-if="props.isEditing"
          v-model="answer.answer_text"
          class="w-full px-2 py-0 my-2 mx-4 resize-none rounded-lg text-sm text-start border-gray-200"
          :disabled="!props.isEditing"
          placeholder="Antwortmöglichkeit"
          rows="1"
          @input="
            (event) => {
              const target = event.target as HTMLInputElement;
              updateMinTextareaHeight(target);
              if (props.isEditing) unsavedChanges = true;
            }
          "
        />
        <span
          class="cursor-pointer flex items-center text-center text-red-600 hover:text-red-800"
          v-if="props.isEditing"
          @click="
            () => {
              localQuestion.answers.splice(answer.originalIndex, 1);
              unsavedChanges = true;
            }
          "
        >
          <span translate="no" class="material-symbols-outlined notranslate bg-white w-fit text-xl">delete</span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
