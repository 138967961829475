<script setup lang="ts">
import { Sparkles } from 'lucide-vue-next';
import { ref, onMounted } from 'vue';
import gsap from 'gsap';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['improveUserMessage']);
const buttonRef = ref(null);

const props = defineProps({
  enabled: {
    type: Boolean,
    default: true,
  },
  inFullHistory: {
    type: Boolean,
    default: false,
  },
  animate: {
    type: Boolean,
    default: true,
  },
});

onMounted(() => {
  // Initial state
  if (buttonRef.value && props.animate) {
    gsap.set(buttonRef.value, {
      scale: 0,
      opacity: 0,
    });

    // Animate in after delay
    setTimeout(() => {
      gsap.to(buttonRef.value, {
        duration: 0.4,
        scale: 1,
        opacity: 1,
        ease: 'back.out(1.7)',
      });
    }, 1600);
  }
});
</script>

<template>
  <button
    ref="buttonRef"
    @click="emit('improveUserMessage')"
    class="flex bg-white items-center gap-1 px-2 py-1 rounded-full transition-colors duration-300 ease-out border self-center select-none"
    :class="[
      props.inFullHistory
        ? props.enabled
          ? 'bg-gray-100 text-gray-700 hover:bg-gray-200 border-gray-200'
          : 'bg-gray-50 text-gray-400 cursor-not-allowed border-gray-100'
        : props.enabled
        ? 'bg-white text-gray-900 hover:bg-gray-100 border-gray-200'
        : 'text-gray-400 border-gray-200/50 backdrop-blur-sm cursor-not-allowed',
    ]"
    :disabled="!props.enabled"
  >
    <Sparkles class="w-4 h-4" />
    <span class="text-sm">{{ $t('message.improveInteraction') }}</span>
  </button>
</template>

<style scoped>
/* Remove previous animation styles */
</style>
