import { Subtask, SubtaskInteraction, TaskInteraction } from '@/apiclient';

export default class CaseInteractionService {
  static getNextSubtask(subtaskInteraction: SubtaskInteraction, caseInteractionStore: any): Subtask | null {
    /* Find the first subtask for which either:
     * 1. the interaction has no completion record, or
     * 2. the interaction was completed after the current subtask interaction
     */

    const currentCompletionTime = subtaskInteraction.completed_at!;

    const taskInteractionId = subtaskInteraction.task_interaction_id;
    const taskInteraction = caseInteractionStore.currentCaseInteraction?.task_interactions.find(
      (ti: TaskInteraction) => ti.id === taskInteractionId,
    );
    const taskIndex = taskInteraction?.index;
    const task = caseInteractionStore.currentCase?.tasks?.[taskIndex];
    const taskSubtaskInteractions =
      caseInteractionStore.currentCaseInteraction?.task_interactions[taskIndex]?.subtask_interactions || [];

    for (let i = 0; i < (task?.subtasks?.length || 0); i++) {
      const subtaskCompletion = taskSubtaskInteractions.find((si: SubtaskInteraction) => si.index === i)?.completed_at;

      if (!subtaskCompletion || new Date(subtaskCompletion) > new Date(currentCompletionTime)) {
        return task?.subtasks?.[i] ?? null;
      }
    }

    // nothing found
    return null;
  }

  static getSubtaskForSubtaskInteraction(
    subtaskInteraction: SubtaskInteraction,
    caseInteractionStore: any,
  ): Subtask | null {
    const taskInteractionId = subtaskInteraction.task_interaction_id;
    const taskInteraction = caseInteractionStore.currentCaseInteraction?.task_interactions.find(
      (ti: TaskInteraction) => ti.id === taskInteractionId,
    );
    const taskIndex = taskInteraction?.index;
    const task = caseInteractionStore.currentCase?.tasks?.[taskIndex];
    const subtaskIndex = subtaskInteraction.index;
    return task?.subtasks?.[subtaskIndex] ?? null;
  }
}
