/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AuthenticationService } from './services/AuthenticationService';
import { CaseInteractionsService } from './services/CaseInteractionsService';
import { CaseListsService } from './services/CaseListsService';
import { CasesService } from './services/CasesService';
import { ChapterInteractionsService } from './services/ChapterInteractionsService';
import { ChaptersService } from './services/ChaptersService';
import { ClinicalNotesService } from './services/ClinicalNotesService';
import { CompanionInteractionsService } from './services/CompanionInteractionsService';
import { CompanionMessagesService } from './services/CompanionMessagesService';
import { CompanionsService } from './services/CompanionsService';
import { CountriesService } from './services/CountriesService';
import { CourseInteractionsService } from './services/CourseInteractionsService';
import { CoursesService } from './services/CoursesService';
import { CourseSectionItemsService } from './services/CourseSectionItemsService';
import { DebugService } from './services/DebugService';
import { DialogsService } from './services/DialogsService';
import { EvaluationService } from './services/EvaluationService';
import { ExaminationsService } from './services/ExaminationsService';
import { ExperiencePointsService } from './services/ExperiencePointsService';
import { FollowsService } from './services/FollowsService';
import { JournalsService } from './services/JournalsService';
import { LabSheetsService } from './services/LabSheetsService';
import { LanguagesService } from './services/LanguagesService';
import { MediaService } from './services/MediaService';
import { ObserverInteractionsService } from './services/ObserverInteractionsService';
import { ObserverMessagesService } from './services/ObserverMessagesService';
import { ObserversService } from './services/ObserversService';
import { OrganizationsService } from './services/OrganizationsService';
import { PatientInteractionsService } from './services/PatientInteractionsService';
import { PatientMessagesService } from './services/PatientMessagesService';
import { PatientsService } from './services/PatientsService';
import { SectionInteractionsService } from './services/SectionInteractionsService';
import { SectionItemInteractionsService } from './services/SectionItemInteractionsService';
import { SectionsService } from './services/SectionsService';
import { SpecialtiesService } from './services/SpecialtiesService';
import { StarringsService } from './services/StarringsService';
import { SttService } from './services/SttService';
import { SubscriptionsService } from './services/SubscriptionsService';
import { TagsService } from './services/TagsService';
import { ThirdPersonInteractionsService } from './services/ThirdPersonInteractionsService';
import { ThirdPersonMessagesService } from './services/ThirdPersonMessagesService';
import { ThirdPersonsService } from './services/ThirdPersonsService';
import { TranslationsService } from './services/TranslationsService';
import { UserBlocksService } from './services/UserBlocksService';
import { UsersService } from './services/UsersService';
import { VocabListsService } from './services/VocabListsService';
import { VocabsService } from './services/VocabsService';
import { VoicesService } from './services/VoicesService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {
  public readonly authentication: AuthenticationService;
  public readonly caseInteractions: CaseInteractionsService;
  public readonly caseLists: CaseListsService;
  public readonly cases: CasesService;
  public readonly chapterInteractions: ChapterInteractionsService;
  public readonly chapters: ChaptersService;
  public readonly clinicalNotes: ClinicalNotesService;
  public readonly companionInteractions: CompanionInteractionsService;
  public readonly companionMessages: CompanionMessagesService;
  public readonly companions: CompanionsService;
  public readonly countries: CountriesService;
  public readonly courseInteractions: CourseInteractionsService;
  public readonly courses: CoursesService;
  public readonly courseSectionItems: CourseSectionItemsService;
  public readonly debug: DebugService;
  public readonly dialogs: DialogsService;
  public readonly evaluation: EvaluationService;
  public readonly examinations: ExaminationsService;
  public readonly experiencePoints: ExperiencePointsService;
  public readonly follows: FollowsService;
  public readonly journals: JournalsService;
  public readonly labSheets: LabSheetsService;
  public readonly languages: LanguagesService;
  public readonly media: MediaService;
  public readonly observerInteractions: ObserverInteractionsService;
  public readonly observerMessages: ObserverMessagesService;
  public readonly observers: ObserversService;
  public readonly organizations: OrganizationsService;
  public readonly patientInteractions: PatientInteractionsService;
  public readonly patientMessages: PatientMessagesService;
  public readonly patients: PatientsService;
  public readonly sectionInteractions: SectionInteractionsService;
  public readonly sectionItemInteractions: SectionItemInteractionsService;
  public readonly sections: SectionsService;
  public readonly specialties: SpecialtiesService;
  public readonly starrings: StarringsService;
  public readonly stt: SttService;
  public readonly subscriptions: SubscriptionsService;
  public readonly tags: TagsService;
  public readonly thirdPersonInteractions: ThirdPersonInteractionsService;
  public readonly thirdPersonMessages: ThirdPersonMessagesService;
  public readonly thirdPersons: ThirdPersonsService;
  public readonly translations: TranslationsService;
  public readonly userBlocks: UserBlocksService;
  public readonly users: UsersService;
  public readonly vocabLists: VocabListsService;
  public readonly vocabs: VocabsService;
  public readonly voices: VoicesService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '0.1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.authentication = new AuthenticationService(this.request);
    this.caseInteractions = new CaseInteractionsService(this.request);
    this.caseLists = new CaseListsService(this.request);
    this.cases = new CasesService(this.request);
    this.chapterInteractions = new ChapterInteractionsService(this.request);
    this.chapters = new ChaptersService(this.request);
    this.clinicalNotes = new ClinicalNotesService(this.request);
    this.companionInteractions = new CompanionInteractionsService(this.request);
    this.companionMessages = new CompanionMessagesService(this.request);
    this.companions = new CompanionsService(this.request);
    this.countries = new CountriesService(this.request);
    this.courseInteractions = new CourseInteractionsService(this.request);
    this.courses = new CoursesService(this.request);
    this.courseSectionItems = new CourseSectionItemsService(this.request);
    this.debug = new DebugService(this.request);
    this.dialogs = new DialogsService(this.request);
    this.evaluation = new EvaluationService(this.request);
    this.examinations = new ExaminationsService(this.request);
    this.experiencePoints = new ExperiencePointsService(this.request);
    this.follows = new FollowsService(this.request);
    this.journals = new JournalsService(this.request);
    this.labSheets = new LabSheetsService(this.request);
    this.languages = new LanguagesService(this.request);
    this.media = new MediaService(this.request);
    this.observerInteractions = new ObserverInteractionsService(this.request);
    this.observerMessages = new ObserverMessagesService(this.request);
    this.observers = new ObserversService(this.request);
    this.organizations = new OrganizationsService(this.request);
    this.patientInteractions = new PatientInteractionsService(this.request);
    this.patientMessages = new PatientMessagesService(this.request);
    this.patients = new PatientsService(this.request);
    this.sectionInteractions = new SectionInteractionsService(this.request);
    this.sectionItemInteractions = new SectionItemInteractionsService(this.request);
    this.sections = new SectionsService(this.request);
    this.specialties = new SpecialtiesService(this.request);
    this.starrings = new StarringsService(this.request);
    this.stt = new SttService(this.request);
    this.subscriptions = new SubscriptionsService(this.request);
    this.tags = new TagsService(this.request);
    this.thirdPersonInteractions = new ThirdPersonInteractionsService(this.request);
    this.thirdPersonMessages = new ThirdPersonMessagesService(this.request);
    this.thirdPersons = new ThirdPersonsService(this.request);
    this.translations = new TranslationsService(this.request);
    this.userBlocks = new UserBlocksService(this.request);
    this.users = new UsersService(this.request);
    this.vocabLists = new VocabListsService(this.request);
    this.vocabs = new VocabsService(this.request);
    this.voices = new VoicesService(this.request);
  }
}
