<!--
TODO: "AZ" => User initals
TODO: Fetch text from DB
TODO: Align patient messages + icon right (as in typical messenger)
-->

<script setup>
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore } from '@/stores';
import { ref } from 'vue';

const authStore = useAuthStore();

const {
  message,
  conversation,
  showAvatar = false,
} = defineProps({
  message: Object,
  conversation: String,
  showAvatar: {
    type: Boolean,
    default: false,
  },
});

const bubbleRef = ref(null);
</script>

<template>
  <li class="max-w-fit flex justify-end gap-x-2 sm:gap-x-4">
    <div class="flex-col flex justify-end max-w-fit">
      <!-- Content -->
      <div ref="bubbleRef" class="inline-block bg-gray-950 rounded-2xl p-3 border border-black shadow-sm">
        <p class="text-base text-white">
          {{ message.content['user_message'] }}
        </p>
      </div>
      <!-- End Content -->
    </div>

    <!-- Icon - only show if showAvatar is true -->
    <span
      v-if="showAvatar"
      class="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-600"
    >
      <span class="text-sm font-medium text-white leading-none">
        <ProfileImage
          :image="authStore.userProfileImageSmall"
          :initials="authStore.userInitials"
          :showIngameLevel="true"
        />
      </span>
    </span>
    <!-- End of icon -->
  </li>
</template>

<style scoped></style>
