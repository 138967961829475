<script setup>
import ChatBubble from './ChatBubble.vue';
import AudioPlayerMultifile from '@/components/audio/AudioPlayerMultifile.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  dialog: Object,
  updateTimestamp: {
    type: String,
    default: '',
  },
  allowViewClosedCaptions: {
    type: Boolean,
    default: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
});

const audioPlayer = ref(null);
const externalScrollInProgress = ref(false);
const internalScrollInProgress = ref(false);
const showDialogAsChat = ref(props.allowViewClosedCaptions);
const activeMessageIndex = ref(0);
const audioFiles = computed(() => {
  console.log(props.dialog.messages);
  return props.dialog.messages?.map((message) => {
    // TTS'ed audies are found by their id, audio effects have a fixed url at their audio_effect_url column
    if (!message.audio_effect_url) {
      // let uuidHex = message.id.replace(/-/g, '');
      return `https://audiodata-prd.data.casuu.health/${message.id}.mp3?timestamp=${props.updateTimestamp}`;
    }
    return message.audio_effect_url;
  });
});

// Map to track which side each speaker appears on
const speakerSides = computed(() => {
  const sides = new Map();

  // Assign sides based on first appearance of each speaker
  props.dialog.messages.forEach((message) => {
    if (message.speaker && !sides.has(message.speaker.id)) {
      // If this is a new speaker, put them on the opposite side of the last speaker
      const lastSide = Array.from(sides.values()).pop();
      sides.set(message.speaker.id, lastSide === 'left' ? 'right' : 'left');
    }
  });

  return sides;
});

// Determine alignment for each message
const getMessageAlignment = (message) => {
  if (!message.speaker) {
    // Center non-speaker messages (like descriptions or audio effects)
    return 'center';
  }
  return speakerSides.value.get(message.speaker.id);
};

const scrollToMessage = (index) => {
  if (internalScrollInProgress.value) {
    return;
  }
  externalScrollInProgress.value = true;
  activeMessageIndex.value = index;
  const messageElement = document.getElementById(`message-${index}`);
  if (messageElement) {
    messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

const monitorScroll = (event) => {
  if (externalScrollInProgress.value) {
    return;
  }
  internalScrollInProgress.value = true;
  // get percentage of scroll
  const scrollPercentage = event.target.scrollTop / event.target.scrollHeight;
  audioPlayer.value.setProgressByPercentage(scrollPercentage * 100);
};
</script>

<template>
  <div class="flex-col flex justify-center px-4">
    <AudioPlayerMultifile :audioFiles="audioFiles" ref="audioPlayer" @trackChanged="scrollToMessage" />
    <button
      v-if="allowViewClosedCaptions || isEditing"
      class="rounded-lg transition-colors flex max-h-fit pt-1 pb-4 items-center"
      :class="showDialogAsChat ? 'text-blue-600 font-bold bg-blue-100/10' : 'text-gray-800'"
      @click="showDialogAsChat = !showDialogAsChat"
    >
      <span
        translate="no"
        class="material-symbols-outlined notranslate transition-all duration-300 pl-[0.5]"
        :style="`
            font-variation-settings:
              'FILL' 0,
              'wght' ${showDialogAsChat ? '300' : '200'},
              'GRAD' 0,
              'opsz' 24;
          `"
      >
        closed_caption
      </span>
      <span class="text-xs text-gray-400 font-light text-center pl-2">
        {{ showDialogAsChat ? 'Verbergen' : 'Zeigen' }}</span
      >
    </button>

    <div
      class="flex flex-col space-y-2 scroll-container overflow-y-auto transition-all duration-300"
      @scroll="monitorScroll"
      @scrollend="
        externalScrollInProgress = false;
        internalScrollInProgress = false;
      "
      :class="{
        'opacity-100 h-0': !showDialogAsChat,
        'opacity-100 h-[150px] ': showDialogAsChat,
      }"
    >
      <div v-for="(message, index) in props.dialog.messages" :key="message.id" :id="`message-${index}`">
        <ChatBubble
          :message="message"
          :leftAligned="getMessageAlignment(message) === 'left'"
          :centered="getMessageAlignment(message) === 'center'"
          :active="index === activeMessageIndex"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.scroll-container {
  scroll-behavior: smooth;
}

.animate-pop-out {
  animation: pop-out 800ms ease-in forwards;
}

.animate-pop-in {
  animation: pop-out 800ms ease-in reverse;
}

@keyframes pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  62.5% {
    transform: scale(0);
    opacity: 0;
    height: var(--original-height);
    margin-top: 0.5rem;
  }
  100% {
    transform: scale(0);
    opacity: 0;
    height: 0;
    margin-top: 0;
  }
}

.bg-blue-500 {
  will-change: width;
}

/* Smooth transitions for play head hover effects */
.group:hover .opacity-0 {
  transition-delay: 150ms;
}

/* Prevent text selection while dragging */
.cursor-grab,
.cursor-grabbing {
  user-select: none;
  -webkit-user-select: none;
}
</style>
