<script setup lang="ts">
import { Subtask, SubtaskInteraction, TaskInteraction } from '@/apiclient';
import ProfileImage from '@/components/ProfileImage.vue';
import { useCaseInteractionStore, useCaseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { Circle, CheckCircle, ListChecks } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
  hightlightNextSubtask: {
    type: Boolean,
    default: true,
  },
  delay: {
    type: Number,
    default: 0,
  },
  integrated: {
    type: Boolean,
    default: false,
  },
  showProfileImage: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['allSubtaskInteractionsCompletedAndAnimationFinished']);
const caseInteractionStore = useCaseInteractionStore();
const caseStore = useCaseStore();
const delayedSubtasks = ref([]);
const delayedCurrentSubtaskInteraction = ref<SubtaskInteraction | null>(null);
const delayedTaskInteraction = ref<TaskInteraction | null>(null);

const { currentSubtaskInteraction } = storeToRefs(caseInteractionStore);
const { isFormCase } = storeToRefs(caseStore);

const taskInteraction = computed(() => {
  if (!props.task) return null;
  return caseInteractionStore.currentCaseInteraction?.task_interactions[props.task.index];
});

onMounted(() => {
  if (!!props.task) {
    delayedSubtasks.value = props.task.subtasks ? JSON.parse(JSON.stringify(props.task.subtasks)) : [];
    delayedTaskInteraction.value = taskInteraction.value ? JSON.parse(JSON.stringify(taskInteraction.value)) : null;
  }
});

watch(
  () => taskInteraction.value,
  (newTaskInteraction) => {
    setTimeout(() => {
      delayedTaskInteraction.value = newTaskInteraction ? JSON.parse(JSON.stringify(newTaskInteraction)) : null;
    }, props.delay);
  },
  { deep: true, immediate: true },
);

const delayedSubtaskInteractionIsComplete = computed(() => {
  let completed: boolean[] = [];
  if (!delayedTaskInteraction.value) return completed;
  if (!delayedTaskInteraction.value.subtask_interactions) return completed;
  for (let i = 0; i < delayedTaskInteraction.value.subtask_interactions.length; i++) {
    completed.push(delayedTaskInteraction.value.subtask_interactions[i].completed_at !== null);
  }
  return completed;
});

const allSubtaskInteractionsCompletedAndAnimationFinished = computed(() => {
  return delayedSubtaskInteractionIsComplete.value.every((completed: boolean) => completed);
});

const profileImagePathOfFirstNonNullPersonForTask = () => {
  if (!props.task) return '';
  if (props.task.index === null) return '';

  let person = caseInteractionStore.getFirstNonNullPerson(props.task.index, t);
  return person?.profileImageSmall || '';
};

const numTask = computed(() => {
  if (!props.task) return '';
  if (props.task.index === null) return '';

  return props.task.index + 1;
});

const taskTitle = computed(() => {
  if (!props.task) return '';
  return props.task.title;
});

const taskDescription = computed(() => {
  if (!props.task) return '';
  return props.task.details.description ? props.task.details.description : '';
});

watch(
  () => props.task?.subtasks,
  (newSubtasks) => {
    setTimeout(() => {
      delayedSubtasks.value = newSubtasks ? JSON.parse(JSON.stringify(newSubtasks)) : [];
    }, props.delay);
  },
  { deep: true },
);

watch(
  () => currentSubtaskInteraction.value,
  (newCurrentSubtaskInteraction) => {
    setTimeout(() => {
      delayedCurrentSubtaskInteraction.value = newCurrentSubtaskInteraction
        ? JSON.parse(JSON.stringify(newCurrentSubtaskInteraction))
        : null;
    }, props.delay);
  },
  { deep: true },
);

watch(
  () => allSubtaskInteractionsCompletedAndAnimationFinished.value,
  (newCompletionState) => {
    if (!newCompletionState) return;
    emit('allSubtaskInteractionsCompletedAndAnimationFinished');
  },
);
</script>

<template>
  <div
    class="text-start rounded-2xl after:absolute after:top-0 after:-start-4 after:w-4 after:h-full"
    :class="{
      'bg-white border border-gray-100 shadow-md dark:bg-neutral-800 dark:border-neutral-700': !integrated,
      'bg-transparent border-none rounded-none shadow-none': integrated,
    }"
  >
    <!-- # {{ props.task?.index }}
    # {{ taskInteraction?.id }}
    # {{ taskInteraction?.index }}
    # {{ currentSubtaskInteraction?.id }}
    # {{ currentSubtaskInteraction?.index }}
    #########################################################
    # {{ delayedSubtasks }}
    #########################################################
    # {{ delayedTaskInteraction }} -->

    <!-- Popover Header -->
    <div class="mb-2" :class="showProfileImage ? 'px-4' : 'px-4'">
      <div class="flex items-start py-3">
        <div v-if="showProfileImage" class="items-center mr-3">
          <ProfileImage :image="profileImagePathOfFirstNonNullPersonForTask()" />
        </div>
        <div class="flex flex-col" :class="{ 'pl-0': !showProfileImage }">
          <p v-if="showProfileImage" class="flex grow text-sm text-gray-500 dark:text-neutral-500">
            Aufgabe {{ numTask }}
          </p>
          <h4 class="text-base font-semibold text-gray-800 dark:text-white">
            {{ taskTitle }}
          </h4>
          <p class="text-sm text-gray-600 dark:text-neutral-500">
            {{ taskDescription }}
          </p>
        </div>
      </div>
      <div class="h-px bg-gray-200 dark:bg-neutral-700"></div>
    </div>

    <!-- Popover List -->
    <div class="px-4 pb-4">
      <div class="flex items-center gap-2 mb-3">
        <ListChecks class="w-5 h-5 text-gray-600 dark:text-neutral-400" />
        <span class="text-sm font-semibold text-gray-600 dark:text-neutral-400 tracking-[0.005rem]">
          {{ $t('message.goals') }}
        </span>
      </div>
      <div class="space-y-3 flex-col flex">
        <!-- subtasks -->
        <div
          v-for="(subtask, index) in delayedSubtasks"
          :key="subtask.id"
          v-if="delayedSubtasks?.length === delayedTaskInteraction?.subtask_interactions?.length"
          class="flex items-start gap-2"
        >
          <span
            class="flex-shrink-0 mt-1 mx-0.5"
            :class="{
              'text-teal-600 dark:text-teal-400': delayedSubtaskInteractionIsComplete[index],
              'text-gray-600 dark:text-neutral-400': !delayedSubtaskInteractionIsComplete[index],
            }"
          >
            <Circle class="w-4 h-4" v-if="!delayedSubtaskInteractionIsComplete[index]" />
            <CheckCircle class="w-4 h-4" v-else />
          </span>
          <span
            class="text-sm"
            :class="{
              'text-green-600 dark:text-green-400': delayedSubtaskInteractionIsComplete[index],
              'text-gray-900 dark:text-neutral-200 font-medium':
                delayedTaskInteraction?.subtask_interactions[index].id === delayedCurrentSubtaskInteraction?.id,
              'text-gray-600 dark:text-neutral-400':
                delayedTaskInteraction?.subtask_interactions[index].id !== delayedCurrentSubtaskInteraction?.id &&
                !delayedSubtaskInteractionIsComplete[index],
            }"
          >
            {{ subtask.details.target }}
            <span
              class="ml-2 text-sm font-light text-gray-500 dark:text-neutral-500"
              v-if="delayedTaskInteraction?.subtask_interactions[index].id === delayedCurrentSubtaskInteraction?.id"
            >
              (Current)
            </span>
          </span>
        </div>

        <!-- additional subtask to fill and submit form -->
        <div v-if="isFormCase" class="flex items-start gap-2">
          <span class="flex-shrink-0 mt-1 mx-0.5 text-gray-600 dark:text-neutral-400">
            <Circle class="w-4 h-4" />
          </span>
          <span class="text-sm"> Formular ausfüllen und abschicken </span>
        </div>
      </div>
    </div>
  </div>
</template>
