<script setup lang="ts">
import Translation from '@/components/case_interaction/Translation.vue';
import ChatBubbleCompanion from '@/components/chat_bubbles/ChatBubbleCompanion.vue';
import { InteractionMessage } from '@/helper';
import { computed, PropType, ref } from 'vue';

// props
const props = defineProps({
  message: {
    type: Object as PropType<InteractionMessage>,
    required: true,
  },
  conversation: String,
  collapseIfNoTranslation: {
    type: Boolean,
    default: true,
  },
});

// refs
const translationComponent = ref<typeof Translation | null>(null);
const companionBubbleContainer = ref<HTMLDivElement | null>(null);

// emits
const emit = defineEmits(['onTranslationToggled']);

// state
const isShowingTranslation = ref(false);

// methods
function translationToggled(new_value: boolean) {
  console.debug('Footer: Translation mode toggled to: ' + new_value);
  isShowingTranslation.value = new_value;
  emit('onTranslationToggled', new_value);
}

const toggleTranslation = async () => {
  if (!translationComponent.value) return;
  await translationComponent.value.toggleTranslation();
};

const randomIntro = computed(() => {
  let possibleIntros = [
    'Formulierungsvorschlag',
    'Vorschlag',
    'Du könntest es so formulieren',
    'Du könntest es so formulieren',
    'Du könntest sagen',
  ];
  return possibleIntros[Math.floor(Math.random() * possibleIntros.length)] + ': ';
});

const recommendation = computed(() => {
  let tip = props.message?.translations?.['deu']?.['user_input_recommendation'];
  if (!tip) return '';
  return `${randomIntro.value}„${tip}"`;
});

const userInputRecommendationAsCompanionInteractionMessage = computed(() => {
  // this isolates the recommended user input and puts it into the form of
  // a CompanionInteractionMessage for display in the chat bubble
  return {
    content: {
      processed_model_output: recommendation.value,
    },
    created_at: 'just now',
    type: 'COMPANION-REACT',
  };
});

defineExpose({
  toggleTranslation,
});
</script>

<template>
  <div
    class="w-full flex-col transition-all duration-300"
    :class="{
      'max-h-0 opacity-0 overflow-hidden': collapseIfNoTranslation && !isShowingTranslation,
    }"
    @click="toggleTranslation"
  >
    <div class="h-0 w-0 overflow-hidden">
      <!-- TODO refactor this as a service class -->
      <Translation
        ref="translationComponent"
        :message="props.message"
        messageType="SAY-USER"
        :conversation="props.conversation"
        @onTranslationToggled="translationToggled"
      />
    </div>

    <div ref="companionBubbleContainer" class="w-full bottom-0 left-0 mt-4 pr-16">
      <ChatBubbleCompanion :message="userInputRecommendationAsCompanionInteractionMessage" />
    </div>
  </div>
</template>

<style scoped></style>
