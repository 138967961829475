<script setup lang="ts">
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import PairOfTerms from '@/components/didactics/pair_of_terms/PairOfTerms.vue';
import { ref, defineProps, defineEmits, computed, PropType, onMounted } from 'vue';
import { nextTick, Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAlertStore, useAuthStore } from '@/stores';
import DOMPurify from 'dompurify';
import { toRef } from 'vue';
import VocabExercise from '@/components/didactics/vocab/VocabExerciseNew.vue';
import CategorizationExercise from '@/components/didactics/categorization/CategorizationExercise.vue';
import FormStructuredExercise from '@/components/didactics/form_structured_exercise/FormStructuredExercise.vue';
import FormCaseExercise from '@/components/didactics/form_case_exercise/FormCaseExercise.vue';
import Cloze from '@/components/didactics/cloze/Cloze.vue';
import ResourceWithQuestions from '@/components/didactics/resource_with_questions/ResourceWIthQuestions.vue';
import SetPhraseList from '@/components/didactics/set_phrases/SetPhraseList.vue';
import ExerciseFinishedModal from '@/components/didactics/ExerciseFinishedModal.vue';
import ScoringExercise from '@/components/didactics/scoring/ScoringExercise.vue';
import ImageLabelingExercise from '@/components/didactics/labeling/ImageLabelingExercise.vue';
import { LearningObjective, SectionContentItemOverview } from '@/apiclient';
import { getApiClient } from '@/apiclient/client';
import CaseHighlightCardNew from '@/components/cases/CaseHighlightCardNew.vue';
import { MessagesSquare } from 'lucide-vue-next';
import ContentItem from '@/components/didactics/content_item/ContentItem.vue';
import TextContent from '@/components/didactics/text/TextContent.vue';
import Title from '@/components/didactics/title/Title.vue';
import LearningObjectiveJournalling from '@/components/didactics/journalling/LearningObjectiveJournalling.vue';

const props = defineProps({
  contentItems: {
    type: Array<SectionContentItemOverview>,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  learningObjectives: {
    type: Array<LearningObjective>,
    required: false,
  },
  chapterId: {
    type: String,
    required: true,
  },
  allowEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  aboutToDeleteContentItemWithId: {
    type: [String, null] as PropType<string | null>, // can be null or String
    required: false,
  },
  aboutToDeleteTextItemAtIndex: {
    type: Array,
    required: false,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  externalFetchCompleted: {
    type: Boolean,
    required: true,
  },
  showNative: {
    type: Boolean,
    required: false,
  },
  outerWidth: {
    type: Number,
    required: true,
  },
  vocabListLoading: {
    type: Boolean,
    required: false,
  },
  isTransitioning: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'signalUnsavedChangesLearningObjectives',
  'onExtractLearningObjectives',
  'onDeleteContentItem',
  'showNativeToggled',
  'generateAndAddMoreVocab',
  'scrollBy',
  'undoLastScroll',
]);

const contentEditors = ref<Array<Ref<HTMLElement | null>>>([]);
const fullWidthIndex = ref(-1);
const fullHeightIndex = ref(-1);

const alertStore = useAlertStore();

// refs for translation column
const reactiveShowNative = toRef(props, 'showNative');
const germanContentContainers = ref<Array<Ref<HTMLElement | null>>>([]); // Array of refs for each text item container
const pairOfTermsGameContainers = ref([]);
const authStore = useAuthStore();
const addVocabForSectionContentItemId = ref(null as string | null);
const { userNativeLanguage } = storeToRefs(authStore);

// computes for translation column
const gap = computed(() => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth', screenWidth);
  if (screenWidth < 640) {
    return 8;
  } else if (screenWidth < 1024) {
    return 20;
  }
  return Math.min(50, Math.round(screenWidth * 0.025));
});

const relativeCharacterLength = computed(() => {
  if (userNativeLanguage.value === 'aeb') return 0.85;
  if (userNativeLanguage.value === 'pes') return 0.85;
  if (userNativeLanguage.value === 'rus' || userNativeLanguage.value === 'tgk') return 1.1;
  return 1.0;
});

const sanitizedSectionContentsHtml = computed(() => {
  return textContentItems.value.map((item) => DOMPurify.sanitize(item?.content ?? ''));
});

const translationAvailable = computed(() => {
  // TODO this needs to be re-implemented for text items
  return textContentItems.value.map(
    (item) => false,
    // !!props.section.content_translations
    // && !!props.section.content_translations[userNativeLanguage.value],
  );
});

const germanContainerWidths = computed(() => {
  return sanitizedSectionContentsHtml.value.map((content, index) => {
    if (!props.outerWidth) return 0;
    const translatedContent = translatedContentIfAny.value[index];
    if (!(content.length + translatedContent.length)) return 0;

    if (!reactiveShowNative.value) {
      return props.outerWidth;
    }
    if (!translationAvailable.value[index]) {
      // marginal column to display 'no translation available'
      return 0.7 * props.outerWidth;
    }
    if (content.length === 0) {
      return 0.7 * props.outerWidth;
    }
    // show German original and translation side by side
    return Math.floor(
      ((props.outerWidth - 2 * gap.value) * content.length) /
        (content.length + translatedContent.length * relativeCharacterLength.value),
    );
  });
});

const translatedContentIfAny = computed(() => {
  return textContentItems.value.map((item) => {
    if (!textContentItems.value.content_translations) return 'No translation available';
    const translation = textContentItems.value.content_translations[userNativeLanguage.value];
    return translation ? DOMPurify.sanitize(translation) : 'No translation available';
  });
});

const nativeContainerInnerWidths = computed(() => {
  return germanContainerWidths.value.map((germanWidth) => {
    if (!props.outerWidth) return 0;
    return props.outerWidth - 2 * gap.value - germanWidth;
  });
});

const nativeContainerOuterWidths = computed(() => {
  return nativeContainerInnerWidths.value.map((innerWidth) => innerWidth + gap.value);
});

const boxReducedWidth = computed(() => {
  // Check if the window width is greater than the MD breakpoint
  return window.innerWidth > 768 ? 200 : 40;
});

const toggleFullWidth = (index: number) => {
  console.log('toggleFullWidth', index);
  if (fullWidthIndex.value === index) {
    fullWidthIndex.value = -1;
  } else {
    fullWidthIndex.value = index;
  }
};

const nativeContainerTranslations = computed(() => {
  return germanContainerWidths.value.map((germanWidth, index) => {
    if (!reactiveShowNative.value) {
      return props.outerWidth;
    }

    // Translation width considering the width with the native language shown
    return props.outerWidth - nativeContainerOuterWidths.value[index];
  });
});

const fetchCompleted = computed(() => {
  return props.externalFetchCompleted;
});

const textContentItems = computed(() => {
  return props.contentItems
    .filter((contentItem) => contentItem.content_type === 'TEXT')
    .map((contentItem) => contentItem.text_item);
});

const textItemIndex = (index: number) => {
  // map index in props.contentItems to index in textContentItems
  return textContentItems.value.findIndex((textItem) => textItem?.id === props.contentItems[index].text_item?.id);
};

const pairOfTermsGameContentItems = computed(() => {
  return props.contentItems
    .filter((contentItem) => contentItem.content_type === 'PAIR_OF_TERMS_GAME')
    .map((contentItem) => contentItem.pair_of_terms_game);
});

// functions for editing mode
const setEditorRefs = async () => {
  if (!props.contentItems) {
    return;
  }
  if (props.contentItems) {
    contentEditors.value.length = 0;
    germanContentContainers.value.length = 0;
    for (let contentItem of props.contentItems) {
      if (contentItem.content_type === 'TEXT') {
        contentEditors.value.push(ref<HTMLElement | null>(null));
        germanContentContainers.value.push(ref<HTMLElement | null>(null));
      }
    }
  }
  console.log('Now holding # content editors: ' + contentEditors.value.length);
  await nextTick();
};

const setPairOfTermsGameRefs = async () => {
  console.log('Setting pair of terms game refs');
  if (!props.contentItems) {
    return;
  }
  pairOfTermsGameContainers.value.length = 0;
  for (let contentItem of props.contentItems) {
    if (contentItem.content_type === 'PAIR_OF_TERMS_GAME') {
      pairOfTermsGameContainers.value.push(ref(null));
    }
  }
  console.log('Now holding # pair of terms game containers: ' + pairOfTermsGameContainers.value.length);
  await nextTick();
};

onMounted(async () => {
  await setPairOfTermsGameRefs();
  await setEditorRefs();
});

const resetTextEditorEmitStates = async () => {
  // wait 10 ms
  await new Promise((resolve) => setTimeout(resolve, 10));
  console.log('length of contentEditors: ' + contentEditors.value.length);
  contentEditors.value.forEach((editor) => {
    console.log(editor);
    console.log(editor.value);
  });
  contentEditors.value.forEach((editor) => {
    editor.value[0].resetEmitState();
  });
};

const addNewPersonalVocab = (sectionContentItemId: string) => {
  console.log('addNewPersonalVocab', sectionContentItemId);
  addVocabForSectionContentItemId.value = sectionContentItemId;
};

const addNewPersonalVocabFromVocabExercise = async (contentItem: SectionContentItemOverview) => {
  // directly add, no modal
  if (!contentItem.meaning_and_making_exercise) {
    alertStore.error('Fehler beim Speichern der Vokabeln aus der Übung - keine Übung', 'Error');
    return;
  }
  let examinedVocabItems = contentItem.meaning_and_making_exercise.vocab_list.vocab_items;
  let usersWortschatzkisteId = authStore.user.vocab_lists[0].id;

  let errorWhenAdding = false;

  for (const vocab of examinedVocabItems) {
    await (await getApiClient()).vocabLists
      .copyVocabToPersonalVocabList(usersWortschatzkisteId, vocab.id)
      .catch((error) => {
        alertStore.error('Failed to add vocab item to Wortschatzkiste', 'Error', error);
        errorWhenAdding = true;
      });
  }

  if (!errorWhenAdding) {
    let message = '';
    if (examinedVocabItems.length === 1) {
      message = 'Eine neue Vokabel wurde in deine Wortschatzkiste übernommen';
    } else {
      message = 'Toll, ' + examinedVocabItems.length + ' neue Begriffe wurden in deine Wortschatzkiste übernommen!';
    }
    alertStore.success(message);
  }
};

defineExpose({
  setEditorRefs,
  setPairOfTermsGameRefs,
  resetTextEditorEmitStates,
  contentEditors,
});
</script>

<template>
  <!-- Content -->
  <div
    class="min-w-full mx-auto grid grid-flow-row grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-2 md:gap-x-6 gap-y-8 pb-3 mb-20"
  >
    <ContentItem
      v-for="(contentItem, index) in props.contentItems"
      :key="contentItem.id"
      :contentItem="contentItem"
      :allowEditing="props.allowEditing"
      :fullWidthIndex="fullWidthIndex"
      :index="index"
      @onDeleteContentItem="(id, type, index) => emit('onDeleteContentItem', id, type, index)"
    >
      <TextContent
        v-if="contentItem.content_type === 'TEXT'"
        :index="index"
        :contentItem="contentItem"
        :sectionId="props.sectionId"
        :sectionIndex="props.sectionIndex"
        :pageIndex="props.pageIndex"
        :isEditing="props.allowEditing"
        :germanContentContainers="germanContentContainers"
        :textItemIndex="textItemIndex(index)"
        :germanContainerWidths="germanContainerWidths"
        :nativeContainerTranslations="nativeContainerTranslations"
        :aboutToDeleteTextItemAtIndex="aboutToDeleteTextItemAtIndex"
        :nativeContainerOuterWidths="nativeContainerOuterWidths"
        :nativeContainerInnerWidths="nativeContainerInnerWidths"
        :translatedContentIfAny="translatedContentIfAny"
        :aboutToDeleteContentItemWithId="aboutToDeleteContentItemWithId"
      />

      <div
        v-if="
          contentItem.content_type === 'MEANING_AND_MAKING' &&
          !!contentItem.meaning_and_making_exercise?.set_phrase_list_id
        "
        class="relative flex w-full max-h-fit mx-auto h-fit divide-gray-400 justify-between transition-all duration-500 ease-in-out overflow-hidden"
        :key="
          !!contentItem.meaning_and_making_exercise.set_phrase_list
            ? contentItem.meaning_and_making_exercise.set_phrase_list.id
            : 'invalid'
        "
        :style="{
          width: `${props.outerWidth - boxReducedWidth}px`,
        }"
      >
        <SetPhraseList
          :contentItem="contentItem"
          :boxReducedWidth="boxReducedWidth"
          :germanContainerWidth="germanContainerWidths[textItemIndex(index)]"
          :sectionId="props.sectionId"
          :sectionIndex="props.sectionIndex"
          :pageIndex="props.pageIndex"
          :isEditing="props.allowEditing"
          :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
        />
      </div>

      <div class="flex min-w-full h-full relative" v-else-if="contentItem.content_type === 'MEDIA'" :key="index">
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <MediaHighlightCard
          v-if="fetchCompleted"
          :contentItemId="contentItem.id"
          :url="contentItem.media_item?.media_url"
          :type="contentItem.media_item?.media_item_type"
          :description="contentItem.media_item?.description"
          :alwaysMaximized="contentItem.media_item?.always_maximized"
          :allowEdit="props.allowEditing"
          :maxOnClick="true"
          :sectionIndex="props.sectionIndex"
          :pageIndex="props.pageIndex"
          :isMaximized="fullWidthIndex === index"
          @removeMediaItem="emit('onDeleteContentItem', contentItem.id, 'MEDIA', null)"
          @toggleFullWidth="toggleFullWidth(index)"
        />
        <span
          v-show="fullWidthIndex !== index"
          class="absolute top-0 start-0 bg-gray-900/50 p-0.5 rounded-lg pointer-events-none"
        >
          <span class="text-white text-center"> Klicken für Vollansicht </span>
        </span>
      </div>

      <div
        class="w-full md:w-2/3 mx-auto h-full rounded-lg relative"
        v-else-if="contentItem.content_type === 'PAIR_OF_TERMS_GAME'"
        :key="!!contentItem.pair_of_terms_game ? contentItem.pair_of_terms_game.id : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <PairOfTerms
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :allowEdit="props.allowEditing"
              :isMaximized="true"
              :fixedMaximization="true"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @scrollBy="(distance) => emit('scrollBy', distance)"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
            />
          </div>
          <button
            v-show="false && fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Übung
            <!--            <span v-show="pairOfTermsGameContainerMaximizedHeights > 295">(Klicken zum Ausklappen)</span>-->
          </button>
        </div>
      </div>

      <div
        class="mx-auto h-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'CATEGORIZATION_EXERCISE'"
        :key="!!contentItem.categorization_exercise ? contentItem.categorization_exercise.id : 'invalid'"
        :class="{
          'w-full md:w-2/3': false && fullHeightIndex !== index,
          'w-full': true || fullHeightIndex === index,
        }"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <CategorizationExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :allowEdit="props.allowEditing"
              :isMaximized="true"
              :fixedMaximization="true"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @scrollBy="(distance) => emit('scrollBy', distance)"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
            />
          </div>
          <button
            v-show="false && fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Übung (Klicken zum Ausklappen)
          </button>
        </div>
      </div>

      <div
        class="mx-auto w-full h-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'CLOZE'"
        :key="!!contentItem.cloze ? contentItem.cloze.id : 'invalid'"
      >
        <div class="flex-col flex">
          <div class="">
            <Cloze
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :isEditing="<boolean>props.allowEditing"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto w-full h-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'RESOURCE_WITH_QUESTIONS'"
        :key="!!contentItem.resource_with_questions ? contentItem.resource_with_questions.id : 'invalid'"
      >
        <div class="flex-col flex">
          <div class="">
            <ResourceWithQuestions
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
              @scrollBy="(distance) => emit('scrollBy', distance)"
              @undoLastScroll="emit('undoLastScroll')"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto h-full w-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'FORM_CASE'"
        :key="!!contentItem.form_structured_exercise ? contentItem.form_structured_exercise.id : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <FormCaseExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto h-full w-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'FORM_STRUCTURED_EXERCISE'"
        :key="!!contentItem.form_structured_exercise ? contentItem.form_structured_exercise.id : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <FormStructuredExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :index="<number>index"
              :sectionIndex="<number>props.sectionIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
            />
          </div>
        </div>
      </div>

      <!--           v-else-if="contentItem.content_type === 'VOCAB_LIST'" -->
      <!--          :key="contentItem.vocab_list.id" -->
      <div
        class="mx-auto h-full relative transition-all transition-1000 ease-in-out"
        v-else-if="
          contentItem.content_type === 'MEANING_AND_MAKING' && !!contentItem.meaning_and_making_exercise?.vocab_list_id
        "
        :class="{
          'w-full md:w-2/3': false && fullHeightIndex !== index,
          'w-full': true || fullHeightIndex === index,
        }"
        :key="!!contentItem ? contentItem.id + '-vocab' : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="" v-if="fetchCompleted">
            <VocabExercise
              :contentItem="contentItem"
              :index="<number>index"
              :sectionIndex="props.sectionIndex"
              :sectionId="props.sectionId"
              :pageIndex="props.pageIndex"
              :showNative="props.showNative"
              :isMaximized="true"
              :fixedMaximization="true"
              :allowEditing="props.allowEditing"
              :outerHeaderHeight="props.outerHeaderHeight"
              :vocab-list-loading="props.vocabListLoading"
              :is-deleted="aboutToDeleteContentItemWithId === contentItem.id"
              :is-transitioning="props.isTransitioning"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @generateAndAddMoreVocab="emit('generateAndAddMoreVocab', contentItem.id)"
              @scrollBy="(distance) => emit('scrollBy', distance)"
              @addNewVocab="addNewPersonalVocabFromVocabExercise(contentItem)"
            />
          </div>
          <button
            v-show="false && fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Vokabeln (Klicken zum Ausklappen & Üben)
          </button>
        </div>
      </div>

      <!-- ordering -->
      <!-- TODO add ordering exercise -->

      <!-- scoring -->
      <div
        class="mx-auto h-full w-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'SCORING_EXERCISE'"
        :key="!!contentItem.scoring_exercise ? contentItem.scoring_exercise.id : 'invalid'"
      >
        <div class="flex-col flex">
          <div class="">
            <ScoringExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :sectionId="props.sectionId"
              :allowEditing="<boolean>props.allowEditing"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
            />
          </div>
        </div>
      </div>

      <!-- image labeling -->
      <div
        class="mx-auto h-full w-full rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'IMAGE_LABELING_EXERCISE'"
        :key="!!contentItem.image_labeling_exercise ? contentItem.image_labeling_exercise.id : 'invalid'"
      >
        <div class="flex-col flex">
          <div class="">
            <ImageLabelingExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :pageIndex="<number>props.pageIndex"
              :sectionId="props.sectionId"
              :allowEditing="<boolean>props.allowEditing"
              @addNewVocab="addNewPersonalVocab(contentItem.id)"
            />
          </div>
        </div>
      </div>

      <!-- case -->
      <div
        class="mx-auto w-full h-full flex-col flex relative"
        v-else-if="contentItem.content_type === 'CASE' && !!contentItem.case"
        :key="contentItem.case.id"
      >
        <Title :iconComponent="MessagesSquare" headline="Fallübung" />

        <div class="z-0 px-4">
          <CaseHighlightCardNew
            v-if="fetchCompleted"
            :case="contentItem.case"
            :allowRouteToCase="true"
            @caseStarted="() => {}"
          />
        </div>
      </div>

      <!-- journal -->
      <div class="mx-auto w-full h-full flex-col flex relative" v-else-if="contentItem.content_type === 'JOURNAL'">
        <LearningObjectiveJournalling
          v-if="fetchCompleted"
          :learningObjectives="props.learningObjectives"
          :contentItem="contentItem"
          :sectionId="props.sectionId"
          :sectionIndex="props.sectionIndex"
          :pageIndex="props.pageIndex"
          :isEditing="props.allowEditing"
        />
      </div>
    </ContentItem>

    <ExerciseFinishedModal
      v-if="addVocabForSectionContentItemId"
      :contentItemId="addVocabForSectionContentItemId"
      @closed="addVocabForSectionContentItemId = null"
    />
  </div>
</template>

<style scoped></style>
