<script setup lang="ts">
import { ref, nextTick, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { useAlertStore, useAuthStore, useCaseInteractionStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { HSOverlay } from 'preline';
import confetti from 'canvas-confetti';
import VocabListNew from '@/components/didactics/vocab/VocabListNew.vue';
import SuccessAnimation from '@/components/SuccessAnimation.vue';
import { useRouter } from 'vue-router';
import { getApiClient } from '@/apiclient/client';

import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '../LoadingSpinnerLarge.vue';
import { GraduationCap, BookOpenText } from 'lucide-vue-next';
import GoalsCard from '@/components/case_interaction/GoalsCard.vue';
import CaseService from '@/services/CaseService';

const { t } = useI18n();

const props = defineProps({
  previousPath: {
    type: [String, null],
    required: false,
    default: null,
  },
});

const emit = defineEmits(['closed']);

// Main cpt refs
const modal = ref(null);
const router = useRouter();

// Stores and store references
const caseInteractionStore = useCaseInteractionStore();
const { currentTask, currentCaseInteractionId } = storeToRefs(caseInteractionStore);
const courseInteractionStore = useCourseInteractionStore();
// Refs for component data
const playSuccessAnimation = ref(false);
const canBeClosed = ref(false);
const authStore = useAuthStore();
const alertStore = useAlertStore();
const routeBackToCourse = ref({});
const suggestedVocabs = ref([]);
const temporaryVocabList = ref({});

const close = async () => {
  let usersActiveListId = authStore.user.vocab_lists[1].id;
  let errorWhenAdding = false;

  if (!temporaryVocabList.value.vocab_items) {
    HSOverlay.close('#case-finished-modal');
    return;
  }

  for (const vocab of temporaryVocabList.value.vocab_items) {
    await (await getApiClient()).vocabLists.copyVocabToPersonalVocabList(usersActiveListId, vocab.id).catch((error) => {
      alertStore.error('Failed to add vocab item to Wortschatzkiste', 'Error', error);
      errorWhenAdding = true;
    });
  }

  HSOverlay.close('#case-finished-modal');

  if (!errorWhenAdding) {
    let message = '';
    if (temporaryVocabList.value.vocab_items.length === 1) {
      message = 'Eine neuer Begriff wurde in deine Wortschatzkiste übernommen';
    } else {
      message =
        'Toll, ' +
        temporaryVocabList.value.vocab_items.length +
        ' neue Begriffe wurden in deine Wortschatzkiste übernommen!';
    }
    alertStore.success(message);
  }
};

// Lifecycle hooks
onMounted(async () => {
  await HSOverlay.autoInit(); // we need to do this in order to make modals for components work which are mounted AFTER the app is initialized and AFTER the router re-initialized HSStaticMethods.autoInit()

  // Get the modal element and initialize it
  const modalElement = document.querySelector('#case-finished-modal');
  modal.value = HSOverlay.getInstance(modalElement);

  // If no instance exists, create one
  if (!modal.value) {
    modal.value = new HSOverlay(modalElement);
  }

  await nextTick();

  modal.value.addEventListener('close.hs.overlay', () => {
    console.debug('closing dialog');
    emit('closed');
  });
});

onBeforeUnmount(() => {});

// Methods
const show = async () => {
  await nextTick();

  console.log(modal.value);
  HSOverlay.open('#case-finished-modal'); // works

  console.log('opened modal');

  await new Promise((resolve) => setTimeout(resolve, 500));
  playSuccessAnimation.value = true;

  const vocabs = await (
    await getApiClient()
  ).vocabs.createVocabItemsFromCaseInteraction(currentCaseInteractionId.value);
  suggestedVocabs.value = vocabs;

  temporaryVocabList.value = {
    vocab_items: suggestedVocabs.value,
  };

  await new Promise((resolve) => setTimeout(resolve, 300));
  confetti({
    particleCount: 100,
    spread: 70,
    origin: {
      y: 0.4,
    },
  });

  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 140,
    spread: 70,
    origin: {
      y: 0.1,
      x: 0.5,
    },
  });

  await new Promise((resolve) => setTimeout(resolve, 100));
  confetti({
    particleCount: 250,
    spread: 70,
    origin: {
      y: 0.5,
      x: 0.55,
    },
  });

  setTimeout(() => {
    canBeClosed.value = true;
  }, 1200);
};

const backToOrigin = async () => {
  if (props.previousPath) {
    await router.push(props.previousPath);
    return;
  }
  await router.push('/home');
};

const isComingFromCourse = computed(() => {
  let courseRelatedRoutes = ['/course/', '/chapter/', '/section/'];
  return props.previousPath && courseRelatedRoutes.some((route) => props.previousPath!.includes(route));
});

const discardVocab = (vocabId) => {
  temporaryVocabList.value.vocab_items = temporaryVocabList.value.vocab_items.filter((vocab) => vocab.id !== vocabId);
};

defineExpose({
  show,
});
</script>

<template>
  <div
    id="case-finished-modal"
    class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[100] overflow-x-hidden overflow-y-hidden pointer-events-none"
  >
    <div
      data-testid="case-finished-modal"
      class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 ease-out transition-all w-full h-full"
    >
      <div
        ref="modalWindow"
        class="flex w-full h-full items-start justify-center bg-white dark:bg-neutral-900 pointer-events-auto"
      >
        <!-- Close button -->
        <button
          type="button"
          class="fixed top-4 left-4 flex justify-center items-center w-10 h-10 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 z-10"
          data-hs-overlay="#case-finished-modal"
          @click="close"
        >
          <span class="sr-only">Close</span>
          <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <!-- Scrollable content -->
        <div class="w-full h-full overflow-y-auto pt-16 pb-24">
          <div class="w-full max-w-4xl mx-auto px-6">
            <h1 class="text-[40px] font-semibold text-center mb-8">{{ t('message.goodJobCaseClosed') }}</h1>

            <GoalsCard :with-title="true" />

            <div class="px-4 py-2 text-gray-800">
              <h3 class="text-medium font-normal pb-2 -mt-12">
                {{ t('message.addedVocab', { count: suggestedVocabs.length ? suggestedVocabs.length : 3 }) }}
              </h3>
              <div v-if="suggestedVocabs.length > 0" class="flex justify-center min-w-full">
                <VocabListNew
                  :vocabList="temporaryVocabList"
                  :start-with-first-opened="false"
                  :show-trash-as-remove-icon="true"
                  :enable-scroll="false"
                  @delete-item="discardVocab"
                />
              </div>
              <div v-else class="flex w-full justify-center py-6">
                <LoadingSpinnerLarge color="black" />
              </div>
            </div>
          </div>
        </div>

        <!-- Fixed Footer -->
        <div
          class="fixed bottom-0 left-0 right-0 py-6 px-6 gap-x-4 flex justify-between bg-white dark:bg-neutral-800 pointer-events-auto"
        >
          <!-- Back Home Button -->
          <button
            v-if="!isComingFromCourse"
            :disabled="!canBeClosed"
            @click="
              async () => {
                await close();
                backToOrigin();
              }
            "
            class="inline-flex items-center gap-2 px-4 py-3.5 text-base font-medium text-gray-700 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
            {{ t('message.back') }}
          </button>
          <div v-else class="w-1 h-0 opacity-0"></div>

          <!-- Next Patient Button -->
          <button
            :disabled="!canBeClosed"
            @click="
              async () => {
                await close();
                if (isComingFromCourse) {
                  backToOrigin();
                  return;
                }
                // play the current random case, then get a fresh one
                await CaseService.playRandomCase(true, true);
              }
            "
            class="inline-flex items-center gap-2 px-4 py-3.5 text-base font-medium text-white bg-black rounded-full hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <GraduationCap v-if="!isComingFromCourse" class="w-5 h-5" />
            <BookOpenText v-else class="w-5 h-5" />
            <span v-if="!isComingFromCourse">{{ t('message.nextCase') }}</span>
            <span v-else>{{ t('message.backToCourse') }}</span>
          </button>
        </div>
      </div>
    </div>

    <SuccessAnimation :show="playSuccessAnimation" @complete="playSuccessAnimation = false" />
  </div>
</template>

<style scoped>
@keyframes grow-pop {
  0% {
    font-size: 24px; /* Initial text size */
    opacity: 1;
  }
  50% {
    font-size: 30px; /* Pop out to larger size */
    opacity: 1;
  }
  100% {
    font-size: 0px; /* Shrink to disappear */
    opacity: 0;
  }
}

.animate-grow-pop {
  animation: grow-pop 300ms ease-out;
}
</style>
