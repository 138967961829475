<script setup>
import { Languages } from 'lucide-vue-next';

const emit = defineEmits(['takeDailyQuickTest']);

function startDailyTest() {
  emit('takeDailyQuickTest');
}
</script>

<template>
  <div
    @click="startDailyTest"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-2xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col justify-between w-[148px] h-[148px]"
  >
    <div class="flex justify-start">
      <span class="text-sm font-medium text-gray-600 dark:text-gray-400">{{ $t('message.vocabDailyTest') }}</span>
    </div>
    <div class="flex items-center justify-center flex-1">
      <button
        class="inline-flex items-center gap-1 pl-3 pr-4 py-2 bg-white text-gray-800 font-semibold rounded-full hover:bg-gray-50"
      >
        <Languages size="20" class="dark:text-white rounded" />
        {{ $t('message.start') }}
      </button>
    </div>
    <div class="flex justify-end">
      <span class="text-sm">+175 XP</span>
    </div>
  </div>
</template>
