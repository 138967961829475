<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { debounce } from 'lodash';
import MediaUploadCard from '@/components/didactics/media/MediaUploadCard.vue';

const alertStore = useAlertStore();

const fetchFinished = ref(false);
const uploadInProgress = ref(false);

const approved = ref(false);
const isDragging = ref(false);

const allowedMediaTypes = ref([]);
const allowedMediaFormats = ref([]);
const allowedMediaSizes = ref([]);

const emit = defineEmits(['uploadedItem']);

const props = defineProps({
  contentItemId: {
    type: String,
    required: true,
  },
});

async function uploadFile(event) {
  uploadInProgress.value = true;
  console.log(event.target.files || event.dataTransfer.files);
  const files = event.target.files || event.dataTransfer.files;
  for (let file of files) {
    await (
      await getApiClient()
    ).courseSectionItems
      .uploadMediaItemForUseInExercise(props.contentItemId, { file }, 'Klicken, um Beschreibung hinzuzufügen')
      .then((response) => {
        alertStore.success('Upload successful');
        emit('uploadedItem', response.id, response.media_item_type, response.media_url);
      })
      .catch((error) => {
        alertStore.error('Upload failed', 'Error', error);
        throw Error('Upload failed: ', error);
      })
      .finally(() => {
        uploadInProgress.value = false;
      });
    // note to self: { file } is shorthand for { 'file': file }
  }
}
</script>

<template>
  <MediaUploadCard
    :upload-file="uploadFile"
    border-rounded="rounded-lg"
    :always-maximized="false"
    :upload-in-progress="uploadInProgress"
  />
</template>
