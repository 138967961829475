<script setup>
import { useAlertStore, useAuthStore } from '@/stores';
import { ref } from 'vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { useForm } from 'vee-validate';
import ProgressButton from '@/components/ProgressButton.vue';
import { useI18n } from 'vue-i18n';
import { Eye, EyeOff } from 'lucide-vue-next';
const { t } = useI18n();

const authStore = useAuthStore();
const isSubmitting = ref(false);
const alertStore = useAlertStore();
const showPassword = ref(false);

const initialValues = {
  email: '',
  password: '',
};

function handleLogin(values, { setErrors, controlledValues }) {
  if (isSubmitting.value) {
    return; // Prevent multiple submissions
  }
  isSubmitting.value = true;

  console.debug('username: ' + controlledValues.email, 'password: ***');
  authStore
    .login(controlledValues.email, controlledValues.password, false, t)
    .then(() => {
      console.debug('login successful');
      isSubmitting.value = false;
    })
    .catch((error) => {
      console.debug('login failed');
      isSubmitting.value = false;
      alertStore.error(t('status.error.emailOrPasswordIncorrectHelp'), t('status.error.emailOrPasswordIncorrect'));
      setErrors({
        email: t('status.error.emailOrPasswordIncorrect'),
        password: t('status.error.emailOrPasswordIncorrect'),
      });
    })
    .finally(() => {
      isSubmitting.value = false;
    });
}

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}
</script>

<template>
  <main
    class="min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100 dark:from-neutral-900 dark:to-neutral-800 p-4"
  >
    <div
      class="w-full max-w-md bg-white border-0 rounded-3xl shadow-lg dark:bg-neutral-800 dark:border-gray-700 overflow-hidden transition-all duration-300"
    >
      <div class="p-6 sm:p-10">
        <div class="text-center mb-8">
          <h1 class="text-3xl font-bold text-gray-800 dark:text-white mb-3">
            {{ $t('message.signInWithYourAccount') }}
          </h1>
          <p class="text-gray-600 dark:text-gray-300">
            {{ $t('message.noAccountYet') }}
            <router-link to="/account/sign-up" class="text-blue-600 hover:text-blue-700 font-medium transition-colors">
              {{ $t('message.signUpNow') }}
            </router-link>
          </p>
        </div>

        <div>
          <!-- Form -->
          <Form @submit="handleLogin" v-slot="{ errors }" :initial-values="initialValues" ref="loginForm">
            <div class="space-y-6">
              <!-- Email Field -->
              <div>
                <label for="email" class="block text-sm font-medium mb-2 dark:text-white">{{
                  $t('message.email')
                }}</label>
                <div class="relative">
                  <Field
                    :disabled="isSubmitting"
                    name="email"
                    type="email"
                    rules="email|required"
                    :class="{
                      'border-red-500 ring-red-500': errors.email,
                      'border-gray-300 focus:border-blue-600 focus:ring-blue-600': !errors.email,
                    }"
                    class="py-4 px-4 block w-full rounded-xl text-base shadow-sm transition-all duration-200 dark:bg-neutral-700 dark:border-gray-600 dark:text-white"
                    placeholder="name@example.com"
                  />
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="email" />
                </div>
              </div>

              <!-- Password Field -->
              <div>
                <div class="flex justify-between items-center mb-2">
                  <label for="password" class="block text-sm font-medium dark:text-white">{{
                    $t('message.password')
                  }}</label>
                  <router-link
                    to="/account/reset-password"
                    tabindex="-1"
                    class="text-sm text-blue-600 hover:text-blue-700 font-medium transition-colors"
                    >{{ $t('message.forgotPassword') }}</router-link
                  >
                </div>
                <div class="relative">
                  <Field
                    class="py-4 px-4 block w-full rounded-xl text-base shadow-sm transition-all duration-200 dark:bg-neutral-700 dark:border-gray-600 dark:text-white pr-12"
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    rules="required"
                    :class="{
                      'border-red-500 ring-red-500': errors.password,
                      'border-gray-300 focus:border-blue-600 focus:ring-blue-600': !errors.password,
                    }"
                  />
                  <button
                    type="button"
                    @click="togglePasswordVisibility"
                    class="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 dark:text-gray-400 focus:outline-none"
                  >
                    <Eye v-if="showPassword" class="w-5 h-5" />
                    <EyeOff v-else class="w-5 h-5" />
                  </button>
                  <ErrorMessage class="text-sm text-red-600 mt-2" name="password" />
                </div>
              </div>

              <ProgressButton
                data-testid="login-submit"
                :text="$t('message.signIn')"
                :showProgress="isSubmitting"
                type="submit"
                size="lg"
                class="w-full py-4 px-8 text-lg font-medium transition-all duration-200 shadow-md hover:shadow-lg"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  </main>
</template>
