<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  shallowRef,
  watch,
} from 'vue';
import { router } from '@/router';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import CoursePage from '@/views/courses/CoursePage.vue';
import EditCoursePage from '@/views/courses/EditCoursePage.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import CourseSectionTitlePage from '@/views/courses/CourseSectionTitlePage.vue';
import SectionContentReorderModal from '@/components/courses/SectionContentReorderModal.vue';
import { getApiClient } from '@/apiclient/client';
import BottomNav from '@/components/BottomNav.vue';
import { useSwipe } from '@vueuse/core';
import { useRoute } from 'vue-router';
import CourseNav from '@/views/courses/CourseNav.vue';
import CourseService from '@/services/CourseService';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
const courseStore = useCourseStore();
const { currentCourse, currentChapter, currentChapterSortedSections, currentChapterTitle } = storeToRefs(courseStore);
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);

const courseInteractionStore = useCourseInteractionStore();

const alertStore = useAlertStore();
const isEditing = ref(false);
const isLoading = ref(false);
const innerHeader = ref(null);
const swipeContainer = shallowRef<HTMLElement | null>(null);
const sectionId = ref('');
const pageTransitionName = ref('slide-right');
const sectionTransitionName = ref('slide-right');
const appendDummyPage = ref(false);
const userConformationModalDeleteSection = ref(null);

const chapterId = ref('');
const fullWidth = ref(1024);
const sectionScrollContainer = ref(null);
const coursePageLoaded = ref(false);
const currentScrollHeight = ref(0);
const headerHeightReducedBy = ref(0);
const addingNewPage = ref(false);
const contentFetchCompleted = ref(false);
const courseAndChapterFetchCompleted = ref(false);
const isDeleted = ref(false);

const showNative = ref(false);
const editOrder = ref(false);

const isTransitioning = ref(false);

const isSelecting = ref(false);
const selectionTimeout = ref<number | null>(null);

const swipePreviewTransform = ref('');
const swipePreviewOpacity = ref(1);

// props
const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const route = useRoute();

// composables
const { direction, isSwiping, lengthX, lengthY } = useSwipe(swipeContainer, {
  passive: false,
  onSwipe(e: TouchEvent) {
    return; // TODO re-enable and check if dragging

    console.log(JSON.stringify(e));
    if (isSelecting.value) return;
    if (!swipeContainer.value) return;

    // Calculate progress as a percentage (0 to 1)
    const progress = Math.min(Math.abs(lengthX.value) / 150, 1);

    // Only preview if swipe is mostly horizontal
    console.log('lengthY: ' + lengthY.value);
    console.log('lengthX: ' + lengthX.value);
    if (Math.abs(lengthY.value) > 0.1 * Math.abs(lengthX.value)) {
      swipePreviewTransform.value = '';
      return;
    }

    // Apply transform based on swipe direction
    if (lengthX.value < 0) {
      // Swiping left
      const canGoBack = currentlyViewingPageWithArrayIndex.value > -1;
      if (!canGoBack) return;

      swipePreviewTransform.value = `translateX(${-lengthX.value}px)`;
      swipePreviewOpacity.value = 1 - progress * 0.3;
    } else {
      // Swiping right
      const canGoForward = currentlyViewingPageWithArrayIndex.value < (currentSectionPages.value?.length ?? 0) - 1;
      if (!canGoForward) return;

      swipePreviewTransform.value = `translateX(${-lengthX.value}px)`;
      swipePreviewOpacity.value = 1 - progress * 0.3;
    }
  },
  onSwipeEnd(e: TouchEvent) {
    return; // TODO re-enable and check if dragging
    if (!swipeContainer.value) return;

    // Reset transform and opacity
    swipePreviewTransform.value = '';
    swipePreviewOpacity.value = 1;

    let isSwipeBelowThreshold =
      Math.abs(lengthX.value) < 150 || Math.abs(lengthX.value) < 0.1 * Math.abs(lengthY.value);
    console.log('isSwipeBelowThreshold: ' + isSwipeBelowThreshold);
    if (isSwipeBelowThreshold) return;

    if (lengthX.value > 0) {
      goToNextPage();
    } else {
      goToPreviousPage();
    }
  },
});

// other
const currentlyViewingPageWithArrayIndex = ref<number | null>(null);

const showingCourseSectionTitlePage = computed(() => {
  return currentlyViewingPageWithArrayIndex.value === null;
});

const emit = defineEmits(['transition-direction']);

const currentSection = computed(() => {
  let section = currentChapterSortedSections.value.find((section) => section.id === sectionId.value);
  if (!section) {
    return {
      id: 'incomplete-load',
      title: 'Lädt...',
      index: 0,
      section_content_items: [],
    };
  }
  return section;
});

type SectionContentItem = {
  page_index: number;
  [key: string]: any; // other properties
};

const currentSectionPages = computed(() => {
  if (!currentSection.value) {
    return null;
  }
  let pages = CourseService.sectionContentItemsByPage(currentSection.value.section_content_items || []);
  if (appendDummyPage.value) {
    pages.push({
      pageIndex: pages.length > 0 ? Math.max(...pages.map((p) => p.pageIndex)) + 1 : 0,
      arrayIndex: pages.length,
      items: [],
    });
  }
  return pages;
});

const currentSectionPagesWithTitlePageBefore = computed(() => {
  if (!currentSectionPages.value) return [];
  return [{ pageIndex: -1, arrayIndex: -1, items: [] }, ...currentSectionPages.value];
});

const innerHeaderHeight = computed(() => {
  return innerHeader.value ? innerHeader.value.offsetHeight : 0;
});

const currentChapterSortedVisibleSections = computed(() => {
  if (isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return currentChapterSortedSections.value;
  }
  let visible = currentChapterSortedSections.value.filter((section) => section.published_at != null);
  console.log('#Visible sections: ' + visible.length);
  return visible;
});

const getWidth = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth: ' + screenWidth);
  if (screenWidth < 640) {
    fullWidth.value = screenWidth;
    return;
  }
  let factor = showNative.value ? 0.9 : 0.75;
  fullWidth.value = Math.round(screenWidth * factor);
};

const debounceGetWidth = debounce(getWidth, 100);

watch(showNative, () => {
  getWidth();
});

const mountingHelper = async () => {
  if (!storeLoaded.value) {
    // go one step back
    router.back();
    return;
  }

  // get editing state from local storage
  let courseId = courseStore.currentCourse.id;
  try {
    const savedState = localStorage.getItem(`course-editing-${courseId}`);
    if (savedState && isOwnerOrEditorOfParentCourseOrAdmin.value) {
      try {
        isEditing.value = JSON.parse(savedState);
      } catch (e) {
        console.warn('Invalid saved state in localStorage, resetting...', e);
        localStorage.removeItem(`course-editing-${courseId}`);
        isEditing.value = false;
      }
    }
  } catch (e) {
    console.error('Error accessing localStorage:', e);
    isEditing.value = false;
  }

  isLoading.value = false;

  await nextTick();

  courseInteractionStore.saveLastViewedForCurrentCourse(sectionId.value, currentlyViewingPageWithArrayIndex.value);
};

const handleSectionChange = async (newSectionId: string, page: number = -1) => {
  if (newSectionId === sectionId.value) return;

  // was onBeforeMount
  sectionId.value = newSectionId;

  isLoading.value = true;

  let section = await (await getApiClient()).sections.getSection(sectionId.value);
  if (!section) {
    router.back();
    return;
  }
  let chapter;
  let course;

  console.log('section: ', section);
  console.log('page: ', page);
  if (page !== null && page !== undefined) {
    currentlyViewingPageWithArrayIndex.value = page;
  } else {
    currentlyViewingPageWithArrayIndex.value = -1;
  }

  if (!currentChapter.value || !currentChapter.value.sections.find((s) => s.id === sectionId.value)) {
    await courseStore.reset();
    await courseInteractionStore.reset();

    // load chapter, see if course fits
    chapter = await (await getApiClient()).chapters.getChapter(section.chapter_id);
    if (!chapter) {
      router.back();
      return;
    }

    console.log('chapter: ', chapter);

    if (!currentCourse.value || currentCourse.value.id !== chapter.course_id) {
      await courseStore.setCurrentCourse(chapter.course_id);
      await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();
    }

    await courseStore.setCurrentChapter(chapter.id);
    await courseInteractionStore.startCurrentChapter().finally(() => {
      courseAndChapterFetchCompleted.value = true;
    });
  } else {
    courseAndChapterFetchCompleted.value = true;
  }

  // was onMounted
  await nextTick(async () => {
    await mountingHelper();
  });

  if (currentlyViewingPageWithArrayIndex.value == null) {
    showCourseSectionTitlePage();
  }

  getWidth();
  setTimeout(() => {
    getWidth(); // let page transition animations finish
  }, 300);
  setTimeout(() => {
    getWidth(); // let section/chapter transition animations finish
  }, 3000);
  window.addEventListener('resize', debounceGetWidth);

  // Add scroll event listener
  if (sectionScrollContainer.value) {
    sectionScrollContainer.value.addEventListener('scroll', handleScroll);
  }

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  emit('transition-direction', ''); // reset router transition

  setTimeout(async () => {
    await fetchSectionContents(); // replaces case preview with full case details
  }, 200); // deferring this is a bit of a dirty hack

  // Add selection event listeners
  document.addEventListener('selectionchange', () => {
    if (window.getSelection()?.toString()) {
      handleSelectionStart();
    } else {
      handleSelectionEnd();
    }
  });
};

// Watch for route param changes
watch(
  () => route.params.sectionId,
  async (newSectionId) => {
    if (!newSectionId) return;
    const page = route.query.page;
    console.log('page: ' + page);
    const pageNum = typeof page === 'string' ? parseInt(page, 10) : -1;
    await handleSectionChange(newSectionId as string, pageNum);
  },
);

onBeforeMount(async () => {
  const sectionId = route.params.sectionId;
  const page = route.query.page;
  const pageNum = typeof page === 'string' ? parseInt(page, 10) : -1;
  await handleSectionChange(sectionId as string, pageNum);
});

watch(
  () => courseAndChapterFetchCompleted.value,
  (newVal) => {
    if (newVal) {
      setTimeout(async () => {
        await fetchSectionContents(); // replaces case preview with full case details
      }, 200); // deferring this is a bit of a dirty hack
    }
  },
);

// Add this ref to track scroll position
const scrollPosition = ref(0);

// Add this ref to track if user has manually scrolled
const userHasScrolled = ref(false);

// Update the handleScroll function to track manual scrolling
const handleScroll = () => {
  if (sectionScrollContainer.value) {
    scrollPosition.value = sectionScrollContainer.value.scrollTop;
    userHasScrolled.value = true;
  }
};

const fetchSectionContents = async () => {
  contentFetchCompleted.value = false;
  if (!currentSection.value) {
    return;
  }
  if (!courseAndChapterFetchCompleted.value) {
    return;
  }
  courseStore
    .refetchSelectedSection(currentSection.value.index)
    .then(async () => {
      await courseInteractionStore.updatePageCompletionStatusForSection(currentSection.value.id).then(() => {
        contentFetchCompleted.value = true;
      });
    })
    .catch((error) => {
      alertStore.error('Fehler beim Laden der Seite', 'Fehler', error);
      throw error;
      router.back();
    })
    .finally(() => {});
  await nextTick();
  appendDummyPage.value = false;
};

onMounted(async () => {});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetWidth);

  // Remove scroll event listener
  if (sectionScrollContainer.value) {
    sectionScrollContainer.value.removeEventListener('scroll', handleScroll);
  }

  // Clean up
  if (selectionTimeout.value) {
    clearTimeout(selectionTimeout.value);
  }
  document.removeEventListener('selectionchange', () => {});
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSortedSections.value !== null;
});

const isOwnerOrEditorOfParentCourseOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

const goToChapter = async (chapterIndex: number, atEnd: boolean = false) => {
  if (!courseStore.currentCourse || !courseStore.currentCourse.chapters) {
    return;
  }
  if (chapterIndex < 0 || chapterIndex >= courseStore.currentCourse.chapters.length) {
    return;
  }
  await router.isReady();
  let targetChapter = courseStore.currentCourse.chapters[chapterIndex];
  await courseStore.setCurrentChapter(targetChapter.id);
  if (!atEnd) {
    // await router.push('/chapter/' + courseStore.currentCourse.chapters[chapterIndex].id);
    let firstSection = targetChapter.sections[0];
    await router.push('/section/' + firstSection.id);
  } else {
    let lastSection = targetChapter.sections[targetChapter.sections.length - 1];
    let lastPage = getMaxPageIndex(lastSection.index, chapterIndex);
    await router.push('/section/' + lastSection.id + '?page=' + lastPage);
  }
};

const getMaxPageIndex = (sectionIndex: number, chapterIndex: number = null) => {
  let targetSection;
  if (chapterIndex === null) {
    targetSection = courseStore.currentChapterSortedSections[sectionIndex];
  } else {
    targetSection = courseStore.currentCourse.chapters[chapterIndex].sections[sectionIndex];
  }
  if (!targetSection?.section_content_items) {
    return -1;
  }
  return Math.max(...targetSection.section_content_items.map((item) => item.page_index));
};

const goToPreviousPage = () => {
  sectionTransitionName.value = 'slide-right'; // in case we trigger a section change
  if (currentlyViewingPageWithArrayIndex.value === null) return;
  if (currentlyViewingPageWithArrayIndex.value === -1) {
    if (currentSection.value.index === 0) {
      if (currentChapter.value.index === 0) {
        console.log('goToCourseOverview (crossing start of course)');
        goToCourseOverview();
        return;
      }
      console.log('goToChapter (at end): ' + (currentChapter.value.index - 1));
      goToChapter(currentChapter.value.index - 1, true);
      return;
    }
    console.log('goToSection (at end): ' + (currentSection.value.index - 1));
    goToSection(currentSection.value.index - 1, true);
    return;
  }
  if (currentlyViewingPageWithArrayIndex.value === 0) {
    showCourseSectionTitlePage();
    return;
  }
  goToPage(currentlyViewingPageWithArrayIndex.value - 1);
};

const goToNextPage = () => {
  sectionTransitionName.value = 'slide-left'; // in case we trigger a section change
  if (currentlyViewingPageWithArrayIndex.value === null) return;
  if (currentlyViewingPageWithArrayIndex.value === currentSectionPages.value.length - 1) {
    console.log('currentlyViewingPageWithArrayIndex.value: ' + currentlyViewingPageWithArrayIndex.value);
    console.log('currentSectionPages.value.length: ' + currentSectionPages.value.length);
    console.log('currentSection.value.index: ' + currentSection.value.index);
    console.log('currentChapterSortedSections.value.length: ' + currentChapterSortedSections.value.length);
    console.log('currentChapter.value.index: ' + currentChapter.value.index);
    console.log('courseStore.currentCourse.chapters.length: ' + courseStore.currentCourse.chapters.length);
    if (currentSection.value.index === currentChapterSortedSections.value.length - 1) {
      if (currentChapter.value.index === courseStore.currentCourse.chapters.length - 1) {
        console.log('goToCourseOverview (at end of course)');
        goToCourseOverview();
        return;
      }
      console.log('goToChapter (at start of chapter): ' + (currentChapter.value.index + 1));
      goToChapter(currentChapter.value.index + 1);
      return;
    }
    console.log('goToSection (at start of section): ' + (currentSection.value.index + 1));
    goToSection(currentSection.value.index + 1);
    return;
  }
  goToPage(currentlyViewingPageWithArrayIndex.value + 1);
};

const goToSection = async (index: number, atEnd: boolean = false) => {
  console.log('goToSection: ' + index);
  console.log('currentSection.value.index: ' + currentSection.value?.index);
  if (currentSection.value?.index === null) return;
  if (index === currentSection.value.index) {
    return;
  }
  await router.isReady();
  // Determine the transition direction
  const direction = index < currentSection.value.index ? 'slide-right' : 'slide-left';
  // Emit the direction to the parent
  emit('transition-direction', direction);

  if (!atEnd) {
    currentlyViewingPageWithArrayIndex.value = -1;
    await router.push('/section/' + courseStore.currentChapterSortedSections[index].id);
  } else {
    await router.push(
      '/section/' + courseStore.currentChapterSortedSections[index].id + '?page=' + getMaxPageIndex(index),
    );
  }
};

const goToCourseOverview = async () => {
  await router.push('/course/' + courseStore.currentCourse.id);
};

const goToEditChapterOverview = async () => {
  await router.push('/edit-chapter/' + courseStore.currentChapterId);
};

const goToPage = async (newPageIndex: number) => {
  if (!currentSectionPages.value) return;
  if (newPageIndex < 0 || newPageIndex >= currentSectionPages.value.length) {
    return;
  }
  if (newPageIndex === currentlyViewingPageWithArrayIndex.value) {
    return;
  }
  currentlyViewingPageWithArrayIndex.value = newPageIndex;
  await new Promise((resolve) => setTimeout(resolve, 400));
  scrollToTop();
  courseInteractionStore.saveLastViewedForCurrentCourse(sectionId.value, newPageIndex);
};

watch(
  () => currentlyViewingPageWithArrayIndex.value,
  (newIndex, oldIndex) => {
    pageTransitionName.value = newIndex > oldIndex ? 'slide-left' : 'slide-right';
  },
);

const toggleIsEditing = (isEditingNow: boolean) => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  if (!courseStore.currentCourse?.id) {
    return;
  }
  isEditing.value = isEditingNow;
  localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditingNow));
};

const addPage = async () => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  if (!currentSectionPages.value) return;

  addingNewPage.value = true;
  appendDummyPage.value = true;
  setTimeout(() => {
    if (!currentSectionPages.value) return;
    const lastPage = currentSectionPages.value.length - 1;
    goToPage(lastPage);
  }, 200);
};

// Update handleCoursePageLoaded function
const handleCoursePageLoaded = async (pageIndex: number, loaded: boolean, hasContent: boolean) => {
  if (!loaded || !sectionScrollContainer.value) return;

  const page = currentSectionPages.value?.find((p) => p.pageIndex === pageIndex);
  if (!page) return;

  if (page.arrayIndex === currentlyViewingPageWithArrayIndex.value) {
    coursePageLoaded.value = true;
  }

  addingNewPage.value = !hasContent;
  console.log('newPage: ' + addingNewPage.value);

  if (!hasContent) {
    // new page added
    setTimeout(() => {
      scrollToBottom();
    }, 700); // delay: let slide animation finish first
    return;
  } else if (!!currentSectionPages.value && page.arrayIndex === currentSectionPages.value.length - 1) {
    addingNewPage.value = false; // last page has content, so adding new page is finished
    appendDummyPage.value = false;
    return;
  }

  // Only scroll to top if this is the current page and user hasn't manually scrolled
  if (page.arrayIndex === currentlyViewingPageWithArrayIndex.value && !userHasScrolled.value) {
    await nextTick();
    sectionScrollContainer.value.scrollTop = 0;
  }
};

const showCourseSectionTitlePage = () => {
  currentlyViewingPageWithArrayIndex.value = -1;
};

watch(
  () => currentSectionPages.value?.length,
  (newLength, oldLength) => {
    if (newLength === undefined || oldLength === undefined) return;
    if (newLength > oldLength && !addingNewPage.value) {
      appendDummyPage.value = false;
    }
  },
);

const scrollToBottom = () => {
  if (!sectionScrollContainer.value) return;
  console.log('scrolling to bottom');
  sectionScrollContainer.value.scroll({
    top: sectionScrollContainer.value.scrollHeight,
    behavior: 'smooth',
  });
};

const scrollToTop = () => {
  if (!sectionScrollContainer.value) return;
  console.log('scrolling to top');
  sectionScrollContainer.value.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

const computedStyleScrollContainer = computed(() => {
  return {
    flex: '1 1 auto',
    overflowY: 'auto',
    minHeight: 0,
  };
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(100vh - ${props.outerHeaderHeight}px)`,
  };
});

const lastScrollAmount = ref(0);

const handleScrollBy = async (distance: number) => {
  let scrollPositionBefore = sectionScrollContainer.value?.scrollTop;
  sectionScrollContainer.value?.scrollBy(0, distance);
  sectionScrollContainer.value?.addEventListener(
    'scrollend',
    () => {
      let scrollPositionAfter = sectionScrollContainer.value?.scrollTop;
      lastScrollAmount.value = scrollPositionAfter - scrollPositionBefore;
    },
    { once: true },
  );
};

const handleUndoLastScroll = () => {
  if (lastScrollAmount.value) {
    sectionScrollContainer.value?.scrollBy(0, -lastScrollAmount.value);
    lastScrollAmount.value = 0;
  }
};

const deleteEmptySection = (sectionId: string) => {
  console.log('deleteEmptySection: ' + sectionId);
  isDeleted.value = true; // this sets off autosave attempts and removes the breadcrumb for the section level
  courseStore
    .deleteSection(sectionId)
    .then(async () => {
      alertStore.success('Section deleted successfully');
      await nextTick();
      await router.push('/chapter/' + courseStore.currentChapterId);
    })
    .catch((error: any) => {
      console.debug('Error: ' + error);
      isDeleted.value = false;
      return false;
    });
};

// Reset userHasScrolled when changing pages
watch(
  () => currentlyViewingPageWithArrayIndex.value,
  () => {
    userHasScrolled.value = false;
  },
);

watch(
  () => isEditing.value,
  async (newValue) => {
    if (newValue) return; // no changes before as editing was false, so nothing to refetch
    isLoading.value = true;
    await courseStore.refetchSelectedSection(currentSection.value?.index);
    isLoading.value = false;
  },
  { immediate: true },
);

// Add these methods
const handleSelectionStart = () => {
  isSelecting.value = true;
  // Clear any existing timeout
  if (selectionTimeout.value) {
    clearTimeout(selectionTimeout.value);
  }
};

const handleSelectionEnd = () => {
  // Add a small delay before resetting isSelecting to handle quick swipes after selection
  if (selectionTimeout.value) {
    clearTimeout(selectionTimeout.value);
  }
  selectionTimeout.value = window.setTimeout(() => {
    isSelecting.value = false;
  }, 300);
};
</script>

<template>
  <div ref="swipeContainer">
    <LoadingSpinnerFullScreen :show="!courseAndChapterFetchCompleted || isLoading" />
    <transition
      :name="sectionTransitionName"
      :mode="'out-in'"
      v-if="courseAndChapterFetchCompleted"
      @before-enter="isTransitioning = true"
      @after-enter="isTransitioning = false"
      @before-leave="isTransitioning = true"
      @after-leave="isTransitioning = false"
    >
      <div
        class="flex flex-col min-w-full overflow-hidden relative"
        :style="computedStyleMain"
        :key="currentSection?.id"
      >
        <CourseNav
          v-if="!showingCourseSectionTitlePage"
          :section="currentSection"
          :currentPageArrayIndex="currentlyViewingPageWithArrayIndex"
          :pages="currentSectionPages || []"
          :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
          :is-editing="isEditing"
          @toCourseOverview="goToCourseOverview"
          @toEditChapterOverview="goToEditChapterOverview"
          @toPreviousPage="goToPreviousPage"
          @toNextPage="goToNextPage"
          @onToggleIsEditing="toggleIsEditing"
          @onEditOrder="editOrder = true"
          @onAddPage="addPage"
        />

        <main
          ref="sectionScrollContainer"
          v-if="!isLoading"
          :id="pageId()"
          class="flex flex-col min-w-full scroll-smooth pb-[200px] sm:pb-4"
          :style="computedStyleScrollContainer"
        >
          <div
            v-for="page in currentSectionPagesWithTitlePageBefore"
            :key="`page-${page?.pageIndex || 'title'}-${page?.items?.[0]?.id || ''}`"
            class="relative"
          >
            <transition
              :name="pageTransitionName"
              :mode="'out-in'"
              @before-enter="isTransitioning = true"
              @after-leave="isTransitioning = false"
              @after-enter="isTransitioning = false"
              @before-leave="isTransitioning = true"
            >
              <div
                v-show="page.arrayIndex === currentlyViewingPageWithArrayIndex"
                class="pt-4"
                :style="{
                  transform: swipePreviewTransform,
                  opacity: swipePreviewOpacity,
                  transition: isSwiping ? 'none' : 'transform 0.5s ease, opacity 0.5s ease',
                }"
              >
                <div v-if="page.arrayIndex === -1" class="">
                  <CourseSectionTitlePage
                    :chapter="currentChapter"
                    :section="currentSection"
                    :numVisibleSections="currentChapterSortedVisibleSections.length"
                    :allow-editing="isEditing"
                    :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
                    :is-deleted="isDeleted"
                    :full-width="fullWidth"
                    @onToggleIsEditing="toggleIsEditing"
                    @onAddPage="addPage"
                  />
                </div>
                <EditCoursePage
                  v-else-if="isEditing"
                  :pageContents="page.items"
                  :pageIndex="page.pageIndex"
                  :arrayIndex="page.arrayIndex"
                  :fetchingSectionContentsCompleted="contentFetchCompleted"
                  :learning-objectives="currentSection.learning_objectives"
                  :sectionIndex="currentSection.index"
                  :sectionId="currentSection.id"
                  :chapterId="chapterId"
                  :chapterIndex="currentChapter.index"
                  :show-native="showNative"
                  :full-width="fullWidth"
                  :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                  :current-scroll-height="currentScrollHeight"
                  @scrollToBottom="scrollToBottom"
                  @scrollTo="
                    (scrollHeight) => {
                      if (!sectionScrollContainer) return;
                      sectionScrollContainer.scroll({
                        top: scrollHeight,
                        behavior: 'smooth',
                      });
                    }
                  "
                  @pageLoaded="handleCoursePageLoaded"
                  @refetchSectionContents="fetchSectionContents"
                />
                <CoursePage
                  v-else
                  :pageContents="page.items"
                  :pageIndex="page.pageIndex"
                  :pageIsVisible="page.arrayIndex === currentlyViewingPageWithArrayIndex"
                  :arrayIndex="page.arrayIndex"
                  :fetchingSectionContentsCompleted="contentFetchCompleted"
                  :learning-objectives="currentSection.learning_objectives"
                  :sectionIndex="currentSection.index"
                  :sectionId="currentSection.id"
                  :chapterId="chapterId"
                  :chapterIndex="currentChapter.index"
                  :show-native="showNative"
                  :full-width="fullWidth"
                  :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                  :is-transitioning="isTransitioning"
                  @showNativeToggled="showNative = $event"
                  @scrollBy="handleScrollBy"
                  @undoLastScroll="handleUndoLastScroll"
                  @pageLoaded="handleCoursePageLoaded"
                />
              </div>
            </transition>
          </div>
        </main>
      </div>
    </transition>

    <BottomNav />

    <UserConfirmationModal
      ref="userConformationModalDeleteSection"
      prompt_message=""
      approve_message="Ja, löschen"
      discard_message="Nein, zurück"
      approve_color="bg-red-600 hover:bg-red-700"
      overlayId="confirmDeleteSection"
      @approved="(sectionId: string) => deleteEmptySection(sectionId)"
    >
    </UserConfirmationModal>

    <SectionContentReorderModal
      v-if="editOrder"
      :section="currentSection"
      @closed="
        () => {
          editOrder = false;
        }
      "
      @updated="
        () => {
          fetchSectionContents();
        }
      "
    />
  </div>
</template>

<style scoped>
/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
}

.slide-left-enter-from {
  transform: translateX(105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-left-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-left-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}

/* Slide Right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-right-enter-from {
  transform: translateX(-105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-right-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-right-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}

[ref='swipeContainer'] {
  will-change: transform;
  touch-action: pan-y pinch-zoom;
}
</style>
