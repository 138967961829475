<script setup>
import CaseInteractionQuickactionResume from '@/components/quickaction-cards/CaseInteractionQuickactionResume.vue';
import CaseInteractionQuickactionStartNew from '@/components/quickaction-cards/CaseInteractionQuickactionStartNew.vue';
import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const caseInteractionStore = useCaseInteractionStore();
const { allUserCaseInteractions } = storeToRefs(caseInteractionStore);

const emit = defineEmits(['resume']);

function handleResume(caseInteractionId) {
  emit('resume', caseInteractionId);
}

const allActiveUserCaseInteractionsForUniqueCases = computed(() =>
  allUserCaseInteractions.value.filter(
    (interaction, index, self) =>
      index === self.findIndex((t) => t.case.id === interaction.case.id && t.solved_at === null),
  ),
);
</script>

<template>
  <div class="relative w-full">
    <div class="flex gap-2 overflow-x-auto px-4 -mx-4 pb-4 snap-x snap-mandatory">
      <div class="w-[248px] flex-none snap-center">
        <CaseInteractionQuickactionStartNew />
      </div>
      <div v-for="interaction in allActiveUserCaseInteractionsForUniqueCases" class="w-[248px] flex-none snap-center">
        <CaseInteractionQuickactionResume :case-interaction="interaction" @resume="handleResume" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.overflow-x-auto {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-x-auto::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
</style>
