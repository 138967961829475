<script setup>
import { computed } from 'vue';
import { router } from '@/router';
import { useAlertStore } from '@/stores';
import { useCourseInteractionStore } from '@/stores';
import { Play } from 'lucide-vue-next';
import CourseService from '@/services/CourseService';

const props = defineProps({
  courseInteraction: {
    type: Object, // TODO fix typing
    default: null,
  },
  // currentStep: {
  //   type: Number,
  //   default: null,
  // },
  // totalSteps: {
  //   type: Number,
  //   default: null,
  // },
  // timeLeft: {
  //   type: Number, // in minutes
  //   default: null,
  // },
  // xpReward: {
  //   type: Number,
  //   default: null,
  // },
});

const currentStep = computed(() => {
  return props.courseInteraction.progress.n_items_completed_in_current_section;
});

const totalSteps = computed(() => {
  return props.courseInteraction.progress.n_items_total_in_current_section;
});

const hasProgress = computed(() => {
  return currentStep.value !== null && totalSteps.value !== null;
});

const progressPercentage = computed(() => {
  if (!hasProgress.value) return 0;
  return (currentStep.value / totalSteps.value) * 100;
});

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();

const routeToCourse = () => CourseService.resumeCourse(router, props.courseInteraction);

async function signalCourseStartedToParent() {
  if (props.course.id) {
    await router.push('/course/' + props.course.id);
  }
}
</script>

<template>
  <div
    @click.prevent="routeToCourse"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-2xl p-4 cursor-pointer hover:shadow-lg transition-shadow"
  >
    <!-- Course Title and Level -->
    <div class="text-gray-800 dark:text-gray-200 mb-6 h-[4.5rem]">
      <p class="text-sm text-gray-500 dark:text-gray-400 truncate mb-2">
        {{ props.courseInteraction.course.title }}
      </p>
      <h3 class="text-xl font-semibold leading-tight tracking-[-0.01rem] line-clamp-2">
        {{ props.courseInteraction.progress.current_section_title }}
      </h3>
    </div>

    <!-- Progress Section -->
    <div v-if="hasProgress" class="space-y-3">
      <!-- Progress Bar Section -->
      <div class="flex items-center gap-x-3">
        <div class="flex-1">
          <div class="h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
            <div
              class="h-full bg-blue-600 dark:bg-blue-500 rounded-full transition-all duration-300"
              :style="{ width: `${progressPercentage}%` }"
            ></div>
          </div>
        </div>

        <!-- Step Counter -->
        <div class="text-sm dark:text-gray-300 whitespace-nowrap">
          {{ $t('message.step') }} {{ currentStep }} / {{ totalSteps }}
        </div>
      </div>

      <!-- Resume Button and Time -->
      <div class="flex items-center mt-4">
        <button
          class="inline-flex items-center gap-1 pl-3 pr-4 py-2 bg-white text-gray-800 font-semibold rounded-full hover:bg-gray-50"
          @click.stop="routeToCourse"
        >
          <Play size="16" class="dark:text-white rounded" fill="currentColor" />
          {{ $t('message.resume') }}
        </button>
        <span
          class="text-sm text-gray-600 dark:text-gray-400 ml-2"
          v-if="props.courseInteraction.progress.mins_left_in_current_section"
        >
          {{ $t('message.minutesLeft', props.courseInteraction.progress.mins_left_in_current_section) }}
        </span>
      </div>
    </div>
  </div>
</template>
