<script setup lang="ts">
import { computed, ref } from 'vue';
import { Pin } from 'lucide-vue-next';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore, useCaseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const authStore = useAuthStore();
const caseInteractionStore = useCaseInteractionStore();
const { user } = storeToRefs(authStore);
const titlePersonType = ref('message.patient');

const props = defineProps({
  showDividers: {
    type: Boolean,
    default: true,
  },
});

// Type assertion to avoid TypeScript errors
const currentCase = computed(() => caseInteractionStore.currentCase as any);

const titlePerson = computed(() => {
  if (!!currentCase.value?.patient) {
    titlePersonType.value = 'message.patient';
    return currentCase.value.patient;
  }
  if (!currentCase.value?.third_persons?.length) {
    titlePersonType.value = 'message.interactionPartner';
    return null;
  }
  titlePersonType.value = 'message.interactionPartner';
  return currentCase.value.third_persons[0];
});

const otherPersonType = computed(() => {
  if (
    !currentCase.value?.third_persons?.length ||
    (!currentCase.value?.patient && currentCase.value?.third_persons?.length <= 1)
  ) {
    // no third person, or only one third person and no patient
    return null;
  }
  let type = currentCase.value.third_persons[0].type;

  switch (type) {
    case 'PHYSICIAN':
      return t('role.physician');
    case 'NURSE':
      return t('role.nurse');
    case 'RELATIVE':
      return t('role.relative');
    default:
      return '';
  }
});

const sexSymbol = computed(() => {
  switch (titlePerson.value?.details.sex) {
    case 'FEMALE':
      return 'weiblich';
    case 'MALE':
      return 'männlich';
    case 'DIVERSE':
    default:
      return '';
  }
});

// use German locale for translation locally
const tDeu = (key: string) => {
  return t(key, undefined, { locale: 'deu' });
};
</script>

<template>
  <div class="flex flex-col py-6">
    <!-- User Info -->
    <div class="flex items-center gap-4 mb-4">
      <ProfileImage :image="user?.profile_image?.image_urls?.small || ''" class="w-12 h-12 border border-gray-200" />
      <div class="text-sm">
        {{ tDeu('message.role') }}: <span class="font-semibold">{{ currentCase?.student_details?.role }}</span>
      </div>
    </div>

    <!-- Divider -->
    <div v-if="props.showDividers" class="h-px bg-gray-200 mb-4"></div>

    <!-- Patient Info -->
    <div class="inline-flex w-full items-center gap-4 mb-4">
      <div>
        <ProfileImage
          :image="titlePerson?.profile_image?.image_urls?.small || ''"
          class="w-12 h-12 border border-gray-200"
        />
      </div>
      <div class="text-sm flex flex-wrap">
        <span class="mr-1">{{ tDeu('message.interactionPartner') }}:</span>
        <span class="font-semibold">
          {{ titlePerson?.details.first_name }} {{ titlePerson?.details.last_name }}, {{ titlePerson?.details.age }},{{
            sexSymbol ? ` ${sexSymbol}` : ''
          }}<span v-if="titlePersonType">,&nbsp;</span>
        </span>
        <span>{{ tDeu(titlePersonType) }}</span>
      </div>
    </div>

    <!-- Divider between patient and third person -->
    <div v-if="currentCase?.third_persons?.length && currentCase?.patient" class="h-px bg-gray-200 mb-4"></div>

    <!-- Third Person Info (if exists and patient exists) -->
    <div
      v-if="currentCase?.third_persons?.length && currentCase?.patient"
      class="inline-flex w-full items-center gap-4 mb-4"
    >
      <div>
        <ProfileImage
          :image="currentCase.third_persons[0]?.profile_image?.image_urls?.small || ''"
          class="w-12 h-12 border border-gray-200"
        />
      </div>
      <div class="text-sm flex flex-wrap">
        <span class="mr-1">{{ tDeu('message.interactionPartner') }}:</span>
        <span class="font-semibold">
          {{ currentCase.third_persons[0]?.details.first_name }}
          {{ currentCase.third_persons[0]?.details.last_name }}<span v-if="otherPersonType">,&nbsp;</span>
        </span>
        <span v-if="otherPersonType">{{ tDeu(otherPersonType) }}</span>
      </div>
    </div>

    <!-- Divider -->
    <div v-if="props.showDividers" class="h-px bg-gray-200 mb-4"></div>

    <!-- Date, Time & Location -->
    <div class="flex items-center gap-4">
      <div class="w-12 h-12 rounded-full bg-transparent border border-gray-200 flex items-center justify-center">
        <Pin class="w-5 h-5" />
      </div>
      <div class="text-sm">
        <div class="font-semibold">
          {{
            new Date(currentCase?.tasks[0]?.details.date).toLocaleDateString('de-DE', {
              day: 'numeric',
              month: 'long',
            })
          }},
          {{
            new Date(`2000-01-01T${currentCase?.tasks[0]?.details.time}`).toLocaleTimeString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            })
          }}
          Uhr
        </div>
        <div>{{ currentCase?.tasks[0]?.details.location }}</div>
      </div>
    </div>
  </div>
</template>
