<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useAutosave } from '@/composables';
import { X } from 'lucide-vue-next';
import { getApiClient } from '@/apiclient/client';
import { useCourseStore } from '@/stores';
import { Teleport } from 'vue';
import { shuffle } from 'lodash';

interface Props {
  id: string;
  contentItemId: string;
  name: string;
  distractors: string[];
  x_pos: number;
  y_pos: number;
  isSelected: boolean;
  isEditing: boolean;
  isDragged: boolean;
}

const props = defineProps<Props>();
const courseStore = useCourseStore();
const emit = defineEmits(['update:position', 'remove', 'select', 'update:id', 'isSolved']);

const labelName = ref(props.name);
const isContainerVisible = ref(false);
const container = ref(null);
const optionsToSelect = ref([] as string[]);

const isCorrect = ref(false);
const showIncorrectOptions = ref(true);
const showCorrectOption = ref(true);
const selectedAsWrongOption = ref<string | null>(null);

const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.contentItemId) return;

    console.log('props.id', props.id);

    if (props.id.startsWith('temp-')) {
      const response = await courseStore.addImageLabelingItem(props.contentItemId, {
        name: labelName.value,
        distractors: props.distractors,
        x_pos: props.x_pos,
        y_pos: props.y_pos,
      });
      emit('update:id', response.id);
      return;
    }

    await courseStore.updateImageLabelingItem(props.contentItemId, props.id, {
      name: labelName.value,
      distractors: props.distractors,
      x_pos: props.x_pos,
      y_pos: props.y_pos,
    });
  },
});

onMounted(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        isContainerVisible.value = true;
      } else {
        isContainerVisible.value = false;
      }
    },
    {
      threshold: 0.1,
      rootMargin: '100px', // Trigger slightly before we reach it
    },
  );

  if (container.value) {
    observer.observe(container.value);
  }

  if (!props.isEditing) {
    let options = props.distractors.map((distractor) => distractor);
    options.push(labelName.value);
    optionsToSelect.value = shuffle(options);
  }

  onBeforeUnmount(() => {
    observer.disconnect();
  });
});

const updateName = (newName: string) => {
  labelName.value = newName;
  unsavedChanges.value = true;
};

watch(
  () => props.isSelected,
  (newVal) => {
    if (newVal) {
      // if selecting a solved dot again, show the correct option
      showCorrectOption.value = true;
    }
  },
);

const dotClasses = computed(() => ({
  'absolute rounded-full cursor-pointer transition-all duration-300': true,
  'bg-blue-800 w-8 h-8 -ml-4 -mt-4': props.isSelected && !isCorrect.value,
  'bg-green-500 w-8 h-8 -ml-4 -mt-4': isCorrect.value,
  'bg-blue-500 w-6 h-6 -ml-3 -mt-3': !props.isSelected,
  'z-10': props.isDragged,
}));

const dotStyle = computed(() => ({
  left: `${props.x_pos}%`,
  top: `${props.y_pos}%`,
  cursor: props.isEditing ? 'move' : 'pointer',
  transform: props.isDragged ? 'scale(1.2)' : 'scale(1)',
}));

const handleOptionSelect = (option: string) => {
  if (option === props.name) {
    emit('isSolved');

    // First hide incorrect options
    showIncorrectOptions.value = false;

    // Then turn correct option green and fade it
    setTimeout(() => {
      isCorrect.value = true;
      setTimeout(() => {
        showCorrectOption.value = false;
      }, 500);
    }, 300);
  } else {
    // Show wrong animation
    selectedAsWrongOption.value = option;
    setTimeout(() => {
      selectedAsWrongOption.value = null;
    }, 400);
  }
};
</script>

<template>
  <div :class="dotClasses" :style="dotStyle" @click.stop="emit('select')" ref="container">
    <!-- Label input in edit mode -->
    <div v-if="isSelected && isEditing" class="absolute top-8 left-0 z-10 bg-white rounded-lg shadow-lg inline-flex">
      <input
        v-model="labelName"
        @input="updateName($event.target.value)"
        class="border border-transparent rounded-lg"
        placeholder="Enter label"
        :disabled="!isEditing"
      />
      <button @click.stop="emit('remove')" class="ml-2 text-red-500 hover:text-red-700">
        <X :size="16" />
      </button>
    </div>
    <Teleport to="body">
      <div
        v-if="isSelected && !isEditing && isContainerVisible"
        class="fixed flex flex-wrap min-w-full items-center justify-center max-w-full gap-2 bottom-24 left-1/2 -translate-x-1/2 z-50 text-white"
      >
        <TransitionGroup name="fade">
          <div v-for="option in optionsToSelect" :key="option">
            <div
              v-if="(option !== name && showIncorrectOptions) || (option === name && showCorrectOption)"
              :class="{
                'rounded-full py-2 px-3 cursor-pointer transition-colors duration-300': true,
                'bg-blue-800 hover:bg-blue-700': !isCorrect && selectedAsWrongOption != option,
                'bg-green-500': isCorrect && option === name,
                'bg-red-500': selectedAsWrongOption == option,
              }"
              @click="handleOptionSelect(option)"
            >
              {{ option }}
            </div>
          </div>
        </TransitionGroup>
      </div>
    </Teleport>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-move {
  transition: transform 0.3s ease;
}
</style>
