<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useOrganizationStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import UserList from '@/components/UserList.vue';
import { useI18n } from 'vue-i18n';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useManagedOrganizations } from '@/composables/useManagedOrganizations';
import { useOrganizationMemberHighlighting } from '@/composables/useOrganizationMemberHighlighting';
import { getColorForMember } from '@/views/dashboard/helper';
import { OrganizationMemberDetails } from '@/apiclient';
import LearningObjectiveCard from '@/components/various/LearningObjectiveCard.vue';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const { t } = useI18n();
const organizationStore = useOrganizationStore();
const initialFetchCompleted = ref(false);

// composables
const { hasManagerAccess } = useManagedOrganizations({
  handleOrganizationChange: async () => {
    initialFetchCompleted.value = false;
    await Promise.all([organizationStore.loadMembers(), organizationStore.loadJournalEntries()]);
    initialFetchCompleted.value = true;
  },
});
const { highlightedUser, highlightUser, clearHighlight } = useOrganizationMemberHighlighting();

// Sort journal entries by date (newest first)
const sortedJournalEntries = computed(() => {
  let entries = [...organizationStore.orgMemberJournalEntries].sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });
  if (highlightedUser.value) {
    entries = entries.filter((entry) => entry.user_id === highlightedUser.value);
  }
  return entries;
});

const sortedComments = computed(() => {
  return sortedJournalEntries.value.filter((entry) => entry.comment);
});

const sortedScores = computed(() => {
  return sortedJournalEntries.value.filter((entry) => entry.confidence_score !== null);
});

const processedMemberData = computed(() => {
  if (!organizationStore.orgMembers || !organizationStore.orgMembers.length) return [];

  return organizationStore.orgMembers
    .map((member: OrganizationMemberDetails, index: number) => {
      return {
        id: member.user.id,
        name: `Student ${index + 1}`,
      };
    })
    .filter(Boolean);
});
</script>

<template>
  <main class="flex relative h-full max-w-[85rem] mx-auto">
    <div
      class="flex absolute top-0 left-0 justify-center items-center h-full w-full transition-opacity duration-300"
      :class="{ 'opacity-100': !initialFetchCompleted, 'opacity-0 pointer-events-none': initialFetchCompleted }"
    >
      <LoadingSpinnerLarge />
    </div>

    <div v-if="initialFetchCompleted" class="flex flex-col m-4 gap-4 w-full">
      <!-- Binning and display mode selectors -->
      <div class="flex justify-end gap-4 mb-4">
        <!-- Date display mode selector -->
        <div class="inline-flex rounded-md shadow-sm" role="group">
          <!-- TODO filter by section title -->
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-7 gap-4 h-full overflow-hidden">
        <!-- User list -->
        <UserList
          :users="processedMemberData"
          :highlighted-user="highlightedUser"
          :get-color-for-user="getColorForMember"
          @highlight="highlightUser"
          @clear-highlight="clearHighlight"
        />

        <!-- Charts container -->
        <div class="md:col-span-3 space-y-6 overflow-auto">
          <div class="bg-white p-4 rounded-lg shadow">
            <h2 class="text-lg font-semibold mb-4">{{ t('Recent Comments') }}</h2>
            <div v-if="sortedComments.length === 0" class="text-gray-500 italic">
              {{ t('No comments available') }}
            </div>
            <div v-else class="space-y-4">
              <transition-group name="list" tag="div" class="space-y-4">
                <div
                  v-for="entry in sortedComments"
                  :key="entry.id"
                  class="p-3 border rounded-md transition-all duration-300"
                  :class="{ 'border-blue-300 bg-blue-50': highlightedUser === entry.user_id }"
                >
                  <div class="flex justify-between items-start mb-2">
                    <div class="font-medium" :style="{ color: getColorForMember(entry.user_id) }">
                      {{ processedMemberData.find((member) => member.id === entry.user_id)?.name }}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ entry.created_at }}
                    </div>
                  </div>
                  <div v-if="entry.learning_objective_description" class="text-sm text-gray-600 mb-2">
                    {{ entry.learning_objective_description }}
                  </div>
                  <div v-if="entry.comment" class="whitespace-pre-wrap">
                    {{ entry.comment }}
                  </div>
                  <div v-else class="text-gray-400 italic">
                    {{ t('No comment provided') }}
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
        </div>

        <div class="md:col-span-3 space-y-6 overflow-auto">
          <div class="bg-white p-4 rounded-lg shadow">
            <h2 class="text-lg font-semibold mb-4">{{ t('Confidence Scores') }}</h2>
            <div v-if="sortedScores.length === 0" class="text-gray-500 italic">
              {{ t('No confidence scores available') }}
            </div>
            <div v-else class="space-y-4">
              <transition-group name="list" tag="div" class="space-y-4">
                <div v-for="entry in sortedScores" :key="entry.id" class="transition-all duration-300">
                  <div class="flex justify-between items-start mb-2">
                    <div class="font-medium" :style="{ color: getColorForMember(entry.user_id) }">
                      {{ processedMemberData.find((member) => member.id === entry.user_id)?.name }}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ entry.created_at }}
                    </div>
                  </div>
                  <LearningObjectiveCard
                    :description="entry.learning_objective_description || t('No learning objective')"
                    :rating="entry.confidence_score || 0"
                    :show-scoring="true"
                    :editable="false"
                  />
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
/* Transition for list items */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateY(30px);
  max-height: 0;
}

.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  border-width: 0;
}

.list-move {
  transition: transform 0.5s ease;
}
</style>
