<script setup lang="ts">
import { PropType, ref, onMounted, computed, watch } from 'vue';
import { JournalEntry, LearningObjective, SectionContentItemOverview } from '@/apiclient';
import Title from '@/components/didactics/title/Title.vue';
import LearningObjectiveCard from '@/components/various/LearningObjectiveCard.vue';
import { BookHeart } from 'lucide-vue-next';
import { useExerciseLifecycle } from '@/composables';
import TextareaWithAudioInputNew from '@/components/inputs/TextareaWithAudioInputNew.vue';
import { getApiClient } from '@/apiclient/client';

// props
const props = defineProps({
  learningObjectives: {
    type: Array as PropType<LearningObjective[]>,
    required: true,
  },
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
});

// state
const ratings = ref<number[]>([]);
const ratingsChanged = ref<boolean[]>([]);
const hasBeenRated = ref<boolean[]>([]);
const comment = ref<string>('');
const commentChanged = ref<boolean>(false);

// composables
const {
  handleExerciseFinished,
  fetchInteractionState,
  reopenExercise,
  itemInteractionState,
  fetchCompleted: interactionFetchCompleted,
} = useExerciseLifecycle({
  contentItem: props.contentItem,
  sectionIndex: props.sectionIndex,
  pageIndex: props.pageIndex,
  isEditing: false,
  postComplete: async (response) => {
    console.log('response', response);
    await submitJournalEntries();

    itemInteractionState.value = response.section_content_item_interaction;
    reset();
  },
  onReopen: () => {
    reset();
  },
});

const reset = () => {
  ratingsChanged.value = props.learningObjectives.map(() => false);
  commentChanged.value = false;
};

// lifecycle hooks
onMounted(async () => {
  console.log('props.learningObjectives', props.learningObjectives);
  if (!props.learningObjectives) return;
  ratings.value = props.learningObjectives.map(() => 0);
  hasBeenRated.value = props.learningObjectives.map(() => false);

  if (props.isEditing) return;
  await fetchJournalEntries();
});

// computed
const allItemsRated = computed(() => {
  return hasBeenRated.value.every((rated) => rated);
});

const somethingChanged = computed(() => {
  return ratingsChanged.value.some((changed) => changed) || commentChanged.value;
});

// methods
const fetchJournalEntries = async () => {
  /*
   * fetch entries for this section, see if they fit the learning objectives (as currently defined)
   * or if we have a comment for this section
   */
  const journalEntries = await (await getApiClient()).journals.listJournalEntriesForSection(props.sectionId, true);
  // find entries for current learning objectives
  for (const [index, objective] of props.learningObjectives.entries()) {
    const journalEntry = journalEntries.find(
      (entry: JournalEntry) => entry.learning_objective_description === objective.description,
    );
    if (journalEntry) {
      ratings.value[index] = <number>journalEntry.confidence_score ?? 0;
      hasBeenRated.value[index] = true;
    }
  }
  // find comment for this section
  const commentingJournalEntry = journalEntries.find((entry: JournalEntry) => entry.comment);
  if (commentingJournalEntry) {
    comment.value = <string>commentingJournalEntry.comment ?? '';
  }
};

const submitJournalEntries = async () => {
  /*
   * create new journal entries for changed inputs
   */
  for (const [index, objective] of props.learningObjectives.entries()) {
    console.log('ratingsChanged', ratingsChanged.value[index]);
    if (!ratingsChanged.value[index]) continue;
    await (
      await getApiClient()
    ).journals.createJournalEntry({
      section_id: props.sectionId,
      confidence_score: ratings.value[index],
      learning_objective_description: objective.description,
    });
  }
  console.log('commentChanged', commentChanged.value);
  if (comment.value.trim() === '' || !commentChanged.value) return;
  await (
    await getApiClient()
  ).journals.createJournalEntry({
    section_id: props.sectionId,
    comment: comment.value,
  });
};

// watch
watch(
  () => allItemsRated.value && somethingChanged.value,
  async (newValue) => {
    if (!newValue) return;
    console.log('newValue', newValue);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await handleExerciseFinished();
  },
);

watch(
  () => comment.value,
  () => {
    commentChanged.value = true;
  },
);
</script>

<template>
  <div>
    <Title title="Lerntagebuch" :icon-component="BookHeart" />

    <h2 class="w-full py-0 my-4 text-3xl font-medium rounded-lg text-start" v-if="props.learningObjectives.length > 0">
      Wie sicher fühlst du dich mit den Inhalten dieses Abschnitts?
    </h2>
    <h2 class="w-full py-0 my-4 text-3xl font-medium rounded-lg text-start" v-else-if="props.isEditing">
      Gib zunächst Lernziele für diesen Abschnitt ein.
    </h2>

    <div v-for="(objective, index) in props.learningObjectives" :key="index" class="py-1.5">
      <LearningObjectiveCard
        :description="objective.description"
        v-model:rating="ratings[index]"
        :show-scoring="true"
        :editable="false"
        @rated="
          () => {
            ratingsChanged[index] = true;
          }
        "
      />
    </div>

    <h2 class="w-full py-0 mt-6 mb-3 text-3xl font-medium rounded-lg text-start">
      Möchtest du deinem Tutor noch etwas sagen?
    </h2>
    <TextareaWithAudioInputNew v-model="comment" />
  </div>
</template>
