<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import { useCourseInteractionStore } from '@/stores';
import { ChevronLeft, ChevronRight } from 'lucide-vue-next';
import { SectionContentItem_Output as SectionContentItem } from '@/apiclient';

interface PageGroup {
  pageIndex: number;
  arrayIndex: number;
  items: SectionContentItem[];
}

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  currentPageArrayIndex: {
    type: [Number, null],
    required: true,
  },
  pages: {
    type: Array as PropType<PageGroup[]>,
    required: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  allowToggleEditing: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'toPreviousPage',
  'toNextPage',
  'toNextSection',
  'toCourseOverview',
  'onToggleIsEditing',
  'onEditOrder',
  'onAddPage',
  'toEditChapterOverview',
]);

const courseInteractionStore = useCourseInteractionStore();
const leaveInvalidPageTimeout = ref(null);

const isPageComplete = (pageIndex: number) => {
  return courseInteractionStore.isPageCompleted(props.section.id, pageIndex);
};

// Count completed pages
const completedPages = computed(() => {
  let completed = 0;
  for (let i = 0; i < numPages.value; i++) {
    if (isPageComplete(i)) {
      completed++;
    }
  }
  return completed;
});

const numPages = computed(() => {
  return props.pages.length;
});

const invalidPage = computed(() => {
  return props.currentPageArrayIndex > numPages.value - 1 && numPages.value > 0;
});

// Add computed property for current page completion status
const isCurrentPageComplete = computed(() => {
  return isPageComplete(props.currentPageArrayIndex);
});

watch(invalidPage, (newValue) => {
  if (newValue) {
    leaveInvalidPageTimeout.value = setTimeout(() => {
      emit('toPreviousPage');
    }, 1000);
  } else if (leaveInvalidPageTimeout.value) {
    clearTimeout(leaveInvalidPageTimeout.value);
    leaveInvalidPageTimeout.value = null;
  }
});
</script>

<template>
  <div>
    <!-- Top navigation bar -->
    <div class="w-full bg-white dark:bg-neutral-900 px-4 py-2 border-b border-gray-200 dark:border-gray-700">
      <div class="flex items-center justify-between">
        <!-- Back button and title -->
        <div
          @click="
            () => {
              if (props.isEditing) {
                emit('toEditChapterOverview');
                return;
              }
              emit('toCourseOverview');
            }
          "
          class="flex items-center gap-2 cursor-pointer pt-2 mb-1"
        >
          <span
            class="hover:bg-gray-50 dark:hover:bg-neutral-800 flex items-center justify-center text-center rounded-lg dark:text-white hover:text-gray-700 cursor-pointer"
          >
            <ChevronLeft class="w-5 h-5" />
          </span>
          <h2 class="text-base text-gray-900 dark:text-white truncate max-w-[200px]">
            {{ props.section.title }}
          </h2>
        </div>

        <!-- Toggle editing or sorting -->
        <div v-if="allowToggleEditing" class="hidden md:inline-flex items-center text-base px-2 relative">
          <span class="hidden sm:block text-sm pr-2">Bearbeiten</span>
          <input
            :checked="props.isEditing"
            @change="emit('onToggleIsEditing', $event?.target?.checked)"
            type="checkbox"
            id="hs-xs-switch-edit-chapter"
            class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 invalidPage:opacity-50 invalidPage:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
          />
          <span
            class="text-base pl-8 pr-2 cursor-pointer items-center text-center inline-flex"
            @click="emit('onEditOrder')"
            v-if="props.isEditing"
          >
            <span class="text-sm">Sortieren</span>
            <span translate="no" class="no-translate select-none material-symbols-outlined text-3xl">swap_vert</span>
          </span>
          <span
            class="text-base pl-4 pr-2 cursor-pointer items-center text-center inline-flex"
            @click="emit('onAddPage')"
            v-if="props.isEditing"
          >
            <span class="text-sm">Seite hinzufügen</span>
            <span translate="no" class="no-translate select-none material-symbols-outlined text-3xl">add</span>
          </span>
        </div>

        <!-- Progress counter -->
        <!-- <div class="text-sm text-gray-500 pt-2">{{ completedPages }}/{{ numPages }}</div> -->
      </div>

      <!-- Progress bar -->
      <div class="mt-2 flex gap-1">
        <!-- Title page -->
        <div
          class="h-1 flex-1 rounded-full transition-colors duration-300 bg-green"
          :class="{
            'ring-2 ring-green': currentPageArrayIndex === -1,
          }"
        />
        <!-- Other pages -->
        <div
          v-for="(page, index) in pages"
          :key="page.pageIndex"
          class="h-1 flex-1 rounded-full transition-colors duration-300"
          :class="{
            'bg-green': isPageComplete(page.pageIndex),
            'bg-gray-200 dark:bg-gray-700': !isPageComplete(page.pageIndex),
            'ring-2 ring-green': currentPageArrayIndex === index && isPageComplete(page.pageIndex),
            'ring-2 ring-gray-200 dark:ring-gray-700':
              currentPageArrayIndex === index && !isPageComplete(page.pageIndex),
          }"
        ></div>
      </div>
    </div>

    <!-- Sticky bottom navigation buttons -->
    <div class="fixed bottom-20 left-0 right-0 px-4 flex justify-between items-center z-50 pointer-events-none">
      <!-- Previous page button -->
      <button
        @click="emit('toPreviousPage')"
        class="h-12 w-12 bg-black dark:bg-white flex items-center justify-center text-center rounded-full cursor-pointer pointer-events-auto border border-gray-200 dark:border-gray-700"
        :class="{
          'text-white dark:text-black hover:bg-gray-900 dark:hover:bg-gray-100': true,
        }"
      >
        <ChevronLeft class="w-6 h-6" />
      </button>

      <!-- Next page/section button -->
      <button
        @click="emit('toNextPage')"
        class="h-12 w-12 flex items-center justify-center text-center rounded-full cursor-pointer pointer-events-auto border"
        :class="{
          'bg-blue-600 border-blue-600 text-white hover:bg-blue-700':
            isCurrentPageComplete && props.currentPageArrayIndex === numPages - 1,
          'bg-black dark:bg-white border-gray-200 dark:border-gray-700 text-white dark:text-black hover:bg-gray-900 dark:hover:bg-gray-100':
            !isCurrentPageComplete || !props.currentPageArrayIndex || props.currentPageArrayIndex < numPages - 1,
        }"
      >
        <ChevronRight class="w-6 h-6" />
      </button>
    </div>
  </div>
</template>

<style scoped>
.transition-colors {
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
</style>
