import { defineStore } from 'pinia';
import {
  CompanionInteraction,
  MessageType,
  EventType,
  CompanionInteractionMessage,
  Companion,
  InteractionMessageContent,
  PatientInteractionMessage,
} from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { getCurrentTask, useCaseInteractionStore } from './caseInteraction.store';
import { useAuthStore } from '@/stores/auth.store';

interface CompanionInteractionStoreState {
  currentCompanionInteractionId: string | null;
  currentCompanion: Companion | null;
  chatMessages: CompanionInteractionMessage[];
  chatIsStreaming: boolean;
  lastCompanionMessage: string;
}

export const useCompanionInteractionStore = defineStore({
  id: 'companionInteraction',
  state: (): CompanionInteractionStoreState => ({
    currentCompanionInteractionId: null,
    currentCompanion: null,
    chatMessages: [],
    chatIsStreaming: false,
    lastCompanionMessage: '',
  }),

  getters: {},

  actions: {
    // async initialize() {
    //   console.log('initialize companion interaction store');

    //   const companionInteractionStore = useCompanionInteractionStore();
    //   const companionInteractionId = companionInteractionStore.currentCompanionInteractionId;

    //   if (!companionInteractionId) {
    //     console.log('create companion interaction');
    //     await this.createCompanionInteraction();
    //   }
    // },

    // async createCompanionInteraction() {
    //   const companionInteractionStore = useCompanionInteractionStore();

    //   if (!companionInteractionStore.currentCompanion) {
    //     const companion = await (await getApiClient()).companions.getOrCreateCompanion();
    //     this.currentCompanion = companion;
    //   }

    //   console.log('created or fetched companion', companionInteractionStore.currentCompanion);

    //   const companionInteraction = await (
    //     await getApiClient()
    //   ).companions.interactWithCompanion(companionInteractionStore.currentCompanion.id);
    //   console.log('companionInteraction', companionInteraction);
    //   this.currentCompanionInteraction = companionInteraction;
    //   await this.loadMessages();
    // },

    async _initiateCompanionInteractionOnCaseInteraction() {
      const caseInteractionStore = useCaseInteractionStore();
      const caseInteraction = caseInteractionStore.currentCaseInteraction;
      if (!caseInteraction) {
        console.warn('Companion interaction set to null.');
        return;
      }
      this.currentCompanionInteractionId = caseInteraction.companion_interaction_id;
      await this.getOrCreateOwnCompanion();
    },

    async getOrCreateOwnCompanion() {
      const companionInteractionStore = useCompanionInteractionStore();
      if (!companionInteractionStore.currentCompanion) {
        const companion = await (await getApiClient()).companions.getOrCreateCompanion();
        this.currentCompanion = companion;
      }
    },

    async fetchHistory() {
      if (!this.currentCompanionInteractionId) return;

      this.chatMessages = await (
        await getApiClient()
      ).companionInteractions.listCompanionInteractionMessages(this.currentCompanionInteractionId);
    },

    async appendToChatMessage(idx: number, chunk: string) {
      let chunk_ = JSON.parse(chunk);
      try {
        if (chunk_.type === 'message') {
          this.chatMessages[idx].content['processed_model_output'] += chunk_['content'];
        } else if (chunk_.type === 'id') {
          this.chatMessages[idx].id = chunk_['content'];
          console.debug('Finished chat message with id ' + chunk_['content']);
        }
      } catch (e) {
        const companionInteractionStore = useCompanionInteractionStore();
        if (!companionInteractionStore.currentCompanionInteractionId) {
          console.warn('Companion interaction set to null while streaming. Ok if left interaction.');
          return;
        }
        throw e;
      }
    },

    async fetchAndReplaceChatMessage(idx: number) {
      const id = this.chatMessages[idx].id;
      console.debug(
        'Fetching complete message for incomplete chat message ' + id + ' of type ' + this.chatMessages[idx].type,
      );
      let completeMessage = await (await getApiClient()).companionMessages.getCompanionInteractionMessage(id);
      this.chatMessages[idx] = completeMessage;
      console.debug('Finished fetching complete chat message for incomplete message ' + id);
    },

    async appendToLastChatMessageAndRefetchOnceFinished(chunk: string) {
      const idx = this.chatMessages.length - 1;
      await this.appendToChatMessage(idx, chunk);
      if (this.chatMessages.length && this.chatMessages[idx].id !== 'not_yet_in_database') {
        // stream finished. Note: if this.chatMessages is empty, store has been reset while streaming
        this.chatIsStreaming = false;
        await this.fetchAndReplaceChatMessage(idx);
      }
    },

    async createPreliminaryChatMessage(userMessage: string = '', type: string = 'SAY') {
      this.chatIsStreaming = true; // will be reset by appendToLastChatMessageAndRefetchOnceFinished
      console.log('Creating preliminary chat message for companion interaction ' + this.currentCompanionInteractionId);

      const content: InteractionMessageContent = {
        processed_model_output: '',
        user_message: userMessage,
        user_message_language: null,
        timestamp: null,
      };
      const message: CompanionInteractionMessage = {
        id: 'not_yet_in_database',
        companion_interaction_id: this.currentCompanionInteractionId,
        content: content,
        type: type as MessageType,
        translations: null,
        created_at: 'incomplete',
      };
      this.chatMessages.push(message);
    },

    async discussCase(message: string) {
      const companionInteractionStore = useCompanionInteractionStore();
      if (!companionInteractionStore.currentCompanionInteractionId) return;

      const caseInteractionStore = useCaseInteractionStore();
      const authStore = useAuthStore();

      let subtask = caseInteractionStore.currentSubtask;
      if (!subtask) {
        console.error('no subtask');
        subtask = caseInteractionStore.currentTask?.subtasks[0];
        // return;
      }

      this.chatIsStreaming = true;
      await this.createPreliminaryChatMessage(message, MessageType.COMPANION_SAY);
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        '/companion-interactions/' + companionInteractionStore.currentCompanionInteractionId,
        {
          type: MessageType.COMPANION_SAY,
          content: message,
          event: {
            type: EventType.CASE_DISCUSSION,
            details: {
              contextual_case_interaction_id: caseInteractionStore.currentCaseInteractionId,
              current_subtask_id: subtask.id,
            },
          },
          language_level: authStore.currentLanguageLevel,
          current_task: caseInteractionStore.currentTask,
        },
        this.appendToLastChatMessageAndRefetchOnceFinished,
      );
    },

    async getTipForCase(message: string) {
      const companionInteractionStore = useCompanionInteractionStore();
      if (!companionInteractionStore.currentCompanionInteractionId) return;

      const caseInteractionStore = useCaseInteractionStore();
      const authStore = useAuthStore();

      let subtask = caseInteractionStore.currentSubtask;
      console.log('subtask = ', JSON.stringify(subtask));
      if (!subtask) {
        console.error('no subtask');
        subtask = caseInteractionStore.currentTask?.subtasks[0];
        // return;
      }

      this.chatIsStreaming = true;
      await this.createPreliminaryChatMessage(message, MessageType.COMPANION_REACT);
      await (
        await getStreamingClient()
      ).streamFetchRequest(
        'POST',
        '/companion-interactions/' + companionInteractionStore.currentCompanionInteractionId,
        {
          type: MessageType.COMPANION_REACT,
          content: message,
          event: {
            type: EventType.REQUEST_TIP_FOR_CASE,
            details: {
              contextual_case_interaction_id: caseInteractionStore.currentCaseInteractionId,
              current_subtask_id: subtask.id,
            },
          },
          language_level: authStore.currentLanguageLevel,
          current_task: caseInteractionStore.currentTask,
        },
        this.appendToLastChatMessageAndRefetchOnceFinished,
      );
    },

    reset() {
      this.currentCompanionInteractionId = null;
      this.currentCompanion = null;
      this.chatMessages = [];
      this.chatIsStreaming = false;
      this.lastCompanionMessage = '';
    },
  },
});
