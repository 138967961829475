import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { SectionContentItemOverview } from '@/apiclient';
import { storeToRefs } from 'pinia';

interface ExerciseLifecycleOptions {
  contentItem: SectionContentItemOverview;
  sectionIndex: number;
  pageIndex: number;
  isEditing: boolean;
  emit?: (event: 'addNewVocab') => void;
  preComplete?: () => void | Promise<void>;
  preCompleteDelay?: number;
  onComplete?: (response: any) => void | Promise<void>;
  postComplete?: (contentItem: SectionContentItemOverview) => void | Promise<void>;
  preReopen?: () => void | Promise<void>;
  onReopen?: () => void | Promise<void>;
  onFetched?: () => void | Promise<void>;
  fetchTimeout?: number;
}

export function useExerciseLifecycle({
  contentItem,
  sectionIndex,
  pageIndex,
  isEditing,
  emit,
  preComplete,
  preCompleteDelay,
  onComplete,
  postComplete,
  preReopen,
  onReopen,
  onFetched,
  fetchTimeout,
}: ExerciseLifecycleOptions) {
  const courseInteractionStore = useCourseInteractionStore();
  const authStore = useAuthStore();
  const alertStore = useAlertStore();

  const { t } = useI18n();

  const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

  const itemInteractionState = ref({});
  const fetchCompleted = ref(false);
  const fetchTimeoutTimer = ref<NodeJS.Timeout | null>(null);
  const fetchTimeoutMs = fetchTimeout ?? 7000;

  // Define default handlers after itemInteractionState is created
  const defaultOnComplete = (response: any) => {
    itemInteractionState.value = response.section_content_item_interaction;
  };

  const defaultPostComplete = (contentItem: SectionContentItemOverview) => {
    if ((contentItem.use_introduced_vocab_list || contentItem.meaning_and_making_exercise) && emit) {
      emit('addNewVocab');
    }
  };

  const defaultOnReopen = () => {};
  const defaultPreReopen = () => {};
  const defaultOnFetched = () => {};

  const handleExerciseFinished = async (options: Partial<ExerciseLifecycleOptions> = {}) => {
    if (preComplete || options.preComplete) {
      await (options.preComplete || preComplete)?.();
    }

    await new Promise((resolve) => setTimeout(resolve, options.preCompleteDelay || preCompleteDelay || 200));

    try {
      const response = await courseInteractionStore.setSectionContentItemCompletedIfNotAlready(
        contentItem.id,
        sectionIndex,
        pageIndex,
      );

      if (response) {
        // Handle notifications and XP
        response.notifications?.forEach((notification) => {
          alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
        });

        // Update user XP
        await authStore.fetchUserXp();

        await (options.onComplete || onComplete || defaultOnComplete)(response);
      }

      // Default post-completion delay
      await new Promise((resolve) => setTimeout(resolve, 500));

      await (options.postComplete || postComplete || defaultPostComplete)(contentItem);

      return response;
    } catch (error) {
      console.error('Exercise completion failed:', error);
      alertStore.error('Error completing exercise');
      throw error;
    }
  };

  const fetchInteractionState = async (options: Partial<ExerciseLifecycleOptions> = {}) => {
    let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(contentItem.id);
    // create local copy - we want to defer updates to wait for animations etc
    if (!!interactionState && interactionState !== {}) {
      fetchCompleted.value = true;
      itemInteractionState.value = { ...interactionState };
      if (fetchTimeoutTimer.value) {
        clearTimeout(fetchTimeoutTimer.value);
        fetchTimeoutTimer.value = null;
      }
    }
    await (options.onFetched || onFetched || defaultOnFetched)();
  };

  watch(
    () => courseInteractionFetchCompleted.value,
    async () => {
      if (!isEditing) {
        await nextTick();
        await fetchInteractionState();
      }
    },
    { immediate: true },
  );

  const reopenExercise = async (options: Partial<ExerciseLifecycleOptions> = {}) => {
    await (options.preReopen || preReopen || defaultPreReopen)();

    const response = await courseInteractionStore.reopenSectionContentItem(contentItem.id);

    if (!response) return; // nothing has happened as the item was already started

    itemInteractionState.value = response;

    // Let the component handle its specific reset logic
    await (options.onReopen || onReopen || defaultOnReopen)();
  };

  onMounted(() => {
    if (fetchTimeoutMs == null || isEditing) return;
    console.log('setting timer with timeout: ' + fetchTimeoutMs + 'ms.');
    fetchTimeoutTimer.value = setTimeout(() => {
      throw new Error(
        'useExerciseLifecycle: Fetch timeout: fetchInteractionState timed out or never called / not called in time. Timeout: ' +
          fetchTimeoutMs +
          'ms.',
      );
    }, fetchTimeoutMs);
  });

  onBeforeUnmount(() => {
    if (!fetchTimeoutTimer.value) return;
    clearTimeout(fetchTimeoutTimer.value);
    fetchTimeoutTimer.value = null;
  });

  return {
    handleExerciseFinished,
    fetchInteractionState,
    reopenExercise,
    itemInteractionState,
    fetchCompleted,
  };
}
