import ERWaitingRoom from '@/views/er-waiting-room/ERWaitingRoom.vue';
import { LoggedInRoot } from '@/views/root';
import { Profile, UserDashboard } from '@/views/home';
import { EditCourseChapter, CourseSection } from '@/views/courses';
import { CreateNewCase } from '@/views/new-case';
import VocabDashboard from '@/components/didactics/VocabDashboard.vue';
import Playground from '@/components/Playground.vue';
import CourseListNew from '@/views/courses/CourseListNew.vue';
import CourseDetailsNew from '@/views/courses/CourseDetailsNew.vue';
import EditCourseDetails from '@/views/courses/EditCourseDetails.vue';

export default {
  path: '/home',
  component: LoggedInRoot,
  children: [
    {
      name: 'user-dashboard',
      path: '',
      component: UserDashboard,
      meta: { forceNewRouterKey: 'home' },
    },
    {
      path: '/profile',
      component: Profile,
      name: 'Profile',
      meta: { forceNewRouterKey: 'profile' },
    },
    {
      name: 'new-or-edit-case',
      path: '/new-case',
      meta: { forceNewRouterKey: 'new-case' },
      component: CreateNewCase,
      props: (route: {
        query: {
          isCopy: string;
          isEdit: string;
          isViewOnly: string;
          fromCaseId: string;
          inCaseListId: string;
        };
      }) => ({
        fromCaseId: route.query.fromCaseId,
        editCase: route.query.isEdit === 'true',
        copyCase: route.query.isCopy === 'true',
        viewOnly: route.query.isViewOnly === 'true',
        caseListId: route.query.inCaseListId,
      }),
    },
    // course library
    {
      name: 'course-library',
      path: '/courses',
      component: CourseListNew,
      props: { type: 'library' },
      meta: { forceNewRouterKey: 'courses' },
    },
    {
      path: '/course/:courseId',
      component: CourseDetailsNew,
      meta: { forceNewRouterKey: 'course' },
    },
    {
      path: '/edit-course/:courseId',
      component: EditCourseDetails,
      meta: { forceNewRouterKey: 'edit-course' },
    },
    { path: '/edit-chapter/:chapterId', component: EditCourseChapter, meta: { forceNewRouterKey: 'chapter' } },
    {
      name: 'course-section',
      path: '/section/:sectionId',
      component: CourseSection,
      props: (route: { query: { page: string } }) => {
        let pageNumber = parseInt(route.query.page as string);
        if (isNaN(pageNumber)) {
          pageNumber = -1;
        }
        return { page: pageNumber };
      },
      meta: { forceNewRouterKey: 'section' },
    },
    {
      name: 'my-courses',
      path: '/my-courses',
      // component: CourseLibrary,
      component: CourseListNew,
      props: { type: 'my-courses' },
      meta: { forceNewRouterKey: 'courses-subs' },
    },
    // cases
    { name: 'all-cases', path: '/wartebereich-zna', component: ERWaitingRoom, meta: { forceNewRouterKey: 'zna' } },
    // vocab
    {
      path: '/vocab-dashboard',
      component: VocabDashboard,
      name: 'VocabDashboard',
      meta: { forceNewRouterKey: 'VocabDashboard' },
      props: (route: {
        query: {
          startInPracticeMode: string;
          hideTabularNav: string;
        };
      }) => ({
        startInPracticeMode: route.query.startInPracticeMode === 'true',
        hideTabularNav: route.query.hideTabularNav === 'true',
      }),
    },
    {
      path: '/dev',
      component: Playground,
    },
    // {
    //   path: '/dev2',
    //   component: Cloze,
    // },
  ],
};
