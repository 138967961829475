import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';
import {
  CourseInteractionWithProgress,
  JournalEntry,
  MemberProgress,
  MemberStats,
  Organization,
  OrganizationMember,
  OrganizationMemberDetails,
  OrganizationSeats,
  OrganizationSubscription,
  OrganizationSubscriptionDetails,
} from '@/apiclient';

export const useOrganizationStore = defineStore({
  id: 'organization',
  state: () => ({
    managedOrganizations: [] as Organization[],
    tutoredOrganizations: [] as Organization[],
    currentlyViewedOrganizationId: null as string | null,
    activeSubscriptions: {} as Record<string, OrganizationSubscriptionDetails[]>,
    pastSubscriptions: {} as Record<string, OrganizationSubscriptionDetails[]>,
    seats: {} as Record<string, OrganizationSeats>,
    members: {} as Record<string, OrganizationMemberDetails[]>,
    countryStats: {} as Record<string, any>,
    nativeLanguageStats: {} as Record<string, any>,
    // memberCourseInteractionsWithProgress: {} as Record<string, CourseInteractionWithProgress[]>,
    memberProgress: {} as Record<string, MemberProgress[]>,
    memberJournalEntries: {} as Record<string, JournalEntry[]>,
  }),
  getters: {
    organizations(state): Organization[] {
      return [...state.managedOrganizations, ...state.tutoredOrganizations];
    },
    currentlyViewedOrganization(state): Organization | null {
      if (!state.currentlyViewedOrganizationId) return null;
      return state.organizations.find((org) => org.id === state.currentlyViewedOrganizationId) || null;
    },
    name(state): string {
      return this.currentlyViewedOrganization ? this.currentlyViewedOrganization.name : '';
    },
    id(state): string {
      return state.currentlyViewedOrganizationId || '';
    },
    orgIsManagedByUser(state): boolean {
      return state.currentlyViewedOrganizationId
        ? state.managedOrganizations.some((org) => org.id === state.currentlyViewedOrganizationId)
        : false;
    },
    orgIsTutoredByUser(state): boolean {
      return state.currentlyViewedOrganizationId
        ? state.tutoredOrganizations.some((org) => org.id === state.currentlyViewedOrganizationId)
        : false;
    },
    orgActiveSubscriptions(state): OrganizationSubscriptionDetails[] | null {
      return state.currentlyViewedOrganizationId
        ? state.activeSubscriptions[state.currentlyViewedOrganizationId] || null
        : null;
    },
    orgCountryStats(state): Record<string, any> | null {
      return state.currentlyViewedOrganizationId
        ? state.countryStats[state.currentlyViewedOrganizationId] || null
        : null;
    },
    orgMembers(state): OrganizationMemberDetails[] | null {
      return state.currentlyViewedOrganizationId ? state.members[state.currentlyViewedOrganizationId] || null : null;
    },
    orgSeats(state): OrganizationSeats | null {
      return state.currentlyViewedOrganizationId ? state.seats[state.currentlyViewedOrganizationId] || null : null;
    },
    orgMemberProgress(state): MemberProgress[] | null {
      return state.currentlyViewedOrganizationId
        ? state.memberProgress[state.currentlyViewedOrganizationId] || null
        : null;
    },
    orgMemberJournalEntries(state): JournalEntry[] | null {
      return state.currentlyViewedOrganizationId
        ? state.memberJournalEntries[state.currentlyViewedOrganizationId] || null
        : null;
    },
    numberUserSeats(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_user_seats
        : 0;
    },
    numberManagerSeats(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_manager_seats
        : 0;
    },
    numberFreeUserSeats(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_free_user_seats
        : 0;
    },
    numberFreeManagerSeats(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_free_manager_seats
        : 0;
    },
    numberActiveUsers(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_active_users
        : 0;
    },
    numberActiveInvitedUsers(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_active_invited_users
        : 0;
    },
    numberActiveManagers(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_active_managers
        : 0;
    },
    numberDeactivatedUsers(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_deactivated_users
        : 0;
    },
    numberDeactivatedInvitedUsers(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_deactivated_invited_users
        : 0;
    },
    numberDeactivatedManagers(state): number {
      if (!state.currentlyViewedOrganizationId) return 0;
      return state.seats[state.currentlyViewedOrganizationId]
        ? state.seats[state.currentlyViewedOrganizationId].n_deactivated_managers
        : 0;
    },
    maxCountForSingleCountry(state): number {
      if (!state.currentlyViewedOrganizationId || !state.countryStats[state.currentlyViewedOrganizationId]) return 0;

      const stats = state.countryStats[state.currentlyViewedOrganizationId];
      let maxCount = 0;

      // Adjust based on actual structure of countryStats
      Object.values(stats).forEach((countryStat: any) => {
        if (countryStat.count > maxCount) {
          maxCount = countryStat.count;
        }
      });

      return maxCount;
    },
  },
  actions: {
    async loadOrganizations() {
      const authStore = useAuthStore();
      const client = await getApiClient();

      try {
        const [managedOrgs, tutoredOrgs] = await Promise.all([
          client.users.listManagedOrganizationsOfUser(authStore.userId),
          client.users.listTutoredOrganizationsOfUser(authStore.userId),
        ]);

        this.managedOrganizations = managedOrgs;
        this.tutoredOrganizations = tutoredOrgs;

        // Set the first organization as current if none is selected
        if (this.organizations.length > 0 && !this.currentlyViewedOrganizationId) {
          this.setCurrentOrganization(this.organizations[0].id);
        }
      } catch (error) {
        console.error('Error loading organizations:', error);
      }
    },

    setCurrentOrganization(organizationId: string) {
      this.currentlyViewedOrganizationId = organizationId;
    },

    async loadSubscriptions() {
      if (!this.id) {
        await this.loadOrganizations();
      }
      if (!this.id) return;

      const client = await getApiClient();

      try {
        // Get active subscription
        const activeSubscriptions = await client.organizations.listOrganizationSubscriptions(this.id, true);

        this.activeSubscriptions[this.id] = activeSubscriptions;

        // Get past subscriptions
        const pastSubscriptions = await client.organizations.listOrganizationSubscriptions(this.id, false);

        this.pastSubscriptions[this.id] = pastSubscriptions;
      } catch (error) {
        console.error('Error loading subscriptions:', error);
      }
    },

    async loadSeats() {
      if (!this.currentlyViewedOrganizationId) {
        await this.loadOrganizations();
      }

      if (!this.id) return;

      const client = await getApiClient();

      try {
        const seats = await client.organizations.getOrganizationSeats(this.id);
        this.seats[this.id] = seats;
      } catch (error) {
        console.error('Error loading seats:', error);
      }
    },

    async loadMembers(searchTerm: string | null = null) {
      if (!this.id) {
        await this.loadOrganizations();
      }
      if (!this.id) return;

      const client = await getApiClient();

      try {
        const members = await client.organizations.listOrganizationMembers(this.id, searchTerm);
        this.members[this.id] = members;
      } catch (error) {
        console.error('Error loading members:', error);
      }
    },

    async loadOrganizationCountryStats() {
      if (!this.currentlyViewedOrganization) {
        await this.loadOrganizations();
      }
      const client = await getApiClient();
      await client.organizations
        .getOrganizationCountryStats(this.id)
        .then((countryStats) => {
          this.countryStats[this.id] = countryStats;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },

    async loadProgress() {
      if (!this.id) {
        await this.loadOrganizations();
      }
      if (!this.id) return;
      await (
        await getApiClient()
      ).organizations
        .listOrganizationMembersProgress(this.id!)
        .then((stats) => {
          this.memberProgress[this.id] = stats;
        })
        .catch((error: Error) => {
          console.error('Error:', error);
        });
    },

    async loadJournalEntries() {
      if (!this.id) {
        await this.loadOrganizations();
      }
      if (!this.id) return;
      await (
        await getApiClient()
      ).organizations
        .listOrganizationMembersJournalEntries(this.id!)
        .then((journalEntries: JournalEntry[]) => {
          this.memberJournalEntries[this.id] = journalEntries;
        })
        .catch((error: Error) => {
          console.error('Error:', error);
        });
    },

    async loadOrganizationNativeLanguageStats() {
      if (!this.id) {
        await this.loadOrganizations();
      }
      if (!this.id) return;
      await (
        await getApiClient()
      ).organizations
        .getOrganizationLanguageStats('native_language')
        .then((nativeLanguageStats) => {
          this.nativeLanguageStats[this.id] = nativeLanguageStats;
        })
        .catch((error: Error) => {
          console.error('Error:', error);
        });
    },

    async deactivateMember(userId: string) {
      if (!this.currentlyViewedOrganization) {
        await this.loadOrganizations();
      }
      const client = await getApiClient();
      await client.organizations.editMemberOfOrganization(this.id, userId, { deactivated_at: 'now' });
      // refersh the members list
      this.loadMembers();
      this.loadSeats();
    },

    async activateMember(userId: string) {
      if (!this.currentlyViewedOrganization) {
        await this.loadOrganizations();
      }
      const client = await getApiClient();
      await client.organizations.editMemberOfOrganization(this.id, userId, { deactivated_at: null });
      // refersh the members list
      this.loadMembers();
      this.loadSeats();
    },
  },
});
