<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue';
import RemoveButton from '@/components/RemoveButton.vue';
import AddIntroducedVocab from '@/components/didactics/AddIntroducedVocab.vue';
import { SectionContentItemOverview } from '@/apiclient';

const props = defineProps({
  contentItem: {
    type: Object as () => SectionContentItemOverview,
    required: true,
  },
  allowEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  fullWidthIndex: {
    type: Number,
    required: false,
    default: -1,
  },
  index: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['onDeleteContentItem']);

const contentType = computed(() => props.contentItem.content_type);

const isFullWidth = computed(() => {
  const isSmallType = contentType.value === 'MEDIA' || contentType.value === 'CASE';
  return !isSmallType || props.fullWidthIndex === props.index;
});

const canIntroduceVocab = computed(() => {
  // TODO: exclude types that should not be allowed to introduce vocabs
  return props.allowEditing;
});

const handleDeleteContentItem = () => {
  emit('onDeleteContentItem', props.contentItem.id, contentType.value, props.contentItem.index);
};
</script>

<template>
  <div
    class="transition-all auto-rows-min duration-500 ease-in-out relative rounded-lg"
    :class="{
      'col-span-full': isFullWidth,
      'pt-10': canIntroduceVocab,
      'border border-gray-200': props.allowEditing,
    }"
  >
    <AddIntroducedVocab v-if="canIntroduceVocab" :contentItem="contentItem" />

    <!-- content type: {{ contentType }} -->

    <slot></slot>

    <RemoveButton v-if="props.allowEditing" @removeItem="handleDeleteContentItem" :top="false" :halfDistance="true" />
  </div>
</template>

<style scoped></style>
