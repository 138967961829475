<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { Loader } from 'lucide-vue-next';
import TextareaWithAudioInputNew from '@/components/inputs/TextareaWithAudioInputNew.vue';
import { useAutosave } from '@/composables';
import ResponseContainer from '../vocab/VocabModal/ResponseContainer.vue';

const { t, locale } = useI18n();

// stores
const alertStore = useAlertStore();
const authStore = useAuthStore();
const courseInteractionStore = useCourseInteractionStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

// props
const props = defineProps({
  sectionContentItemId: {
    type: String,
    required: true,
  },
  sectionContentItemIndex: {
    type: Number,
    required: true,
  },
  resourceId: {
    type: String,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  itemInteractionState: {
    type: Object,
    required: true,
  },
});

// emits
const emit = defineEmits(['interactionStarted', 'questionSolved', 'scrollBy', 'undoLastScroll']);

// composables
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.isEditing) return;

    await (
      await getApiClient()
    ).courseSectionItems.updateOpenQuestionInContentItemWithResource(props.sectionContentItemId, props.question.id, {
      question_text: localQuestion.value.question_text,
      question_media_item_id: localQuestion.value.question_media_item_id,
      sample_answer: localQuestion.value.sample_answer,
    });
    // TODO would expect this needs to be done over the store, but not problem so far

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

// ref
const textarea = ref<HTMLTextAreaElement | null>(null);

// state
const localQuestion = ref({
  ...props.question,
  attempted_answer: props.question.attempted_answer || '',
});
const hasBeenCheckedCorrect = ref(false); // disallow multiple checks (at least until remounted...)
const isSolved = ref(false);
const isWrong = ref(false);
const feedback = ref('');
const isGettingFeedback = ref(false);
const textareaHeightAdjustedToInitialFetch = ref(false);
const isTranscribingAudio = ref(false);

// lifecycle
onMounted(async () => {
  emit('questionSolved', false);
  if (textarea.value && !localQuestion.value.question_text) {
    updateMinTextareaHeight(textarea.value, 1);
  }
});

// methods
const getAnswerValue = () => {
  return props.isEditing ? localQuestion.value.sample_answer : localQuestion.value.attempted_answer;
};

const setAnswerValue = (value: string) => {
  console.log('setAnswerValue', value);
  if (props.isEditing) {
    localQuestion.value.sample_answer = value;
    unsavedChanges.value = true;
  } else {
    localQuestion.value.attempted_answer = value;
    emit('interactionStarted');
  }
};

watch(
  () => textarea.value,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        updateMinTextareaHeight(newVal, 1);
      }, 100);
    }
  },
);

watch(
  // this updates the textarea height when the question text is set initially
  () => localQuestion.value.question_text,
  (newVal) => {
    if (textareaHeightAdjustedToInitialFetch.value) return;
    if (!textarea.value) return;
    if (!localQuestion.value.question_text) return; // empty, nothing to do
    if (newVal) {
      setTimeout(() => {
        updateMinTextareaHeight(textarea.value, 1);
        textareaHeightAdjustedToInitialFetch.value = true;
      }, 100);
    }
  },
);

watch(
  () => textarea.value?.scrollHeight,
  (newVal) => {
    updateMinTextareaHeight(textarea.value, 1);
  },
);

const submitAnswer = async () => {
  if (!courseInteractionFetchCompleted.value) return;
  if (hasBeenCheckedCorrect.value) return; // disallow multiple checks (at least until remounted...)
  isGettingFeedback.value = true;
  await courseInteractionStore
    .evaluateOpenQuestion(
      props.sectionContentItemId,
      props.resourceId,
      localQuestion.value.id,
      localQuestion.value.attempted_answer,
    )
    .then(async (response) => {
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      await authStore.fetchUserXp();
      if (response.is_correct) {
        isSolved.value = true;
        emit('questionSolved', true);
        hasBeenCheckedCorrect.value = true;
      } else {
        isWrong.value = true;
        alertStore.playMajorFailureSound();
      }
      feedback.value = response.feedback;
    })
    .catch((error) => {
      alertStore.error('Failed to evaluate form', 'Error', error);
      console.error(error);
      throw new Error('Failed to evaluate form');
    })
    .finally(() => {
      isGettingFeedback.value = false;
    });
};

watch(
  () => props.itemInteractionState?.reopened_at,
  (newReponedAt) => {
    if (!newReponedAt) return;
    // Reset the question state
    hasBeenCheckedCorrect.value = false;
    isSolved.value = false;
    isWrong.value = false;
    feedback.value = '';
    localQuestion.value.attempted_answer = '';
    emit('questionSolved', false);
  },
);
</script>

<template>
  <div class="border-gray-200 bg-white shadow-sm mx-2 p-4 rounded-2xl text-sm">
    <div class="text-xs font-semibold">Frage</div>
    <textarea
      ref="textarea"
      v-if="props.isEditing && (!!localQuestion.question_text || props.isEditing)"
      v-model="localQuestion.question_text"
      class="w-full py-0 my-2 resize-none text-sm rounded-lg text-start border-gray-200"
      rows="1"
      @input="
        (event) => {
          const target = event.target as HTMLInputElement;
          updateMinTextareaHeight(target);
          if (props.isEditing) unsavedChanges = true;
        }
      "
    />
    <h2 v-else-if="localQuestion.question_text" class="w-full py-0 my-4 text-3xl font-medium rounded-lg text-start">
      {{ localQuestion.question_text }}
    </h2>

    <div class="flex items-center text-xs md:text-sm">
      <div class="relative w-full flex-col flex">
        <div class="w-full flex-col flex relative pt-2">
          <span v-if="props.isEditing" class="gray-500 font-semibold text-xs">
            {{ props.isEditing ? 'Musterlösung' : 'Deine Antwort' }}
          </span>

          <!-- Regular textarea for editing mode -->
          <textarea
            v-if="props.isEditing"
            v-model="localQuestion.sample_answer"
            class="w-full px-2 py-0 my-2 resize-none rounded-lg text-sm text-start border-gray-200"
            :placeholder="props.isEditing ? 'Musterlösung' : 'Antwort'"
            rows="1"
            @input="
              (event) => {
                const target = event.target as HTMLInputElement;
                updateMinTextareaHeight(target);
                unsavedChanges = true;
              }
            "
          />

          <!-- Loader -->
          <div
            v-if="isGettingFeedback"
            class="flex h-28 mt-4 rounded-2xl justify-center items-center border-orange bg-orange-light"
          >
            <Loader :size="56" class="animate-spin text-orange" />
          </div>

          <!-- Response Container -->
          <ResponseContainer
            v-else-if="isSolved || isWrong"
            :type="isSolved ? 'correct' : 'wrong'"
            :message="feedback"
            class="mt-4"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="w-full overflow-hidden flex pt-4">
    <!-- TextareaWithAudioInputNew for student answers -->
    <TextareaWithAudioInputNew
      v-if="!props.isEditing"
      :model-value="localQuestion.attempted_answer"
      v-model="localQuestion.attempted_answer"
      class="w-full"
      :placeholder="'Antwort'"
      :disabled="isSolved || isGettingFeedback || isTranscribingAudio"
      :allow-audio-input="true"
      :allow-text-input="true"
      :is-submitted="isSolved"
      :is-finished="false"
      @submit="submitAnswer"
      @update:model-value="setAnswerValue"
    />
  </div>
</template>

<style scoped></style>
