<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { House, BookOpenText, Languages, UserRoundPlus } from 'lucide-vue-next';
import CaseService from '@/services/CaseService';
const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const bottomNavContainer = ref(null);
const authStore = useAuthStore();

const navigate = (path: string) => {
  router.push(path);
};

const scrollHeight = computed(() => {
  return bottomNavContainer.value?.scrollHeight;
});

defineExpose({
  scrollHeight,
});
</script>

<template>
  <nav ref="bottomNavContainer" class="fixed bottom-0 left-0 w-full z-[30] lg:hidden">
    <!-- Masked background -->
    <div class="absolute inset-0 bg-gray/90 dark:bg-gray-800/90 backdrop-blur-md masked-bg"></div>

    <!-- Navigation content -->
    <div class="grid grid-cols-4 h-16 mb-2 relative z-[1]">
      <!-- Home -->
      <button
        @click="navigate('/home')"
        class="flex flex-col items-center justify-center gap-1"
        :class="route.path === '/home' ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'"
      >
        <House size="20" />
        <span class="text-xs text-medium tracking-[-0.01em]">{{ t('message.home') }}</span>
      </button>

      <!-- Courses -->
      <button
        @click="navigate('/my-courses')"
        class="flex flex-col items-center justify-center gap-1"
        :class="route.path.includes('/my-courses') ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'"
      >
        <BookOpenText size="20" />
        <span class="text-xs">{{ t('message.courses') }}</span>
      </button>

      <!-- Vocabulary -->
      <button
        @click="navigate('/vocab-dashboard')"
        class="flex flex-col items-center justify-center gap-1"
        :class="
          route.path.includes('/vocab-dashboard') ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'
        "
      >
        <Languages size="20" />
        <span class="text-xs text-medium tracking-[-0.01em]">{{ t('message.vocabulary') }}</span>
      </button>

      <!-- Cases -->
      <button
        @click="CaseService.navigateToMyCases(authStore.user.id)"
        class="flex flex-col items-center justify-center gap-1"
        :class="
          route.path.includes('case-interactions') ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'
        "
      >
        <UserRoundPlus size="20" />
        <span class="text-xs text-medium tracking-[-0.01em]">{{ t('message.cases') }}</span>
      </button>
    </div>
  </nav>
</template>

<style scoped>
.masked-bg {
  mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.4) 15%,
    rgba(255, 255, 255, 0.8) 30%,
    black 50%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(255, 255, 255, 0.4) 15%,
    rgba(255, 255, 255, 0.8) 30%,
    black 50%
  );
}
</style>
