<script setup lang="ts">
import { ChevronUp, ChevronDown } from 'lucide-vue-next';
import { FunctionalComponent } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
    default: 'bg-white',
  },
  isClickable: {
    type: Boolean,
    default: true,
  },
  borderRadius: {
    type: String,
    default: 'rounded-2xl',
  },
  showArrowIcon: {
    type: Boolean,
    default: false,
  },
  openIcon: {
    type: Object as () => FunctionalComponent,
    default: null,
  },
  closeIcon: {
    type: Object as () => FunctionalComponent,
    default: null,
  },
  playIcon: {
    type: Object as () => FunctionalComponent,
    default: null,
  },
  iconSize: {
    type: Number,
    default: 20,
  },
  showDivider: {
    type: Boolean,
    default: true,
  },
  arrangeIconsHorizontally: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['toggle', 'play']);

const onToggle = (event: Event) => {
  if (!props.isClickable) {
    return;
  }
  event.stopPropagation();
  emit('toggle');
};

const onPlay = () => {
  if (!props.isClickable) {
    return;
  }
  emit('play');
};
</script>

<template>
  <div class="flex flex-col py-3 px-4 transition-colors duration-300" :class="[bgColor, borderRadius]">
    <div class="flex items-center gap-x-3" :class="{ 'cursor-pointer': isClickable }" @click="onToggle">
      <slot name="header" />

      <div
        class="flex items-center min-h-full"
        :class="{
          'flex-row gap-x-1': arrangeIconsHorizontally,
          'flex-col gap-y-1 -mr-2 -my-2': !arrangeIconsHorizontally,
        }"
      >
        <div v-if="!!playIcon" :icon-size="iconSize" class="flex items-center gap-x-3" @click="onPlay">
          <component :is="playIcon" :size="iconSize" fill="currentColor" />
        </div>

        <div v-if="!!playIcon" :class="arrangeIconsHorizontally ? 'hidden' : 'flex-grow'"></div>

        <div v-if="showArrowIcon">
          <transition name="fade" mode="out-in" v-if="openIcon && closeIcon">
            <component :is="isOpen ? openIcon : closeIcon" :size="iconSize" />
          </transition>
          <ChevronDown
            v-else
            :size="iconSize"
            class="transition-transform duration-300"
            :class="{ 'rotate-180': isOpen }"
          />
        </div>
      </div>
    </div>

    <transition name="slide">
      <div v-if="isOpen" class="flex flex-col gap-y-3 pt-3 text-sm">
        <hr v-if="showDivider" class="h-[1px] bg-gray-medium opacity-20 border-0" />
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease-out;
  max-height: 500px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
