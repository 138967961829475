<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, nextTick } from 'vue';
import { HSTooltip } from 'preline';
import DropDownListItem from '@/components/DropDownListItem.vue';
import { useAlertStore } from '@/stores';
import { v4 as uuidv4 } from 'uuid';

const emit = defineEmits(['addTextSnippet', 'addSetPhraseList']);

const popoverIsOpen = ref(false);
const popover = ref(null);
const container = ref(null);
const alertStore = useAlertStore();
const uuid = uuidv4();

const props = defineProps({
  sectionId: {
    type: String,
    required: true,
  },
  createVocabRef: {
    type: Object,
    required: false,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  dataTestOverlayIdSuffix: {
    type: String,
    required: false,
  },
});

onMounted(async () => {
  await nextTick();

  let el = document.querySelector(`#select-new-text-item-${props.sectionId}-${uuid}`);
  if (el) {
    try {
      // Try to get and destroy existing instance
      const { instance: existingInstance } = HSTooltip.getInstance(el, true);
      if (existingInstance) {
        existingInstance.destroy();
      }
    } catch (e) {
      // Ignore any errors during cleanup
      console.debug('No existing tooltip instance found, ', e);
    }

    // Create new instance
    try {
      await new Promise((resolve) => setTimeout(resolve, 100));
      popover.value = new HSTooltip(el);
    } catch (e) {
      console.error('Failed to create tooltip:', e);
    }
  }

  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event: Event) => {
  if (container.value && !!popover.value && !container.value.contains(event.target)) {
    popoverIsOpen.value = false;
    if (!popover.value) return;
    popover.value.hide();
  }
};

const togglePopover = () => {
  if (!popover.value) return;
  popoverIsOpen.value = !popoverIsOpen.value;
  if (popoverIsOpen.value) {
    if (!popover.value) return;
    popover.value.show();
  } else {
    if (!popover.value) return;
    popover.value.hide();
  }
};

const handleAttemptCreateVocabTest = async () => {
  alertStore.info(
    'Um einen Vokabeltest zu gestalten, fügen Sie einfach eine Vokabelliste hinzu. Diese wird für den Nutzer automatisch in einen Test umgewandelt.',
  );
  togglePopover();

  await new Promise((resolve) => {
    setTimeout(() => {
      props.createVocabRef?.togglePopover();
      resolve(true);
    }, 200);
  });
};
</script>

<template>
  <!-- Base card -->
  <div class="relative h-full w-full z-20" ref="container">
    <button
      class="h-full w-full cursor-pointer select-none flex-col flex border-2 border-gray-200 border-dashed bg-white rounded-xl text-center items-center justify-center"
      @click.prevent="togglePopover"
      :data-testid="`text-item-create-button-${dataTestOverlayIdSuffix}`"
    >
      <span
        translate="no"
        class="material-symbols-outlined notranslate text-gray-400 dark:text-gray-500 text-7xl text-center"
        >description</span
      >
      <span class="pe-1 font-medium text-gray-400 dark:text-neutral-200 text-sm pt-2 pb-1">Weiteren Textabschnitt</span>
      <span
        class="relative text-sm cursor-pointer font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600"
        >Erstellen</span
      >
    </button>

    <!--    <button-->
    <!--        class="h-full cursor-pointer select-none flex-col flex border-2 border-gray-200 border-dashed bg-white rounded-xl text-center items-center justify-center"-->
    <!--        :disabled="isAddingContentItem"-->
    <!--        @click.prevent="addTextSnippet"-->
    <!--    >-->

    <!--    </button>-->
    <!-- End Base card -->

    <!-- Popover to select type of exercise to create -->
    <div
      :id="'select-new-text-item-' + sectionId + '-' + uuid"
      class="hs-tooltip [--trigger:click] [--placement:left] mt-20 inline-block absolute top-[-128px] md:top-[48px] left-[164px] md:left-[64px]"
    >
      <span
        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
        role="tooltip"
      >
        <span class="flex-col flex overflow-y-scroll">
          <DropDownListItem
            header="Textabschnitt"
            description="Ein allgemeiner Textabschnitt"
            :center-items="true"
            @click.prevent="
              () => {
                emit('addTextSnippet', 'GENERAL');
                togglePopover();
              }
            "
            :limitedDescriptionWidth="true"
            :data-testid="`add-general-text-item-button-${dataTestOverlayIdSuffix}`"
          />
          <DropDownListItem
            header="Lernstrategie-Box"
            description="Eine Box mit Tipps, wie bestimmte Übungen oder Inhalte oder ein ganzes Kapitel zu nutzen sind"
            :center-items="true"
            @click.prevent="
              () => {
                emit('addTextSnippet', 'LEARNING_STRATEGY_BOX');
                togglePopover();
              }
            "
            :limitedDescriptionWidth="true"
          />
          <DropDownListItem
            header="Grammatik-Box"
            description="Eine Infobox für grammatikalische Inhalte"
            :center-items="true"
            @click.prevent="
              () => {
                emit('addTextSnippet', 'GRAMMAR_BOX');
                togglePopover();
              }
            "
            :limitedDescriptionWidth="true"
          />
          <DropDownListItem
            header="Landeskunde-Box"
            description="Eine Infobox mit Fakten zur deutschen (Klinik-)Kultur"
            :center-items="true"
            @click.prevent="
              () => {
                emit('addTextSnippet', 'CULTURE_BOX');
                togglePopover();
              }
            "
            :limitedDescriptionWidth="true"
          />
          <DropDownListItem
            header="Klinik-Box"
            description="Eine Infobox mit kokreten Tipps, wie eine bestimmte Situation in der Klinik zu meistern ist"
            :center-items="true"
            @click.prevent="
              () => {
                emit('addTextSnippet', 'CLINICAL_PRACTICE_BOX');
                togglePopover();
              }
            "
            :limitedDescriptionWidth="true"
          />
          <DropDownListItem
            header="Redemittel-Box"
            description="Eine Infobox mit Redemitteln"
            :center-items="true"
            @click.prevent="
              () => {
                emit('addSetPhraseList');
                togglePopover();
              }
            "
            :limitedDescriptionWidth="true"
          />
        </span>
      </span>
    </div>
    <!-- End Popover to select type of exercise to create -->
  </div>
</template>

<style scoped></style>
