<script setup>
import ProfileImage from '@/components/ProfileImage.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCaseStore } from '@/stores';
import { ArrowRight } from 'lucide-vue-next';
import CaseService from '@/services/CaseService';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';

const { t } = useI18n();

const props = defineProps({
  case: {
    type: Object,
    required: false,
    default: null,
  },
  formCaseContentItem: {
    type: Object,
    required: false,
    default: null,
  },
  allowRouteToCase: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();
const caseStore = useCaseStore();
const emit = defineEmits(['caseStarted']);

const caseData = computed(() => {
  return props.case || props.formCaseContentItem?.form_case?.case;
});

const isLoadingCase = ref(false);

const titlePerson = computed(() => {
  if (!!caseData.value.patient) {
    return caseData.value.patient;
  }
  return caseData.value.third_persons?.[0];
});

const badges = computed(() => {
  if (!caseData.value?.tasks) return [];

  return caseData.value.tasks.map((task) => ({
    short: task.title.length > 12 ? task.title.substring(0, 12) + '...' : task.title,
    long: task.title,
  }));
});

async function startCase() {
  if (!caseData.value.id || !props.allowRouteToCase) return;

  isLoadingCase.value = true;

  try {
    if (props.formCaseContentItem) {
      await caseStore.setCurrentFormCase(props.formCaseContentItem);
      await router.push('/case-interactions/new?formDataContentItemId=' + props.formCaseContentItem.id);
    } else {
      await caseStore.setCurrentCase(caseData.value.id);
      await router.push('/case-interactions/new');
    }
    emit('caseStarted', caseData.value.id);
  } catch (error) {
    console.error('Error while loading case:', error);
  } finally {
    isLoadingCase.value = false;
  }
}
</script>

<template>
  <div
    @click="startCase"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-2xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col h-[200px]"
    :class="{ 'cursor-not-allowed opacity-75': !props.allowRouteToCase }"
  >
    <LoadingSpinnerFullScreen :show="isLoadingCase" :blockClickIfShown="false" />

    <div class="relative">
      <!-- Tasks badges above heading -->
      <div class="flex flex-wrap gap-1 mb-2 pr-16">
        <span v-for="badge in badges" class="px-2 py-1 bg-gray-900 text-white dark:bg-gray-800 rounded-full text-xs">
          {{ badge.short }}
        </span>
      </div>

      <div class="flex flex-col pr-16">
        <h3 class="text-xl font-medium text-gray-900 dark:text-white break-words leading-tight line-clamp-2">
          {{ caseData.details.heading }}
        </h3>
        <p class="text-sm mt-1" v-if="caseData.student_details?.role">
          <span class="text-gray-500">{{ $t('message.myRole') }}: </span>
          <span class="text-gray-900 dark:text-white">{{ caseData.student_details.role }}</span>
        </p>
      </div>

      <!-- Patient info at top right -->
      <div class="absolute top-0 right-0 flex flex-col items-end">
        <div class="relative">
          <!-- Third person image (if exists) -->
          <ProfileImage
            v-if="caseData.third_persons?.[0] && titlePerson !== caseData.third_persons[0]"
            :image="caseData.third_persons[0]?.profile_image?.image_urls.small || ''"
            :firstName="caseData.third_persons[0]?.details.first_name"
            :lastName="caseData.third_persons[0]?.details.last_name"
            class="w-12 h-12 absolute -right-3 -top-3"
          />
          <!-- Patient image -->
          <ProfileImage
            :image="titlePerson?.profile_image?.image_urls.small || ''"
            :firstName="titlePerson?.details.first_name"
            :lastName="titlePerson?.details.last_name"
            class="w-12 h-12 relative z-10"
          />
        </div>

        <p class="text-sm font-medium text-gray-900 dark:text-white text-right">
          {{ titlePerson?.details.first_name }}<br />
          {{ titlePerson?.details.last_name }}
        </p>
        <p class="text-sm text-gray-400 text-right">
          {{ titlePerson?.details.sex[0] }}, {{ titlePerson?.details.age }} Jahre
        </p>
      </div>
    </div>

    <!-- Add spacer -->
    <div class="flex-grow"></div>

    <!-- Start Case Button -->
    <div class="flex items-center justify-between mt-auto">
      <button
        class="inline-flex items-center gap-1 pl-3 pr-4 py-2 rounded-full font-semibold bg-white text-gray-800 hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
      >
        <ArrowRight class="dark:text-white" />
        {{ $t('message.startThisCase') }}
      </button>
      <div class="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
        <span>+{{ CaseService.getXpRewardForCase(caseData.id) }} XP</span>
      </div>
    </div>
  </div>
</template>
