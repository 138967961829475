export const grammaticalCategories = [
  'NOUN',
  'VERB',
  'ADJECTIVE',
  'ADVERB',
  'PRONOUN',
  'PREPOSITION',
  'CONJUNCTION',
  'INTERJECTION',
  'NOUN_VERB_COMPOUND',
  'ADVERB_VERB_COMPOUND',
];

export const articles = [
  { value: 'der', label: 'der (m)' },
  { value: 'die', label: 'die (f)' },
  { value: 'das', label: 'das (n)' },
];

export const nounTypes = [
  { value: 'regular', label: 'Reguläres Nomen', pluralNoun: false, uncountableNoun: false },
  { value: 'singularetantum', label: 'Singularetantum', pluralNoun: false, uncountableNoun: true },
  { value: 'pluraletantum', label: 'Pluraletantum', pluralNoun: true, uncountableNoun: false },
];
