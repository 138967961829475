<script setup lang="ts">
declare global {
  interface ImportMeta {
    env: {
      DEV: boolean;
      MODE: string;
    };
  }
}

import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { Mic, Send, ArrowRight, CircleCheckBig, Circle } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import AudioRecorderService from '@/services/AudioRecorderService';
import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  allowAudioInput: {
    type: Boolean,
    default: true,
  },
  isSubmitted: {
    type: Boolean,
    default: false,
  },
  isFinished: {
    type: Boolean,
    default: false,
  },
  ttsContext: {
    type: [String, null],
    default: null,
  },
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  input: [event: Event];
  submit: [];
  next: [];
  close: [];
}>();

const textarea = ref<HTMLTextAreaElement | null>(null);

const caseInteractionStore = useCaseInteractionStore();
const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

const audioService = new AudioRecorderService();
const { isRecording, transcriptionInProgress } = audioService;

const isStartingRecording = ref(false);
const delayedIsRecording = ref(false);
const buttonScale = computed(() => (isStartingRecording.value ? 'scale(0.95)' : 'scale(1)'));
const buttonType = computed(() => {
  if (props.isFinished) {
    return 'finish';
  }

  if (props.isSubmitted) {
    return 'next';
  }

  if (delayedIsRecording.value) {
    return 'recording';
  }

  if (props.modelValue.length > 0) {
    return 'submit';
  }

  return 'default';
});

const buttonClasses = computed(() => {
  // default classes
  const classes = ['rounded-[48px] gap-x-2 px-4 py-3 text-white font-semibold select-none'];

  if (buttonType.value === 'default') {
    classes.push('bg-black'); // Default state
  } else if (buttonType.value === 'recording') {
    classes.push('bg-blue'); // Recording state
  } else if (buttonType.value === 'submit') {
    classes.push('bg-orange'); // Has text state
  } else if (buttonType.value === 'next') {
    classes.push('bg-green'); // Next state
  } else if (buttonType.value === 'finish') {
    classes.push('bg-green'); // Finish state
  }

  if (props.disabled) {
    classes.push('opacity-50 cursor-not-allowed');
  }

  return classes;
});

const adjustTextareaHeight = () => {
  const target = textarea.value;
  if (!target) return;

  target.style.height = 'auto';
  target.style.height = `${Math.max(48, target.scrollHeight)}px`;
};

const onInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  emit('update:modelValue', target.value);
  emit('input', event);
};

// Handle speech-to-text result
const updateModelValue = (text: string) => {
  // textarea.value is the textarea itself, .value.value is the textarea's text
  if (!text || !textarea.value) return;
  console.log('onTranscriptionResult', text);
  emit('update:modelValue', text);

  textarea.value.value = text;
};

watch(
  () => [props.modelValue, props.placeholder],
  async (text) => {
    await nextTick();
    adjustTextareaHeight();
  },
);

watch(isRecording, (newVal) => {
  if (newVal) {
    // When starting recording, delay by 200ms
    setTimeout(() => {
      delayedIsRecording.value = true;
    }, 200);
  } else {
    // When stopping recording, update immediately
    delayedIsRecording.value = false;
  }
});

const startRecording = async () => {
  if (props.disabled || isRecording.value) return;

  isStartingRecording.value = true;
  setTimeout(() => {
    isStartingRecording.value = false;
  }, 300);

  await audioService.startRecording();
};

const stopRecording = async () => {
  if (!isRecording.value) return;
  const transcribedText = await audioService.stopRecording(
    currentCaseInteractionLanguage.value || 'deu',
    props.ttsContext,
  );
  if (!transcribedText) return;
  updateModelValue(transcribedText);
  // Automatically submit after updating the model value
  emit('submit');
};

onMounted(() => {
  audioService.requestMicrophonePermissions();
});

onBeforeUnmount(() => {
  audioService.cleanup();
});
</script>

<template>
  <div class="mobile-textarea-container flex flex-row mt-auto items-end gap-x-2 px-4 py-4 select-none">
    <div v-if="!isSubmitted" class="relative flex flex-grow">
      <textarea
        ref="textarea"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="transcriptionInProgress"
        :rows="1"
        class="flex grow bg-[#F6F6F6]/40 backdrop-blur-md border border-gray-200 rounded-3xl max-h-48 px-4 py-2.5 resize-none focus:outline-none focus:ring-0 focus:border-gray-200"
        style="-ms-overflow-style: none; scrollbar-width: none"
        @input="onInput"
        @keydown.prevent.enter="
          () => {
            if (props.disabled || transcriptionInProgress) return;
            emit('submit');
          }
        "
      />
    </div>

    <div class="ml-auto">
      <button
        v-if="buttonType === 'default' || buttonType === 'recording'"
        @mousedown.prevent="startRecording"
        @mouseup.prevent="stopRecording"
        @touchstart.prevent="startRecording"
        @touchend.prevent="stopRecording"
        @touchcancel.prevent="stopRecording"
        @contextmenu.prevent
        draggable="false"
        type="button"
        class="transition-all duration-150"
        :style="{
          transform: buttonScale,
          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
        }"
        :class="[buttonClasses, { 'transcription-flip-animation': transcriptionInProgress }]"
        data-testid="audio-recorder-ptt"
      >
        <div class="flex flex-row items-center gap-x-2">
          <component
            :is="delayedIsRecording ? Circle : Mic"
            :size="20"
            :class="{ 'recording-circle fill-current': delayedIsRecording }"
          />
          <span class="select-none">{{ t('Voice') }}</span>
        </div>
      </button>

      <button
        v-if="buttonType === 'submit'"
        :class="buttonClasses"
        @click="
          () => {
            if (props.disabled || transcriptionInProgress) return;
            emit('submit');
          }
        "
      >
        <div class="flex flex-row items-center gap-x-2">
          <Send :size="20" />
          <span class="select-none">{{ t('Send') }}</span>
        </div>
      </button>

      <button
        v-if="buttonType === 'next'"
        :class="buttonClasses"
        @click="
          () => {
            if (props.disabled) return;
            emit('next');
          }
        "
      >
        <div class="flex flex-row items-center gap-x-2">
          <ArrowRight :size="20" />
          <span class="select-none">{{ t('message.next') }}</span>
        </div>
      </button>

      <button
        v-if="buttonType === 'finish'"
        :class="buttonClasses"
        @click="
          () => {
            if (props.disabled || transcriptionInProgress) return;
            emit('close');
          }
        "
      >
        <div class="flex flex-row items-center gap-x-2">
          <CircleCheckBig :size="20" />
          <span class="select-none">{{ t('message.done') }} </span>
          🎉
        </div>
      </button>
    </div>
  </div>
</template>

<style scoped>
@keyframes recordingPulse {
  0%,
  100% {
    transform: scale(0.8); /* 16px */
  }
  50% {
    transform: scale(1); /* 20px */
  }
}

.recording-circle {
  animation: recordingPulse 1s ease-in-out infinite;
}

@keyframes flip3D {
  0% {
    transform: perspective(400px) rotateX(0) rotateY(0);
  }
  25% {
    transform: perspective(400px) rotateX(180deg) rotateY(0);
  }
  50% {
    transform: perspective(400px) rotateX(180deg) rotateY(180deg);
  }
  75% {
    transform: perspective(400px) rotateX(0) rotateY(180deg);
  }
  100% {
    transform: perspective(400px) rotateX(0) rotateY(0);
  }
}

.transcription-flip-animation {
  animation: flip3D 3s ease-in-out infinite;
  transform-style: preserve-3d;
  backface-visibility: visible;
}
</style>
