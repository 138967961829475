<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { router } from '@/router';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumbElement from '@/components/breadcrumbs/BreadcrumbElement.vue';
import ChapterBreadcrumbElementWithDropdown from '@/components/breadcrumbs/ChapterBreadcrumbElementWithDropdown.vue';
import { debounce } from 'lodash';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { headingToId } from '@/helper';
import ChapterTitleAndDescription from './ChapterTitleAndDescription.vue';
import BottomNav from '@/components/BottomNav.vue';
import { useRoute } from 'vue-router';
const courseStore = useCourseStore();
const { currentCourse, currentChapter, currentChapterSortedSections, currentChapterTitle } = storeToRefs(courseStore);
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);

const courseInteractionStore = useCourseInteractionStore();
const { fetchCompletedAndChapterSet: interactionsFetched } = storeToRefs(courseInteractionStore);

const alertStore = useAlertStore();
const isLoading = ref(false);
const savingChangedOrder = ref(false); // TODO
const innerHeader = ref(null);
const clicked = ref([] as Array<boolean>);
const clickedNew = ref(false);

const activeScrollspyElement = ref(0);
const chapterId = ref('');
const fullWidth = ref(1024);
const chapterScrollContainer = ref(null);

const showNative = ref(false);
const isEditing = ref(false);
const isAddingNewSection = ref(false);
const someDropdownOpen = ref(false);
const mountingHelperPending = ref(false);

const route = useRoute();

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const innerHeaderHeight = computed(() => {
  return innerHeader.value ? innerHeader.value.offsetHeight : 0;
});

const currentCourseTitleShortened = computed(() => {
  if (!currentCourse.value) {
    return '';
  }
  return currentCourse.value.title.length > 12
    ? currentCourse.value.title.substring(0, 12) + '...'
    : currentCourse.value.title;
});

const currentChapterTitleShortened = computed(() => {
  if (!currentChapter.value) {
    return '';
  }
  return currentChapter.value.title.length > 10
    ? currentChapter.value.title.substring(0, 10) + '...'
    : currentChapter.value.title;
});

const currentChapterActiveSectionTitleShortened = computed(() => {
  return currentChapterSortedVisibleSections.value[activeScrollspyElement.value].title.length > 20
    ? currentChapterSortedVisibleSections.value[activeScrollspyElement.value].title.substring(0, 20) + '...'
    : currentChapterSortedVisibleSections.value[activeScrollspyElement.value].title;
});

const currentChapterSortedVisibleSections = computed(() => {
  if (isOwnerOrEditorOfParentCourseOrAdmin.value) {
    // Create new array to ensure reactivity
    return [...currentChapterSortedSections.value];
  }
  // Create new filtered array to ensure reactivity
  let visible = [...currentChapterSortedSections.value]
    .filter((section) => section.published_at != null)
    .map((section) => ({ ...section })); // Deep clone each section
  console.log('#Visible sections: ' + visible.length);
  return visible;
});

const getWidth = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth: ' + screenWidth);
  if (screenWidth < 640) {
    fullWidth.value = screenWidth;
    return;
  }
  let factor = showNative.value ? 0.9 : 0.75;
  fullWidth.value = Math.round(screenWidth * factor);
};

//       :style="{ width: showNative ? `${widthWithNativeShown}px` : `${widthWithoutNativeShown}px` }"

const debounceGetWidth = debounce(getWidth, 100);

watch(showNative, () => {
  getWidth();
});

// Watch for route param changes
watch(
  () => route.params.chapterId,
  async (newChapterId) => {
    if (!newChapterId) return;
    await handleChapterChange(newChapterId as string);
  },
);

onBeforeMount(async () => {
  const chapterId = route.params.chapterId;
  await handleChapterChange(chapterId as string);
});

const handleChapterChange = async (newChapterId: string) => {
  if (!newChapterId) {
    console.error('newChapterId cannot be null');
    return;
  }

  if (!storeLoaded.value) {
    console.log('store not loaded');
    return;
  }

  if (newChapterId === currentChapter.value?.id) {
    return;
  }

  isLoading.value = true;
  chapterId.value = newChapterId;
  console.log('Premount for chapter: #' + chapterId.value + '#');

  await courseStore.setCurrentChapter(chapterId.value);
  if (!currentChapter.value) {
    alertStore.error('Kapitel nicht gefunden.');
    await router.push({ name: 'course-library' });
  }
  let courseInteractionFound = await courseInteractionStore.setCourseInteractionForCurrentCourseIfEnrolled();
  if (!courseInteractionFound) {
    alertStore.error('Kurs nicht gefunden oder nicht eingeschrieben.');
    await router.push({ name: 'course-library' });
  }
  console.log('Chapter index is: ' + currentChapter.value.index);

  clicked.value.length = 0;
  courseStore.currentChapterSortedSections.forEach((section, index) => {
    clicked.value.push(false);
  });

  // get editing state from local storage
  if (!courseStore.currentCourse) {
    throw new Error('No current course when required');
  }
  let courseId = courseStore.currentCourse.id;
  try {
    const savedState = localStorage.getItem(`course-editing-${courseId}`);
    if (savedState && isOwnerOrEditorOfParentCourseOrAdmin.value) {
      try {
        isEditing.value = JSON.parse(savedState);
      } catch (e) {
        console.warn('Invalid saved state in localStorage, resetting...', e);
        localStorage.removeItem(`course-editing-${courseId}`);
        isEditing.value = false;
      }
    }
  } catch (e) {
    console.error('Error accessing localStorage:', e);
    isEditing.value = false;
  }

  if (!isEditing.value && !!currentChapter.value) {
    await courseInteractionStore.startCurrentChapter();
    console.log('Chapter started');
  }
  isLoading.value = false;
};

onMounted(async () => {
  // await router.isReady();
  // isLoading.value = true;

  // mountingHelperPending.value = false;
  // await nextTick(async () => {
  //   if (storeLoaded.value) {
  //     mountingHelperPending.value = false;
  //     await mountingHelper();
  //   } else {
  //     mountingHelperPending.value = true; // we have to wait for the store to load
  //   }
  // });

  // watch(
  //   () => storeLoaded.value,
  //   async (newVal: boolean) => {
  //     if (newVal && mountingHelperPending.value) {
  //       mountingHelperPending.value = false;
  //       await mountingHelper();
  //     }
  //   },
  //   { immediate: true },
  // );

  getWidth();
  window.addEventListener('resize', debounceGetWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetWidth);
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const pageHeading = () => {
  return courseStore.currentChapterTitle;
};

const hashtagedId = () => {
  return '#' + pageId();
};

async function onPreviousChapter() {
  console.debug('Navigating to previous chapter');
  alertStore.error('Navigation zu vorherigem Kapitel wird noch nicht unterstützt.');
}

async function onNextChapter() {
  console.debug('Navigating to next chapter');
  alertStore.error('Navigation zum nächsten Kapitel wird noch nicht unterstützt.');
}

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSortedSections.value !== null;
});

const isOwnerOrEditorOfParentCourseOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

function scrollToSection(sectionTitle: string) {
  console.log('Scrolling to section: ' + sectionTitle);
  let id = headingToId(sectionTitle);
  const element = document.getElementById(id);
  console.log('Element: ' + element);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const goToChapter = async (chapterIndex: number) => {
  console.log('Navigating to chapter: ' + chapterIndex);
  if (chapterIndex === currentChapter.value.index) {
    return;
  }
  await router.isReady();
  await router.push('/edit-chapter/' + courseStore.currentCourse.chapters[chapterIndex].id);
};

const goToSection = async (index: number) => {
  clicked.value[index] = true;
  await router.isReady();
  setTimeout(async () => {
    await router.push('/section/' + courseStore.currentChapterSortedSections[index].id);
  }, 150);
};

const toggleIsEditing = (isEditingNow: boolean) => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  isEditing.value = isEditingNow;
  localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditingNow));
};

const addNewSection = async () => {
  if (isAddingNewSection.value) return;
  clickedNew.value = true;
  await new Promise((resolve) => setTimeout(resolve, 150));
  isAddingNewSection.value = true;
  await courseStore
    .appendEmptySection()
    .then(() => {
      alertStore.success('Neuer Abschnitt hinzugefügt');
    })
    .finally(() => {
      clickedNew.value = false;
      isAddingNewSection.value = false;
      setTimeout(() => {
        chapterScrollContainer.value?.scrollTo({
          top: chapterScrollContainer.value.scrollHeight,
          behavior: 'smooth',
        });
      }, 150);
    });
};
</script>

<template>
  <div
    ref="chapterScrollContainer"
    class="max-h-full justify-center min-w-full relative min-h-full h-full overflow-y-auto overflow-x-hidden pb-20"
  >
    <div v-if="storeLoaded" class="">
      <!-- Navigation -->
      <div class="relative">
        <h2
          ref="innerHeader"
          class="w-full sticky top-0 z-40 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-1.5 justify-center"
          :class="{
            'overflow-visible': someDropdownOpen,
            'overflow-y-visible overflow-x-scroll': !someDropdownOpen,
          }"
        >
          <div class="w-full mx-auto px-2 md:px-6 lg:px-8 inline-flex justify-between overflow-visible">
            <div class="inline-flex overflow-visible items-center whitespace-nowrap w-full">
              <!-- level 1 breadcrump: all courses -->
              <BreadcrumbElement class="hidden md:inline-flex" label="Meine Kurse" to="/my-courses" />
              <!-- level 2 breadcrump: current course -->
              <BreadcrumbElement
                class="hidden md:inline-flex"
                :label="currentCourse.title"
                :to="'/edit-course/' + currentCourse.id"
                data-testid="course-breadcrumb-current-course"
              />
              <BreadcrumbElement
                class="inline-flex md:hidden"
                :label="currentCourseTitleShortened"
                :to="'/edit-course/' + currentCourse.id"
              />
              <!-- level 3 breadcump: current chapter with dropdown to directly jump to other chapter -->
              <ChapterBreadcrumbElementWithDropdown
                :current-chapter="currentChapter"
                :current-chapter-title="currentChapterTitle"
                :current-chapter-title-shortened="currentChapterTitleShortened"
                :chapters="currentCourse.chapters"
                id-prefix="course-chapter-chapter"
                @dropdownOpen="someDropdownOpen = $event"
                @goToChapter="goToChapter"
              />
            </div>
          </div>
        </h2>
      </div>

      <!-- End of Navigation -->

      <!-- end of header -->

      <div
        v-if="storeLoaded"
        class="py-4 flex mx-auto transition-all duration-500 ease-in-out"
        :key="currentChapter.id"
        :style="{ width: fullWidth + 'px' }"
      >
        <!-- Bearbeiten an/ aus-->
        <ChapterTitleAndDescription
          ref="editCourseTitleAndDescription"
          :chapter="currentChapter"
          :allow-editing="isEditing"
          :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
          :disable-toggle-editing="savingChangedOrder"
          @scrollTo="(sectionTitle) => scrollToSection(sectionTitle)"
          @onToggleIsEditing="toggleIsEditing"
        />
      </div>

      <!-- section highlight cards -->
      <div
        v-if="storeLoaded"
        class="grid grid-cols-2 xl:grid-cols-3 gap-4 justify-center mx-auto"
        :style="{ width: fullWidth + 'px' }"
      >
        <div
          v-for="(section, index) in currentChapterSortedVisibleSections"
          :key="section.id"
          class="group px-1 md:px-3 py-5 md:py-8 w-full rounded-xl shadow-sm border cursor-pointer relative"
          :class="{
            'bg-white hover:bg-gray-50 border-gray-200':
              isEditing || !interactionsFetched || !courseInteractionStore.sectionStarted(section.id),
            'bg-blue-50 hover:bg-blue-100/50 border-blue-500':
              !isEditing &&
              interactionsFetched &&
              courseInteractionStore.sectionStarted(section.id) &&
              !courseInteractionStore.sectionCompleted(section.id),
            'bg-teal-50 hover:bg-teal-100/50 border-teal-500':
              !isEditing && interactionsFetched && courseInteractionStore.sectionCompleted(section.id),
          }"
          @click="goToSection(<number>index)"
          :data-testid="`section-highlight-card-${index}`"
        >
          <div
            class="absolute top-0 left-2 transition-all duration-500 ease-in-out"
            :class="{
              'scale-150': clicked[index],
            }"
            v-show="courseInteractionStore.sectionCompleted(section.id)"
          >
            <span
              translate="no"
              class="no-translate material-symbols-outlined text-8xl font-bold text-teal-600 opacity-10"
            >
              check
            </span>
          </div>
          <div
            :class="{
              'scale-150': clicked[index],
            }"
            class="absolute top-5 left-2 transition-all duration-500 ease-in-out"
            v-show="
              courseInteractionStore.sectionStarted(section.id) && !courseInteractionStore.sectionCompleted(section.id)
            "
          >
            <span
              translate="no"
              class="no-translate material-symbols-outlined text-6xl font-bold text-blue-600 opacity-10"
            >
              exercise
            </span>
          </div>
          <div
            class="w-full h-full flex-col flex justify-between items-center gap-y-4"
            :class="{
              'text-gray-800': isEditing || !interactionsFetched || !courseInteractionStore.sectionStarted(section.id),
              'text-blue-600':
                !isEditing &&
                interactionsFetched &&
                courseInteractionStore.sectionStarted(section.id) &&
                !courseInteractionStore.sectionCompleted(section.id),
              'text-teal-600': !isEditing && interactionsFetched && courseInteractionStore.sectionCompleted(section.id),
            }"
          >
            <div class="flex-col flex items-center">
              <div
                class="text-left font-bold"
                :class="{
                  'text-gray-300':
                    isEditing || !interactionsFetched || !courseInteractionStore.sectionStarted(section.id),
                  'text-blue-600/50':
                    !isEditing &&
                    interactionsFetched &&
                    courseInteractionStore.sectionStarted(section.id) &&
                    !courseInteractionStore.sectionCompleted(section.id),
                  'text-teal-600/50':
                    !isEditing && interactionsFetched && courseInteractionStore.sectionCompleted(section.id),
                }"
              >
                <span class="">{{ currentChapter.index + 1 }}.</span>
                <span class="text-3xl">{{ section.index + 1 }}</span>
              </div>

              <div class="text-center font-semibold">
                {{ section.title }}
              </div>
            </div>

            <div class="flex-col flex px-2 md:px-6 py-0 md:py-4 overflow-hidden max-w-full">
              <div class="flex-shrink-0 rounded-lg flex items-center justify-center overflow-hidden">
                <div class="group-hover:scale-105 transition-all duration-300 ease-in-out">
                  <img
                    :src="
                      !!section.title_image
                        ? section.title_image.media_url
                        : 'https://assets-prd.data.casuu.health/eta_io_24790_two_nurses_one_male_and_blond_haired_one_female_ph_d0972ad7-5e7e-4ef4-b6a3-dae0da10224d.png'
                    "
                    alt=""
                  />
                </div>
              </div>

              <div
                class="inline-flex mx-auto pt-3 md:pt-8 items-center justify-between text-blue-600 group-hover:text-blue-700"
                :class="{ 'animate-separate': clicked[index] }"
              >
                <span
                  v-if="isEditing || !courseInteractionStore.sectionCompleted(section.id)"
                  translate="no"
                  class="no-translate select-none material-symbols-outlined"
                >
                  chevron_right
                </span>
                <span
                  v-if="
                    !isEditing &&
                    interactionsFetched &&
                    courseInteractionStore.sectionStarted(section.id) &&
                    !courseInteractionStore.sectionCompleted(section.id)
                  "
                >
                  Fortsetzen
                </span>
                <span v-else-if="!isEditing && !courseInteractionStore.sectionCompleted(section.id)"> Loslegen </span>
                <span v-else-if="isEditing"> Bearbeiten </span>
              </div>

              <ul class="text-xs pt-3 md:pt-4 px-1 md:px-4 space-y-1 md:space-y-2 flex-col flex">
                <span v-if="!!section.learning_objectives">
                  <span v-if="!isEditing && interactionsFetched && courseInteractionStore.sectionCompleted(section.id)"
                    >Hier habe ich schon gelernt:</span
                  >
                  <span v-else>Hier lerne ich:</span>
                </span>
                <li
                  v-for="(lobj, index) in section.learning_objectives"
                  :key="index"
                  class="flex space-x-1 md:space-x-2 items-start"
                >
                  <span
                    v-if="!isEditing && interactionsFetched && courseInteractionStore.sectionCompleted(section.id)"
                    translate="no"
                    class="no-translate material-symbols-outlined -mt-1"
                    >task_alt</span
                  >
                  <span v-else translate="no" class="no-translate material-symbols-outlined -mt-1">target</span>
                  <span class="">{{ lobj.description }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- add new section -->
        <div
          v-show="isEditing"
          @click="addNewSection"
          :key="isAddingNewSection"
          class="group px-3 py-8 w-full rounded-xl shadow-sm border cursor-pointer bg-blue-50 hover:bg-blue-100 border-blue-600 hover:border-blue-700"
          :class="{ 'opacity-50': isAddingNewSection }"
        >
          <div v-if="isAddingNewSection" class="flex w-full h-full items-center justify-center">
            <LoadingSpinnerLarge />
          </div>
          <div v-else class="w-full text-blue-600 group-hover:text-blue-700 h-full flex-col flex items-center gap-y-4">
            <div class="flex-col flex items-center">
              <div class="text-left text-blue-600 font-bold">
                <span class="">{{ currentChapter.index + 1 }}.</span>
                <span class="text-3xl">{{ currentChapterSortedVisibleSections.length + 1 }}</span>
              </div>

              <div class="text-center font-semibold">Neuer Abschnitt</div>
            </div>

            <div class="flex-col flex px-6 py-4 overflow-hidden max-w-full">
              <div class="flex-shrink-0 rounded-lg flex items-center justify-center overflow-hidden">
                <div class="group-hover:scale-125 transition-all duration-300 ease-in-out">
                  <span translate="no" class="select-none no-translate material-symbols-outlined text-8xl"> add </span>
                </div>
              </div>

              <div
                class="inline-flex mx-auto pt-8 pb-2 items-center justify-between text-blue-600 group-hover:text-blue-700"
                :class="{ 'animate-separate': clickedNew }"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined"> chevron_right </span>
                Hinzufügen
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomNav />

    <div v-show="!storeLoaded">
      <div class="pt-20 flex justify-center items-center h-full">
        <LoadingSpinnerLarge />
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes separate {
  0% {
    letter-spacing: 0;
    opacity: 1;
  }
  10% {
    letter-spacing: 5px;
    opacity: 1;
  }
  20% {
    letter-spacing: 12px;
    opacity: 0.8;
  }
  30% {
    letter-spacing: 20px;
    transform: translateX(-10px);
    opacity: 0.6;
  }
  40% {
    letter-spacing: 50px;
    transform: translateX(-25px);
    opacity: 0.4;
  }
  50% {
    letter-spacing: 70px;
    transform: translateX(-35px);
    opacity: 0.2;
  }
  100% {
    letter-spacing: 200px;
    transform: translateX(-100px);
    opacity: 0;
  }
}

.animate-separate {
  animation: separate 2s ease-in-out;
}
</style>
