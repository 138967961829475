<script setup lang="ts">
import { useAuthStore } from '@/stores';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { getLevelFromXp, getThresholdForCurrentLevel, getThresholdForNextLevel } from '@/helper';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { HSStaticMethods, HSTooltip } from 'preline';
import { nextTick } from 'vue';

const { t, locale } = useI18n();

const authStore = useAuthStore();
const { userXp } = storeToRefs(authStore);

const progressTooltip = ref(null);
const progressBar = ref(null);

const currentLevel = computed(() => getLevelFromXp(userXp.value));
const progress = computed(
  () =>
    ((userXp.value - getThresholdForCurrentLevel(currentLevel.value)) /
      (getThresholdForNextLevel(currentLevel.value) - getThresholdForCurrentLevel(currentLevel.value))) *
    100,
);

const props = defineProps({
  includeCurrentXpAsTooltip: {
    type: Boolean,
    default: true,
  },
  forceTop: {
    type: Boolean,
    default: true,
  },
});

onMounted(async () => {
  // await nextTick();
  // HSStaticMethods.autoInit();
  // await nextTick();

  await nextTick();
  // wait 200ms -> otherwise error is displayed (effect?), perhaps App.vue has not yet inited HSStaticMethods?
  await new Promise((resolve) => setTimeout(resolve, 200));
  HSTooltip.autoInit();

  progressTooltip.value = new HSTooltip(document.getElementById('progress-tooltip'));
  if (!progressBar.value) {
    console.warn('Cannot find progressBar in IngameProgressCard. Failed to add EventListener');
    return;
  }
  progressBar.value.addEventListener('mouseenter', () => {
    if (!progressTooltip.value) return;
    try {
      progressTooltip.value.show();
    } catch (error) {
      // we just fail this preline shit silently
      console.warn('Failed to show progressTooltip', error);
    }
  });
  progressBar.value.addEventListener('mouseleave', () => {
    if (!progressTooltip.value) return;
    try {
      progressTooltip.value.hide();
    } catch (error) {
      // we just fail this preline shit silently
      console.warn('Failed to hide progressTooltip', error);
    }
  });
});

onUnmounted(() => {
  if (progressBar.value) {
    progressBar.value.removeEventListener('mouseenter', () => {
      if (!progressTooltip.value) return;
      try {
        progressTooltip.value.show();
      } catch (error) {
        // we just fail this preline shit silently
        console.warn('Failed to show progressTooltip', error);
      }
    });
    progressBar.value.removeEventListener('mouseleave', () => {
      if (!progressTooltip.value) return;
      try {
        progressTooltip.value.hide();
      } catch (error) {
        // we just fail this preline shit silently
        console.warn('Failed to hide progressTooltip', error);
      }
    });
  }
});
</script>

<template>
  <!-- Card -->
  <div
    class="h-fit w-full relative flex flex-col overflow-visible bg-[#F6F6F6] rounded-2xl dark:bg-neutral-800 p-4"
    :class="{ 'z-[60]': props.forceTop }"
  >
    <!-- Level Display -->
    <div class="flex items-center gap-x-4 mb-4">
      <span class="text-5xl font-normal">{{ currentLevel }}</span>
      <div>
        <p class="text-sm">{{ $t('message.youreDoingWellThisWeek') }} —</p>
        <p class="text-sm">{{ getThresholdForNextLevel(currentLevel) - userXp }} {{ $t('message.xpLeftToLevelUp') }}</p>
      </div>
    </div>

    <!-- Progress Bar Section -->
    <div class="flex items-center gap-x-3">
      <div class="flex-1">
        <div class="hs-tooltip relative" id="progress-tooltip">
          <div class="relative">
            <div
              ref="progressBar"
              class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
              role="progressbar"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="flex flex-col justify-center transition-all duration-2000 ease-in-out bg-blue-600 rounded-full"
                :style="'width: ' + progress + '%'"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!-- XP Counter -->
      <div class="text-sm whitespace-nowrap">{{ userXp }} / {{ getThresholdForNextLevel(currentLevel) }} XP</div>
    </div>
  </div>
  <!-- End Card -->
</template>

<style scoped></style>
