<!--
TODO: Fetch text from DB
TODO: Replace preline icon by patient's "profile" image
-->

<script setup lang="ts">
import SystemMessageFooter from '@/components/case_interaction/SystemMessageFooter.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import {
  useAuthStore,
  usePatientInteractionStore,
  useCaseInteractionStore,
  useThirdPersonInteractionStore,
  useAlertStore,
} from '@/stores';
import { onMounted, onUnmounted, ref, computed, onBeforeUnmount, PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { MoreHorizontal, Reply, Volume2, Pause } from 'lucide-vue-next';
import { InteractionMessage } from '@/helper';
const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);
const caseInteractionStore = useCaseInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const patientInteractionStore = usePatientInteractionStore();
const alertStore = useAlertStore();

const {
  message,
  isLatestBubble,
  showAvatar = false,
  personType,
  personIndex,
  companionInFooter,
  reserveSpaceForToggle,
} = defineProps({
  message: Object as PropType<InteractionMessage>,
  isLatestBubble: Boolean,
  showAvatar: {
    type: Boolean,
    default: false,
  },
  personType: {
    type: String,
    default: 'PATIENT',
    validator: (value) => ['PATIENT', 'THIRD_PERSON'].includes(value),
  },
  personIndex: {
    type: [Number, null],
    default: null,
  },
  companionInFooter: {
    type: Boolean,
    default: false,
  },
  reserveSpaceForToggle: {
    type: Boolean,
    default: false,
  },
});

const showingTranslation = ref(false);

// import shared functions
import { useChatBubbleFunctions } from '@/components/chat_bubbles/chatBubbleFunctions';
import { unobfuscateUserName } from '@/helper';

const { calculateCols, calculateRows } = useChatBubbleFunctions();

const audioPlayer = ref<HTMLAudioElement | null>(null);
const audioUrlWithTimestamp = ref(null as string | null);
const checkInterval = ref<NodeJS.Timeout | null>(null);
const isPlaying = ref(false);

// Add computed property for audio source
const audioSrc = computed(() => {
  if (!message?.id) return null;
  const uuidHex = message.id.replace(/-/g, '');
  return `https://audiodata-prd.data.casuu.health/${uuidHex}.wav`;
});

// Add audio related functions
const updateAudioSource = () => {
  if (!message?.id) return;
  if (message.id === 'not_yet_in_database') return;
  audioUrlWithTimestamp.value = `${audioSrc.value}?t=${Date.now()}`;
  if (audioPlayer.value) {
    audioPlayer.value.load();
  }
};

const onAudioLoaded = () => {
  if (!audioPlayer.value) return;
  if (audioPlayer.value.duration > 0) {
    if (!!checkInterval.value) {
      clearInterval(checkInterval.value);
      checkInterval.value = null;
    }
  }
};

const handleAudioClick = () => {
  if (!audioPlayer.value) return;
  if (awaitingAudioStream.value) return;

  if (isPlaying.value) {
    console.log('pausing audio');
    audioPlayer.value.pause();
    audioPlayer.value.currentTime = 0;
    isPlaying.value = false;
  } else {
    if (audioPlayer.value.duration === 0 || audioPlayer.value.readyState === 0) {
      alertStore.info('Keine Audiodaten verfügbar.');
      return;
    }

    console.log('playing audio');
    audioPlayer.value.play();
    isPlaying.value = true;
  }
};

const onAudioEnded = () => {
  isPlaying.value = false;
};

async function translationToggled(new_value: boolean) {
  showingTranslation.value = new_value;
}

const isStreamingAudio = computed(() => {
  return isLatestBubble && caseInteractionStore.isReplaying;
});

const awaitingAudioStream = computed(() => {
  return isLatestBubble && (caseInteractionStore.waitingForAudioPlayback || caseInteractionStore.someChatIsStreaming);
});

const audioStreamIsFinished = computed(() => {
  return !isStreamingAudio.value && !awaitingAudioStream.value;
});

const reply = computed(() => {
  // If streaming, return the spoken words up to now (full text as fallback when streaming is done).
  // If not streaming, return the full text.
  if (audioStreamIsFinished.value) return message.content['processed_model_output'];
  let wordsUpToNow = caseInteractionStore.subtitleWords
    .slice(0, caseInteractionStore.currentWordIndex + 1)
    .map((word) => word.word)
    .join(' ');
  return wordsUpToNow || message.content['processed_model_output'];
});

onMounted(() => {
  checkInterval.value = setInterval(updateAudioSource, 1500);
});

onBeforeUnmount(() => {
  if (checkInterval.value) clearInterval(checkInterval.value);
  checkInterval.value = null;
});
</script>

<template>
  <li class="flex gap-x-2 sm:gap-x-4 mb-2 mt-4">
    <!-- Icon - only show if showAvatar is true -->
    <span
      v-if="showAvatar"
      class="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-600"
    >
      <span class="text-sm font-medium text-white leading-none">
        <ProfileImage
          v-if="personType === 'PATIENT'"
          :image="patientInteractionStore.patientProfileImageSmall"
          :initials="patientInteractionStore.patientInitials"
        />
        <ProfileImage
          v-else-if="personType === 'THIRD_PERSON'"
          :image="thirdPersonInteractionStore.thirdPersonProfileImageSmall(personIndex)"
          :initials="thirdPersonInteractionStore.thirdPersonInitials(personIndex)"
        />
      </span>
    </span>
    <!-- End of icon -->

    <div v-if="message?.content">
      <!-- Content -->
      <div
        class="bg-white border border-black/5 inline-flex"
        :class="[
          awaitingAudioStream ? 'rounded-full' : 'rounded-2xl pl-3 pr-4 py-3',
          'dark:bg-neutral-900 dark:border-gray-700',
        ]"
      >
        <div class="flex items-start" :class="{ 'gap-x-3': !awaitingAudioStream }">
          <!-- Combined audio button/loading placeholder -->
          <div
            class="flex-shrink-0 inline-flex items-center justify-center h-9 w-9 rounded-full cursor-pointer"
            :class="{
              'hover:bg-gray-200 transition-colors bg-gray-100 border border-gray-200': !awaitingAudioStream,
              'bg-white border border-white': awaitingAudioStream,
            }"
            @click.prevent="handleAudioClick"
          >
            <template v-if="awaitingAudioStream">
              <MoreHorizontal class="inline animate-strong-pulse w-5 h-5" />
            </template>
            <template v-else>
              <Volume2 v-if="!isPlaying" class="text-black" :size="20" />
              <Pause v-else class="text-black" :size="20" />
            </template>
            <!-- {{  caseInteractionStore.waitingForAudioPlayback }}
            #{{  caseInteractionStore.someChatIsStreaming }} -->
          </div>

          <audio
            ref="audioPlayer"
            class="hidden"
            controls
            controlsList="nodownload"
            oncontextmenu="return false;"
            @loadedmetadata="onAudioLoaded"
            @ended="onAudioEnded"
          >
            <source :src="audioUrlWithTimestamp" type="audio/wav" />
          </audio>

          <!-- Message content -->
          <div :class="{ 'flex-grow': !awaitingAudioStream, 'w-fit': awaitingAudioStream }">
            <p v-if="awaitingAudioStream" class="flex items-center justify-center h-7"></p>
            <p v-else class="text-base text-gray-800 dark:text-white">
              {{ unobfuscateUserName(reply, userFirstName, userLastName).replaceAll('"', '') }}
            </p>
          </div>
        </div>
      </div>

      <!-- System Message Footer -->
      <SystemMessageFooter
        :message="message"
        :message-type="personType === 'PATIENT' ? 'SAY-PATIENT' : 'SAY-ATTENDING'"
        :conversation="personType === 'PATIENT' ? 'PATIENT' : 'ATTENDING'"
        @onTranslationToggled="translationToggled"
        :hideFooter="isLatestBubble && !audioStreamIsFinished"
        :hideAudio="true"
        :show-companion-help-button="isLatestBubble && companionInFooter"
        :reserve-space-for-toggle="reserveSpaceForToggle"
      />
    </div>
  </li>
</template>

<style scoped>
@keyframes strong-pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.animate-strong-pulse {
  animation: strong-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.bg-white {
  transition: border-radius 0.2s ease-in-out;
}
</style>
