import { EventType, MessageType, VocabItem } from '@/apiclient';

export enum PairOfTermsItemType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export type PairOfTermsItem = {
  id: string;
  // pair_of_terms_item_type: PairOfTermsItemType;
  content: string;
  description: string;
};

export type PairOfTermsItemPair = {
  id: string;
  pair_of_terms_items: PairOfTermsItem[];
};

export type CategorizationCategoryType = {
  id: string;
  name: string;
  items: Array<CategorizationItemType>;
};

export type CategorizationItemType = {
  id: string;
  content: string;
  categoryId: string;
  isIncorrect: boolean;
};

export const roleTypes = [
  { value: 'PHYSICIAN', display_name: 'Ärzt:in' },
  { value: 'NURSE', display_name: 'Pfleger:in' },
  { value: 'PATIENT', display_name: 'Patient:in' },
  { value: 'RELATIVE', display_name: 'Angehöriger' },
  { value: 'OTHER', display_name: 'Andere' },
]; // TODO load from backend (ThirdPersonType enum + PATIENT)

export enum ResourceType {
  READING = 'READING',
  LISTENING = 'LISTENING',
  NO_RESOURCE = 'NO_RESOURCE',
}

export type InteractionMessage = {
  id: string;
  type: ExtendedMessageType | null;
  content: {
    user_message: string | null;
    user_message_language: string | null;
    processed_model_output: string | null;
    event?: {
      type?: EventType | null;
      details?: Record<string, any> | null;
    } | null;
    user_edited_output?: string | null;
    timestamp: string | null;
  };
  language: string | null;
  translations?: Record<string, string> | null;
  created_at: string;
  undone_at: string | null;
};

export type InfoMessage = {
  id?: string;
  type: ExtendedMessageType;
  content: {
    info_message: string;
    this_target?: string;
    next_target?: string;
  };
  language?: string | null;
  translations?: Record<string, string> | null;
  created_at: string;
  undone_at: string | null;
};

export type ExtendedMessageType = MessageType | 'SUBTASK_COMPLETION';
