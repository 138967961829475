<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore, useCaseInteractionStore, useCaseStore, usePatientInteractionStore } from '@/stores';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import { debounce } from 'lodash';
import { unobfuscateUserName } from '@/helper';
import AudioPlayerTTS from '@/components/audio/AudioPlayerTTS.vue';
import { useRouter } from 'vue-router';
import { Captions, CaptionsOff, X, MessageSquare, ListChecks, LogOut, ClipboardPenLine } from 'lucide-vue-next';

import TextareaWithAudioInputCaseInteraction from '@/components/inputs/TextareaWithAudioInputCaseInteraction.vue';
import InteractionColumn from '@/components/deprecated/InteractionColumn.vue';
import TaskInfoCard from '@/components/case_interaction/TaskInfoCard.vue';
import { useAlertStore } from '@/stores';
import ChatHistoryNew from '@/components/case_interaction/ChatHistoryNew.vue';
import DifficultyButton from '@/components/case_interaction/DifficultyButton.vue';
import CloseButton from '@/components/case_interaction/CloseButton.vue';
import BarthelAsklepios from '@/components/customer_forms/BarthelAsklepios.vue';

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  originChapterId: {
    type: String,
    required: false,
    default: '',
  },
  isLoadingInteractionHistory: {
    type: Boolean,
    required: true,
  },
  showingMainCaseView: {
    type: Boolean,
    required: true,
  },
  lastInputAt: {
    type: String,
    default: '',
  },
  inputText: {
    type: String,
    default: '',
  },
  showSubtitles: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['updateResponse', 'personSelected', 'chatSubmit', 'closeCase', 'showCaseFinishedModal']);

// Store refs
const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const alertStore = useAlertStore();
const caseStore = useCaseStore();

const { user, userFirstName, userLastName, textToSpeechEnabled } = storeToRefs(authStore);
const {
  allPersons,
  selectedPerson,
  selectedPersonIndex,
  selectedPersonsLastChatMessage,
  selectedPersonMaxImage,
  selectedPersonsVoice,
  unselectedPersons,
  currentlyReadingChatMessageText,
  someChatIsStreaming,
  waitingForAudioPlayback,
  isReplaying,
} = storeToRefs(caseInteractionStore);
const { isFormCase, currentFormDataContentItem } = storeToRefs(caseStore);

// Add necessary store refs for header
const { currentCase, currentTaskIndex, currentSubtaskIndex, currentCaseInteractionId } =
  storeToRefs(caseInteractionStore);
const { someMessageToUndo, caseIsFinished } = storeToRefs(caseInteractionStore);

// Component refs
const interactionColumn = ref<InstanceType<typeof InteractionColumn> | null>(null);
const caseInteractionHeader = ref(null);
const caseInteractionFooter = ref(null);
const audioPlayer = ref<InstanceType<typeof AudioPlayerTTS> | null>(null);

// State
const interactionHeaderHeight = ref(0);
const interactionFooterHeight = ref(0);
const mountAudioPlayerTTS = ref(true);
const playingAudio = ref(false);
const personIsSelected = ref(false);
const currentUnwatch = ref(null);
const currentInput = ref('');
const showChatHistory = ref(false);
const showGoals = ref(false);
const showShortHistory = ref(true);
const showForm = ref(false);

// Router
const router = useRouter();

const messagesToRemove = ref(new Set());
const seenUndoneMessages = ref(new Set());
const messagesToRestore = ref(new Set());

// Initialize seenUndoneMessages with messages already undone
onBeforeMount(() => {
  if (chatMessages.value) {
    chatMessages.value.forEach((message) => {
      if (message.undone_at) {
        seenUndoneMessages.value.add(message.id);
      }
    });
  }
});

const inputPlaceholderMessage = computed(() => {
  if (selectedPerson.value && !!selectedPerson.value.placeholderMessage) {
    return selectedPerson.value.placeholderMessage;
  }
  return 'Etwas sagen...';
});

const awaitingOrStreamingAudio = computed(() => {
  return waitingForAudioPlayback.value || someChatIsStreaming.value || isReplaying.value;
});

const moreThanOnePerson = computed(() => {
  return allPersons.value.length > 1;
});

const chatMessages = computed(() => {
  if (!selectedPerson.value) return [];

  if (selectedPerson.value.type === 'PATIENT') {
    return patientInteractionStore.chatMessages;
  } else if (selectedPerson.value.type === 'THIRD_PERSON') {
    return thirdPersonInteractionStore.chatMessages[selectedPerson.value.thirdPersonIndex];
  }
  return [];
});

// Add computed for undo button state
const canUndo = computed(() => {
  if (!chatMessages.value) return false;
  const visibleMessages = chatMessages.value.filter((msg) => !msg.undone_at);
  return visibleMessages.length > 0 && !someChatIsStreaming.value;
});

// Add helper function
function stringNotEmptyOrNull(str) {
  return str !== null && str.trim() !== '';
}

// Methods
function handleSubmit() {
  if (!currentInput.value.trim()) return;

  onHandleChatSubmit(currentInput.value);
  currentInput.value = ''; // Clear input after submission
}

function onHandleChatSubmit(payload) {
  emit('chatSubmit', payload);
}

function onPersonSelected(person) {
  console.log('Received person selection: ', person);
  selectedPerson.value = person;
}

const adjustHeights = async () => {
  await nextTick();
  if (caseInteractionHeader.value) {
    interactionHeaderHeight.value = caseInteractionHeader.value.offsetHeight;
  }
  if (caseInteractionFooter.value) {
    interactionFooterHeight.value = caseInteractionFooter.value.offsetHeight;
  }
};

const debouncedAdjustHeights = debounce(adjustHeights, 200);

function selectPerson(index) {
  if (playingAudio.value) {
    console.log('Cannot select person while playing audio');
    return;
  }
  currentlyReadingChatMessageText.value = '';
  caseInteractionStore.selectPerson(index);
  emit('personSelected', allPersons.value[index]);
}

// Lifecycle hooks
onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  if (dvhSupported) {
    document.documentElement.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  window.addEventListener('resize', debouncedAdjustHeights);
  await adjustHeights();

  if (props.showingMainCaseView) {
    await unlockAudioContext();
  }

  // Add click outside handler to close goals
  document.addEventListener('click', (event) => {
    const goalsButton = document.querySelector('[data-goals-button]');
    const goalsCard = document.querySelector('[data-goals-card]');
    if (!goalsButton?.contains(event.target as Node) && !goalsCard?.contains(event.target as Node)) {
      showGoals.value = false;
    }
  });

  // Set header and button heights for goals card positioning
  const header = document.querySelector('[data-testid="case-interaction-header"]');
  const goalsButton = document.querySelector('[data-goals-button]');

  if (header) {
    document.documentElement.style.setProperty('--header-height', `${header.offsetHeight}px`);
  }
  if (goalsButton) {
    document.documentElement.style.setProperty('--button-height', `${goalsButton.offsetHeight}px`);
  }

  // Set footer height for chat history positioning
  const footer = document.querySelector('[data-testid="case-interaction-footer"]');
  if (footer) {
    document.documentElement.style.setProperty('--footer-height', `${footer.offsetHeight}px`);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeights);
  if (currentUnwatch.value) {
    currentUnwatch.value();
    currentUnwatch.value = null;
  }
  document.removeEventListener('click', () => {});
});

// Audio context handling
const unlockAudioContext = async () => {
  if (audioPlayer.value) {
    return await audioPlayer.value.unlockAudioContext();
  }
  return false;
};

defineExpose({
  unlockAudioContext,
});

// Add watch for input handling
watch(
  () => props.lastInputAt,
  async (newLastInputAt) => {
    if (!selectedPerson.value || !props.inputText) {
      console.log('No selected person or no input text');
      return;
    }

    // Clean up previous watcher
    if (currentUnwatch.value) {
      currentUnwatch.value();
      currentUnwatch.value = null;
    }

    // Set up watcher for streaming completion
    const streamingComplete = new Promise<void>((resolve) => {
      const unwatch = watch(
        () => someChatIsStreaming.value,
        async (isStreaming, wasStreaming) => {
          if (wasStreaming && !isStreaming) {
            console.log('Chat streaming completed. Evaluating if task completed.');
            await caseInteractionStore.evaluateIfTaskIsCompleted();
            unwatch();
            currentUnwatch.value = null;
            resolve();
          }
        },
      );
      currentUnwatch.value = unwatch;
    });

    try {
      // Send the message
      await caseInteractionStore.say(props.inputText);
      await streamingComplete;

      // Emit update response after completion
      emit('updateResponse', {
        success: true,
        message: 'Input processed successfully',
      });
    } catch (error) {
      console.error('Error processing input:', error);
      emit('updateResponse', {
        success: false,
        message: 'Error processing input',
      });
    }
  },
);

// Add watch for selectedPersonIndex
watch(
  () => selectedPersonIndex.value,
  (newIndex) => {
    if (newIndex == null) return;
    console.log('New selected person: ', selectedPerson.value, ' at index ', newIndex);
    personIsSelected.value = true;
  },
  { immediate: true },
);

// Add handler method
function handleTaskSelection(taskIndex: number) {
  // Add task selection logic here
  console.log('Task selected:', taskIndex);
}

// Add watch for chat history visibility
watch(showChatHistory, (isVisible) => {
  if (isVisible) {
    // Wait for the transition to complete and DOM to update
    nextTick(() => {
      const historyContainer = document.querySelector('.chat-history-container');
      if (historyContainer) {
        historyContainer.scrollTop = historyContainer.scrollHeight;
      }
    });
  }
});

// Add a watch for chat messages
watch(
  [() => chatMessages.value, () => someChatIsStreaming.value, () => currentInput.value],
  () => {
    if (showChatHistory.value) {
      nextTick(() => {
        const historyContainer = document.querySelector('.chat-history-container');
        if (historyContainer) {
          // Check if user is already near bottom before auto-scrolling
          const isNearBottom =
            historyContainer.scrollHeight - historyContainer.scrollTop - historyContainer.clientHeight < 100;

          if (isNearBottom) {
            historyContainer.scrollTop = historyContainer.scrollHeight;
          }
        }
      });
    }
  },
  { deep: true }, // Watch for nested changes in messages array
);

// Close other overlay when one is opened
watch(showGoals, (newValue) => {
  if (newValue && showChatHistory.value) {
    showChatHistory.value = false;
  }
});

watch(showChatHistory, (newValue) => {
  if (newValue && showGoals.value) {
    showGoals.value = false;
  }
});

const closeCase = async () => {
  emit('closeCase');
};

// Update the undo method to handle multiple undos
async function undoInteraction() {
  if (!canUndo.value) return;

  try {
    const someMessageHasBeenUndone = await caseInteractionStore.undoSay();
    if (someMessageHasBeenUndone) {
      alertStore.success('Interaction undone');
    } else {
      alertStore.info('No interaction to undo');
    }
  } catch (error) {
    alertStore.error('Error undoing interaction', 'Error', error);
    throw error;
  }
}

// Add these helper functions
function handleMessageRemoval(messageId) {
  messagesToRemove.value.add(messageId);
  setTimeout(() => {
    messagesToRemove.value.delete(messageId);
  }, 800);
}

function handleMessageRestoration(messageId) {
  messagesToRestore.value.add(messageId);
  setTimeout(() => {
    messagesToRestore.value.delete(messageId);
  }, 800);
}

// Add watch for changes to messages that become undone
watch(
  () => chatMessages.value,
  (newMessages, oldMessages) => {
    if (!oldMessages) return;
    newMessages.forEach((message) => {
      if (message.undone_at && !seenUndoneMessages.value.has(message.id)) {
        seenUndoneMessages.value.add(message.id);
        handleMessageRemoval(message.id);
      }
      if (!message.undone_at && seenUndoneMessages.value.has(message.id)) {
        seenUndoneMessages.value.delete(message.id);
        handleMessageRestoration(message.id);
      }
    });
  },
  { deep: true, immediate: true },
);

// Replace the gradientColor computed with gradientHeight
const gradientHeight = computed(() => {
  // Amplify the volume level to make it more reactive
  const volume = Math.pow(audioPlayer.value?.volumeLevel * 1.5 || 0, 2);
  if (!playingAudio.value) {
    return '40vh'; // default height when not playing
  }
  // Keep the same height range but make it more responsive
  const minHeight = 30;
  const maxHeight = 80;
  const height = minHeight + Math.min(volume, 1) * (maxHeight - minHeight);
  return `${height}vh`;
});
</script>

<template>
  <div class="flex flex-col w-full h-full overflow-hidden">
    <!-- Background Image with gradient overlay -->
    <div class="absolute inset-0 w-full h-full">
      <img v-if="selectedPerson" :src="selectedPersonMaxImage" class="w-full h-full object-cover" />
      <!-- Background overlay -->
      <div class="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent h-[20vh]"></div>
      <!-- Solid white container -->
      <div
        class="absolute w-full bg-white"
        :style="{
          bottom: '0',
          height: '128px',
          opacity: '1',
        }"
      ></div>
      <!-- Gradient overlay -->
      <div
        class="absolute w-full transition-all duration-500"
        :style="{
          bottom: '128px',
          height: gradientHeight,
          background: `linear-gradient(to top,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.95) 10%,
            rgba(255, 255, 255, 0.8) 25%,
            rgba(255, 255, 255, 0.6) 40%,
            rgba(255, 255, 255, 0.4) 55%,
            rgba(255, 255, 255, 0.2) 70%,
            rgba(255, 255, 255, 0.1) 85%,
            transparent 100%
          )`,
        }"
      ></div>
    </div>

    <!-- Header -->
    <header
      ref="caseInteractionHeader"
      class="absolute top-0 left-0 right-0 w-full z-10"
      data-testid="case-interaction-header"
    >
      <div class="flex items-center px-4 py-3">
        <!-- Left section -->
        <div class="flex-1 flex justify-start">
          <button
            @click="
              () => {
                if (showChatHistory) {
                  showChatHistory = false;
                  return;
                }
                closeCase();
              }
            "
            class="p-2 rounded-full backdrop-blur-sm transition-colors duration-200 focus:outline-none text-white hover:bg-white/10 border border-white/10"
          >
            <span class="sr-only">Close case</span>
            <X class="h-5 w-5" />
          </button>
        </div>

        <!-- Center section -->
        <div class="flex-1 flex justify-center px-2 gap-x-2">
          <button
            @click="
              () => {
                showChatHistory = !showChatHistory;
                showForm = false;
              }
            "
            class="flex items-center gap-1 pl-3 pr-4 py-2 rounded-full transition-all duration-200 focus:outline-none"
            :class="
              showChatHistory
                ? 'bg-white text-gray-900'
                : 'backdrop-blur-sm text-white hover:bg-white/10 border border-white/10'
            "
          >
            <MessageSquare class="h-5 w-5" />
            <span class="text-sm font-medium">{{ $t('message.details') }}</span>
          </button>

          <button
            v-if="isFormCase"
            @click="
              () => {
                showForm = !showForm;
                showChatHistory = false;
              }
            "
            class="flex items-center gap-1 pl-3 pr-4 py-2 rounded-full transition-all duration-200 focus:outline-none"
            :class="
              showForm
                ? 'bg-white text-gray-900'
                : 'backdrop-blur-sm text-white hover:bg-white/10 border border-white/10'
            "
          >
            <ClipboardPenLine class="h-5 w-5" />
            <span class="text-sm font-medium">{{ $t('message.form') }}</span>
          </button>
        </div>

        <!-- Right section -->
        <div class="flex-1 flex justify-end">
          <button
            v-if="!caseIsFinished"
            data-goals-button
            @click="showGoals = !showGoals"
            class="flex items-center gap-1 pl-3 pr-4 py-2 rounded-full transition-all duration-200 focus:outline-none"
            :class="
              showGoals
                ? 'bg-white text-gray-900'
                : 'backdrop-blur-sm text-white hover:bg-white/10 border border-white/10'
            "
          >
            <ListChecks class="h-5 w-5" />
            <span class="text-sm font-medium">{{ $t('message.goals') }}</span>
          </button>
          <button
            v-else
            @click="emit('showCaseFinishedModal')"
            class="flex items-center gap-1 pl-3 pr-4 py-2 rounded-full transition-all duration-200 focus:outline-none backdrop-blur-sm text-white hover:bg-white/10 border border-white/10"
          >
            <LogOut class="h-5 w-5" />
            <span class="text-sm font-medium">{{ $t('message.finishCase') }}</span>
          </button>
        </div>
      </div>
    </header>

    <!-- Goals Modal Card -->
    <TaskInfoCard
      v-if="showGoals && currentCase && currentCase.tasks"
      data-goals-card
      class="fixed w-80 bg-white rounded-2xl shadow-lg overflow-hidden z-50 border border-gray-200"
      style="top: calc(var(--button-height, 40px) + 24px); right: 16px"
      :task="currentCase.tasks[currentTaskIndex]"
      :showCard="false"
      :showProfileImage="false"
    />

    <!-- Main content -->
    <main class="flex flex-col justify-end h-full relative z-0">
      <!-- Content -->
      <div class="relative h-full flex flex-col justify-end">
        <!-- Toggle button for short history -->
        <button
          v-show="!showChatHistory && !showForm"
          @click="showShortHistory = !showShortHistory"
          class="absolute right-4 bottom-[88px] z-[50] p-3 rounded-full backdrop-blur-sm transition-colors duration-200 focus:outline-none border border-gray-200/50"
          :class="
            showShortHistory
              ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              : 'bg-white text-gray-900 hover:bg-white/90'
          "
        >
          <span class="sr-only">{{ showShortHistory ? 'Hide chat' : 'Show chat' }}</span>
          <CaptionsOff v-if="showShortHistory" class="w-5 h-5" />
          <Captions v-else class="w-5 h-5" />
        </button>

        <!-- Short chat history: sticky at the bottom -->
        <div
          class="w-full h-full relative overflow-hidden flex flex-col justify-end"
          :class="[
            'transition-all duration-300 ease-in-out',
            showShortHistory
              ? 'opacity-100 transform translate-y-0'
              : 'opacity-0 pointer-events-none transform translate-y-8',
          ]"
        >
          <div
            class="w-full h-full flex flex-col justify-end overflow-hidden transition-opacity duration-300"
            :style="{
              maskImage:
                'linear-gradient(to bottom, transparent 6%, rgba(0,0,0,0.05) 12vh, rgba(0,0,0,0.3) 16vh, rgba(0,0,0,0.7) 20vh, black 24vh)',
              WebkitMaskImage:
                'linear-gradient(to bottom, transparent 6%, rgba(0,0,0,0.05) 12vh, rgba(0,0,0,0.3) 16vh, rgba(0,0,0,0.7) 20vh, black 24vh)',
            }"
          >
            <div class="min-h-full flex-col flex justify-end">
              <ChatHistoryNew
                v-if="!!selectedPerson"
                :selectedPerson="selectedPerson"
                :showFullHistory="false"
                :canUndo="canUndo"
                @undoInteraction="undoInteraction"
                :style="{ paddingBottom: 'calc(64px + 1rem)' }"
              />
            </div>
          </div>
        </div>

        <!-- Hidden audio player -->
        <AudioPlayerTTS
          v-if="!!selectedPersonsLastChatMessage && mountAudioPlayerTTS"
          ref="audioPlayer"
          :message="
            unobfuscateUserName(
              selectedPersonsLastChatMessage.content['processed_model_output'],
              userFirstName,
              userLastName,
            )
          "
          :messageIsComplete="!someChatIsStreaming"
          :messageId="selectedPersonsLastChatMessage.id"
          :voice="selectedPersonsVoice"
          @playingAudio="(isPlaying) => (playingAudio = isPlaying)"
          @playbackFinished="
            () => {
              // remountAudioPlayerTTS();
              playingAudio = false;
            }
          "
        />

        <!-- Full chat history: Background overlay for when active -->
        <div
          class="fixed inset-0 bg-black transition-opacity duration-300 z-10"
          :class="showChatHistory ? 'opacity-40' : 'opacity-0 pointer-events-none'"
        ></div>

        <!-- Chat History Overlay -->
        <div
          class="fixed inset-x-0 bg-white/95 backdrop-blur-mdm transition-all duration-300 ease-in-out transform overflow-hidden z-20 rounded-t-2xl"
          :class="
            showChatHistory
              ? `translate-y-0 opacity-100 pointer-events-auto`
              : 'translate-y-full opacity-0 pointer-events-none'
          "
          :style="{
            top: `calc(var(--button-height, 40px) + 24px + ${props.outerHeaderHeight}px)`,
            bottom: '0',
          }"
        >
          <!-- Settings bar -->
          <div class="sticky top-0 bg-white border-b border-gray-200 px-3 py-3">
            <div class="flex items-center justify-between">
              <DifficultyButton />
              <CloseButton @clicked="showChatHistory = false" />
            </div>
          </div>

          <div class="overflow-hidden h-full pb-16 chat-history-container">
            <ChatHistoryNew
              :selectedPerson="selectedPerson"
              :showFullHistory="true"
              :canUndo="canUndo"
              @undoInteraction="undoInteraction"
            />
          </div>
        </div>

        <!-- Form Overlay -->
        <div
          class="fixed inset-x-0 bg-white/95 backdrop-blur-mdm transition-all duration-300 ease-in-out transform overflow-hidden z-20 rounded-t-2xl"
          :class="
            showForm
              ? `translate-y-0 opacity-100 pointer-events-auto`
              : 'translate-y-full opacity-0 pointer-events-none'
          "
          :style="{
            top: `calc(var(--button-height, 40px) + 24px + ${props.outerHeaderHeight}px)`,
            bottom: '0',
          }"
        >
          <!-- Settings bar -->
          <div class="sticky top-0 bg-white border-b border-gray-200 px-3 py-3">
            <div class="flex items-center justify-between">
              <CloseButton @clicked="showForm = false" />
            </div>
          </div>

          <div class="overflow-hidden h-full pb-16 px-0.5">
            <BarthelAsklepios
              :contentItem="currentFormDataContentItem"
              :case-interaction-id="currentCaseInteractionId"
              :for-case="true"
              :is-editing-solution="false"
              :reserve-space-for-bottom-buttons="true"
            />
          </div>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <footer
      v-show="showingMainCaseView"
      ref="caseInteractionFooter"
      class="flex-none fixed bottom-0 left-0 right-0 z-30"
      data-testid="case-interaction-footer"
    >
      <!-- ## {{ caseInteractionStore.waitingForAudioPlayback }}
      ## {{ caseInteractionStore.someChatIsStreaming }}
      ## {{ caseInteractionStore.isReplaying }} -->
      <TextareaWithAudioInputCaseInteraction
        v-model="currentInput"
        :placeholder="inputPlaceholderMessage"
        :disabled="awaitingOrStreamingAudio"
        :allowAudioInput="true"
        :isSubmitted="false"
        :isFinished="false"
        @submit="handleSubmit"
      />
    </footer>
  </div>
</template>

<style scoped>
.scroll-container {
  scroll-behavior: smooth;
}

/* smooth scrolling for chat history */
.chat-history-container {
  scroll-behavior: smooth;
}

button {
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

/* Remove the scale animations from the previous button styles */
button:hover {
  /* Remove transform: scale(1.05); */
}

button:active {
  /* Remove transform: scale(0.95); */
}

/* Ensure the header is positioned absolutely */
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

/* Allow the main content to fill the entire height */
main {
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
</style>
