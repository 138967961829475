<script setup lang="ts">
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore, useOrganizationStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { computed, onMounted, watch } from 'vue';
import ProgressButton from '../ProgressButton.vue';
import { feedbackIntegration } from '@sentry/vue';
import SignInOrEditProfileDropdownContents from '@/components/headers/SignInOrEditProfileDropdownContents.vue';

const authStore = useAuthStore();
const organizationStore = useOrganizationStore();
const { user } = storeToRefs(authStore);
const { isSignedIn } = storeToRefs(authStore);

onMounted(async () => {
  if (!isSignedIn.value) {
    return;
  }
  await organizationStore.loadOrganizations();
});

watch(
  () => isSignedIn,
  async () => {
    if (!isSignedIn.value) {
      return;
    }
    await organizationStore.loadOrganizations();
  },
  { immediate: true },
);
</script>

<template>
  <!-- <div class="pt-3 md:pt-0" v-if="!isSignedIn">
    <router-link
      class="inline-flex justify-center items-center gap-x-2 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-2.5 dark:focus:ring-offset-gray-800"
      to="/account/sign-in"
    >
      <span translate="no" class="material-symbols-outlined notranslate">person</span>
      {{ $t('message.signIn') }}
    </router-link>
  </div> -->

  <ProgressButton
    v-if="!isSignedIn"
    routerLinkTo="/account/sign-in"
    color="blue"
    size="sm"
    :text="$t('message.signIn')"
    data-testid="sign-in-button"
  ></ProgressButton>

  <div class="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] inline-flex pr-1" v-else>
    <button
      id="hs-dropdown-custom-trigger"
      type="button"
      class="hs-dropdown-toggle py-1 pl-1 pr-2 inline-flex justify-center items-center gap-1 rounded-full border bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-neutral-800 dark:hover:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
    >
      <ProfileImage
        :image="authStore.userProfileImageSmall"
        :initials="authStore.userInitials"
        :showIngameLevel="true"
      />
      <span
        translate="no"
        class="material-symbols-outlined notranslate text-2xl block hs-dropdown-open:rotate-180 transition-all transform duration-300 ease-in-out"
      >
        expand_more
      </span>
    </button>

    <div
      class="hs-dropdown-menu transition-[opacity,margin] duration-300 hs-dropdown-open:opacity-100 opacity-0 hidden z-10 top-full min-w-[15rem] bg-white md:shadow-2xl rounded-lg py-2 md:p-4 space-y-0.5 mt-2 dark:bg-neutral-800 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:h-5"
      aria-labelledby="hs-dropdown-with-title"
    >
      <SignInOrEditProfileDropdownContents />
    </div>
  </div>
</template>

<style scoped></style>
