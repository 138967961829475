<!-- TODO: Replace "translate" text by flag symbol for the targeted other language -->
<!-- TODO 2: If hit, replace text by translated content (if bilingual mode) -- or redirect to product page to buy -->

<!-- TODO: add CSP as additional layer of security against XSS? <meta http-equiv="Content-Security-Policy" content="script-src 'self';"> etc. ? -->

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import DOMPurify from 'dompurify';

import { usePatientInteractionStore, useAuthStore, useThirdPersonInteractionStore } from '@/stores';
import { countNumberOfSentences, splitSentences, unobfuscateUserName } from '@/helper';

// Props
const props = defineProps({
  markedSentencesOrigin: {
    type: Array,
  },
  message: {
    type: Object,
  },
  messageType: {
    type: String,
  },
  conversation: {
    type: String,
  },
});

// Emits
const emit = defineEmits(['onTranslationToggled']);

// Store setup
const patientInteractionStore = usePatientInteractionStore();
const authStore = useAuthStore();

const { patientLanguage } = storeToRefs(patientInteractionStore);
const { userNativeLanguage: userNative, userFirstName, userLastName } = storeToRefs(authStore);

// Reactive state
const showTranslation = ref(false);
const showDirectTranslation = ref(false);
const showUserInputRecommendation = ref(false);
const showExaminationReport = ref(false);
const showZurueck = ref(false);
const translationLanguage = ref('');
const translationOption = ref('');
const recommendationLanguage = ref('');
const recommendationOption = ref('');

// Computed properties
const languagePair = computed(() => {
  if (!userNative.value || !patientLanguage.value) {
    return ['', ''];
  }
  return [userNative.value, patientLanguage.value];
});

const highlightedDirectTranslation = computed(() => {
  return highlightSentence(
    unobfuscateUserName(
      props.message.translations[translationLanguage.value][translationOption.value],
      userFirstName.value,
      userLastName.value,
    ),
    props.markedSentencesOrigin,
    totalNumberOfSentencesOriginalChatMessage.value,
  );
});

const totalNumberOfSentencesOriginalChatMessage = computed(() => {
  return countNumberOfSentences(props.message.content['processed_model_output']);
});

const highlightedExaminationReportTranslation = computed(() => {
  return highlightSentence(
    unobfuscateUserName(
      props.message.translations[translationLanguage.value]['report'],
      userFirstName.value,
      userLastName.value,
    ),
    props.markedSentencesOrigin,
    totalNumberOfSentencesOriginalExaminationReport.value,
  );
});

const totalNumberOfSentencesOriginalExaminationReport = computed(() => {
  return countNumberOfSentences(props.message.report);
});

// Methods
function highlightSentence(text, markedSentences, totalNumberOfSentences) {
  if (text === undefined) {
    return '';
  }
  const sentences = splitSentences(text);
  const numSentencesOrig = totalNumberOfSentences;
  const numSentencesTarget = sentences.length;
  const ratio = numSentencesTarget / numSentencesOrig;
  const startSentence = Math.floor(markedSentences[0] * ratio);
  const endSentence = Math.ceil(markedSentences[markedSentences.length - 1] * ratio);
  const highlightedSentences = [];

  for (let i = 0; i < sentences.length; i++) {
    if (i >= startSentence && i <= endSentence) {
      highlightedSentences.push('<span class="bg-yellow-200">' + sentences[i] + '</span>');
    } else {
      highlightedSentences.push(sentences[i]);
    }
  }
  return DOMPurify.sanitize(highlightedSentences.join(''));
}

function getOtherElement(list, element, defaultElement = 'eng') {
  if (!list || !Array.isArray(list) || list.length !== 2) {
    console.warn('Invalid language pair:', list);
    return defaultElement;
  }

  if (!element) {
    console.warn('Invalid element:', element);
    return defaultElement;
  }

  if (list[0] === element) {
    return list[1];
  } else if (list[1] === element) {
    return list[0];
  }

  return defaultElement;
}

async function reset(message, languagePair) {
  if (props.messageType === 'LAB') {
    const defaultLanguage = message[0].language;
    await patientInteractionStore.translateLabSheet(message, defaultLanguage);
  }
}

async function getTranslation(message, languagePair) {
  let store = null;
  if (props.conversation === 'PATIENT') {
    store = patientInteractionStore;
  } else if (props.conversation === 'THIRD-PERSON' || props.conversation === 'ATTENDING') {
    store = useThirdPersonInteractionStore();
  } else {
    throw new Error('Conversation not supported');
  }

  if (
    props.messageType === 'DESCRIPTION' ||
    props.messageType === 'SAY-PATIENT' ||
    props.messageType === 'SAY-ATTENDING' ||
    props.messageType === 'SAY-THIRD-PERSON'
  ) {
    translationLanguage.value = getOtherElement(languagePair, message.language);
    console.log('translationLanguage', translationLanguage.value);
    console.log('languagePair', languagePair);
    console.log('message', message);
    translationOption.value = 'system_response_translation';

    if (props.messageType === 'DESCRIPTION') {
      await store.translateDescMessage(message, translationLanguage.value || 'eng', translationOption.value);
    } else {
      await store.translateChatMessage(message, translationLanguage.value || 'eng', translationOption.value);
    }

    showDirectTranslation.value = true;
    showUserInputRecommendation.value = false;
  } else if (props.messageType === 'SAY-USER') {
    if (message.content['user_message_language'] === userNative.value) {
      recommendationLanguage.value = getOtherElement(languagePair, message.content['user_message_language']);
      recommendationOption.value = 'user_input_recommendation';
      await store.translateChatMessage(message, recommendationLanguage.value || 'deu', recommendationOption.value);
      showDirectTranslation.value = false;
      showUserInputRecommendation.value = true;
    } else {
      recommendationLanguage.value = message.content['user_message_language'];
      recommendationOption.value = 'user_input_recommendation';
      await store.translateChatMessage(message, recommendationLanguage.value || 'deu', recommendationOption.value);

      translationLanguage.value = userNative.value;
      translationOption.value = 'user_input_translation';
      await store.translateChatMessage(message, translationLanguage.value || 'eng', translationOption.value);

      showDirectTranslation.value = false;
      showUserInputRecommendation.value = true;
    }
  } else if (props.messageType === 'EXAMINATION') {
    translationLanguage.value = getOtherElement(languagePair, message.language);
    await store.translateExamination(message, translationLanguage.value || 'eng');
    showExaminationReport.value = true;
  } else if (props.messageType === 'LAB') {
    const targetLanguage = getOtherElement(languagePair, message[0].language);
    await store.translateLabSheet(message, targetLanguage || 'eng');
    showZurueck.value = true;
  } else {
    showZurueck.value = true;
  }
}

async function toggleTranslation() {
  showTranslation.value = !showTranslation.value;
  emit('onTranslationToggled', showTranslation.value);

  let message = props.message;

  if (!showTranslation.value) {
    await reset(message, languagePair.value);
    return;
  }

  await getTranslation(message, languagePair.value);
}

defineExpose({
  toggleTranslation,
});
</script>

<template>
  <button
    type="button"
    @click="toggleTranslation()"
    class="text-sm text-left px-3 inline-flex justify-center items-center gap-x-2 rounded-lg border border-transparent text-black focus:outline-none transition-all dark:hover:bg-neutral-800 dark:text-white dark:hover:text-gray-400 dark:hover:border-gray-900"
  >
    <div v-if="!showTranslation">{{ $t('message.translate') }}</div>
    <div v-if="showTranslation" class="flex-col flex gap-y-2">
      <div v-if="showZurueck">{{ $t('message.back') }}</div>
      <div v-else-if="showExaminationReport">
        {{
          message.translations[translationLanguage]['name']
            ? message.translations[translationLanguage]['name'] + ':'
            : ''
        }}
        <div v-html="highlightedExaminationReportTranslation"></div>
      </div>
      <div v-else class="flex-col flex gap-y-2">
        <div v-if="showDirectTranslation">
          <span v-if="showUserInputRecommendation">{{ $t('message.translation') }}: </span>
          <div
            v-html="highlightedDirectTranslation"
            v-if="!!message.translations?.[translationLanguage]?.[translationOption]"
          ></div>
          <div
            v-else
            class="animate-spin h-4 w-4 border-[3px] border-gray-500 border-current border-t-transparent rounded-full"
          ></div>
        </div>
        <div v-if="showUserInputRecommendation">
          {{ $t('message.recommendedFormulation') }}:
          {{ message.translations?.[recommendationLanguage]?.[recommendationOption] }}
        </div>
      </div>
    </div>
  </button>
</template>

<style scoped></style>
