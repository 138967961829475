<script setup lang="ts">
const props = defineProps<{
  color?: 'blue' | 'green' | 'teal' | 'black';
}>();

const color = props.color ?? 'blue';
</script>

<template>
  <div class="flex justify-center items-center">
    <div
      class="animate-spin inline-block mt-6 md:mt-0 w-20 h-20 md:w-32 md:h-32 border-2 md:border-4 border-current border-t-transparent rounded-full"
      :class="{
        'text-blue-600': color === 'blue',
        'text-black': color === 'black',
        'text-green': color === 'green',
        'text-teal-600': color === 'teal',
      }"
      role="status"
      aria-label="loading"
    />
  </div>
</template>

<style scoped></style>
