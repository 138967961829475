<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { GraduationCap, CheckCircle } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  testIsFinished: {
    type: Boolean,
    default: false,
  },
  exerciseIsCompleted: {
    type: Boolean,
    default: false,
  },
  withBottomNav: {
    type: Boolean,
    default: false,
  },
  collapseIfScrolledDown: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

// emits
const emit = defineEmits(['startVocabTest', 'finishExercise', 'repeatVocabTest']);

// state
const isScrollingDown = ref(false);
let lastScrollPosition = 0;

// computed
const buttonType = computed(() => {
  if (props.exerciseIsCompleted) {
    return 'repeatVocabTest';
  }

  if (props.testIsFinished) {
    return 'finish';
  }

  return 'startVocabTest';
});

const buttonClasses = computed(() => {
  // default classes
  const classes = [
    'flex text-white px-4 py-3 rounded-full text-base font-semibold gap-x-2 pointer-events-auto cursor-pointer',
  ];

  if (buttonType.value === 'startVocabTest') {
    classes.push('bg-black'); // Default state
  } else if (buttonType.value === 'finish') {
    classes.push('bg-orange'); // Has text state
  } else if (buttonType.value === 'repeatVocabTest') {
    classes.push('bg-green hover:bg-black'); // Next state
  }

  return classes;
});

const shouldCollapse = computed(() => {
  return isScrollingDown.value && props.collapseIfScrolledDown;
});

// methods
const handleScroll = (event: Event) => {
  const scrollContainer = event.target as Element;
  const currentScrollPosition = scrollContainer.scrollTop;

  const isAtTop = currentScrollPosition <= 30;
  const isAtBottom = currentScrollPosition + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 30;

  // Determine scroll direction (ignoring bounce backs from top / bottom)
  if (currentScrollPosition > lastScrollPosition && !isAtTop) {
    isScrollingDown.value = true;
  } else if (currentScrollPosition < lastScrollPosition && !isAtBottom) {
    isScrollingDown.value = false;
  }

  lastScrollPosition = currentScrollPosition;
};

// lifecycle
onMounted(() => {
  // Find the scrollable container (the one with overflow-auto), in parent
  const scrollContainer = document.querySelector('.overflow-auto') || document.querySelector('.overflow-y-auto');
  if (scrollContainer) {
    scrollContainer.addEventListener('scroll', handleScroll, { passive: true });
  }
});

onUnmounted(() => {
  const scrollContainer = document.querySelector('.overflow-auto') || document.querySelector('.overflow-y-auto');
  if (scrollContainer) {
    scrollContainer.removeEventListener('scroll', handleScroll);
  }
});
</script>

<template>
  <div
    class="fixed z-40 left-0 flex justify-center w-full items-center mb-6 pointer-events-none select-none"
    :class="[props.withBottomNav ? 'bottom-16' : 'bottom-0', { 'opacity-40': shouldCollapse }]"
    @click="
      async () => {
        if (buttonType === 'startVocabTest') emit('startVocabTest');
        else if (buttonType === 'repeatVocabTest') emit('repeatVocabTest');
        else if (buttonType === 'finish') emit('finishExercise');
      }
    "
  >
    <div :class="buttonClasses" v-if="buttonType === 'startVocabTest'">
      <GraduationCap :size="20" />
      <span
        class="transform -translate-y-[1px] transition-all duration-300 ease-in select-none"
        :class="['overflow-hidden whitespace-nowrap', { collapsed: shouldCollapse, expanded: !shouldCollapse }]"
      >
        {{ t('message.startExercise') }}
      </span>
    </div>
    <div :class="buttonClasses" v-else-if="buttonType === 'finish'">
      <CheckCircle :size="20" />
      <span
        class="transform -translate-y-[1px] transition-all duration-300 ease-in select-none"
        :class="['overflow-hidden whitespace-nowrap', { collapsed: shouldCollapse, expanded: !shouldCollapse }]"
      >
        {{ t('message.finishExercise') }}
      </span>
    </div>
    <div :class="buttonClasses" v-else-if="buttonType === 'repeatVocabTest'">
      <GraduationCap :size="20" />
      <span
        class="transform -translate-y-[1px] transition-all duration-300 ease-in select-none"
        :class="['overflow-hidden whitespace-nowrap', { collapsed: shouldCollapse, expanded: !shouldCollapse }]"
      >
        {{ t('message.repeatExercise') }}
      </span>
    </div>
  </div>
</template>

<style scoped>
.collapsed {
  max-width: 0;
  margin-left: 0;
  opacity: 0;
  transition: all 300ms ease-out;
}
.expanded {
  max-width: 200px; /* Large enough to accommodate different text lengths */
  margin-left: 0.5rem;
  opacity: 1;
  transition: all 300ms ease-out;
}
</style>
