<script setup lang="ts">
import { X } from 'lucide-vue-next';

const emit = defineEmits(['clicked']);
</script>

<template>
  <button
    @click="emit('clicked')"
    class="p-1 text-gray-400 hover:text-gray-700 bg-gray-50 rounded-full transition-colors"
  >
    <X class="h-5 w-5" />
    <span class="sr-only">Close</span>
  </button>
</template>

<style scoped></style>
