<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { truncate } from '@/helper';
import { Play, ArrowRight } from 'lucide-vue-next';
import CaseService from '@/services/CaseService';
import ProfileImage from '@/components/ProfileImage.vue';

const props = defineProps({
  caseInteraction: {
    type: Object,
    required: true,
  },
});

const router = useRouter();

const titlePerson = computed(() => {
  if (!!props.caseInteraction.case.patient) {
    return props.caseInteraction.case.patient;
  }
  if (!props.caseInteraction.case.third_persons) {
    console.error('CaseQuickactionResume.vue: case ' + props.case.id + ' has no patient and third_persons');
    return;
  }
  return props.caseInteraction.case.third_persons[0];
});

const caseSetting = computed(() => {
  return props.caseInteraction.case?.tasks?.[0]?.details?.location || '';
});

const currentTask = computed(() => {
  if (!props.caseInteraction.current_interaction?.current_task_interaction_index) {
    return props.caseInteraction.case.tasks?.[0];
  }
  return props.caseInteraction.case.tasks?.[
    props.caseInteraction.case.current_interaction.current_task_interaction_index
  ];
});

const currentSubtask = computed(() => {
  if (!currentTask.value?.subtasks) return null;

  const taskInteraction =
    props.caseInteraction.current_interaction?.task_interactions?.[
      props.caseInteraction.current_interaction.current_task_interaction_index
    ];

  if (!taskInteraction) return currentTask.value.subtasks[0];

  // Find first uncompleted subtask
  const uncompletedSubtaskIndex = taskInteraction.subtask_interactions?.findIndex((subtask) => !subtask.completed_at);

  return uncompletedSubtaskIndex >= 0
    ? currentTask.value.subtasks[uncompletedSubtaskIndex]
    : currentTask.value.subtasks[currentTask.value.subtasks.length - 1];
});

async function resumeCaseInteraction() {
  await router.push({
    name: 'case-interaction-main',
    params: {
      caseInteractionId: props.caseInteraction.id,
    },
  });
}

const currentSubtaskInteraction = computed(() => {
  let currentTaskInteraction =
    props.caseInteraction.task_interactions[props.caseInteraction.current_task_interaction_index];

  // First check current task's uncompleted subtasks
  if (currentTaskInteraction) {
    const uncompletedSubtaskInteraction = currentTaskInteraction.subtask_interactions.find(
      (interaction) => !interaction.completed_at,
    );
    if (uncompletedSubtaskInteraction) {
      return truncate(currentTask.value.subtasks[uncompletedSubtaskInteraction.index]?.details?.target, 60);
    }
  }

  // If all subtasks in current task are complete, show first subtask of next task
  const nextTaskIndex = (props.caseInteraction.current_task_interaction_index || 0) + 1;
  if (nextTaskIndex < props.caseInteraction.case.tasks.length) {
    const nextTask = props.caseInteraction.case.tasks[nextTaskIndex];
    if (nextTask?.subtasks?.[0]?.details?.target) {
      return truncate(nextTask.subtasks[0].details.target, 60);
    }
  }

  // If no next task/subtask found, return default message
  return props.caseInteraction.case.tasks[0]?.subtasks[0]?.details?.target || 'Start the case';
});
</script>

<template>
  <div
    @click="resumeCaseInteraction"
    class="bg-[#F6F6F6] dark:bg-gray-800 rounded-2xl p-4 cursor-pointer hover:shadow-lg transition-shadow flex flex-col h-[198px]"
  >
    <!-- Title section -->
    <div class="relative">
      <h3 class="text-xl font-medium text-gray-900 dark:text-white break-words pr-16 leading-tight line-clamp-2">
        {{ currentTask?.title || props.caseInteraction.case.details.heading }}
      </h3>

      <!-- Patient and Third Person Images -->
      <div class="absolute top-0 right-0">
        <div class="relative">
          <!-- Third person image (if exists) -->
          <ProfileImage
            v-if="
              props.caseInteraction.case.third_persons?.[0] &&
              titlePerson !== props.caseInteraction.case.third_persons[0]
            "
            :image="props.caseInteraction.case.third_persons[0]?.profile_image?.image_urls.small || ''"
            :firstName="props.caseInteraction.case.third_persons[0]?.details.first_name"
            :lastName="props.caseInteraction.case.third_persons[0]?.details.last_name"
            class="w-12 h-12 absolute -right-3 -top-3"
          />
          <!-- Patient/Primary person image -->
          <ProfileImage
            :image="titlePerson.profile_image?.image_urls.small || ''"
            :firstName="titlePerson.details.first_name"
            :lastName="titlePerson.details.last_name"
            class="w-12 h-12 relative z-10"
          />
        </div>
      </div>
    </div>

    <!-- Spacer to push content to bottom -->
    <div class="flex-grow"></div>

    <!-- Subtask section - positioned at bottom -->
    <div class="mb-4">
      <div class="flex items-start gap-1">
        <ArrowRight size="16" class="text-gray-600 dark:text-gray-400 flex-shrink-0 mt-0.5" />
        <p v-if="currentSubtaskInteraction" class="text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
          {{ currentSubtaskInteraction }}
        </p>
      </div>
    </div>

    <!-- Resume Button -->
    <div class="flex items-center justify-between mt-auto">
      <button
        class="inline-flex items-center gap-1 pl-3 pr-4 py-2 bg-white text-gray-800 font-semibold rounded-full hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
      >
        <Play size="16" class="dark:text-white rounded" fill="currentColor" />
        {{ $t('message.resume') }}
      </button>
      <!-- <div class="flex-grow"></div> -->
      <div class="flex items-center gap-1 text-sm text-gray-600 dark:text-gray-400">
        <span>+{{ CaseService.getRemainingXpForCaseInteraction(props.caseInteraction.id) }} XP</span>
      </div>
    </div>
  </div>
</template>
