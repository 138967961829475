<script setup>
// TODO make elements own components

import ProfileImage from '@/components/ProfileImage.vue';
import { getApiClient } from '@/apiclient/client';
import { router } from '@/router';
import Badge from '@/components/Badge.vue';
import CaseInteractionListEmptyState from '@/components/cases/CaseInteractionListEmptyState.vue';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
import { computed } from 'vue';
import ActiveCaseCard from '@/components/cases/ActiveCaseCard.vue';

const props = defineProps({
  caseInteractions: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showUniqueCasesOnly: {
    type: Boolean,
    default: false,
  },
  showActiveCasesOnly: {
    type: Boolean,
    default: false,
  },
  reserveSpaceForButton: {
    type: Boolean,
    default: false,
  },
});

const titlePerson = (case_) => {
  if (!!case_.patient) {
    return case_.patient;
  }
  return case_.third_persons[0];
};

function badges(caseObject) {
  let taskTitles = [];
  caseObject.tasks.forEach((task) => {
    taskTitles.push({
      short: task.title.length > 12 ? task.title.substring(0, 12) + '...' : task.title,
      long: task.title,
    });
  });
  return taskTitles;
}

const dropdownId = (index) => {
  return 'hs-table-dropdown-1-' + index;
};

async function deleteCaseInteraction(caseInteraction) {
  await router.isReady();
  console.log('deleteCaseInteraction', caseInteraction);
  await (await getApiClient()).caseInteractions.deleteCaseInteraction(caseInteraction.id);
  await router.go(router.currentRoute);
}

const showCaseInteraction = computed(() => {
  if (!props.showUniqueCasesOnly && !props.showActiveCasesOnly) {
    return props.caseInteractions.map(() => true);
  }

  if (props.showActiveCasesOnly && !props.showUniqueCasesOnly) {
    return isActive.value;
  }

  if (!props.showActiveCasesOnly && props.showUniqueCasesOnly) {
    return isFirstInteractionForCase.value;
  }

  // First unique of all active interactions
  return isFirstActiveInteractionForCase.value;
});

const isFirstInteractionForCase = computed(() => {
  const firstInteractionWithUniqueCase = new Set();

  // First pass: collect IDs of first interactions for each unique case
  props.caseInteractions.forEach((interaction) => {
    if (interaction.case && !firstInteractionWithUniqueCase.has(interaction.case.id)) {
      firstInteractionWithUniqueCase.add(interaction.case.id); // Store case.id instead of interaction.id
    }
  });

  // Second pass: return true only for first interaction of each case
  return props.caseInteractions.map((interaction, index) => {
    const isFirstForCase =
      interaction.case &&
      firstInteractionWithUniqueCase.has(interaction.case.id) &&
      !props.caseInteractions.slice(0, index).some((prev) => prev.case && prev.case.id === interaction.case.id);
    return isFirstForCase;
  });
});

const isFirstActiveInteractionForCase = computed(() => {
  const firstActiveInteractionWithUniqueCase = new Set();

  props.caseInteractions.forEach((interaction) => {
    if (interaction.case && !firstActiveInteractionWithUniqueCase.has(interaction.case.id)) {
      firstActiveInteractionWithUniqueCase.add(interaction.case.id);
    }
  });

  return props.caseInteractions.map((interaction, index) => {
    const isFirstActiveForCase =
      interaction.case &&
      interaction.solved_at === null &&
      firstActiveInteractionWithUniqueCase.has(interaction.case.id) &&
      !props.caseInteractions
        .slice(0, index)
        .some((prev) => prev.case && prev.case.id === interaction.case.id && prev.solved_at === null);
    return isFirstActiveForCase;
  });
});

const isActive = computed(() => {
  return props.caseInteractions.map((interaction) => {
    return interaction.solved_at === null;
  });
});
</script>

<template>
  <div class="block h-fit min-w-full" :class="{ 'pb-20': reserveSpaceForButton }">
    <LoadingSpinnerFullScreen :show="isLoading" :blockClickIfShown="false" :solidBackground="true" />

    <CaseInteractionListEmptyState v-if="caseInteractions.length === 0" :show-active-cases-only="showActiveCasesOnly" />
    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-4">
      <transition-group name="pop-and-fade">
        <template v-for="(caseInteraction, index) in caseInteractions" :key="caseInteraction.id">
          <ActiveCaseCard v-if="showCaseInteraction[index]" :case-interaction="caseInteraction" />
        </template>
      </transition-group>
    </div>
  </div>
</template>

<style scoped>
.pop-and-fade-leave-active {
  animation: pop-out 500ms ease-in forwards;
  position: relative;
  will-change: opacity;
  background-color: rgb(243 244 246); /* gray-100 */
  transform-origin: center;
}

.pop-and-fade-enter-active,
.pop-and-fade-enter-from {
  animation: pop-in 500ms ease-in forwards;
  will-change: opacity;
  background-color: rgb(243 244 246); /* gray-100 */
  transform-origin: center;
}

@keyframes pop-out {
  0% {
    opacity: 1;
    background-color: rgb(243 244 246);
  }
  50% {
    opacity: 0.8;
    background-color: rgb(243 244 246);
  }
  62.5% {
    opacity: 0.5;
    background-color: rgb(243 244 246);
  }
  100% {
    opacity: 0;
    background-color: rgb(243 244 246);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0.1;
    background-color: rgb(243 244 246);
  }
  100% {
    opacity: 1;
    background-color: rgb(243 244 246);
  }
}
</style>
