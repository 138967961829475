import { VocabItem } from '@/apiclient';

export const displayGrammaticalCategory = (category: string) => {
  if (category === 'NOUN') {
    return 'Substantiv';
  }
  if (category === 'VERB') {
    return 'Verb';
  }
  if (category === 'NOUN_VERB_COMPOUND') {
    return 'Substantiv-Verb-Verbindung';
  }
  if (category === 'ADVERB_VERB_COMPOUND') {
    return 'Adverb-Verb-Verbindung';
  }
  if (category === 'ADJECTIVE') {
    return 'Adjektiv';
  }
  if (category === 'ADVERB') {
    return 'Adverb';
  }
  if (category === 'PRONOUN') {
    return 'Pronomen';
  }
  if (category === 'PREPOSITION') {
    return 'Präposition';
  }
  if (category === 'CONJUNCTION') {
    return 'Konjunktion';
  }
  if (category === 'INTERJECTION') {
    return 'Interjektion';
  }
  if (category === 'UNKNOWN') {
    return 'Nicht klassifiziert';
  }
  return category;
};

export const isVerbType = (item: VocabItem) => {
  return (
    item.grammatical_category === 'VERB' ||
    item.grammatical_category === 'NOUN_VERB_COMPOUND' ||
    item.grammatical_category === 'ADVERB_VERB_COMPOUND'
  );
};
