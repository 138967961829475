<script setup lang="ts">
import { ref, computed, onMounted, PropType } from 'vue';
import draggable from 'vuedraggable';
import { ProgressButton } from '@/components';
import cloneDeep from 'lodash/cloneDeep';
import { Section, SectionContentItem_Output } from '@/apiclient';
import { truncate } from '@/helper';
import CourseService from '@/services/CourseService';

interface PageGroup {
  pageIndex: number;
  arrayIndex: number;
  items: SectionContentItem_Output[];
}

const props = defineProps({
  section: {
    type: Object as PropType<Section>,
    required: true,
  },
});

const emit = defineEmits<{
  (event: 'updated-items', items: SectionContentItem_Output[]): void;
}>();

const pageItems = ref<SectionContentItem_Output[][]>([]);
const originalOrder = ref<SectionContentItem_Output[]>([]);

// Initialize pages on mount
onMounted(() => {
  initializePages();
});

function initializePages() {
  const items = props.section?.section_content_items || [];

  const pageGroups = CourseService.sectionContentItemsByPage(items);
  pageItems.value = pageGroups.map((group) => group.items);

  // Make sure we have items before setting original order
  if (items.length > 0) {
    const initialItems = getAllItems();
    originalOrder.value = cloneDeep(initialItems);
  }
}

function getAllItems(): SectionContentItem_Output[] {
  return pageItems.value.flatMap((items, pageIndex) =>
    items.map((item, index) => ({
      ...item,
      page_index: pageIndex,
      index: index,
    })),
  );
}

const getChangedItems = () => {
  const currentItems = getAllItems();

  // Return empty array if no original order is set yet
  if (!originalOrder.value) return [];

  try {
    const originalItems = originalOrder.value;

    return currentItems.filter((currentItem) => {
      const originalItem = originalItems.find((item: SectionContentItem_Output) => item.id === currentItem.id);
      return (
        originalItem && (originalItem.page_index !== currentItem.page_index || originalItem.index !== currentItem.index)
      );
    });
  } catch (e) {
    console.error('Error parsing original order:', e);
    return [];
  }
};

const hasChanges = computed(() => {
  const changedItems = getChangedItems();
  return changedItems.length > 0;
});

function handlePageDragChange() {
  // Update all indices after page reorder
  pageItems.value.forEach((items, pageIndex) => {
    items.forEach((item, index) => {
      item.page_index = pageIndex;
      item.index = index;
    });
  });
}

function handleItemDragChange() {
  // Update indices after item drag
  pageItems.value.forEach((items, pageIndex) => {
    items.forEach((item, index) => {
      item.page_index = pageIndex;
      item.index = index;
    });
  });
}

function applyNewOrder() {
  const changedItems = getChangedItems();
  emit('updated-items', changedItems);
  originalOrder.value = getAllItems();
}

const getResourceTitle = (item: SectionContentItem_Output) => {
  const resource = item.resource_with_questions;
  if (resource?.title) return resource.title;
  if (resource?.resource_text) return truncate(resource.resource_text);
  return 'Audio-Dialog';
};

const getFirstQuestion = (item: SectionContentItem_Output) => {
  const resource = item.resource_with_questions;
  const firstQuestion = resource?.mc_questions?.[0]?.question_text || resource?.open_questions?.[0]?.question_text;
  return firstQuestion ? truncate(firstQuestion) : null;
};

const displayCotentItemThumbnail = (item: SectionContentItem_Output) => {
  if (item.content_type.toUpperCase() === 'TEXT') {
    return truncate(item.text_item?.content, 50, true) || 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'VOCAB_LIST') {
    return item.vocab_list?.name || 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'SET_PHRASE_LIST') {
    return item.set_phrase_list?.title || 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'CLOZE') {
    return item.cloze?.title || truncate(item.cloze?.html_content, 50, true) || 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'CATEGORIZATION_EXERCISE') {
    const exercise = item.categorization_exercise;
    if (exercise?.task_instructions) {
      return truncate(exercise.task_instructions, 50, true);
    }

    const categories = exercise?.categorization_exercise_categories || [];
    if (categories.length > 0) {
      const categoryNames = categories
        .slice(0, 2)
        .map((cat) => cat.name)
        .filter((name) => name);

      if (categoryNames.length > 0) {
        return `Kategorien: ${categoryNames.join(', ')}${categories.length > 2 ? ', ...' : ''}`;
      }
    }

    return 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'FORM_CASE') {
    return truncate(item.form_case?.task_instructions, 50, true) || 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'FORM_STRUCTURED_EXERCISE') {
    return truncate(item.form_structured_exercise?.task_instructions, 50, true) || 'Keine weiteren Angaben';
  }

  if (item.content_type.toUpperCase() === 'PAIR_OF_TERMS_GAME') {
    return truncate(item.pair_of_terms_game?.task_instructions, 50, true) || 'Keine weiteren Angaben';
  }

  return truncate(item.task_instructions, 50, true) || 'Keine weiteren Angaben';
};
</script>

<template>
  <div class="p-4">
    <draggable
      v-model="pageItems"
      class="space-y-4 pb-4"
      group="pages"
      item-key="pageIndex"
      @change="handlePageDragChange"
    >
      <template #item="{ element: page, index: pageIndex }">
        <div>
          <!-- Root element wrapper -->
          <div
            class="border border-dashed border-gray-300 rounded-lg p-4 bg-white cursor-move hover:bg-gray-50/50 transition-colors duration-200"
          >
            <div class="font-semibold text-gray-800 mb-3 flex items-center gap-2">
              <span>Lernbaustein {{ pageIndex + 1 }}</span>
              <span class="text-xs text-gray-500">(Drag to reorder page)</span>
            </div>
            <draggable
              v-model="pageItems[pageIndex]"
              class="min-h-[50px] bg-gray-50 rounded-md p-2"
              group="items"
              @change="handleItemDragChange"
              item-key="id"
            >
              <template #item="{ element }">
                <div
                  class="bg-white border border-gray-200 rounded-md p-3 mb-2 cursor-move hover:bg-gray-50 transition-colors duration-200"
                >
                  <div class="flex-col flex items-start">
                    <div class="text-sm font-medium text-gray-700">
                      {{ CourseService.displayCotentItemType(element) }}
                    </div>
                    <div v-if="element.content_type.toUpperCase() === 'RESOURCE_WITH_QUESTIONS'">
                      <div class="text-sm text-gray-500">
                        {{ getResourceTitle(element) }}
                      </div>
                      <div v-if="getFirstQuestion(element)" class="text-sm text-gray-500 italic">
                        Frage 1: {{ getFirstQuestion(element) }}
                      </div>
                    </div>
                    <div v-else class="text-sm text-gray-500">
                      {{ displayCotentItemThumbnail(element) }}
                    </div>
                    <div class="text-sm text-gray-500">
                      Seite: {{ element.page_index + 1 }}, Position: {{ element.index + 1 }}
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </template>
    </draggable>

    <ProgressButton
      :disabled="!hasChanges"
      @click="applyNewOrder"
      :text="hasChanges ? 'Apply changes' : 'No changes to apply'"
    />
  </div>
</template>

<style scoped></style>
