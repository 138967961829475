<script setup lang="ts">
import { onMounted, PropType, Ref, ref, watch } from 'vue';
import CategoryZone from '@/components/didactics/categorization/CategoryZone.vue';
import DraggableItem from '@/components/didactics/categorization/DraggableItem.vue';
import { useAlertStore, useCourseStore } from '@/stores';
import { CategorizationCategoryType, CategorizationItemType } from '@/helper/typing';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { useExerciseLifecycle } from '@/composables/useExerciseLifecycle';
import { useAutosave } from '@/composables/useAutosave';
import { SectionContentItemOverview } from '@/apiclient';
import { Tags } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import Title from '@/components/didactics/title/Title.vue';

const { t } = useI18n();

// stores
const courseStore = useCourseStore();
const alertStore = useAlertStore();

// props
const props = defineProps({
  allowEdit: {
    type: Boolean,
    default: false,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

// emits
const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'scrollBy', 'addNewVocab']);

// composables
const { handleExerciseFinished, fetchInteractionState, reopenExercise, itemInteractionState, fetchCompleted } =
  useExerciseLifecycle({
    contentItem: props.contentItem,
    sectionIndex: props.sectionIndex,
    pageIndex: props.pageIndex,
    isEditing: props.allowEdit,
    emit,
    onComplete: (response) => {
      itemInteractionState.value = response.section_content_item_interaction;
    },
  });
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.contentItem.categorization_exercise) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Kategorisierungsübung - keine Übung', 'Error');
      return;
    }
    if (!props.allowEdit) return;
    const categorizationUpdate = {
      id: props.contentItem.categorization_exercise.id,
      task_instructions: taskInstructions.value,
      title: title.value,
    };

    await courseStore.updateCategorizationExercise(
      props.contentItem.section_id,
      props.contentItem.id,
      categorizationUpdate,
    );
    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

// refs
const container = ref(null);
const categoryZoneRefs = ref([] as Ref<HTMLElement | null>[]);
const currentlyDraggingOver = ref([] as boolean[]);
const currentlyDraggingItemId = ref('');
const categories = ref<Array<CategorizationCategoryType>>([]);
const unplacedItems = ref<Array<CategorizationItemType>>([]);

// state
const taskInstructions = ref(props.contentItem.task_instructions);
const title = ref(props.contentItem.title);

onMounted(() => {
  if (!props.fixedMaximization) {
    document.addEventListener('click', handleClickOutside);
  }

  initCategoriesAndItems().then(async () => {
    if (props.allowEdit) {
      fetchCompleted.value = true;
      return;
    }

    await fetchInteractionState();
  });
});

const initCategoriesAndItems = async () => {
  // reset categories
  categories.value.length = 0;

  // reset unplacedItems and categorized items
  unplacedItems.value.length = 0;
  categories.value.forEach((category) => {
    category.items.length = 0;
  });

  // fetch categories
  let allItems: CategorizationItemType[] = [];

  props.contentItem?.categorization_exercise?.categorization_exercise_categories?.forEach((category: any) => {
    // if we are editing, assign all items their parent category
    categories.value.push({
      id: category.id,
      name: category.name,
      items: props.allowEdit ? category.categorization_items || [] : [],
    });

    // if we are not editing, assign all items to unplaced items
    if (!props.allowEdit) {
      category.categorization_items.forEach((item: any) => {
        allItems.push({
          id: item.id,
          content: item.content,
          categoryId: category.id,
          isIncorrect: false,
        });
      });
    }
  });

  if (!props.allowEdit) {
    unplacedItems.value = allItems.sort(() => Math.random() - 0.5);
  }

  currentlyDraggingOver.value = Array(categories.value.length).fill(false);
  categories.value.forEach((pair: any) => {
    categoryZoneRefs.value.push(ref<HTMLElement | null>(null));
  });
};

const handleDrop = async (categoryId: string) => {
  let itemId = currentlyDraggingItemId.value;
  const itemIndex = unplacedItems.value.findIndex((item) => item.id === itemId);
  const item = unplacedItems.value[itemIndex];

  if (item) {
    const isCorrect = item.categoryId === categoryId;
    if (isCorrect) {
      // Place item in the correct category and remove from the waiting zone
      const category = categories.value.find((cat) => cat.id === categoryId);
      category.items.push(item);
      unplacedItems.value.splice(itemIndex, 1);
    } else {
      // Trigger bounce-back effect and red background for incorrect items
      item.isIncorrect = true;
      // wait 500 ms
      await new Promise((resolve) => setTimeout(resolve, 500)); // Match the bounce-back animation duration
      item.isIncorrect = false; // Reset after animation
    }
  }
  currentlyDraggingItemId.value = '';
};

const handleClickOutside = (event: Event) => {
  if (props.fixedMaximization) return;
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (props.fixedMaximization) return;
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

const handleDraggingAtPosition = (itemId, position: { x: number; y: number }) => {
  currentlyDraggingItemId.value = itemId;
  for (let i = 0; i < categoryZoneRefs.value.length; i++) {
    const categoryZoneContainer = categoryZoneRefs.value[i]?.value?.[0];

    if (!categoryZoneContainer) {
      currentlyDraggingOver.value[i] = false;
      continue;
    }

    const rect = categoryZoneContainer.getBoundingClientRect();

    currentlyDraggingOver.value[i] =
      position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom;
  }
};

const handleDroppedItemAtPosition = (itemId: string, position: { x: number; y: number }) => {
  currentlyDraggingItemId.value = itemId;
  for (let i = 0; i < categoryZoneRefs.value.length; i++) {
    const categoryZoneContainer = categoryZoneRefs.value[i]?.value?.[0];
    if (!categoryZoneContainer) {
      continue;
    }

    const rect = categoryZoneContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(categories.value[i].id);
    }
  }
  currentlyDraggingOver.value = Array(categories.value.length).fill(false);
};

const resetCategorizationItems = () => {
  // reset all items to unplaced items
  categories.value.forEach((category) => {
    category.items.forEach((item) => {
      unplacedItems.value.push({
        id: item.id,
        content: item.content,
        categoryId: category.id,
        isIncorrect: false,
      });
    });
    category.items.length = 0;
  });
};

const handleReopenExercise = async () => {
  await reopenExercise(props.contentItem.id, resetCategorizationItems);
};

watch(
  () => unplacedItems.value.length,
  async (newLength) => {
    if (newLength > 0) return;
    await handleExerciseFinished();
  },
);
</script>

<template>
  <div
    ref="container"
    class="relative w-full p-0.5 overflow-hidden flex-col flex"
    :class="{
      'h-fit': isMaximized,
      'h-[295px]': !isMaximized,
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
    :key="categories.length"
  >
    <div
      class="w-full flex-col flex"
      :class="{
        'opacity-25':
          !props.allowEdit &&
          (!fetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <Title
        :iconComponent="Tags"
        headline="Kategorisieren"
        :isEditing="props.allowEdit"
        v-model:title="title"
        @change="unsavedChanges = true"
      />

      <h2 class="pt-2 pb-1 px-4 w-full rounded-lg text-start" v-if="props.allowEdit || !!taskInstructions">
        <span v-if="!props.allowEdit" class="text-base">{{ taskInstructions }}</span>
        <textarea
          v-if="props.allowEdit"
          v-model="taskInstructions"
          @input="unsavedChanges = true"
          placeholder="Erläuterungen zur Aufgabenstellung (optional)"
          class="w-full font-normal text-xs md:text-sm text-center border-gray-200 rounded-lg resize-none min-w-2"
        />
      </h2>

      <!-- Display categories as drop zones -->
      <span class="pt-2 pb-1 text-center text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400">
        Kategorien
      </span>
      <div class="flex flex-wrap justify-center gap-1">
        <div
          v-for="(category, index) in categories"
          :ref="categoryZoneRefs[index]"
          class="w-[calc(50%-4px)] md:w-52 h-fit"
        >
          <CategoryZone
            :key="category.id"
            :category="category"
            @dropItem="handleDrop"
            :isEditing="props.allowEdit"
            :isDraggingOver="currentlyDraggingOver[index]"
            @deleteCategory="
              async (categoryId) => {
                courseStore
                  .removeCategorizationExerciseCategory(props.contentItem.section_id, props.contentItem.id, categoryId)
                  .then(async () => {
                    alertStore.success('Kategorie entfernt');
                  })
                  .catch((error) => {
                    alertStore.error('Fehler beim Entfernen der Kategorie: ' + error);
                  });
              }
            "
            @changeCategory="
              async (categoryId, newContent) => {
                courseStore
                  .updateCategorizationExerciseCategory(
                    props.contentItem.section_id,
                    props.contentItem.id,
                    categoryId,
                    newContent,
                  )
                  .then(async () => {})
                  .catch((error) => {
                    alertStore.error('Fehler beim Aktualisieren der Kategorie: ' + error);
                  });
              }
            "
          >
            <!-- Display items already placed in this category -->
            <div v-for="item in category.items" class="w-full flex-col flex h-fit gap-y-1">
              <DraggableItem
                :key="item.id"
                :item="item"
                :isInCategory="true"
                :isEditing="props.allowEdit"
                :icon="props.allowEdit ? 'delete' : null"
                @deleteItem="
                  async (itemId) => {
                    courseStore
                      .removeCategorizationExerciseItem(
                        props.contentItem.section_id,
                        props.contentItem.id,
                        category.id,
                        itemId,
                      )
                      .then(async () => {
                        alertStore.success('Item entfernt');
                      })
                      .catch((error) => {
                        alertStore.error('Fehler beim Entfernen des Items: ' + error);
                      });
                  }
                "
                @changeItem="
                  async (itemId, newContent) => {
                    courseStore
                      .updateCategorizationExerciseItem(
                        props.contentItem.section_id,
                        props.contentItem.id,
                        category.id,
                        itemId,
                        newContent,
                      )
                      .then(async () => {})
                      .catch((error) => {
                        alertStore.error('Fehler beim Aktualisieren des Items: ' + error);
                      });
                  }
                "
                @currentDraggingPosition="handleDraggingAtPosition"
                @droppedItemAtPosition="handleDroppedItemAtPosition"
                @scrollBy="(distance) => emit('scrollBy', distance)"
              />
            </div>
            <DraggableItem
              v-if="props.allowEdit"
              :item="{
                id: 'add-item',
                content: 'Item hinzufügen',
                categoryId: '',
                isIncorrect: false,
              }"
              color="blue"
              icon="add"
              :onClick="
                async () => {
                  courseStore
                    .addItemToCategorizationExerciseCategory(
                      props.contentItem.section_id,
                      props.contentItem.id,
                      category.id,
                    )
                    .then(async () => {
                      alertStore.success('Item hinzugefügt');
                    })
                    .catch((error) => {
                      alertStore.error('Fehler beim Hinzufügen des Items: ' + error);
                      throw Error('Error adding categorization exercise item');
                    });
                }
              "
            />
          </CategoryZone>
        </div>
        <div class="w-[calc(50%-4px)] md:w-52 h-fit">
          <CategoryZone
            v-if="props.allowEdit"
            :category="{
              name: 'Kategorie hinzufügen',
            }"
            color="blue"
            icon="add"
            :onClick="
              () => {
                courseStore
                  .addCategorizationExerciseCategory(props.contentItem.section_id, props.contentItem.id)
                  .then(() => {
                    alertStore.success('Kategorie hinzugefügt');
                  })
                  .catch((error) => {
                    alertStore.error('Fehler beim Hinzufügen der Kategorie: ' + error);
                    throw Error('Error adding category');
                  });
              }
            "
          />
        </div>
      </div>

      <!-- Waiting Zone for unplaced items -->
      <span
        v-show="unplacedItems.length > 0"
        class="pt-6 pb-1 text-center text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400"
      >
        Items
      </span>
      <div class="flex flex-wrap gap-1 justify-center">
        <DraggableItem
          v-for="item in unplacedItems"
          :key="item.id"
          :item="item"
          :isInCategory="false"
          :isIncorrect="item.isIncorrect"
          @currentDraggingPosition="handleDraggingAtPosition"
          @droppedItemAtPosition="handleDroppedItemAtPosition"
          @scrollBy="(distance) => emit('scrollBy', distance)"
          @startDraggingItem="currentlyDraggingItemId = $event"
        />
      </div>
    </div>
    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!fetchCompleted"
    >
      <LoadingSpinnerLarge />
    </div>
    <div
      v-if="
        fetchCompleted &&
        !props.allowEdit &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="handleReopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped></style>
