import { defineStore } from 'pinia';

import { router } from '@/router';
import { useAlertStore } from '@/stores';

import { UserDetailsUpdate, VocabItemUpdate } from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import jwt_decode from 'jwt-decode';
import { getLevelFromXp, getThresholdForCurrentLevel, VocabItemDefault } from '@/helper';
import * as Sentry from '@sentry/vue';

interface vocabItemCreate {
  term: string;
  explanation?: string | null;
  remarks?: string | null;
  translations?: Object;
  audio_url?: string | null;
  image_url?: string | null;
}

const defaultVocabItem: vocabItemCreate = {
  term: '',
  explanation: null,
  remarks: null,
  translations: {},
  audio_url: null,
  image_url: null,
};

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => {
    // Define default state
    const defaultState = {
      user: null,
      token: null,
      returnUrl: null,
      currentChatEnabled: false,
      currentSubtitlesEnabled: false,
      currentLanguageLevel: null as string | null,
    };

    try {
      // Load all items at once
      const items = {
        user: localStorage.getItem('user'),
        token: localStorage.getItem('token'),
        currentChatEnabled: localStorage.getItem('currentChatEnabled'),
        currentSubtitlesEnabled: localStorage.getItem('currentSubtitlesEnabled'),
        currentLanguageLevel: localStorage.getItem('currentLanguageLevel'),
      };

      // Parse all items that need parsing
      const parsed = {
        user: items.user ? JSON.parse(items.user) : null,
        token: items.token ? JSON.parse(items.token) : null,
        currentChatEnabled: items.currentChatEnabled ? JSON.parse(items.currentChatEnabled) : false,
        currentSubtitlesEnabled: items.currentSubtitlesEnabled ? JSON.parse(items.currentSubtitlesEnabled) : false,
        currentLanguageLevel: items.currentLanguageLevel || null,
        returnUrl: null,
      };

      // If we get here, all data is valid
      return {
        ...defaultState,
        ...parsed,
      };
    } catch (e) {
      // On any error, clear everything
      console.error('Error loading auth store from localStorage:', e);
      const keysToRemove = ['user', 'token', 'currentChatEnabled', 'currentSubtitlesEnabled', 'currentLanguageLevel'];

      keysToRemove.forEach((key) => localStorage.removeItem(key));

      // Return clean default state
      return defaultState;
    }
  },
  getters: {
    isSignedIn(state) {
      return state.token !== null;
    },
    isAdmin(state) {
      return state.user ? !!state.user.is_admin : false;
    },
    nonValidatedToken(state) {
      return state.token;
    },
    requiresOnboarding(state) {
      // check if one of the following fields is not set
      if (!state.user) {
        return false;
      }
      return (
        !state.user.first_name ||
        !state.user.last_name ||
        !state.user.ingame_language ||
        !state.user.native_language ||
        !state.user.country_of_origin
      );
    },
    userId(state) {
      return state.user ? state.user.id : '';
    },
    userFirstName(state) {
      return state.user ? state.user.first_name : '';
    },
    userLastName(state) {
      return state.user ? state.user.last_name : '';
    },
    userFullName(state) {
      return state.user ? this.userFirstName + ' ' + this.userLastName : '';
    },
    userUsername(state) {
      return state.user ? state.user.username : '';
    },
    userEmail(state) {
      return state.user ? state.user.auth_user.email : '';
    },
    userGender(state) {
      return state.user ? state.user.gender : null;
    },
    userAcademicTitle(state) {
      return state.user ? state.user.academic_title : null;
    },
    userJobStatus(state) {
      return state.user ? state.user.job_status : null;
    },
    userBiography(state) {
      return state.user ? state.user.biography : null;
    },
    userInitials(state) {
      // TS problem: https://github.com/vuejs/pinia/discussions/1076
      // return initials based on userFirstName and userLastName
      // check if length of userFirstName and userLastName is > 0
      var firstInitial = '';
      var lastInitial = '';

      if (state.user && state.user.first_name && state.user.first_name.length > 0) {
        firstInitial = state.user.first_name[0].toUpperCase();
      }
      if (state.user && state.user.last_name && state.user.last_name.length > 0) {
        lastInitial = state.user.last_name[0].toUpperCase();
      }

      return firstInitial + lastInitial;
    },
    userProfileImageSmall(state) {
      return state.user?.profile_image?.image_urls?.small || null;
    },
    userProfileImageMedium(state) {
      return state.user?.profile_image?.image_urls?.medium || null;
    },
    userProfileImageLarge(state) {
      return state.user?.profile_image?.image_urls?.large || null;
    },
    userNativeLanguage(state) {
      return state.user ? state.user.native_language : null;
    },
    userIngameLanguage(state) {
      return state.user ? state.user.ingame_language : null;
    },
    userInterfaceLanguage(state) {
      return state.user ? state.user.interface_language : null;
    },
    userCountryOfOrigin(state) {
      return state.user ? state.user.country_of_origin : null;
    },
    expertMode(state) {
      return state.user ? state.user.expert_mode : false;
    },
    notificationSounds(state) {
      return state.user ? state.user.notification_sounds : true;
    },
    textToSpeechEnabled(state) {
      return state.user ? state.user.text_to_speech_enabled : false;
    },
    // initiallyObfuscateText(state) {
    //   return state.user ? state.user.initially_obfuscate_text : false;
    // },
    // hideChatHistory(state) {
    //   return state.user ? state.user.hide_chat_history : false;
    // },
    chatEnabledUserDefault(state) {
      return state.user ? state.user.chat_enabled : false;
    },
    subtitlesEnabledUserDefault(state) {
      return state.user ? state.user.subtitles_enabled : false;
    },
    chatEnabled(state) {
      return state.currentChatEnabled;
    },
    subtitlesEnabled(state) {
      return state.currentSubtitlesEnabled;
    },
    userXp(state) {
      return state.user ? state.user.experience_points : null;
    },
    userLevel(state) {
      return state.user ? getLevelFromXp(state.user.experience_points) : null;
    },
    dailyCasePlayed(state) {
      if (!state.user || !state.user.user_stats || !state.user.user_stats.cases_played_at) {
        return false;
      }

      const casesPlayedAt = state.user.user_stats.cases_played_at;
      if (casesPlayedAt.length === 0) {
        return false;
      }

      // Get the last played date
      const lastPlayedDate = new Date(casesPlayedAt[casesPlayedAt.length - 1]);

      // Get today's date
      const today = new Date();

      // Check if last played date is today (compare year, month, day)
      return (
        lastPlayedDate.getDate() === today.getDate() &&
        lastPlayedDate.getMonth() === today.getMonth() &&
        lastPlayedDate.getFullYear() === today.getFullYear()
      );
    },
    dailyVocabTestPlayed(state) {
      if (!state.user || !state.user.user_stats || !state.user.user_stats.cases_played_at) {
        return false;
      }

      const vocabTestPlayedAt = state.user.user_stats.vocab_tests_taken_at;
      if (vocabTestPlayedAt.length === 0) {
        return false;
      }

      // Get the last played date
      const lastPlayedDate = new Date(vocabTestPlayedAt[vocabTestPlayedAt.length - 1]);

      // Get today's date
      const today = new Date();

      // Check if last played date is today (compare year, month, day)
      return (
        lastPlayedDate.getDate() === today.getDate() &&
        lastPlayedDate.getMonth() === today.getMonth() &&
        lastPlayedDate.getFullYear() === today.getFullYear()
      );
    },
    vocabLists(state) {
      return state.user?.vocab_lists || [];
    },
    favoriteVocabList(state) {
      return state.user?.vocab_lists[1] || [];
    },
  },
  actions: {
    async setToken(token) {
      // this function should only be called by login function
      console.debug('Setting token ' + JSON.stringify(token));
      const expiresAt = this.getExpirationDateOfToken(token);
      console.debug('Token expires at ' + expiresAt);
      this.token = token;
      localStorage.setItem('token', JSON.stringify(token));
      await this.fetchUserDetails();
    },
    async getValidatedToken() {
      // gets token from state and will refresh token if it is about to expire
      if (!this.token) {
        return null;
      }

      // get expiration date of token
      // refresh token if it is about to expire (<= 15 minutes)
      if (this.tokenHasExpired(this.token, 15)) {
        // token has expired

        // first try to refresh token
        const refreshedToken = await this.getRefreshedToken();
        if (refreshedToken) {
          // token was refreshed, update state and return token
          await this.setToken(refreshedToken);
          console.debug('Token refreshed');
        } else {
          // cannot refresh token, redirect to login page
          this.logout('/account/sign-in');
          return null;
        }
      }
      return this.token;
    },
    tokenHasExpired(token, minMinutesToExpiration: number = 15) {
      if (!token) {
        return true;
      }

      const tokenExpiresAtDate = this.getExpirationDateOfToken(token);
      const now = new Date();
      const expired = tokenExpiresAtDate.getTime() < now.getTime() + minMinutesToExpiration * 60 * 1000;
      if (expired) {
        console.debug('Token expired/is about to expire on ' + tokenExpiresAtDate);
      }
      return expired;
    },
    getExpirationDateOfToken(token) {
      const { exp } = jwt_decode(token.access_token);
      return new Date(exp * 1000);
    },
    async login(username: string, password: string, showError: boolean = false, t: any = null) {
      try {
        console.debug('Trying to log in with ' + username + ' ***');
        const loginResponse = await (
          await getApiClient(false)
        ).authentication.generateOrRefreshAccessToken({
          grant_type: 'password',
          username: username,
          password: password,
        });

        // set token in local storage and fetch user details
        await this.setToken(loginResponse['token']);

        // set Sentry user context
        Sentry.setUser({
          id: this.userId,
          fullName: this.userFullName,
          email: this.userEmail,
        });

        // redirect to previous url or default to home page
        // check if users requires onboarding
        if (this.requiresOnboarding) {
          await router.push('/onboarding');
        } else {
          await router.push(this.returnUrl || '/home');
        }
        if (!!loginResponse['notifications'] && !!t) {
          const alertStore = useAlertStore();
          for (const notification of loginResponse['notifications']) {
            if (notification.type === 'XP') {
              alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
            }
            // check if login has leveled up the user. Note: this has to be handled here as changes occur before
            // user properties are set, so cannot recognize it in the components (e.g., App.vue)
            let level = getLevelFromXp(this.user.experience_points);
            if (this.user.experience_points - notification.xp - getThresholdForCurrentLevel(level) < 0) {
              alertStore.xp(t('message.levelUp', level), t('message.levelUpTitle'));
            }
          }
        }
        await Promise.all([this.fetchUserDetails(), this.fetchUserVocabLists()]);
      } catch (error) {
        console.error('Login failed');
        console.error(JSON.stringify(error));
        if (showError) {
          const alertStore = useAlertStore();
          alertStore.error(error.body?.message || 'An error occurred while logging in.');
        }

        throw error;
      }
    },
    async getRefreshedToken() {
      if (!this.token) {
        return null;
      }

      try {
        console.debug('Trying to refresh token');
        // we do not validate the token here when getting the API client
        const loginResponse = await (
          await getApiClient(false)
        ).authentication.generateOrRefreshAccessToken({
          grant_type: 'refresh_token',
          refresh_token: this.token.refresh_token,
        });

        return loginResponse['token'];
      } catch (error) {
        console.error('Refresh token failed');
        console.error(JSON.stringify(error));
        // cannot refresh token, redirect to login page
        return null;
      }
    },
    _setCurrentSettingsToUserDefault() {
      let store = useAuthStore();
      console.log('setting currentChatEnabled to ' + store.user.chat_enabled);
      this.currentChatEnabled = store.user ? store.user.chat_enabled : false;
      localStorage.setItem('currentChatEnabled', JSON.stringify(this.currentChatEnabled));
      this.currentSubtitlesEnabled = store.user ? store.user.subtitles_enabled : false;
      localStorage.setItem('currentSubtitlesEnabled', JSON.stringify(this.currentSubtitlesEnabled));
      this.currentLanguageLevel = store.user ? store.user.language_level : null;
      localStorage.setItem('currentLanguageLevel', this.currentLanguageLevel);
    },
    async fetchUserDetails() {
      try {
        console.debug('trying to load user details');
        let vocabLists = this.user?.vocab_lists || [];
        const user = await (await getApiClient()).users.getCurrentUser();
        // update pinia state
        this.user = user;
        this.user.vocab_lists = vocabLists;
        this._setCurrentSettingsToUserDefault();
        console.debug('Fetched: ' + JSON.stringify(user));

        // store user details in local storage
        localStorage.setItem('user', JSON.stringify(user));
      } catch (error) {
        console.debug('Cannot fetch user details');
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
        throw error;
      }
    },
    async fetchUserXp() {
      try {
        if (!this.user) {
          await this.fetchUserDetails();
        }
        console.debug('trying to refetch user xp');
        const xp = await (await getApiClient()).users.getCurrentUserXp();
        // update pinia state
        this.user.xp = xp;
        console.debug('Refetched: ' + JSON.stringify(xp));

        // store user details in local storage
        let store = useAuthStore();
        localStorage.setItem('user', JSON.stringify(store.user));
      } catch (error) {
        console.debug('Cannot refetch user details');
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
        throw error;
      }
    },
    async fetchUserVocabLists() {
      if (!this.user) {
        await this.fetchUserDetails();
      }
      try {
        const vocabLists = await (await getApiClient()).vocabLists.getMyVocabLists();
        // update pinia state
        // console.log('Fetched user vocab lists: ' + JSON.stringify(vocabLists));
        this.user.vocab_lists = vocabLists;
      } catch (error) {
        console.debug('Cannot fetch user vocab lists');
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
        throw error;
      }
    },
    isFavorite(vocabItem: any) {
      const store = useAuthStore();
      if (!store.favoriteVocabList?.vocab_items) {
        return false;
      }
      return store.favoriteVocabList.vocab_items.some((item: any) => item.term === vocabItem.term);
    },
    async createNewUserVocabList(name: string) {
      try {
        const vocabList = await (
          await getApiClient()
        ).vocabLists.createPersonalVocabList({
          name: name,
        });
        this.user.vocab_lists.push(vocabList);
      } catch (error) {
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
        throw error;
      }
    },
    async updateUserVocabList(vocabListId: string, name: string) {
      try {
        const vocabList = await (
          await getApiClient()
        ).vocabLists.updatePersonalVocabList(vocabListId, {
          name: name,
          description: null,
        });
        const index = this.user.vocab_lists.findIndex((list) => list.id === vocabListId);
        this.user.vocab_lists[index] = vocabList;
      } catch (error) {
        console.debug(error);
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async updateUserVocabItem(vocabItemId: string, vocabItem: VocabItemUpdate = VocabItemDefault) {
      const alertStore = useAlertStore();
      (await getApiClient()).vocabs
        .updatePersonalVocabItem(vocabItemId, vocabItem)
        .then((updatedItem) => {
          // find vocabItemId in all user vocab lists adn update
          this.user.vocab_lists.forEach((list: any) => {
            const vocabItemIndex = list.vocab_items.findIndex((item: any) => item.id === vocabItemId);
            if (vocabItemIndex !== -1) {
              list.vocab_items[vocabItemIndex] = updatedItem;
            }
          });
        })
        .catch((error) => {
          alertStore.error('Fehler beim Bearbeiten des Eintrags', 'Fehler', error);
          throw error;
        });
    },
    async updateUserDetails(changes: UserDetailsUpdate) {
      const alertStore = useAlertStore();
      try {
        console.debug('trying to update user details');
        console.debug(changes);
        const user = await (await getApiClient()).users.updateUser(this.user.id, changes);
        // update the users i18n language
        //this.i18n.locale.value = user.native_language;

        // update pinia state
        this.user = user;
        this._setCurrentSettingsToUserDefault();
        console.debug('Updated user details: ' + JSON.stringify(user));

        // store user details in local storage
        localStorage.setItem('user', JSON.stringify(user));
        alertStore.success('Änderungen gespeichert');
      } catch (error) {
        console.debug('Cannot update user details');
        console.debug(error);
        alertStore.error(error);
        throw error;
      }
    },
    async copyVocabItemToPersonalVocabList(
      vocabListId: string,
      vocabItemId: string,
      makeCopy: boolean = true,
      avoidToCopyMultipleTimes: boolean = true,
    ) {
      const alertStore = useAlertStore();
      await (
        await getApiClient()
      ).vocabLists
        .copyVocabToPersonalVocabList(vocabListId, vocabItemId, makeCopy, avoidToCopyMultipleTimes)
        .then((updatedVocabList) => {
          // update the vocab list in the user object
          let vocabLists = this.user.vocab_lists;
          let vocabListIndex = vocabLists.findIndex((list) => list.id === vocabListId);
          this.user.vocab_lists[vocabListIndex] = updatedVocabList;
          alertStore.success('Vokabel erfolgreich hinzugefügt');
        })
        .catch((error) => {
          console.error(error);
          alertStore.error('Vokabel konnte nicht hinzugefügt werden', 'Error', error);
          throw error;
        });
    },
    temporarilySetSubtitlesEnabled(enabled: boolean) {
      this.currentSubtitlesEnabled = enabled;
      localStorage.setItem('currentSubtitlesEnabled', JSON.stringify(enabled));
    },
    temporarilySetChatEnabled(enabled: boolean) {
      this.currentChatEnabled = enabled;
      localStorage.setItem('currentChatEnabled', JSON.stringify(enabled));
    },
    temporarilySetLanguageLevel(level: string) {
      this.currentLanguageLevel = level;
      localStorage.setItem('currentLanguageLevel', level);
    },
    async uploadProfileImage(image: File) {
      let store = useAuthStore();
      const alertStore = useAlertStore();
      await (
        await getApiClient()
      ).users
        .uploadProfileImage(store.user.id, { image: image })
        .then((response) => {
          alertStore.success('Upload successful');
          store.user.profile_image = response;
        })
        .catch((error) => {
          alertStore.error('Upload failed', 'Error', error);
          throw Error('Upload failed: ', error);
        })
        .finally(() => {});
    },
    async logout(redirectToPage: string = '/') {
      console.debug('Sign out user');

      this.token = null;
      this.user = null;

      this.currentChatEnabled = null;
      this.currentSubtitlesEnabled = null;
      this.currentLanguageLevel = null;

      localStorage.removeItem('token');
      localStorage.removeItem('user');

      await router.push(redirectToPage);

      console.debug('Signed out');
    },
  },
});
