<script setup lang="ts">
import { InfoMessage } from '@/helper/typing';
import { computed, PropType } from 'vue';
import { CircleCheck, PartyPopper, ArrowRight } from 'lucide-vue-next';

const props = defineProps({
  message: {
    type: Object as PropType<InfoMessage>,
    required: true,
  },
});

const isSubtaskCompletion = computed(() => {
  return props.message.type === 'SUBTASK_COMPLETION';
});
</script>

<template>
  <div class="text-[#19CB06] mb-2 pt-2">
    <div
      class="inline-flex items-center justify-between gap-2 px-4 py-2 rounded-full bg-[#EFFDF1] border border-[#A8F5B3] text-sm font-semibold leading-normal tracking-[-0.00438rem] w-full"
    >
      <div class="flex items-center gap-2">
        <PartyPopper class="min-w-[20px] min-h-[20px]" :size="20" v-if="isSubtaskCompletion" />
        {{ message.content.info_message }}
      </div>
      <span class="text-sm" v-if="isSubtaskCompletion">+65XP</span>
    </div>
    <div class="flex items-start gap-2 pl-4 pr-16 mt-2 font-normal">
      <CircleCheck class="min-w-[16px] min-h-[16px] mt-0.5" :size="16" />
      <span class="text-sm">{{ message.content.this_target }}</span>
    </div>
    <div class="flex items-start gap-2 pl-4 pr-16 text-black mt-2 font-normal" v-if="message.content.next_target">
      <ArrowRight class="min-w-[16px] min-h-[16px] mt-0.5" :size="16" />
      <span class="text-sm">{{ message.content.next_target }}</span>
    </div>
    <div class="h-[30px]" v-if="!message.content.next_target"></div>
  </div>
</template>

<style scoped></style>
