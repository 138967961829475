<script setup lang="ts">
import { useAuthStore } from '@/stores';
import { computed, PropType } from 'vue';
import { InteractionMessage } from '@/helper';
import { unobfuscateUserName } from '@/helper';
import { storeToRefs } from 'pinia';
import { Sparkles } from 'lucide-vue-next';

// props
const props = defineProps({
  message: {
    type: Object as PropType<InteractionMessage>,
    required: true,
  },
});

// stores
const authStore = useAuthStore();
const { userFirstName, userLastName } = storeToRefs(authStore);

// computed
const isThinking = computed(() => {
  return !props.message?.content?.processed_model_output;
});

const timestamp = computed(() => {
  if (props.message.created_at === 'not_yet_in_database') return 'just now';
  if (props.message.created_at === 'incomplete') return 'just now';
  if (!props.message.created_at) return 'just now';
  try {
    const date = new Date(props.message.created_at);
    if (isNaN(date.getTime())) return props.message.created_at;
    return date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  } catch (e) {
    return props.message.created_at;
  }
});
</script>

<template>
  <div class="flex gap-x-2 sm:gap-x-4 mb-2">
    <div v-if="props.message?.content">
      <!-- Content -->
      <div
        class="bg-blue-50 border border-blue-200 inline-flex"
        :class="[
          isThinking ? 'rounded-full' : 'rounded-2xl pl-3 pr-4 py-3',
          'dark:bg-blue-900/20 dark:border-blue-800/30',
        ]"
      >
        <div class="flex items-start" :class="{ 'gap-x-3': !isThinking }">
          <!-- Combined sparkles icon/thinking placeholder -->
          <div
            class="flex-shrink-0 inline-flex items-center justify-center h-9 w-9 rounded-full"
            :class="{
              'border border-blue-200': !isThinking,
              'bg-blue-50': isThinking,
            }"
          >
            <Sparkles
              :class="{
                'inline animate-variable-spin w-5 h-5': isThinking,
                'text-[#124B87] w-5 h-5': !isThinking,
              }"
            />
          </div>

          <!-- Companion response -->
          <div :class="{ 'flex-grow': !isThinking, 'w-fit': isThinking }">
            <p v-if="isThinking" class="flex items-center justify-center h-7"></p>
            <p v-else class="text-sm text-[#124B87] dark:text-white">
              {{ unobfuscateUserName(props.message.content['processed_model_output'], userFirstName, userLastName) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes variable-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-variable-spin {
  animation: variable-spin 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

.bg-blue-50 {
  transition: border-radius 0.2s ease-in-out;
}
</style>
