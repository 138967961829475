<script setup lang="ts">
import CardPair from '@/components/didactics/pair_of_terms/CardPair.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { PairOfTermsItemPair, PairOfTermsItemType } from '@/helper/typing';
import { v4 as uuidv4 } from 'uuid';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { Ref } from 'vue';
import { storeToRefs } from 'pinia';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useExerciseLifecycle, useAutosave } from '@/composables';
import { ChevronsLeftRightEllipsis } from 'lucide-vue-next';
import Title from '@/components/didactics/title/Title.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// stores
const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const courseStore = useCourseStore();
const alertStore = useAlertStore();

// props
const props = defineProps({
  allowEdit: {
    type: Boolean,
    default: false,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

// emits
const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'scrollBy', 'addNewVocab']);

// composables
const { handleExerciseFinished, fetchInteractionState, reopenExercise, itemInteractionState, fetchCompleted } =
  useExerciseLifecycle({
    contentItem: props.contentItem,
    sectionIndex: props.sectionIndex,
    pageIndex: props.pageIndex,
    isEditing: props.allowEdit,
    emit,
    preCompleteDelay: 2000,
    preComplete: () => {},
    onComplete: (response) => {
      itemInteractionState.value = response.section_content_item_interaction;
    },
    onReopen: () => {
      solvedPairs.value.length = 0;
      showItem1ResultsAtIndex.value.length = 0;
      showItem2ResultsAtIndex.value.length = 0;
      item1CorrectAtIndex.value.length = 0;
      item2CorrectAtIndex.value.length = 0;
      item1WasSolvedBeforeAtIndex.value.length = 0;
      item2WasSolvedBeforeAtIndex.value.length = 0;
      shuffle();
    },
  });
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.contentItem.pair_of_terms_game) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Paare-Bilden-Übung - keine Übung', 'Error');
      return;
    }

    const pairOfTermsUpdate = {
      id: props.contentItem.pair_of_terms_game.id,
      task_instructions: prompt.value,
      pair_of_terms_item_tuples: currentData.value,
      title: localTitle.value,
    };

    await courseStore.updatePairOfTermsGame(props.contentItem.section_id, props.contentItem.id, pairOfTermsUpdate);

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Kategorisierungsübung', 'Error', error);
  },
});

// refs
const container = ref(null);
const pairContainerRefs = ref([] as Ref<HTMLElement | null>[]);
const truePairs = ref([] as PairOfTermsItemPair[]);
const currentData = ref([] as PairOfTermsItemPair[]);
const solvedPairs = ref([] as PairOfTermsItemPair[]);
const localTitle = ref(props.contentItem.title);

// state
const prompt = ref('');
const isLoading = ref(false);
const isSubmitting = ref(false);
const isEditing = ref(props.allowEdit);
const maximizedHeight = ref(500);
const selectedItem1Index = ref<number | null>(null);
const selectedItem2Index = ref<number | null>(null);
const pairBeingMoved = ref<number | null>(null);

const showItem1ResultsAtIndex = ref<number[]>([]);
const showItem2ResultsAtIndex = ref<number[]>([]);
const item1WasSolvedBeforeAtIndex = ref<number[]>([]);
const item2WasSolvedBeforeAtIndex = ref<number[]>([]);
const item1CorrectAtIndex = ref<number[]>([]);
const item2CorrectAtIndex = ref<number[]>([]);
const firstSelectedItem1 = ref<boolean>(false);

const checkCurrentPair = () => {
  if (selectedItem1Index.value === null || selectedItem2Index.value === null) {
    return false;
  }

  // If both items are selected, check if they form a correct pair
  const item1Index = selectedItem1Index.value;
  const item2Index = selectedItem2Index.value;

  // Check if the selected items form a correct pair
  const item1 = currentData.value[item1Index].pair_of_terms_items[0];
  const item2 = currentData.value[item2Index].pair_of_terms_items[1];

  // Check if this specific pair is correct
  const isCorrectPair = truePairs.value.some((truePair) => {
    const [truePairItem1, truePairItem2] = truePair.pair_of_terms_items;

    let isMatch = false;
    if (item1.media_item_id || item2.media_item_id) {
      isMatch =
        (item1.id === truePairItem1.id && item2.id === truePairItem2.id) ||
        (item1.id === truePairItem2.id && item2.id === truePairItem1.id);
    } else {
      isMatch =
        (item1.content === truePairItem1.content && item2.content === truePairItem2.content) ||
        (item1.content === truePairItem2.content && item2.content === truePairItem1.content);
    }
    console.log('truePair', truePair);
    console.log('isMatch', isMatch);
    return isMatch;
  });
  return isCorrectPair;
};

const moveItems = async (index1: number, index2: number) => {
  if (index1 === -1 || index2 === -1 || index1 === null || index2 === null) return;
  // Wait for pop-out animation
  await new Promise((resolve) => setTimeout(resolve, 550));

  // add items to solvedPairs
  solvedPairs.value.push({
    id: uuidv4(),
    pair_of_terms_items: [
      currentData.value[index1].pair_of_terms_items[0],
      currentData.value[index2].pair_of_terms_items[1],
    ],
  });
};

const handleItemClick = async (isItem1: boolean, index: number) => {
  // Start interaction if not already started
  courseInteractionStore.startSectionContentItemIfNotAlready(props.contentItem.id).then((response) => {
    if (!response) return;
    itemInteractionState.value = response.section_content_item_interaction;
  });

  console.log('handleItemClick', isItem1, index);

  if (isItem1) {
    // If clicking on an item1
    selectedItem1Index.value = index;
    firstSelectedItem1.value = selectedItem2Index.value === null;
  } else {
    // If clicking on an item2
    selectedItem2Index.value = index;
  }

  if (selectedItem1Index.value === null || selectedItem2Index.value === null) {
    return;
  }

  const isCorrectPair = checkCurrentPair();
  console.log('isCorrectPair', isCorrectPair);

  showItem1ResultsAtIndex.value.push(selectedItem1Index.value);
  showItem2ResultsAtIndex.value.push(selectedItem2Index.value);

  // If correct, swap the items
  if (isCorrectPair) {
    if (item1CorrectAtIndex.value.length > 0) {
      item1WasSolvedBeforeAtIndex.value.push(item1CorrectAtIndex.value.pop());
    }
    if (item2CorrectAtIndex.value.length > 0) {
      item2WasSolvedBeforeAtIndex.value.push(item2CorrectAtIndex.value.pop());
    }

    item1CorrectAtIndex.value.push(selectedItem1Index.value);
    item2CorrectAtIndex.value.push(selectedItem2Index.value);

    setTimeout(() => {
      // automatically move highlighted correct pair to background afer 1.5 s
      if (item1CorrectAtIndex.value.length > 0) {
        item1WasSolvedBeforeAtIndex.value.push(item1CorrectAtIndex.value.pop());
      }
      if (item2CorrectAtIndex.value.length > 0) {
        item2WasSolvedBeforeAtIndex.value.push(item2CorrectAtIndex.value.pop());
      }
    }, 500);

    // Animate and move correct pair
    let index1 = selectedItem1Index.value;
    let index2 = selectedItem2Index.value;
    setTimeout(() => {
      moveItems(index1, index2);
    }, 800);
  } else {
    // Reset the results display after a short delay
    setTimeout(() => {
      showItem1ResultsAtIndex.value.pop();
      showItem2ResultsAtIndex.value.pop();
    }, 1000);
  }

  resetSelection();
};

const resetSelection = () => {
  selectedItem1Index.value = null;
  selectedItem2Index.value = null;
  firstSelectedItem1.value = false;
};

const checkIfAllPairsAreCorrect = async () => {
  if (
    item1CorrectAtIndex.value.length + item1WasSolvedBeforeAtIndex.value.length !== currentData.value.length ||
    item2CorrectAtIndex.value.length + item2WasSolvedBeforeAtIndex.value.length !== currentData.value.length
  ) {
    return;
  }

  await handleExerciseFinished();
};

watch(
  () => item1CorrectAtIndex.value,
  async () => {
    await checkIfAllPairsAreCorrect();
  },
  { deep: true },
);

const addPair = () => {
  isSubmitting.value = true;
  currentData.value.push({
    id: uuidv4(),
    pair_of_terms_items: [
      {
        id: uuidv4(),
        content: '',
        description: null,
        media_item_id: null,
      },
      {
        id: uuidv4(),
        content: '',
        description: null,
        media_item_id: null,
      },
    ],
  });
  isSubmitting.value = false;
};

const deletePair = (index: number) => {
  isSubmitting.value = true;
  currentData.value.splice(index, 1);
  isSubmitting.value = false;
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const shuffle = () => {
  // Extract all items from both columns
  const firstItems = currentData.value.map((pair) => pair.pair_of_terms_items[0]);
  const secondItems = currentData.value.map((pair) => pair.pair_of_terms_items[1]);

  // Shuffle both arrays independently
  shuffleArray(firstItems);
  shuffleArray(secondItems);

  // Reassign the shuffled items back to the pairs
  for (let i = 0; i < currentData.value.length; i++) {
    currentData.value[i].pair_of_terms_items[0] = firstItems[i];
    currentData.value[i].pair_of_terms_items[1] = secondItems[i];
  }
};

watch(
  () => currentData.value?.length,
  () => {
    currentData.value.forEach((pair: any) => {
      pairContainerRefs.value.push(ref<HTMLElement | null>(null));
    });
  },
);

const initData = () => {
  // currentData.value = JSON.parse(JSON.stringify(props.pairOfTermsGameTuple));
  if (!props.contentItem?.pair_of_terms_game?.pair_of_terms_item_tuples) return;
  let tuples = props.contentItem.pair_of_terms_game.pair_of_terms_item_tuples;
  currentData.value = JSON.parse(JSON.stringify(tuples));

  prompt.value = props.contentItem.task_instructions;

  // currentData.value = props.contentItem.pair_of_terms_game.pair_of_terms_item_tuples;
  truePairs.value = JSON.parse(JSON.stringify(tuples));

  tuples.forEach((pair: any) => {
    pairContainerRefs.value.push(ref<HTMLElement | null>(null));
  });

  if (!props.allowEdit) {
    shuffle();
  } else if (tuples.length === 0) {
    addPair();
  }
};

onMounted(async () => {
  initData();

  document.addEventListener('click', handleClickOutside);

  console.log('fetchCompleted', fetchCompleted.value);
  if (props.allowEdit) fetchCompleted.value = true;
  console.log('fetchCompleted', fetchCompleted.value);

  if (!props.allowEdit) {
    await fetchInteractionState();
  }
});

onBeforeUnmount(async () => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event: Event) => {
  console.log('click outside');
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

defineExpose({
  maximizedHeight,
});
</script>

<template>
  <div
    ref="container"
    class="w-full p-2 overflow-hidden relative"
    :class="{
      'h-fit': isMaximized,
      'h-[295px]': !isMaximized,
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
  >
    <div
      :class="{
        'opacity-75':
          !props.allowEdit &&
          (!fetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <Title
        :iconComponent="ChevronsLeftRightEllipsis"
        headline="Paare bilden"
        :isEditing="props.allowEdit"
        v-model:title="localTitle"
        @change="unsavedChanges = true"
        class="px-2"
      />

      <div>
        <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4 py-2" v-show="isEditing">
          <span translate="no" class="material-symbols-outlined notranslate text-2xl select-none">info</span>
          <h3 class="text-sm text-justify">
            Lege Paare in der korrekten Zuordnung an. Dem User wird eine zufällige Sortierung angezeigt.
          </h3>
        </div>
        <h2 class="px-2 py-4 w-full rounded-lg text-start" :class="{ 'border border-gray-200 shadow-sm': isEditing }">
          <span v-if="!isEditing" class="text-base">{{ prompt }}</span>
          <textarea
            v-if="isEditing"
            v-model="prompt"
            @input="unsavedChanges = true"
            placeholder="Aufgabenstellung, die dem User angezeigt werden soll, z.B. 'Ordne die Begriffe den Definitionen zu.'"
            class="w-full font-normal text-xs md:text-sm text-center border-gray-200 rounded-lg resize-none min-w-2"
          />
        </h2>
      </div>

      <div class="w-full gap-y-1 md:gap-y-4 relative">
        <!-- these are the unsolved pairs -->
        <div v-for="(pair, index) in currentData" :key="pair.id">
          <transition name="pair-collapse" tag="div">
            <div
              :ref="pairContainerRefs[index]"
              v-if="!(item1WasSolvedBeforeAtIndex.includes(index) && item2WasSolvedBeforeAtIndex.includes(index))"
            >
              <CardPair
                :items="pair.pair_of_terms_items"
                :contentItemId="props.contentItem.id"
                :isEditing="isEditing"
                :isItem1Correct="item1CorrectAtIndex.includes(index)"
                :isItem2Correct="item2CorrectAtIndex.includes(index)"
                :showItem1Results="showItem1ResultsAtIndex.includes(index)"
                :showItem2Results="showItem2ResultsAtIndex.includes(index)"
                :item1Selected="selectedItem1Index === index"
                :item2Selected="selectedItem2Index === index"
                :item1WasSolvedBefore="item1WasSolvedBeforeAtIndex.includes(index)"
                :item2WasSolvedBefore="item2WasSolvedBeforeAtIndex.includes(index)"
                :showConnectingLine="false"
                @isLoading="isLoading = $event"
                @itemClicked="(isItem1) => handleItemClick(isItem1, index)"
                @inputItem1="
                  (newItem) => {
                    unsavedChanges = true;
                    pair.pair_of_terms_items[0] = newItem;
                  }
                "
                @inputItem2="
                  (newItem) => {
                    unsavedChanges = true;
                    pair.pair_of_terms_items[1] = newItem;
                  }
                "
                @delete="
                  () => {
                    unsavedChanges = true;
                    deletePair(index);
                  }
                "
                @scrollBy="(distance) => emit('scrollBy', distance)"
              />
            </div>
          </transition>
        </div>
        <!-- end of unsolved pairs -->

        <!-- these are the solved pairs -->
        <transition-group name="pair-collapse" tag="div">
          <div class="" v-for="(pair, index) in solvedPairs" :key="pair.id">
            <div :ref="pairContainerRefs[index]">
              <CardPair
                :items="pair.pair_of_terms_items"
                :contentItemId="props.contentItem.id"
                :isEditing="false"
                :isItem1Correct="true"
                :isItem2Correct="true"
                :showItem1Results="true"
                :showItem2Results="true"
                :showConnectingLine="true"
              />
            </div>
          </div>
        </transition-group>
        <!-- end of solved pairs -->
      </div>

      <!-- add pair button (when editing) -->
      <div class="w-fit items-center mx-auto">
        <div class="pt-1 mx-auto justify-center flex">
          <ProgressButton
            v-show="isEditing"
            :disabled="isSubmitting || !fetchCompleted"
            @click="
              () => {
                unsavedChanges = true;
                addPair();
              }
            "
            icon="add"
            iconSize="text-2xl"
            :text="'Add pair'"
          />
        </div>
      </div>
      <!-- end of add pair button -->
    </div>
    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!fetchCompleted"
    >
      <LoadingSpinnerLarge color="black" />
    </div>
    <div
      v-if="
        fetchCompleted &&
        !props.allowEdit &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-green text-2xl bg-green-veryLight/20 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-green">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped>
.pair-item {
  transition: all 0.5s ease;
  position: relative;
}

.pair-collapse-enter-active,
.pair-collapse-leave-active {
  transition: all 0.5s ease;
}

.pair-collapse-enter-from,
.pair-collapse-leave-to {
  opacity: 0;
  transform: translateY(-20px);
  max-height: 0;
  overflow: hidden;
}

.pair-collapse-enter-to,
.pair-collapse-leave-from {
  opacity: 1;
  max-height: 300px; /* Adjust based on your card's maximum height */
}
</style>
