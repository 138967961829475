<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import IngameProgressCard from '@/views/home/IngameProgressCard.vue';
import { useAuthStore, useCaseInteractionStore } from '@/stores';
import { router } from '@/router';
import { ChevronRight } from 'lucide-vue-next';
import ProfileImage from '@/components/ProfileImage.vue';

import IconWithUnreadMark from '@/components/IconWithUnreadMark.vue';
import UserDashboardCaseInteractionList from '@/components/user_dashboard/UserDashboardCaseInteractionList.vue';
import UserDashboardCourseInteractionsList from '@/components/user_dashboard/UserDashboardCourseInteractionList.vue';
import { storeToRefs } from 'pinia';
import VocabQuickactionDailyTest from '@/components/quickaction-cards/VocabQuickactionDailyTest.vue';
import BottomNav from '@/components/BottomNav.vue';
import CaseService from '@/services/CaseService';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
import CourseService from '@/services/CourseService';
import AudioRecorderService from '@/services/AudioRecorderService';

const authStore = useAuthStore();
const { dailyCasePlayed } = storeToRefs(authStore);
const caseInteractionStore = useCaseInteractionStore();

const dailyVocabTestPlayed = ref(false);

const fetchCompleted = ref(false);
const showWelcome = ref(true);
const animationClass = ref('fly-in');
const showCompletion = ref(false);
const clickedDailyVocabTest = ref(false);
const completion = ref('');
const possibleCompletions = ['bei casuu ❤️', 'bei casuu \uD83D\uDCAA', 'zurück ❤️', 'zurück 🏋️‍♂️', 'im Spiel 🎮'];

const emit = defineEmits(['takeDailyQuickTest']);

async function handleDailyQuickTest() {
  console.log('UserDashboard: handleDailyQuickTest');
  clickedDailyVocabTest.value = true;
  emit('takeDailyQuickTest');
}

onMounted(async () => {
  await nextTick();
  console.log('UserDashboard mounted');

  // set random completion
  completion.value = possibleCompletions[Math.floor(Math.random() * possibleCompletions.length)];

  setTimeout(() => {
    showCompletion.value = true;
  }, 1200);

  setTimeout(() => {
    animationClass.value = 'fly-in-highlight';
  }, 1400);

  setTimeout(() => {
    animationClass.value = 'fly-out';
  }, 2000);

  // Remove the element after animation ends
  setTimeout(() => {
    showWelcome.value = false;
  }, 2600); // 500ms for fly-in + 500ms hold + 500ms fly-out

  await Promise.all([authStore.fetchUserDetails(), authStore.fetchUserVocabLists()]);

  const audioService = new AudioRecorderService();
  await audioService.requestMicrophonePermissions();

  await caseInteractionStore.loadCaseInteractionsOfUser(true).finally(() => {
    fetchCompleted.value = true;
  });

  // dont await this, it will be done in the background
  CourseService.enrollAvailableCourses();
});
</script>

<template>
  <div class="relative w-full h-full overflow-y-auto bg-gray dark:bg-gray-900">
    <div v-if="fetchCompleted" class="relative w-full mx-auto flex flex-col gap-y-6 py-4 px-4 lg:px-6">
      <!-- Greeting -->
      <div class="flex justify-between items-center">
        <h1 class="text-3xl font-medium dark:text-white">Hi {{ authStore.userFirstName }} 👋</h1>
        <ProfileImage
          :image="authStore.user?.profile_image_small"
          :initials="authStore.user?.initials"
          :showInitials="true"
          :showIngameLevel="false"
          class="w-[48px] h-[48px] border-2 rounded-full border-white dark:border-gray-900 cursor-pointer hover:opacity-80"
          @click="router.push('/profile')"
        />
      </div>

      <!-- Progress Section -->
      <section class="w-full">
        <div class="w-full">
          <IngameProgressCard :forceTop="false" />
        </div>
      </section>

      <!-- Courses Section -->
      <section class="w-full">
        <div
          class="inline-flex justify-end items-center gap-0.5 mb-2 cursor-pointer hover:opacity-80"
          @click="
            router.push({
              name: 'my-courses',
            })
          "
        >
          <h2 class="text-lg font-medium dark:text-white">{{ $t('message.myCourses') }}</h2>
          <ChevronRight size="20" class="dark:text-white" />
        </div>
        <div class="w-full overflow-x-visible">
          <div class="snap-x snap-mandatory flex gap-x-4 -mx-4 px-4">
            <UserDashboardCourseInteractionsList class="snap-center min-w-full" :inline-overflowing-x="true" />
          </div>
        </div>
      </section>

      <!-- Patients Section -->
      <section class="w-full">
        <div
          class="inline-flex justify-end items-center gap-0.5 mb-2 cursor-pointer hover:opacity-80"
          @click="CaseService.navigateToMyCases(authStore.user.id)"
        >
          <h2 class="text-lg font-medium dark:text-white">{{ $t('message.myCases') }}</h2>
          <ChevronRight size="20" class="dark:text-white" />
        </div>
        <UserDashboardCaseInteractionList />
      </section>

      <!-- Vocab Section -->
      <section class="w-full">
        <div
          class="inline-flex justify-end items-center gap-0.5 mb-2 cursor-pointer hover:opacity-80"
          @click="router.push('/vocab-dashboard')"
        >
          <h2 class="text-lg font-medium dark:text-white">{{ $t('message.myVocabulary') }}</h2>
          <ChevronRight size="20" class="dark:text-white" />
        </div>
        <div class="w-full overflow-x-visible">
          <div class="snap-x snap-mandatory flex gap-x-4 -mx-4 px-4 pb-4">
            <VocabQuickactionDailyTest class="snap-center" @takeDailyQuickTest="handleDailyQuickTest" />
          </div>
        </div>
      </section>
    </div>

    <LoadingSpinnerFullScreen :show="!fetchCompleted" :blockClickIfShown="false" :solidBackground="true" />

    <!-- Add bottom padding to prevent content from being hidden behind the nav -->
    <div class="pb-16 lg:pb-0"></div>

    <!-- Bottom Navigation -->
    <BottomNav />
  </div>
</template>

<style scoped>
/* Fly-in animation */
@keyframes fly-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fly-in-highlight {
  0% {
    transform: translateX(0%);
    scale: 100%;
  }
  50% {
    transform: translateX(0%);
    scale: 102%;
  }
  100% {
    transform: translateX(0%);
    scale: 100%;
  }
}

/* Fly-out animation */
@keyframes fly-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Animation classes */
.fly-in {
  animation: fly-in 1s ease-out forwards;
}

.fly-in-highlight {
  animation: fly-in-highlight 0.3s forwards;
}

.fly-out {
  animation: fly-out 0.6s ease-in forwards;
}

@keyframes highlight {
  0% {
    scale: 100%;
  }
  50% {
    scale: 101%;
  }
  100% {
    scale: 100%;
  }
}

.highlight-in {
  animation: highlight 0.5s ease-in-out;
}
</style>
