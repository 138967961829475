<script setup lang="ts">
import { SectionContentItemOverview } from '@/apiclient';
import { defineProps, computed, onMounted, PropType, ref, Ref, nextTick } from 'vue';
import TextItem from './TextItem.vue';

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  germanContentContainers: {
    type: Array as PropType<Ref<HTMLElement | null>[]>,
    required: true,
  },
  textItemIndex: {
    type: Number,
    required: true,
  },
  nativeContainerTranslations: {
    type: Array as PropType<number[]>,
    required: true,
  },
  aboutToDeleteTextItemAtIndex: {
    type: Array as PropType<boolean[]>,
    required: true,
  },
  nativeContainerOuterWidths: {
    type: Array as PropType<number[]>,
    required: true,
  },
  nativeContainerInnerWidths: {
    type: Array as PropType<number[]>,
    required: true,
  },
  translatedContentIfAny: {
    type: Array as PropType<string[]>,
    required: true,
  },
  aboutToDeleteContentItemWithId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits();

const contentEditor = ref<Ref<HTMLElement | null>>();

const textItem = computed(() => props.contentItem.text_item);

const getContentEditor = () => {
  return contentEditor.value;
};

defineExpose({
  getContentEditor,
});
</script>

<template>
  <div
    class="max-h-fit mx-auto h-fit divide-gray-400 justify-between transition-all duration-500 ease-in-out overflow-hidden text-sm"
    :key="!!textItem ? textItem.id : 'invalid'"
    :class="{
      'bg-transparent ': textItem?.designation === 'GENERAL',
      'bg-amber-200 rounded-lg border border-amber-500 px-2 ': textItem?.designation === 'GRAMMAR_BOX',
      'bg-indigo-300 rounded-lg border border-indigo-500 px-2 ': textItem?.designation === 'LEARNING_STRATEGY_BOX',
      'bg-violet-200 rounded-lg border border-violet-500 px-2 ': textItem?.designation === 'CULTURE_BOX',
      'bg-fuchsia-200 rounded-lg border border-fuchsia-500 px-2  ': textItem?.designation === 'CLINICAL_PRACTICE_BOX',
    }"
  >
    <!-- GERMAN -->
    <div
      :ref="props.germanContentContainers[props.textItemIndex]"
      class="col-start-1 row-start-1 overflow-hidden text-justify text-gray-800 dark:text-gray-200 pb-4 relative rounded-lg flex-col flex"
      :class="{
        'border-red-600 bg-red-100': props.aboutToDeleteTextItemAtIndex
          ? props.aboutToDeleteTextItemAtIndex[props.textItemIndex]
          : false,
      }"
    >
      <span v-if="textItem?.designation === 'GRAMMAR_BOX'" class="text-sm uppercase pt-1 pb-2 text-amber-800">
        📚 Grammatik
      </span>
      <span
        v-else-if="textItem?.designation === 'LEARNING_STRATEGY_BOX'"
        class="text-sm uppercase pt-1 pb-2 text-indigo-800"
      >
        🤓 Lern-Tipp
      </span>
      <span v-else-if="textItem?.designation === 'CULTURE_BOX'" class="text-sm uppercase pt-1 pb-2 text-violet-800">
        🇩🇪 Land & Leute
      </span>
      <span
        v-else-if="textItem?.designation === 'CLINICAL_PRACTICE_BOX'"
        class="text-sm uppercase pt-1 pb-2 text-fuchsia-800"
      >
        🏥 Klinik-Tipp
      </span>
      <TextItem
        :ref="contentEditor"
        :contentItem="props.contentItem"
        :sectionId="props.sectionId"
        :sectionIndex="props.sectionIndex"
        :pageIndex="props.pageIndex"
        :isEditing="props.isEditing"
        :idSuffixForTesting="`${props.pageIndex}-${props.index}`"
        @addNewVocab="addNewPersonalVocab(props.contentItem.id)"
      />
    </div>
    <!-- NATIVE -->
    <!-- TODO: unused? -->
    <!-- <div
      class="col-start-1 row-start-1 inline-flex text-justify items-justify text-gray-400 dark:text-gray-200 transition-all duration-500 ease-in-out overflow-hidden"
      :style="{
        height: showNative ? `auto` : `${props.germanContentContainerHeights[props.textItemIndex]}px`,
        transform: `translateX(${props.nativeContainerTranslations[props.textItemIndex]}px)`,
        width: `${
          props.nativeContainerOuterWidths[props.textItemIndex] -
          (props.contentItem.text_item?.designation !== 'GENERAL' ? boxReducedWidth : 0)
        }px`,
        gap: `${gap}px`,
      }"
    >
      <p class="border-s border-s-gray-100"></p>
      <p :style="{ width: `${props.nativeContainerInnerWidths[props.textItemIndex]}px` }">
        <TextEditor :content="props.translatedContentIfAny[props.textItemIndex]" :allowEdit="false" />
      </p>
    </div> -->
  </div>
</template>

<style scoped></style>
