<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { nextTick } from 'vue';
import { debounce } from 'lodash';
import { updateMinTextareaHeight } from '@/helper';
import { v4 as uuidv4 } from 'uuid';
import VocabTestButton from '@/components/didactics/vocab/VocabTestButton.vue';
import { getApiClient } from '@/apiclient/client';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import VocabTestModalNew from '@/components/didactics/vocab/VocabTestModalNew.vue';
import VocabListNew from '@/components/didactics/vocab/VocabListNew.vue';
import { SectionContentItemOverview, VocabItem, VocabItemUpdate } from '@/apiclient';
import { useExerciseLifecycle, useAutosave } from '@/composables';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import { ListChecks } from 'lucide-vue-next';
import Title from '@/components/didactics/title/Title.vue';

const { t } = useI18n();

// stores
const authStore = useAuthStore();
const alertStore = useAlertStore();
const courseStore = useCourseStore();
const courseInteractionStore = useCourseInteractionStore();

// props
const props = defineProps({
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  showNative: {
    type: Boolean,
    default: false,
  },
  allowEditing: {
    type: Boolean,
    default: false,
  },
  vocabListLoading: {
    type: Boolean,
    default: false,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isDeleted: {
    type: Boolean,
    required: false,
    default: false,
  },
  isTransitioning: {
    type: Boolean,
    required: true,
  },
});

// emits
const emit = defineEmits([
  'viewFullHeightOn',
  'viewFullHeightOff',
  'generateAndAddMoreVocab',
  'scrollBy',
  'addNewVocab',
]);

// composables
const { handleExerciseFinished, fetchInteractionState, reopenExercise, itemInteractionState, fetchCompleted } =
  useExerciseLifecycle({
    contentItem: props.contentItem,
    sectionIndex: props.sectionIndex,
    pageIndex: props.pageIndex,
    isEditing: props.allowEditing,
    emit,
    onComplete: (response) => {
      itemInteractionState.value = response.section_content_item_interaction;
    },
    preReopen: () => {
      currentTestSessionId.value = uuidv4();
    },
    onFetched: () => {
      if (exerciseCompleted.value) {
        // show play-again button if fetched completed exercise
        showVocabTestButton.value = true;
      }
    },
  });
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!props.contentItem.meaning_and_making_exercise) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Vokabel-/Redemittelübung - keine Übung', 'Error');
      return;
    }
    if (!props.allowEditing) return;

    console.log('specificTaskToUse.value', specificTaskToUse.value);
    console.log('title.value', title.value);
    console.log('taskInstructions.value', taskInstructions.value);

    await courseStore.updateMeaningAndMakingExercise(props.sectionId, props.contentItem.id, {
      title: title.value,
      task_instructions: taskInstructions.value,
      tasks: specificTaskToUse.value,
    });

    alertStore.success('status.success.changesSaved');
  },
  onSavedBeforeUnmountOrLeave: async () => {
    if (!props.allowEditing || !vocabWithoutAudio.value) return;

    if (!props.contentItem.meaning_and_making_exercise?.vocab_list) {
      alertStore.error(
        'Fehler beim Speichern der Auto-Vervollständigen einer Vokabel-/Redemittelübung - keine Übung oder keine Vokabel-Liste',
        'Error',
      );
      return;
    }

    console.log(
      'Teacher finished editing list ',
      props.contentItem.meaning_and_making_exercise.vocab_list.id,
      ' => sending for audio generation ' +
        '(and extraction of new vocab for generated-from-context list, if any) or fetching audio (if already known)',
    );
    (await getApiClient()).vocabs
      .completeAndSyncVocabListAndGeneratedFromContextVocabList(
        props.contentItem.meaning_and_making_exercise.vocab_list.id,
      )
      .then(() => {})
      .catch((error) => {
        console.error('Error completing vocab items and adding to generated-from-context list', error);
        throw Error('Error completing vocab items and adding to generated-from-context list');
      });
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Vokabel-/Redemittelübung', 'Error', error);
  },
});

// refs
const container = ref(null);
const vocabListRefs = ref([] as Ref<HTMLElement | null>[]); // user's personal lists
const taskInstructionsTextarea = ref(null);
const isContainerVisible = ref(false);

// state
const isSubmitting = ref(false);
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const testFinished = ref(false);
const defineSpecificTask = ref(false);
const specificTaskText = ref('Bilde einen Beispielsatz im Perfekt mit dem Verb');
const title = ref('');
const taskInstructions = ref('');
const vocabTestModalIsOpen = ref(false);
const showVocabTestButton = ref(false);
const currentTestSessionId = ref<string>(uuidv4());

const exerciseCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

// methods, computed, lifecycle hooks
const vocabWithoutAudio = computed(() => {
  return props.contentItem.meaning_and_making_exercise.vocab_list.vocab_items.some(
    (vocabItem: VocabItem) => !vocabItem.audio_url,
  );
});

const specificTaskToUse = computed(() => {
  return defineSpecificTask.value
    ? { MAKE_SPECIFIC_SENTENCE: specificTaskText.value }
    : {
        EXPLAIN: '',
        FIND_TERM: '',
        MAKE_SENTENCE: '',
      };
});

watch(
  () => specificTaskToUse.value,
  (value) => {
    unsavedChanges.value = true;
    if (value) {
      updateMinTextareaHeight(taskInstructionsTextarea.value);
    }
  },
);

onMounted(async () => {
  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  authStore.user.vocab_lists.forEach(() => {
    vocabListRefs.value.push(ref(null));
  });

  console.log(
    'props.contentItem.meaning_and_making_exercise.tasks=',
    props.contentItem.meaning_and_making_exercise.tasks,
  );
  if (!!props.contentItem.meaning_and_making_exercise.tasks.MAKE_SPECIFIC_SENTENCE) {
    defineSpecificTask.value = true;
    specificTaskText.value = props.contentItem.meaning_and_making_exercise.tasks.MAKE_SPECIFIC_SENTENCE;
  }

  title.value = props.contentItem.title;
  taskInstructions.value = props.contentItem.task_instructions;

  await nextTick();
  console.log('VocabExercise mounted');
  await fetchInteractionState();

  const observer = new IntersectionObserver(([entry]) => {
    console.log('IntersectionObserver entry', entry);
    isContainerVisible.value = entry.isIntersecting;
  });

  if (container.value) {
    observer.observe(container.value);
  }
});

const addVocabItem = async () => {
  isSubmitting.value = true;
  await courseStore
    .addVocabItemToContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, {
      term: 'Begriff',
      explanation: '',
      usage_examples: [''],
      grammatical_category: 'NOUN',
      specific_article: 'der',
      plural: 'die Begriffe',
      uncountable_noun: false,
      plural_noun: false,
    })
    .then(() => {
      alertStore.success('Vokabel erfolgreich hinzugefügt');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht hinzugefügt werden', 'Fehler', error);
      console.error(error);
      isSubmitting.value = false;
      throw error;
    });
  isSubmitting.value = false;
};

const deleteVocabItem = async (vocabItemId: string) => {
  isSubmitting.value = true;
  await courseStore
    .deleteVocabItemFromContentItemWithVocabList(props.contentItem.section_id, props.contentItem.id, vocabItemId)
    .then(() => {
      alertStore.success('Vokabel erfolgreich gelöscht');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht gelöscht werden', 'Fehler', error);
      console.error(error);
      isSubmitting.value = false;
      throw error;
    });
  isSubmitting.value = false;
};

const updateVocabItem = async (vocabItemId: string, vocabItemUpdate: VocabItemUpdate) => {
  console.log('updateVocabItem', vocabItemId, vocabItemUpdate);
  await courseStore
    .updateVocabItemInContentItemWithVocabList(props.sectionId, props.contentItem.id, vocabItemId, vocabItemUpdate)
    .then(() => {
      console.log('Vokabel erfolgreich aktualisiert');
    })
    .catch((error) => {
      alertStore.error('Vokabel konnte nicht aktualisiert werden', 'Fehler', error);
      console.error(error);
      throw error;
    });
};

const handleCloseModal = async (finished: boolean) => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (finished) {
    testFinished.value = true;
    // if user doesnt do, set to completed after some secs
    setTimeout(() => {
      handleExerciseFinished();
    }, 8000);
  }
  vocabTestModalIsOpen.value = false; // reenables minimize by click outside shortly after, not directly
};

const takeTest = async () => {
  vocabTestModalIsOpen.value = true;
};
</script>

<template>
  <div
    ref="container"
    class="w-full bg-transparent h-fit px-4 border-none overflow-auto transition-1000 transition-all ease-in-out relative flex-col flex"
  >
    <Title
      :iconComponent="ListChecks"
      headline="Vokabelliste"
      v-model:title="title"
      :isEditing="props.allowEditing"
      @change="unsavedChanges = true"
    />

    <!-- Add task instructions -->
    <h3 v-if="props.allowEditing || taskInstructions !== ''">
      <span v-if="!props.allowEditing" class="text-base">{{ taskInstructions }}</span>
      <textarea
        v-if="props.allowEditing"
        ref="taskInstructionsTextarea"
        v-model="taskInstructions"
        class="w-full text-xs bg-transparent md:text-sm px-2 py-0 mb-2 resize-none border-gray-400 rounded-lg text-start"
        :placeholder="
          defineSpecificTask
            ? 'Erläuterungen zur Aufgabenstellung (optional), z.B. kurze Wiederholung zu Regeln der Perfektbildung'
            : 'Erläuterungen zur Aufgabenstellung (optional)'
        "
        :rows="defineSpecificTask ? 2 : 1"
        @change="unsavedChanges = true"
        @input="
          (event) => {
            updateMinTextareaHeight(event.target);
            taskInstructions = event.target.value;
          }
        "
      />
    </h3>

    <div class="w-full h-full flex-col flex">
      <div class="px-2 inline-flex items-center text-center text-blue-600 gap-x-4" v-show="props.allowEditing">
        <span translate="no" class="material-symbols-outlined pb-32 notranslate text-2xl select-none">info</span>
        <div class="w-full items-start flex-col">
          <h3 class="text-xs md:text-sh text-justify">
            Ergänze und überarbeite die Liste von Begriffen.
            <span>
              Standardmäßig wird die Liste als Vokabelliste angezeigt und mit einer automatisch erzeugten Übung zum
              Lernen der Begriffe versehen.
            </span>
          </h3>
          <span class="text-xs md:text-sh text-start flex py-2">
            Mit "Spezifische Satzbildungs- statt Vokabelübung" kannst du die Liste verwenden, um z.B. eine bestimmte
            grammatikatlische Konstruktion zu üben. Formuliere hierzu eine entsprechen Aufgabenstellung, z.B. "Bilde
            einen Beispielsatz *im Perfekt* mit dem Begriff..."
          </span>
          <span class="text-xs md:text-sh text-start flex pb-8">
            Aus allen Begriffen werden Audio-Dateien zum Anhören für deine Studierenden generiert.
          </span>
        </div>
      </div>
      <div class="text-justify text-xs md:text-sm">
        <!-- Vocab vs grammar exercise -->
        <div class="flex items-center w-full justify-center pb-4" v-if="props.allowEditing">
          <label class="text-sm me-3 dark:text-neutral-400 inline-flex items-center gap-x-2">
            <span class="flex flex-col">
              <span class="text-center">Vokabelübung</span>
            </span>
            <span translate="no" class="material-symbols-outlined no-translate"> dictionary </span>
          </label>
          <input
            type="checkbox"
            id="test-test-test"
            class="relative w-[3.25rem] h-7 p-px bg-gray border border-gray-400 checked:border-blue-400 text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-gray-600 focus:checked:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600 before:inline-block before:size-6 before:bg-whitebe before:bg-gray-400 checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
            :checked="defineSpecificTask"
            @click="defineSpecificTask = !defineSpecificTask"
          />
          <label class="text-sm ms-3 dark:text-neutral-400 inline-flex items-center gap-x-2">
            <span translate="no" class="material-symbols-outlined no-translate"> engineering </span>
            <span class="flex flex-col">
              <span class="text-center">Spezifische Satzbildung</span>
              <span class="text-center">z.B. Grammatik</span>
            </span>
          </label>
        </div>

        <!-- Define the task -->
        <div v-if="defineSpecificTask && props.allowEditing" class="flex items-center w-full justify-center pb-4">
          <input
            v-model="specificTaskText"
            type="text"
            class="w-full max-w-xl px-4 py-2 text-sm border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200"
            placeholder="Aufgabenstellung bezogen auf einen einzelnen Begriff"
            @change="unsavedChanges = true"
          />
        </div>
      </div>

      <div>
        <VocabListNew
          :hide-title="true"
          :vocab-list="props.contentItem.meaning_and_making_exercise.vocab_list"
          :enable-seen-check="!props.allowEditing"
          :all-seen-before="exerciseCompleted"
          :show-searchbar="false"
          :reserve-space-for-button="true"
          :allow-remove-vocab="props.allowEditing"
          :show-trash-as-remove-icon="true"
          :is-editing-full="props.allowEditing"
          :enable-scroll="false"
          @allVocabsSeen="showVocabTestButton = true"
          @deleteItem="deleteVocabItem"
          @updateItem="updateVocabItem"
        />

        <!-- Vocab test button -->
        <div v-if="showVocabTestButton && !props.allowEditing && !props.isTransitioning && isContainerVisible">
          <VocabTestButton
            :testIsFinished="testFinished"
            :exerciseIsCompleted="exerciseCompleted"
            :withBottomNav="true"
            @startVocabTest="takeTest"
            @finishExercise="handleExerciseFinished"
            @repeatVocabTest="takeTest"
          />
        </div>
        <!-- End of vocab test button -->
      </div>
      <div class="p-4 pt-8 gap-x-6 w-full flex items-center justify-center" v-show="allowEditing">
        <ProgressButton
          @click="addVocabItem"
          text="Vokabel hinzufügen"
          :show-progress="isSubmitting"
          :disabled="isSubmitting || vocabListLoading"
          icon="add"
          iconSize="text-2xl"
        />
        <ProgressButton
          @click="emit('generateAndAddMoreVocab')"
          text="Fünf weitere generieren"
          :show-progress="vocabListLoading"
          :disabled="isSubmitting || vocabListLoading"
          icon="ai_sparkle"
          iconSize="text-2xl"
        />
      </div>
    </div>
  </div>

  <VocabTestModalNew
    v-if="vocabTestModalIsOpen"
    :testSessionId="currentTestSessionId || uuidv4()"
    :vocabItems="props.contentItem.meaning_and_making_exercise.vocab_list.vocab_items || []"
    :nItems="props.contentItem.meaning_and_making_exercise.vocab_list.vocab_items.length"
    :mode="specificTaskToUse"
    @closed="handleCloseModal"
  />
</template>

<style scoped></style>
