<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { onBeforeRouteLeave } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';
import ProgressButton from '@/components/ProgressButton.vue';
import SetPhrase from './SetPhrase.vue';
import VocabTestModalNew from '@/components/didactics/vocab/VocabTestModalNew.vue';
import { storeToRefs } from 'pinia';
import { SectionContentItemOverview } from '@/apiclient';
import { useI18n } from 'vue-i18n';
import { useExerciseLifecycle, useAutosave } from '@/composables';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
const { t } = useI18n();

// props
const props = defineProps({
  contentItem: {
    type: Object as PropType<SectionContentItemOverview>,
    required: true,
  },
  boxReducedWidth: {
    type: Number,
    required: true,
  },
  germanContainerWidth: {
    type: Number,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
});

// emits
const emit = defineEmits(['']); // TODO add-new-set-phrase logic once we have added set phrases to Wortchatzkiste

// composables
const { handleExerciseFinished, fetchInteractionState, reopenExercise, itemInteractionState, fetchCompleted } =
  useExerciseLifecycle({
    contentItem: props.contentItem,
    sectionIndex: props.sectionIndex,
    pageIndex: props.pageIndex,
    isEditing: props.isEditing,
    emit,
    onComplete: (response) => {
      itemInteractionState.value = response.section_content_item_interaction;
    },
    postComplete: () => {},
  });
const { unsavedChanges, isSavingChanges, saveChanges } = useAutosave({
  saveFunction: async () => {
    if (!localContent.value) {
      alertStore.error('Fehler beim Speichern der Änderungen einer Redemittelübung - keine Übung', 'Error');
      return;
    }

    await courseStore.updateMeaningAndMakingExercise(props.sectionId, props.contentItem.id, {
      title: localContent.value.title,
      task_instructions: localContent.value.task_instructions,
      tasks: {
        USE_SET_PHRASE: 'Bilden Sie einen Satz mit dem Redemittel',
      },
    });

    alertStore.success('status.success.changesSaved');
  },
  onError: (error) => {
    alertStore.error('Error beim Speichern der Änderungen der Redemittelübung', 'Error', error);
  },
});

// stores
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const courseInteractionStore = useCourseInteractionStore();

const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

// state
const vocabTestModalIsOpen = ref(false);
const localContent = ref(null as SectionContentItemOverview | null);
const testFinished = ref(false);
const isSubmitting = ref(false);
const atLeastOneItemOrTimeout = ref(false);
const waitingForItemTimeout = ref<NodeJS.Timeout | null>(null);

// computed & methods
const exerciseCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

const onlyOneItem = computed(() => {
  return localContent.value?.meaning_and_making_exercise?.set_phrase_list?.set_phrases.length === 1;
});

const atLeastOneItem = computed(() => {
  return localContent.value?.meaning_and_making_exercise?.set_phrase_list?.set_phrases.length > 0;
});

onBeforeMount(async () => {
  localContent.value = props.contentItem;

  if (localContent.value?.meaning_and_making_exercise?.set_phrase_list?.set_phrases.length === 0) {
    await addSetPhrase();
  }
});

onMounted(async () => {
  await fetchInteractionState();
  waitingForItemTimeout.value = setTimeout(() => {
    atLeastOneItemOrTimeout.value = true;
  }, 3000);
});

onBeforeUnmount(() => {
  if (waitingForItemTimeout.value) {
    clearTimeout(waitingForItemTimeout.value);
  }
});

watch(
  () => atLeastOneItem.value,
  (newValue) => {
    if (!newValue) return;
    atLeastOneItemOrTimeout.value = true;
  },
);

const addSetPhrase = async () => {
  console.log('add set phrase');
  try {
    // First save all existing set phrases
    for (const setPhrase of localContent.value?.meaning_and_making_exercise?.set_phrase_list?.set_phrases || []) {
      await courseStore.updateSetPhraseInContentItemWithSetPhraseList(
        props.sectionId,
        props.contentItem.id,
        props.contentItem.meaning_and_making_exercise.set_phrase_list.id,
        setPhrase.id,
        {
          phrase: setPhrase.phrase,
          explanation: setPhrase.explanation,
        },
      );
    }

    // Then add the new set phrase
    await courseStore.addSetPhraseToContentItemWithSetPhraseList(props.sectionId, props.contentItem.id, {
      phrase: '',
      explanation: '',
    });
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to add set phrase', 'Error', error);
    throw new Error('Failed to add set phrase');
  }
};

const deleteSetPhrase = async (setPhraseId: string) => {
  if (!localContent.value) return;
  if (onlyOneItem.value) return;

  console.log('delete set phrase with id', setPhraseId);
  try {
    await courseStore.deleteSetPhraseFromContentItemWithSetPhraseList(
      props.sectionId,
      props.contentItem.id,
      setPhraseId,
      props.contentItem.meaning_and_making_exercise.set_phrase_list.id,
    );
  } catch (error) {
    console.error(error);
    alertStore.error('Failed to delete set phrase', 'Error', error);
    throw new Error('Failed to delete set phrase');
  }
};

const handleCloseModal = async (finished: boolean) => {
  console.log('handleCloseModal', finished);
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (finished) {
    testFinished.value = true;
    // if user doesnt do, set to completed after some secs
    setTimeout(() => {
      handleExerciseFinished();
    }, 8000);
  }
  vocabTestModalIsOpen.value = false; // reenables minimize by click outside shortly after, not directly
};

const setPhrasesAsVocabItems = computed(() => {
  if (!props?.contentItem?.meaning_and_making_exercise?.set_phrase_list?.set_phrases) return [];
  return props.contentItem.meaning_and_making_exercise.set_phrase_list.set_phrases.map((setPhrase: SetPhrase) => ({
    id: setPhrase.id,
    term: setPhrase.phrase,
    remarks: setPhrase.explanation,
  }));
});
</script>

<template>
  <div
    class="relative w-full bg-lime-200 rounded-lg border border-lime-500 px-2 pb-1 text-sm flex-col flex"
    v-if="!!localContent"
  >
    <div v-if="atLeastOneItemOrTimeout">
      <span class="text-sm pt-1 pb-2 text-lime-800 inline-flex text-center items-center">
        <span class="text-center uppercase flex items-center"> 🛠️ Redemittel </span>
        <span class="px-2 text-xl mb-1 flex items-center" v-if="props.isEditing || localContent.title !== ''"> | </span>
        <textarea
          v-if="props.isEditing"
          v-model="localContent.title"
          class="px-2 py-0 flex-grow bg-lime-100 resize-none border-lime-500 rounded-lg text-lime-800 my-auto"
          placeholder="Titel (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
        <span v-else class="text-lime-800 dark:text-neutral-200 font-semibold flex items-center">
          {{ localContent.title }}
        </span>
      </span>

      <span v-if="!props.isEditing && localContent.task_instructions !== ''" class="text-xs md:text-sm pt-2 pb-4">
        {{ localContent.task_instructions }}
      </span>
      <textarea
        v-if="props.isEditing"
        v-model="localContent.task_instructions"
        class="w-full bg-lime-100 text-xs md:text-sm px-2 py-0 mb-2 resize-none text-gray-800 border-lime-500 rounded-lg"
        placeholder="Erläuterungen (zur Gesamtliste; optional)"
        rows="1"
        @change="unsavedChanges = true"
      />
    </div>
    <div v-else>
      <LoadingSpinnerLarge />
    </div>

    <ul class="" v-if="!!localContent.meaning_and_making_exercise?.set_phrase_list">
      <li
        v-for="setPhrase in localContent.meaning_and_making_exercise.set_phrase_list.set_phrases"
        :key="setPhrase.id"
        class="pb-4"
      >
        <div class="flex-col flex w-full -ml-2">
          <SetPhrase
            :key="setPhrase.id"
            :setPhrase="setPhrase"
            :isEditing="props.isEditing"
            :allowDelete="!onlyOneItem"
            :sectionId="props.sectionId"
            :contentItemId="props.contentItem.id"
            :setPhraseListId="props.contentItem.meaning_and_making_exercise.set_phrase_list.id"
            @deleteSetPhrase="deleteSetPhrase"
          />
        </div>
      </li>
    </ul>

    <div class="pt-1 mx-auto justify-center flex">
      <ProgressButton
        v-if="props.isEditing"
        :disabled="isSubmitting"
        @click="
          () => {
            unsavedChanges = true;
            addSetPhrase();
          }
        "
        icon="add"
        iconSize="text-2xl"
        :text="'Redemittel hinzufügen'"
      />
    </div>

    <div v-if="!props.isEditing" class="w-full flex justify-center group">
      <div v-if="!testFinished && !exerciseCompleted" @click="vocabTestModalIsOpen = true">
        <ProgressButton text="Üben" />
      </div>
      <div class="" v-else-if="testFinished && !exerciseCompleted" @click="handleExerciseFinished">
        <div class="block group-hover:hidden">
          <ProgressButton text="Vokabeln speichern" color="teal" icon="circle" iconSize="text-2xl" />
        </div>
        <div class="hidden group-hover:block">
          <ProgressButton
            text="Vokabeln speichern"
            color="teal"
            icon="check_circle"
            iconSize="text-2xl"
            :hovered="true"
          />
        </div>
      </div>
      <div v-else>
        <div class="block group-hover:hidden">
          <button
            type="button"
            class="group select-none py-1.5 px-3 text-xs md:text-sm inline-flex items-center gap-x-0.5 md:gap-x-2 font-medium rounded-lg shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white text-teal-500 bg-teal-200/10 border-gray-200 border-2 cursor-default dark:border-teal-700 dark:bg-teal-900 '"
          >
            <span translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
            {{ 'Fertig' }}
          </button>
        </div>
        <div class="hidden group-hover:block">
          <ProgressButton
            text="Nochmal üben"
            color="blue"
            icon="exercise"
            iconSize="text-2xl"
            @click="vocabTestModalIsOpen = true"
          />
        </div>
      </div>
    </div>

    <!-- <div class="absolute bottom-0.5 end-3">
      <TextFinishedButton
        v-if="!props.isEditing"
        :contentItemId="contentItem.id"
        :sectionIndex="<number>props.sectionIndex"
        :pageIndex="<number>props.pageIndex"
      />
    </div> -->
    <VocabTestModalNew
      v-if="vocabTestModalIsOpen"
      :overlayId="`set-phrase-test-modal-${uuidv4()}`"
      :vocabItems="setPhrasesAsVocabItems || []"
      :nItems="5"
      :mode="
        props.contentItem?.meaning_and_making_exercise?.tasks || {
          USE_SET_PHRASE: 'Bilden Sie einen Satz mit dem Redemittel',
        }
      "
      @closed="handleCloseModal"
    />
  </div>
</template>

<style scoped></style>
