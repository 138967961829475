<script setup lang="ts">
import { Circle, TableOfContents, ArrowRight, Play, Book, BookOpen, CircleCheck, Edit, Check } from 'lucide-vue-next';
import ResumeIcon from '@/components/various/ResumeIcon.vue';
import type { CourseOverview, CourseInteractionWithProgress } from '@/apiclient';
import { computed, PropType } from 'vue';
import ProgressBar from '@/components/various/ProgressBar.vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import CourseService from '@/services/CourseService';
import LastCompletedAndNextUp from '@/components/various/LastCompletedAndNextUp.vue';

const props = defineProps({
  type: {
    type: String as PropType<'library' | 'my-courses'>,
    default: 'library',
  },
  courseOverview: {
    type: Object as PropType<CourseOverview>,
    required: true,
  },
  courseInteraction: {
    type: Object as PropType<CourseInteractionWithProgress>,
    required: false,
  },
});

const { t } = useI18n();
const authStore = useAuthStore();
const { isAdmin: userIsAdmin } = storeToRefs(authStore);

const emit = defineEmits(['resume', 'navigateToCourse', 'navigateToEditCourse']);

const onResume = () => {
  emit('resume', props.courseOverview.id);
};

const onNavigateToCourse = () => {
  emit('navigateToCourse', props.courseOverview.id);
};

const onNavigateToEditCourse = () => {
  emit('navigateToEditCourse', props.courseOverview.id);
};

const hasLogo = computed(() => {
  return false;
});

const isOwnerOrEditorOrAdmin = computed(() => {
  return CourseService.isOwnerOrEditor(props.courseOverview, authStore.user.id) || userIsAdmin.value;
});

const progress = computed(() => {
  if (!isEnrolled.value) return 0;
  if (!props.courseInteraction?.progress) return 0;
  if (
    props.courseInteraction.progress.n_items_completed == null ||
    props.courseInteraction.progress.n_items_total == null
  )
    return 0;
  if (props.courseInteraction.progress.n_items_total === 0) return 0;
  return (props.courseInteraction.progress.n_items_completed / props.courseInteraction.progress.n_items_total) * 100;
});

const isEnrolled = computed(() => {
  return !!props.courseInteraction;
});

const currentChapterNumber = computed(() => {
  if (!props.courseInteraction?.progress) return null;
  if (props.courseInteraction.progress.current_chapter_index == null) return null;
  return props.courseInteraction.progress.current_chapter_index + 1;
});

const lastSectionNumber = computed(() => {
  if (!props.courseInteraction?.progress) return null;
  if (props.courseInteraction.progress.last_section_index == null) return null;
  return props.courseInteraction.progress.last_section_index + 1;
});

const currentSectionNumber = computed(() => {
  if (!props.courseInteraction?.progress) return null;
  if (props.courseInteraction.progress.current_section_index == null) return null;
  return props.courseInteraction.progress.current_section_index + 1;
});

const nextSectionNumber = computed(() => {
  if (!props.courseInteraction?.progress) return null;
  if (props.courseInteraction.progress.next_section_index == null) return null;
  return props.courseInteraction.progress.next_section_index + 1;
});
</script>

<template>
  <div class="flex flex-col bg-gray-light-bg rounded-lg p-4 gap-y-4 cursor-pointer" @click="onNavigateToCourse">
    <!-- header -->
    <div class="flex flex-row items-center gap-x-2 text-xs text-gray-text">
      <div class="w-9 h-9 bg-gray-border rounded-full flex-shrink-0" v-if="hasLogo">
        <!-- TODO: add logo in backend -->
      </div>
      <div
        class="w-9 h-9 bg-gray-border flex items-center justify-center rounded-full flex-shrink-0"
        v-else-if="progress > 0 && progress < 100"
      >
        <BookOpen />
      </div>
      <div class="w-9 h-9 bg-gray-border flex items-center justify-center rounded-full flex-shrink-0" v-else>
        <Book />
      </div>
      <div class="min-w-0 ml-2 inline-flex" v-if="'course_user_editorships' in courseOverview">
        <p class="font-medium">{{ $t('message.courseBy') }}&nbsp;</p>
        <p class="font-medium truncate">
          {{ courseOverview.user?.first_name }} {{ courseOverview.user?.last_name
          }}{{ courseOverview.course_user_editorships.length > 0 ? ', ' : '' }}
          {{
            courseOverview.course_user_editorships
              .map((editorship) => `${editorship.user.first_name} ${editorship.user.last_name}`)
              .join(', ')
          }}
        </p>
      </div>
    </div>
    <!-- end header -->

    <!-- progress bar -->
    <ProgressBar
      v-if="isEnrolled"
      :progress="progress"
      :label="t('course.overallProgress')"
      background-color="bg-gray-border"
    />
    <!-- end progress bar -->

    <!-- course info -->
    <div class="flex flex-col gap-y-2">
      <h2 class="text-2xl font-semibold inline-flex items-center gap-x-2">
        {{ courseOverview.title }}
        <span
          v-if="isOwnerOrEditorOrAdmin"
          class="text-xs font-medium text-gray-text"
          @click.stop="onNavigateToEditCourse"
        >
          <Edit class="w-4 h-4" />
        </span>
      </h2>

      <p class="text-xs font-medium text-gray-text">
        {{ courseOverview.description }}
      </p>
    </div>

    <div class="flex flex-row items-center gap-x-2">
      <TableOfContents class="w-4 h-4" />
      <p class="text-xs font-medium">{{ courseOverview.n_chapters }} Kapitel</p>
      <span class="text-xs font-medium">·</span>
      <p class="text-xs font-medium">{{ courseOverview.n_sections }} Einheiten</p>
    </div>
    <!-- end course info -->

    <hr class="h-[1px] bg-gray-border border-0 mt-auto" v-if="isEnrolled" />

    <!-- course chapters -->
    <div class="flex flex-row items-center justify-between gap-x-2" v-if="isEnrolled">
      <LastCompletedAndNextUp
        :parentNumbering="currentChapterNumber"
        :lastCompleted="{
          number: lastSectionNumber,
          title: courseInteraction?.progress?.last_section_title,
        }"
        :current="{
          number: currentSectionNumber,
          title: courseInteraction?.progress?.current_section_title,
        }"
      />

      <!-- <div class="flex flex-row gap-x-2 hidden" v-if="currentSectionNumber !== null">
        <Circle class="w-4 h-4 shrink-0" v-if="nextSectionNumber" />
        <CircleCheck class="w-4 h-4 shrink-0" v-else />
        <div class="text-xs font-medium">
          <p>
            <span v-if="nextSectionNumber !== null"
              >{{ String(currentChapterNumber).padStart(2, '0') }}-{{
                String(nextSectionNumber).padStart(1, '0')
              }}</span
            >
            {{ courseInteraction?.progress?.next_section_title || 'Kapitel abschließen' }}
          </p>
        </div>
      </div> -->
    </div>
    <!-- end course chapters -->

    <!-- resume -->
    <div class="flex flex-row items-center gap-x-2" v-if="isEnrolled">
      <button
        v-if="courseInteraction && CourseService.hasProgress(courseInteraction)"
        class="flex items-center text-sm font-semibold rounded-full bg-white py-2 px-3 cursor-pointer"
        @click.stop="onResume"
      >
        <ResumeIcon color="black" :size="16" />
        {{ $t('button.resumeCourseShort') }}
      </button>
      <button
        v-else
        class="flex text-sm font-semibold rounded-full bg-white py-2 px-3 items-center cursor-pointer"
        @click.stop="onResume"
      >
        <Play class="w-5 h-5 mr-1" fill="black" />
        {{ $t('button.beginCourseShort') }}
      </button>
      <div class="text-sm font-medium pl-2" v-if="courseInteraction?.progress?.mins_left_in_current_section">
        {{ $t('message.minutesLeft', courseInteraction.progress.mins_left_in_current_section) }}
      </div>
      <div
        class="ml-auto text-sm font-medium whitespace-nowrap"
        v-if="courseInteraction?.progress?.xp_left_in_current_section !== null"
      >
        + {{ courseInteraction?.progress?.xp_left_in_current_section }} XP
      </div>
    </div>
    <!-- end resume -->
  </div>
</template>
