import { ref, watch, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useAlertStore } from '@/stores';

interface AutosaveOptions {
  saveFunction: () => Promise<void>;
  debounceMs?: number;
  beforeUnloadCheck?: boolean;
  beforeUnmountCheck?: boolean;
  routerGuard?: boolean;
  onError?: (error: any) => void;
  onSavedBeforeUnmountOrLeave?: () => Promise<void>;
}

// Keep track of all active save handlers
const activeHandlers: Array<(e: Event) => void> = [];

export function useAutosave({
  saveFunction,
  debounceMs = 1500,
  beforeUnloadCheck = true,
  beforeUnmountCheck = true,
  routerGuard = true,
  onError = (error) => {
    const alertStore = useAlertStore();
    alertStore.error('Autosave failed', 'Error', error as Error);
  },
  onSavedBeforeUnmountOrLeave = async () => {},
}: AutosaveOptions) {
  const unsavedChanges = ref(false);
  const isSavingChanges = ref(false);

  // Handle autosave on changes
  watch(
    () => unsavedChanges.value,
    async (newValue) => {
      if (newValue && !isSavingChanges.value) {
        setTimeout(async () => {
          await executeSave();
        }, debounceMs);
      }
    },
  );

  // Save function wrapper with state management
  const executeSave = async () => {
    if (isSavingChanges.value) return;

    try {
      isSavingChanges.value = true;
      await saveFunction();
      unsavedChanges.value = false;
    } catch (error) {
      onError(error);
      throw error;
    } finally {
      isSavingChanges.value = false;
    }
  };

  // Try to save before browser refresh - unrelibale!
  let handleBeforeUnload: (e: BeforeUnloadEvent) => void;
  if (beforeUnloadCheck) {
    handleBeforeUnload = async (e: BeforeUnloadEvent) => {
      if (unsavedChanges.value) {
        await executeSave();
        await onSavedBeforeUnmountOrLeave();
        e.preventDefault();
        return null;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    activeHandlers.push(handleBeforeUnload);
  }

  if (beforeUnmountCheck || beforeUnloadCheck) {
    onBeforeUnmount(async () => {
      // Try to save one last time before unmounting
      if (unsavedChanges.value && beforeUnmountCheck) {
        await executeSave();
        await onSavedBeforeUnmountOrLeave();
      }

      // Cleanup
      if (beforeUnloadCheck) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        const index = activeHandlers.indexOf(handleBeforeUnload);
        if (index > -1) {
          activeHandlers.splice(index, 1);
        }
      }
    });
  }

  // Handle save before route change
  if (routerGuard) {
    onBeforeRouteLeave(async (to, from, next) => {
      if (unsavedChanges.value) {
        try {
          await executeSave();
          await onSavedBeforeUnmountOrLeave();
          next();
        } catch (error) {
          const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
          if (userWantsToLeave) {
            next();
          } else {
            next(false);
          }
        }
      } else {
        next();
      }
    });
  }

  return {
    unsavedChanges,
    isSavingChanges,
    saveChanges: executeSave,
  };
}
