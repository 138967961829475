<script lang="ts" setup>
import { ref, computed, reactive, onMounted, watch } from 'vue';
import CaseHighlightList from '@/components/cases/CaseHighlightList.vue';
import BottomNav from '@/components/BottomNav.vue';
import { Search, ChevronLeft } from 'lucide-vue-next';
import LoadingSpinnerFullScreen from '@/components/LoadingSpinnerFullScreen.vue';
import { useRouter } from 'vue-router';
import { useCaseStore } from '@/stores';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const caseStore = useCaseStore();
const cases = ref([]);
const tagNames = ref([]);
const specialtyNames = ref([]);
const fetchInPgrogress = ref(false);
const searchTerm = ref('');

const filteredCases = computed(() => {
  if (searchTerm.value === '') {
    return cases.value;
  }
  if (!cases.value) {
    return [];
  }
  let filter = searchTerm.value.trim().toLowerCase();
  return cases.value.filter((case_) => {
    return (
      JSON.stringify(case_.details).toLowerCase().includes(filter) ||
      JSON.stringify(case_.tags ? case_.tags : {})
        .toLowerCase()
        .includes(filter) ||
      JSON.stringify(case_.specialties ? case_.specialties : {})
        .toLowerCase()
        .includes(filter) ||
      JSON.stringify(case_.patient ? case_.patient.details : [])
        .toLowerCase()
        .includes(filter) ||
      (case_.third_persons ? case_.third_persons : []).some((thirdPerson) => {
        return JSON.stringify(thirdPerson.details).toLowerCase().includes(filter);
      }) ||
      (case_.tasks ? case_.tasks : []).some((task) => {
        return JSON.stringify(task.details).toLowerCase().includes(filter);
      })
    );
  });
});

function selectTags(tags_: object[]) {
  console.debug('selected tags: ' + JSON.stringify(tags_));
  tagNames.value = [];
  tags_.forEach((tag: any) => {
    if (tag.name === '') {
      return;
    }
    tagNames.value.push(tag.name);
  });
}

function selectSpecialties(specialties_: object[]) {
  console.debug('selected specialties: ' + JSON.stringify(specialties_));
  specialtyNames.value = [];
  specialties_.forEach((specialty: any) => {
    if (specialty === '') {
      fetchInPgrogress.value = true;
      return;
    }
    specialtyNames.value.push(specialty);
  });
}

watch([tagNames, specialtyNames], async () => {
  fetchInPgrogress.value = true;
  cases.value = await caseStore.getCasesByTagsAndSpecialties(tagNames.value, specialtyNames.value);
  fetchInPgrogress.value = false;
});

onMounted(async () => {
  fetchInPgrogress.value = true;
  cases.value = await caseStore.listAllCases(true);
  fetchInPgrogress.value = false;

  // reload every minute
  setInterval(async () => {
    if (tagNames.value.length > 0 || specialtyNames.value.length > 0) {
      cases.value = await caseStore.getCasesByTagsAndSpecialties(tagNames.value, specialtyNames.value);
    } else {
      cases.value = await caseStore.listAllCases(true);
    }
    fetchInPgrogress.value = false;
  }, 60000);
});

function goBack() {
  router.push('/users/' + caseStore.userId + '/case-interactions');
}
</script>

<template>
  <LoadingSpinnerFullScreen :show="fetchInPgrogress" :blockClickIfShown="false" :solidBackground="false" />

  <div class="w-full h-full overflow-auto">
    <div class="flex flex-row items-center mx-4 mt-4">
      <button @click="goBack" class="mr-2">
        <ChevronLeft :size="24" />
      </button>
      <h1 class="text-[28px] font-medium">{{ $t('message.allCases') }}</h1>
    </div>

    <header class="sticky top-0 z-20 flex flex-wrap bg-transparent">
      <div class="w-full px-4 py-4">
        <div
          class="flex overflow-hidden items-center gap-x-2 border-none bg-white rounded-full px-4 min-h-[48px] h-[48px]"
        >
          <Search :size="20" />
          <input
            type="text"
            v-model="searchTerm"
            :placeholder="$t('message.filterCases')"
            class="w-full h-12 focus:outline-none focus:ring-0 border-none"
          />
        </div>
      </div>
    </header>

    <main>
      <CaseHighlightList :cases="filteredCases" class="w-full" />
    </main>

    <BottomNav />
  </div>
</template>

<style></style>

<style></style>
