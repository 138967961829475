import { getApiClient } from '@/apiclient/client';
import { CaseInteraction, Evaluation, CaseInteractionDashboard } from '@/views/case-interactions';
import { LoggedInRoot } from '@/views/root';
import { RouteLocationNormalized } from 'vue-router';

export default {
  path: '/case-interactions',
  component: LoggedInRoot,
  children: [
    {
      name: 'case-interaction-main',
      path: '/case-interactions/:caseInteractionId',
      component: CaseInteraction,
      meta: { forceNewRouterKey: 'case-interaction-main' },
      props: (route: { query: { formDataContentItemId: string } }) => ({
        formDataContentItemId: route.query.formDataContentItemId,
      }),
    },
    {
      name: 'case-interaction-evaluation',
      path: '/case-interactions/:caseInteractionId/evaluation',
      component: Evaluation,
      meta: { forceNewRouterKey: 'case-interaction-evaluation' },
    },
    {
      name: 'case-interaction-list',
      path: '/users/:userId/case-interactions',
      component: CaseInteractionDashboard,
      meta: { forceNewRouterKey: 'case-interaction-list' },
    },
    {
      name: 'case-interaction-download',
      path: '/case-interactions/:caseInteractionId/download',
      beforeEnter: async (to: RouteLocationNormalized) => {
        try {
          const response = await (
            await getApiClient()
          ).caseInteractions.downloadCaseInteractionTranscript(to.params.caseInteractionId as string);

          // Create blob from response
          const blob = new Blob([response], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);

          // Trigger download
          const link = document.createElement('a');
          link.href = url;
          link.download = `case_interaction_${to.params.caseInteractionId}.txt`;
          document.body.appendChild(link);
          link.click();

          // Cleanup
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          // Redirect back to the case interaction page
          return { name: 'case-interaction-main', params: { caseInteractionId: to.params.caseInteractionId } };
        } catch (error) {
          console.error('Failed to download transcript:', error);
          // Still redirect back on error
          return { name: 'case-interaction-main', params: { caseInteractionId: to.params.caseInteractionId } };
        }
      },
    },
  ],
};
