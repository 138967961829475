<script setup lang="ts">
import { InteractionMessage } from '@/helper';
import { usePatientInteractionStore } from '@/stores';
import { computed } from 'vue';
import ChatBubbleUser from '@/components/chat_bubbles/ChatBubbleUser.vue';
import ChatBubbleNpc from '@/components/chat_bubbles/ChatBubbleNpc.vue';

const props = defineProps({
  messageIds: {
    type: Array as () => string[],
    default: () => [],
  },
});

const patientInteractionStore = usePatientInteractionStore();

const messages = computed(() => {
  if (!props.messageIds || props.messageIds.length === 0) return [];
  return patientInteractionStore.chatMessages.filter((msg) => props.messageIds.includes(msg.id));
});
</script>

<template>
  <div class="flex flex-col gap-y-2">
    <div v-for="message in messages" :key="message.id">
      <div
        class="flex w-full gap-x-2 ml-auto justify-end items-center"
        :class="{
          'user-message-long':
            (message as InteractionMessage).content['user_message'] &&
            (message as InteractionMessage).content['user_message'].length > 40,
        }"
      >
        <ChatBubbleUser :message="message as InteractionMessage" conversation="PATIENT" />
      </div>

      <div class="pr-16">
        <ChatBubbleNpc :message="message as InteractionMessage" personType="PATIENT" :reserve-space-for-toggle="true" />
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Vertical layout for long messages */
.user-message-long .button-container {
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  height: auto;
}
</style>
