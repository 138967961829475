<script lang="ts" setup>
import { VocabItem } from '@/apiclient';
import { useI18n } from 'vue-i18n';
import { grammaticalCategories, articles, nounTypes } from './typing';
import { displayGrammaticalCategory, isVerbType } from './helper';

const { t } = useI18n();

const editingItem = defineModel<VocabItem>('editingItem');
const emit = defineEmits(['unsavedChanges']);

const getNounType = (item: VocabItem) => {
  if (item.plural_noun) return 'pluraletantum';
  if (item.uncountable_noun) return 'singularetantum';
  return 'regular';
};

const handleNounTypeChange = (type: string) => {
  if (!editingItem.value) return;

  const nounType = nounTypes.find((t) => t.value === type);
  if (nounType) {
    editingItem.value.plural_noun = nounType.pluralNoun;
    editingItem.value.uncountable_noun = nounType.uncountableNoun;
  }

  if (nounType?.pluralNoun) {
    editingItem.value.plural = 'die ' + editingItem.value.term ?? '';
  }
};
</script>

<template>
  <div class="flex flex-col gap-y-2" v-if="editingItem">
    <select
      v-model="editingItem.grammatical_category"
      class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors appearance-none bg-[url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 20 20\'%3e%3cpath stroke=\'%236b7280\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\' d=\'M6 8l4 4 4-4\'/%3e%3c/svg%3e')] bg-[right_0.5rem_center] bg-no-repeat bg-[length:1.5em_1.5em]"
      :class="{
        'text-gray-500': !editingItem.grammatical_category,
      }"
      @change="emit('unsavedChanges')"
    >
      <option :value="null" disabled>{{ t('grammar.grammaticalCategory', 'Type of word') }}</option>
      <option v-for="category in grammaticalCategories" :key="category" :value="category">
        {{ displayGrammaticalCategory(category) }}
      </option>
    </select>

    <!-- Show article selection only for nouns -->
    <template v-if="editingItem.grammatical_category === 'NOUN'">
      <div class="flex flex-col gap-y-2">
        <div class="flex gap-x-2">
          <select
            v-model="editingItem.specific_article"
            class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors appearance-none bg-[url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 20 20\'%3e%3cpath stroke=\'%236b7280\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\' d=\'M6 8l4 4 4-4\'/%3e%3c/svg%3e')] bg-[right_0.5rem_center] bg-no-repeat bg-[length:1.5em_1.5em]"
            :class="{
              'text-gray-500': !editingItem.specific_article,
            }"
            @change="emit('unsavedChanges')"
          >
            <option :value="null" disabled>{{ t('grammar.specificArticle', 'Specific article') }}</option>
            <option v-for="article in articles" :key="article.value" :value="article.value">
              {{ article.label }}
            </option>
          </select>
        </div>

        <div class="flex gap-x-2">
          <select
            :value="getNounType(editingItem)"
            @change="
              (event) => {
                handleNounTypeChange((event.target as HTMLSelectElement).value);
                emit('unsavedChanges');
              }
            "
            class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors appearance-none bg-[url('data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 20 20\'%3e%3cpath stroke=\'%236b7280\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\' d=\'M6 8l4 4 4-4\'/%3e%3c/svg%3e')] bg-[right_0.5rem_center] bg-no-repeat bg-[length:1.5em_1.5em]"
          >
            <option v-for="type in nounTypes" :key="type.value" :value="type.value">
              {{ type.label }}
            </option>
          </select>
        </div>

        <div v-if="!editingItem.plural_noun && !editingItem.uncountable_noun">
          <input
            v-model="editingItem.plural"
            class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors"
            :placeholder="t('placeholder.plural')"
            @change="emit('unsavedChanges')"
          />
        </div>
      </div>
    </template>

    <!-- Show verb tenses only for verbs -->
    <template v-if="isVerbType(editingItem)">
      <div class="flex gap-x-2">
        <input
          v-model="editingItem.present_tense"
          class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors"
          :placeholder="t('placeholder.present')"
          @change="emit('unsavedChanges')"
        />
        <input
          v-model="editingItem.simple_past_tense"
          class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors"
          :placeholder="t('placeholder.simplePast')"
          @change="emit('unsavedChanges')"
        />
        <input
          v-model="editingItem.present_perfect_tense"
          class="w-full px-3 py-2 bg-gray-50 border border-gray-100 rounded-xl focus:outline-none focus:ring-1 focus:ring-blue-200 focus:border-blue-200 transition-colors"
          :placeholder="t('placeholder.presentPerfect')"
          @change="emit('unsavedChanges')"
        />
      </div>
    </template>
  </div>
</template>
