import {
  ChapterOverview,
  Course,
  CourseOverview,
  CourseUserEditorship,
  EnrollCourseResponse,
  SectionContentItem_Input as SectionContentItem,
} from '@/apiclient';
import { Router } from 'vue-router';
import { CourseInteractionWithProgress } from '@/apiclient';
import { useCourseStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';

type PageGroup = {
  pageIndex: number; // original, non-continuous index
  arrayIndex: number; // continuous index for navigation
  items: SectionContentItem[];
};

export default class CourseService {
  static sectionContentItemsByPage(sectionContentItems: SectionContentItem[]): PageGroup[] {
    if (!sectionContentItems) {
      return [];
    }

    const groupedItems: { [key: number]: SectionContentItem[] } = {};

    sectionContentItems.forEach((item) => {
      if (!groupedItems[item.page_index]) {
        groupedItems[item.page_index] = [];
      }
      groupedItems[item.page_index].push(item);
    });

    return Object.entries(groupedItems).map(([pageIndex, items], arrayIndex) => ({
      pageIndex: parseInt(pageIndex),
      arrayIndex,
      items: items,
    }));
  }

  static displayCotentItemType(item: SectionContentItem): string {
    switch (item.content_type.toUpperCase()) {
      case 'PAGE_HEAD':
        return 'Seitenüberschrift';
      case 'TEXT':
        switch (item.item_designation || 'GENERAL') {
          case 'GRAMMAR_BOX':
            return 'Grammatik-Box';
          case 'LEARNING_STRATEGY_BOX':
            return 'Lernstrategie-Box';
          case 'CULTURE_BOX':
            return 'Kultur-Box';
          case 'CLINICAL_PRACTICE_BOX':
            return 'Klinische Praxis-Box';
          case 'GENERAL':
            return 'Text';
          default:
            return 'Text';
        }
      case 'MEDIA':
        return 'Medien';
      case 'CASE':
        return 'Fallbeispiel';
      case 'FORM_CASE':
        return 'Formular-Fallbeispiel';
      case 'FORM_STRUCTURED_EXERCISE':
        return 'Strukturierte Formular-Übung';
      case 'VOCAB_LIST':
        return 'Vokabelliste';
      case 'PAIR_OF_TERMS_GAME':
        return 'Zuordnungsübung (Paare bilden)';
      case 'CATEGORIZATION_EXERCISE':
        return 'Kategorisierungsübung';
      case 'CLOZE':
        return 'Lückentext';
      case 'RESOURCE_WITH_QUESTIONS':
        return 'Lesematerial oder Audio-Dialog mit Fragen';
      case 'SET_PHRASE_LIST':
        return 'Redemittel-Box';
      default:
        return item.content_type;
    }
  }

  static async resumeCourse(router: Router, courseInteraction: CourseInteractionWithProgress): Promise<void> {
    if (!courseInteraction.course.id) {
      await router.push({ name: 'Courses' });
      return;
    }

    console.debug(
      'Resuming : ' +
        courseInteraction.progress.current_section_id +
        '@ page=' +
        courseInteraction.progress.current_page_index,
    );

    let fallbackSectionId = null;
    if (!CourseService.hasProgress(courseInteraction)) {
      console.log('no progress');
      const courseStore = useCourseStore();
      await courseStore.setCurrentCourse(courseInteraction.course.id);
      const chapters = courseStore.currentCourse?.chapters;
      let sortedChapters = [];
      if (!chapters) {
        sortedChapters = [];
      }
      sortedChapters = [...chapters].sort((a: ChapterOverview, b: ChapterOverview) => a.index - b.index);
      fallbackSectionId = sortedChapters[0].sections[0].id;
    }

    const sectionId = courseInteraction?.progress?.current_section_id || fallbackSectionId;
    const pageIndex = courseInteraction?.progress?.current_page_index || -1;

    await router.push({
      name: 'course-section',
      params: {
        sectionId: sectionId,
      },
      query: {
        page: pageIndex.toString(),
      },
    });
  }

  static hasProgress(courseInteraction: CourseInteractionWithProgress): boolean {
    return (
      !!courseInteraction?.progress &&
      courseInteraction.progress.current_section_id !== null &&
      courseInteraction.progress.current_page_index !== null
    );
  }

  static hasProgressInChapter(courseInteraction: CourseInteractionWithProgress, chapterId: string): boolean {
    return (
      !!courseInteraction?.progress?.content_item_progress_per_chapter &&
      courseInteraction.progress.content_item_progress_per_chapter[chapterId] !== null &&
      courseInteraction.progress.content_item_progress_per_chapter[chapterId].current_page_index !== null
    );
  }

  static isOwnerOrEditor(course: CourseOverview, userId: string): boolean {
    return (
      course?.user?.id === userId ||
      course?.course_user_editorships?.some((editor: CourseUserEditorship) => editor.user.id === userId)
    );
  }

  static async listAvailableCourses(): Promise<CourseOverview[]> {
    const courseStore = useCourseStore();
    const courses = await courseStore.listCourses();
    return courses;
  }

  static async enrollAvailableCourses(): Promise<void> {
    const courses = await CourseService.listAvailableCourses();
    console.log('enrolling courses: ', courses);

    await Promise.all(courses.map((course) => this.enrollCourse(course.id)));
  }

  static async enrollCourse(courseId: string): Promise<EnrollCourseResponse | null> {
    let response = null;
    let courseInteraction = await (await getApiClient()).courses.tryGetCourseInteraction(courseId);
    console.log('courseInteraction', courseInteraction);
    if (!courseInteraction) {
      console.log('enrolling course ' + courseId);
      response = await (await getApiClient()).courses.createCourseInteraction(courseId);
    }
    return response;
  }
}
