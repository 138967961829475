// Generate consistent colors for members by hash of their id
export function getColorForMember(id: string) {
  if (!id) {
    return 'hsl(0, 0%, 50%)';
  }
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;
  return `hsl(${hue}, 70%, 50%)`;
}
