import { computed, onMounted, watch } from 'vue';
import { useOrganizationStore } from '@/stores';
import { storeToRefs } from 'pinia';

interface ManagedOrganizationsOptions {
  handleOrganizationChange: () => void;
}

export function useManagedOrganizations({ handleOrganizationChange }: ManagedOrganizationsOptions) {
  const organizationStore = useOrganizationStore();
  const { currentlyViewedOrganizationId } = storeToRefs(organizationStore);

  watch(
    () => currentlyViewedOrganizationId.value,
    async () => {
      await handleOrganizationChange();
    },
  );

  onMounted(async () => {
    await handleOrganizationChange();
  });

  const hasManagerAccess = computed(() => {
    return organizationStore.orgIsManagedByUser;
  });

  return {
    hasManagerAccess,
  };
}
