/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserVocabListDetails } from '../models/UserVocabListDetails';
import type { VocabItemCreate } from '../models/VocabItemCreate';
import type { VocabListCreate } from '../models/VocabListCreate';
import type { VocabListUpdate } from '../models/VocabListUpdate';
import type { VocabListUpdateResponse } from '../models/VocabListUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VocabListsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get My Vocab Lists
   * Get the current user's vocab lists.
   *
   * If no Wortschatzkiste or favorite list present, create them.
   * @returns UserVocabListDetails Successful Response
   * @throws ApiError
   */
  public getMyVocabLists(): CancelablePromise<Array<UserVocabListDetails>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/vocab-lists/my',
    });
  }

  /**
   * Create Personal Vocab List
   * Add a new personal vocab list for the current user.
   * @param requestBody
   * @returns UserVocabListDetails Successful Response
   * @throws ApiError
   */
  public createPersonalVocabList(requestBody: VocabListCreate): CancelablePromise<UserVocabListDetails> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/vocab-lists/create-my',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Personal Vocab List
   * Update a personal vocab list of the current user.
   * @param vocabListId
   * @param requestBody
   * @returns VocabListUpdateResponse Successful Response
   * @throws ApiError
   */
  public updatePersonalVocabList(
    vocabListId: string,
    requestBody: VocabListUpdate,
  ): CancelablePromise<VocabListUpdateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/vocab-lists/update-my/{vocab_list_id}',
      path: {
        vocab_list_id: vocabListId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Vocab To Personal Vocab List
   * Add a vocab item to a personal vocab list.
   *
   * Add a true copy of the vocab item to the user's complete vocab list.
   * If the vocab list is not the complete vocab list, add an additional
   * reference to that list.
   * @param vocabListId
   * @param requestBody
   * @returns string Successful Response
   * @throws ApiError
   */
  public addVocabToPersonalVocabList(vocabListId: string, requestBody: VocabItemCreate): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/vocab-lists/add-vocab-to-my/{vocab_list_id}',
      path: {
        vocab_list_id: vocabListId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Remove Vocab From Personal Vocab List
   * Remove a vocab item from a personal vocab list.
   * @param vocabListId
   * @param vocabItemId
   * @returns any Successful Response
   * @throws ApiError
   */
  public removeVocabFromPersonalVocabList(vocabListId: string, vocabItemId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/vocab-lists/remove-vocab-from-my/{vocab_list_id}/{vocab_item_id}',
      path: {
        vocab_list_id: vocabListId,
        vocab_item_id: vocabItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Copy Vocab To Personal Vocab List
   * Copy a vocab item to a personal vocab list.
   *
   * If not in some user's vocab list, create a copy.
   *
   * Reason: The original vocab item is not modified when the user works on their copy if the user added an external vocab to
   * his or her personal lists.
   * In detail: The true copy is always added to the user's complete vocab list.
   * If different the target vocab list is different from the user's complete vocab list, an additional reference is added to the target vocab list.
   * @param vocabListId
   * @param vocabItemId
   * @returns UserVocabListDetails Successful Response
   * @throws ApiError
   */
  public copyVocabToPersonalVocabList(
    vocabListId: string,
    vocabItemId: string,
  ): CancelablePromise<UserVocabListDetails> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/vocab-lists/copy-vocab-to-my/{vocab_list_id}',
      path: {
        vocab_list_id: vocabListId,
      },
      query: {
        vocab_item_id: vocabItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
