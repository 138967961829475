<script setup lang="ts">
import PatientCarousel from '@/components/cases/CaseCarousel.vue';
import { nextTick, onMounted, reactive, ref } from 'vue';
import { useCaseStore } from '@/stores';
import MainHeader from '@/components/headers/MainHeader.vue';
import SocialIcon from '@/views/about-us/SocialIcon.vue';
import PartnerCarousel from '@/views/landing/PartnerCarousel.vue';
import BlogpostCarousel from '@/views/landing/BlogpostCarousel.vue';
import DarkModeToggle from '@/components/DarkModeToggle.vue';
import { ChevronRight } from 'lucide-vue-next';
import { mountState } from '@/helper';
import PublicFooter from '@/views/landing/PublicFooter.vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import type { Splide } from '@splidejs/splide';

const partnerCarousel = ref(null);
const blogpostCarousel = ref(null);
const isLoadingCases = ref(true);
const isLoadingCarousels = ref(true);
const skipWaitingForPageMount = ref(false);
const caseStore = useCaseStore();
const completeHeading = ref('casuu');
const cases = ref([] as object[]);
const headingCompletions = ['casual', 'case-based', 'casuu'];

onMounted(async () => {
  // heading completions
  let i = 0;
  setInterval(() => {
    completeHeading.value = headingCompletions[i];
    i++;
    if (i === headingCompletions.length) {
      i = 0;
    }
  }, 2000);

  setTimeout(() => {
    skipWaitingForPageMount.value = true;
  }, 1200);

  // cases.value = await caseStore.getTrendingCases();
  isLoadingCases.value = false;
});

const carousels = reactive<{
  splide1: Splide | null;
  splide2: Splide | null;
}>({
  splide1: null,
  splide2: null,
});

const onCarouselMounted = (splide: Splide) => {
  if (!carousels.splide1) {
    carousels.splide1 = splide;
  } else if (!carousels.splide2) {
    carousels.splide2 = splide;
  }

  if (carousels.splide1 && carousels.splide2) {
    carousels.splide1.sync(carousels.splide2);
    console.log('Synchronization successful');
    isLoadingCarousels.value = false;
  }
};
</script>

<template>
  <div
    v-show="mountState.portionMounted <= 0.8 && !skipWaitingForPageMount"
    class="flex flex-col h-screen w-screen overflow-y-auto bg-gray-50 dark:bg-neutral-800 text-center items-center px-4 sm:px-6 lg:px-8 pt-29"
  >
    <h1 class="block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200 mt-[68px]">
      Medical & language training<br />
      <div class="bg-clip-text bg-blue-600 text-transparent">
        {{ completeHeading }}
      </div>
    </h1>
    <div class="mt-28">
      <LoadingSpinnerLarge />
    </div>
  </div>
  <div
    v-show="mountState.portionMounted > 0.8 || skipWaitingForPageMount"
    class="flex flex-col h-screen w-full overflow-y-auto overflow-x-hidden bg-gray dark:bg-neutral-800"
  >
    <header
      class="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full border-b border-gray-200 dark:border-none bg-white text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showCommunityLink="true"
        :showRootLink="true"
        :showCoursesDropdown="true"
        :showPatientCaseDropdown="false"
        :showChallengesAndEventsLink="false"
        :showVocabDropdown="false"
        :showAboutUsLink="true"
        :showSignInOrEditProfile="true"
      />
    </header>

    <div
      class="relative flex-grow before:absolute before:top-0 before:left-1/2 before:bg-[url('../svg/component/polygon.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:w-full before:h-full before:-z-[1] before:transform before:-translate-x-1/2 dark:before:bg-[url('../svg/component/polygon-dark.svg')]"
    >
      <div class="max-w-full overflow-x-hidden mx-auto px-4 sm:px-6 lg:px-8 pt-8 lg:pt-16">
        <!-- Title -->

        <div class="mt-5 max-w-3xl text-center mx-auto">
          <h1 class="block font-semibold text-gray-900 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200">
            Medizinisches Berufssprachtraining<br />
            <div class="bg-clip-text bg-blue-600 text-transparent">
              {{ completeHeading }}
            </div>
          </h1>
        </div>
        <!-- End Title -->

        <div class="mt-5 max-w-2xl text-center mx-auto">
          <p class="text-base md:text-lg text-gray-700 dark:text-gray-400 text-center">
            Berufssprache in virtuellen, KI-basierten Simulationen lernen. Mit Ihrer Patientenklientel, Ihren Abläufen,
            Ihren Behandlungsschwerpunkten. Weil Routine Sicherheit gibt.
            <!-- Praxisnahe, virtuelle Trainingsszenarien zur fachsprachlichen Aus- und Weiterbildung für
            Ihre nicht-muttersprachlichen Ärzt:innen und Pflegekräfte - individuell auf Ihre Patientenklientel abgestimmt. -->
          </p>
        </div>
        <!--        <div class="mt-5 max-w-4xl text-center mx-auto">-->
        <!--          <p class="text-lg text-gray-600 dark:text-gray-400 text-center">-->
        <!--            casuu ist-->
        <!--            <a href="/about-us" class="hover:text-gray-400">ein Team aus der Medizin und ärztlich geführt</a> - wir-->
        <!--            kennen Ihre Anforderungen. Sprechen Sie uns an!-->
        <!--          </p>-->
        <!--        </div>-->
        <!-- Buttons -->
        <router-link to="/about-us">
          <div class="mt-5 w-full inline-flex justify-center">
            <a
              class="inline-flex justify-center items-center gap-x-1 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white text-sm font-medium rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
            >
              Jetzt kontaktieren
              <ChevronRight class="w-4 h-4" />
            </a>
          </div>
        </router-link>
        <video
          class="mt-16 mb-3 md:max-w-3xl rounded-2xl md:rounded-3xl mx-auto"
          controls
          controlsList="nodownload"
          oncontextmenu="return false;"
          src="https://assets-prd.data.casuu.health/Kurzvorstellung%20casuu%20v2.mp4"
        />

        <div class="mx-auto text-center items-center mt-12">
          <div class="space-y-5 md:space-y-8 items-center">
            <div class="space-y-5 items-center">
              <h3 class="text-sm font-normal md:text-2xl dark:text-white">Unsere Partner</h3>

              <div class="max-w-xl md:max-w-4xl mx-auto">
                <PartnerCarousel ref="partnerCarousel" @carouselMounted="onCarouselMounted" direction="rtl" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="max-w-3xl flex-col flex items-start sm:items-center text-left sm:text-center mt-24 px-4 lg:mt-32 mx-auto"
        >
          <h2 class="text-3xl font-semibold text-gray-900 dark:text-white max-w-2xl">
            Sichere Kommunikation und Handlungsfähigkeit sind die Grundlagen von
            <a class="text-blue-600">Patientensicherheit und effizienten klinischen Abläufen.</a>
          </h2>
          <p class="text-base md:text-lg text-gray-600 dark:text-gray-700 mt-4 max-w-3xl">
            Und deshalb sammeln Ihre Mitarbeiter:innen praktische Erfahrung im Umgang mit virtuellen Patient:innen. Im
            eigenen Tempo und in geschützter Umgebung. Vor dem ersten Arbeitstag schon im Herkunftsland oder begleitend
            zum Anerkennungsverfahren.
          </p>
          <a href="mailto:ruediger@casuu.care" class="inline-flex items-center mt-4 text-blue-600 hover:text-blue-700">
            <span>Wann und wie casuu eingesetzt wird</span>
            <ChevronRight class="w-4 h-4 ml-1" />
          </a>
        </div>

        <!-- Features Grid -->
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-8 lg:mt-24 px-2 lg:px-12 max-w-7xl mx-auto">
          <!-- Card 1: Patient Cases -->
          <div class="bg-gray-100 dark:bg-neutral-700 rounded-2xl p-8">
            <h2 class="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Sprachliche Routine.
              <span class="text-blue-600">Aktives Sprechen.</span>
            </h2>
            <p class="text-base text-gray-600 dark:text-gray-300">
              Ob Elefant auf der Brust oder Andeutungen zur unzureichenden Versorgung zu Hause - "Patientendeutsch"
              lernt man nur im Gespräch. Und Klinikjargon auch. Und deshalb üben Ihre Mitarbeiter:innen aktives Sprechen
              in hunderten Interatkionen.
            </p>
            <a
              href="mailto:ruediger@casuu.care"
              class="inline-flex items-center mt-4 text-blue-600 hover:text-blue-700"
            >
              <span>Inhalte entdecken</span>
              <ChevronRight class="w-4 h-4 ml-1" />
            </a>
          </div>

          <!-- Card 2: Courses -->
          <div class="bg-gray-100 dark:bg-neutral-700 rounded-2xl p-8">
            <h2 class="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Spezifische Redemittel.
              <span class="text-blue-600">Für jede Situation.</span>
            </h2>
            <p class="text-base text-gray-600 dark:text-gray-300">
              Von Aufnahmegesprächen bis zur Teamkommunikation: Wir vermitteln Ihren Mitarbeiter:innen sprachliche
              Grundlagen und fachliche Standards. Mit klarem Focus auf sichere Kommunikation und sprachliche
              Handlungsfähigkeit.
            </p>
            <a
              href="mailto:ruediger@casuu.care"
              class="inline-flex items-center mt-4 text-blue-600 hover:text-blue-700"
            >
              <span>Inhalte entdecken</span>
              <ChevronRight class="w-4 h-4 ml-1" />
            </a>
          </div>

          <!-- Card 3: Customization -->
          <div class="bg-gray-100 dark:bg-neutral-700 rounded-2xl p-8">
            <h2 class="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Ihre Patientenklientel.
              <span class="text-blue-600">Ihre Formulare.</span>
            </h2>
            <p class="text-base text-gray-600 dark:text-gray-300">
              Ihr Behandlungsschwerpunkt geht mit eigenen Interaktionen und Vokabular einher. Deshalb bilden wir genau
              Ihre Patient:innen und Prozeduren ab. Und integrieren Ihre Dokumentationsbögen und Formulare in das
              Training.
            </p>
            <a
              href="mailto:ruediger@casuu.care"
              class="inline-flex items-center mt-4 text-blue-600 hover:text-blue-700"
            >
              <span>Training individualisieren</span>
              <ChevronRight class="w-4 h-4 ml-1" />
            </a>
          </div>
        </div>

        <!-- <div class="max-w-xl md:max-w-4xl mx-auto mt-12">
          <PatientCarousel v-if="!isLoadingCases" :cases="cases" />
        </div> -->

        <div class="mx-auto text-center mt-24 lg:mt-32" v-show="!isLoadingCarousels">
          <!-- Content -->
          <div class="space-y-5 md:space-y-8">
            <div class="space-y-5">
              <h2 class="text-2xl font-bold md:text-3xl dark:text-white">
                Hinter den <span class="text-blue-600">Kulissen</span>
              </h2>

              <div class="max-w-xl md:max-w-4xl mx-auto pt-4 md:pb-16">
                <BlogpostCarousel ref="blogpostCarousel" @carouselMounted="onCarouselMounted" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PublicFooter />
  </div>
</template>
